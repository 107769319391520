import { AxiosResponse } from "axios"

import {
  FeedbackDTO,
  FeedbackData,
  FeedbackPromptData,
} from "@framework/types/feedback"

import HttpService from "./http.service"

export interface GetPromptResponse {
  data: FeedbackPromptData
}

export interface SendFeedbackResponse {
  data: FeedbackData
}

class FeedbackApi extends HttpService {
  getPrompt = (): Promise<AxiosResponse<GetPromptResponse>> =>
    this.get("ts/user/feedback/prompt")

  sendFeedback = (
    payload: FeedbackDTO
  ): Promise<AxiosResponse<SendFeedbackResponse>> =>
    this.post("ts/user/feedback/submit", payload)

  ignore = (): Promise<AxiosResponse> =>
    this.post("ts/user/feedback/ignore", {})
}

export default new FeedbackApi()
