/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from "react"

import { AnswerItem } from "@framework/types/search"
import { useStore } from "@store/index"
import { parseDataString } from "@utils/textUtils"
import {
  ImageAnswerSection,
  TableAnswerSection,
} from "@components/prototypes/ResultsCard/AnswerSection/AnswerSection"
import ResultsCard from "@components/prototypes/ResultsCard/ResultsCard"

import SearchAnswerControls from "../AnswerControl/SearchAnswerControls"
import usePassageChips from "./usePassageChips"

export interface TableResultsCardProps {
  id: string
  answer: AnswerItem<"discard" | "quants">
  question: string
  color?: "primary" | "transparent"
  className?: string
  onTitleClick?: React.MouseEventHandler
  onReadMoreClick?: React.MouseEventHandler
}

export const TableResultsCard: React.FC<TableResultsCardProps> = ({
  id,
  answer: answerItem,
  question,
  color,
  className,
  onTitleClick,
}) => {
  const { restrictionsStore: access } = useStore()

  const answer = answerItem.value

  const chips = usePassageChips(answerItem)

  const tableData: string[][] = useMemo(
    () => (answer.value ? parseDataString(answer.value) : [[]]),
    [answer]
  )

  const imageView = access.tableViewResultIsImage && answer.image_url

  return (
    <ResultsCard
      id={id}
      color={color}
      chips={chips}
      type={imageView ? "vertical" : "horizontal"}
      className={className}
      answerSocket={
        imageView ? (
          <ImageAnswerSection
            title={answer.source_name}
            onTitleClick={onTitleClick}
            content={answer.image_url}
          />
        ) : (
          <TableAnswerSection
            title={answer.source_name}
            onTitleClick={onTitleClick}
            content={tableData}
          />
        )
      }
      controllers={
        <SearchAnswerControls
          question={question}
          answer={answerItem}
          type="Table"
        />
      }
      key={id}
    />
  )
}

export default TableResultsCard
