import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { SimpleInviteUserFormType } from "@framework/types/auth"
import MultiStep from "@components/ui/MultiStep/MultiStep"
import InviteUserEmail from "@pages/auth/components/InviteUserForm/InviteUserEmail"
import InviteUserRoles from "@pages/auth/components/InviteUserForm/InviteUserRoles"
import InviteUserAvatars from "@pages/auth/components/InviteUserForm/InviteUserAvatars"
import InviteUserModalFooter from "@pages/auth/components/InviteUserForm/InviteUserModalFooter"
import NotFound from "@components/ui/NotFound/NotFound"

import styles from "./InviteUserForm.module.sass"

const tabs = [
  {
    name: "email",
    value: "Email",
  },
  {
    name: "roles",
    value: "Roles",
  },
  {
    name: "avatars",
    value: "Avatars",
  },
]

interface InviteUserFormProps {
  isLoading?: boolean
  className?: string
  onSubmit: (form: SimpleInviteUserFormType) => void
  onCancel?: () => void
}

export const InviteUserForm: React.FC<InviteUserFormProps> = observer(
  ({ isLoading, className, onCancel, onSubmit }) => {
    const [formData, setFormData] = React.useState<
      Partial<SimpleInviteUserFormType>
    >({})

    const footerNode = <InviteUserModalFooter isLoading={isLoading} />

    return (
      <div className={clsx(styles.root, className)}>
        <MultiStep steps={tabs} onExit={() => onCancel?.()} canJump={false}>
          {(step, { isLastStep, next }) => {
            const handleSubmit = async (
              data: Partial<SimpleInviteUserFormType>
            ) => {
              const newValue = { ...formData, ...data }

              if (isLastStep)
                await onSubmit(newValue as SimpleInviteUserFormType)

              setFormData(newValue)

              next()
            }

            if (step.name === "email")
              return (
                <InviteUserEmail
                  initialValues={formData}
                  onSubmit={handleSubmit}
                  key="email"
                >
                  {footerNode}
                </InviteUserEmail>
              )

            if (step.name === "roles")
              return (
                <InviteUserRoles
                  initialValues={formData}
                  onSubmit={handleSubmit}
                  key="roles"
                >
                  {footerNode}
                </InviteUserRoles>
              )

            if (step.name === "avatars")
              return (
                <InviteUserAvatars
                  initialValues={formData}
                  onSubmit={handleSubmit}
                  key="avatars"
                >
                  {footerNode}
                </InviteUserAvatars>
              )

            return <NotFound />
          }}
        </MultiStep>
      </div>
    )
  }
)

export default InviteUserForm
