const defaults = {
  API_ROOT: process.env.REACT_APP_API_ROOT || window.location.origin,
  HOST: process.env.REACT_APP_CANONICAL_URL,
  FULL_ACCESS_MODE: process.env.REACT_APP_FULL_ACCESS_MODE === "true",
  ALL_FEATURES_ENABLED: process.env.REACT_APP_ALL_FEATURES_ENABLED === "true",
  ALL_ACTIONS_ENABLED: process.env.REACT_APP_ALL_ACTIONS_ENABLED === "true",
  MICROSOFT_OAUTH_API_URL:
    process.env.REACT_APP_MICROSOFT_OAUTH_API_URL ||
    "https://login.microsoftonline.com/common/oauth2/v2.0",
}

const config = {
  development: {
    ...defaults,
  },

  staging: {
    ...defaults,
  },

  production: {
    ...defaults,
  },
}

export default config[process.env.NODE_ENV] || config.production
