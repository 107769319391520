import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store/index"
import mainRoutes from "@root/main.routes"
import { SolutionData } from "@framework/types/solution"

import SolutionsCardContainer from "./SolutionsCardContainer"
import useAvatarScope from "../AvatarScopeContext/useAvatarScope"

import styles from "./AvailableSolutions.module.sass"

const AvailableSolutions: React.FC = () => {
  const navigate = useNavigate()
  const { solutionsController } = useController()
  const { avatar } = useAvatarScope()
  const { solutionsStore } = useStore()

  const handleLaunch = (solution: SolutionData) => {
    solutionsStore.setSolution(solution)
    navigate(mainRoutes.solutionView(solution.key))
  }

  useEffect(() => {
    if (avatar?.id) solutionsController.loadAvatarSolutions(avatar.id)
  }, [avatar?.id])

  return (
    <div className={styles.root}>
      <Text variant="h4" weight="bold">
        Top Solutions for you
      </Text>

      <SolutionsCardContainer onLaunch={handleLaunch} />
    </div>
  )
}

export default AvailableSolutions
