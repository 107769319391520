/* eslint-disable camelcase */
import { Option } from "@framework/types/utils"
import { AvatarData } from "@framework/types/avatar"
import { Company } from "@framework/types/company"

export const avatarsToOptions = (avatars: AvatarData[]): Option[] =>
  avatars.map<Option>(({ name, imageUrl, id }) => ({
    name,
    coverImage: imageUrl,
    value: name,
    index: id,
  }))

export const companiesToOptions = (
  companies: Company[],
  baseCompanyId: string
): string[] =>
  companies.filter(({ id }) => id !== baseCompanyId).map(({ id, name }) => id)

export default {}
