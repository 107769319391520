import clsx from "clsx"
import React from "react"

import Button from "../Button/Button"

import styles from "./ContentOverflowContainer.module.sass"

interface ContentOverflowContainerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  readMoreTitle?: string
  containerClassName?: string
  onReadMore?: React.MouseEventHandler
}

export const ContentOverflowContainer: React.FC<
  ContentOverflowContainerProps
> = ({
  className,
  containerClassName,
  readMoreTitle = "Read More",
  children,
  onReadMore,
  ...props
}) => {
  return (
    <div className={clsx(styles.root, className)} {...props}>
      <div className={clsx(styles.content, containerClassName)}>{children}</div>

      <div className={styles.blur} />

      {onReadMore != null && (
        <div className={styles.control}>
          <Button size="small" variant="slim" onClick={onReadMore}>
            {readMoreTitle}
          </Button>
        </div>
      )}
    </div>
  )
}

export default ContentOverflowContainer
