import { makeAutoObservable } from "mobx"
import keyBy from "lodash/keyBy"

import { ListResponse } from "@framework/types/product"
import { Company } from "@framework/types/company"

import { companiesToOptions } from "../user/dataTransformers"

export default class TargetCompaniesStore {
  baseCompany: Company = {
    id: "",
    name: "",
  }

  companies: Company[] | null = null

  selectedCompanyId: string | null = null

  businessUnit: string | null = null

  businessUnitList: string[] = []

  error: string | null = null

  isLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  get companyOptions(): string[] | null {
    return this.companies && this.baseCompany.id
      ? companiesToOptions(this.companies, this.baseCompany.id)
      : null
  }

  get companyMap(): Record<string, Company> {
    return keyBy(this.companies ?? [], "id")
  }

  setCompanies = (companies: ListResponse<Company>) => {
    this.companies = companies.data
  }

  selectBusinessUnit = (businessUnit: string | null) => {
    this.businessUnit = businessUnit
  }

  selectCompany = (companyId: string | undefined) => {
    this.selectedCompanyId = companyId ?? null
  }

  reset = (baseCompany?: Company) => {
    if (baseCompany != null) this.baseCompany = { ...baseCompany }
    this.selectedCompanyId = null
    this.businessUnit = null
  }

  findCompanyById = (id: string | null): Company | undefined => {
    return id ? this.companyMap[id] : undefined
  }
}
