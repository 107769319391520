/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Checkbox from "@components/ui/Checkbox/Checkbox"
import IconChip from "@components/ui/Chip/IconChip"
import { useStore } from "@store/index"

import ActionContextMenu from "./ActionContextMenu"
import ActionDescription from "./ActionDescrioption"

import styles from "./index.module.sass"

interface RolesActionRowProps {
  actionId: string
}

export const RolesActionRow: React.FC<RolesActionRowProps> = observer(
  ({ actionId }) => {
    const {
      actionsStore,
      rolesStore,
      rolesActionsStore,
      restrictionsStore: access,
    } = useStore()

    const action = actionsStore.actions[actionId]

    return (
      <Row className={clsx(styles.row)} highlightable>
        <Column align="center" key="name">
          <ActionDescription action={action} />
        </Column>

        <Column align="center" justify="start" key="menu">
          {access.canUpdateAction && <ActionContextMenu action={action} />}
        </Column>

        {rolesStore.roleIds.map((roleId) => {
          const role = rolesStore.roles[roleId]
          const isEditable = role.name !== "superAdmin" && access.canUpdateRole
          const wasSelected = rolesActionsStore.isSelected(roleId, actionId)
          const wasChanged = rolesActionsStore.wasChanged(roleId, actionId)
          const isSelected = wasChanged ? !wasSelected : wasSelected
          const title = `${action.name.toUpperCase()} action is ${
            isSelected ? "allowed" : "forbidden"
          } for ${role.name?.toUpperCase()} role`

          return (
            <Column key={roleId} align="start">
              <label className={styles.checkboxLabel} title={title}>
                <IconChip color={wasChanged ? "secondary" : "transparent"}>
                  <Checkbox
                    name={roleId}
                    checked={isSelected}
                    onClick={() => rolesActionsStore.toggle(roleId, actionId)}
                    disabled={!isEditable}
                  />
                </IconChip>
              </label>
            </Column>
          )
        })}
      </Row>
    )
  }
)

export default RolesActionRow
