import React from "react"
import { observer } from "mobx-react-lite"

import { TableAnswerType } from "@framework/types/search"
import { parseDataString } from "@utils/textUtils"
import SimpleTable from "@components/ui/SimpleTable/SimpleTable"

import styles from "./TableResult.module.sass"

export interface ImageResultProps {
  data?: TableAnswerType
}

export const TableResult: React.FC<ImageResultProps> = observer(({ data }) => {
  const tableData = data ? parseDataString(data.value) : [[]]

  return (
    <div className={styles.root}>
      <SimpleTable data={tableData} />
    </div>
  )
})

export default TableResult
