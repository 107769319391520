import React from "react"

import Templates from "@components/ui/Templates"
import UserCard from "@components/ui/UserCard/UserCard"
import ChatMessages from "@components/prototypes/Chat/ChatMessages"
import ContentOverflowContainer from "@components/ui/ContentOverflowContainer/ContentOverflowContainer"

import styles from "./Chat.module.sass"

export type ChatMessage = {
  message: string
  fromMe: boolean
  moment: number
}

interface ChatPreviewProps {
  onShowMore?: React.MouseEventHandler
  messages: ChatMessage[]
}

export const ChatPreview: React.FC<ChatPreviewProps> = ({
  messages,
  onShowMore,
}) => {
  return (
    <Templates.RollScript
      className={styles.root}
      headerSocket={
        <div className={styles.header}>
          <UserCard fullName="Some User" metaInfo="last message 4 hours ago" />
        </div>
      }
    >
      <ContentOverflowContainer onReadMore={onShowMore}>
        <ChatMessages messages={messages} />
      </ContentOverflowContainer>
    </Templates.RollScript>
  )
}

export default ChatPreview
