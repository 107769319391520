import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import { Synonym } from "@framework/types/glossary"
import { ManagementMode } from "@framework/types/utils"
import { useStore } from "@store/index"
import { constructComplexUrlWithParams } from "@utils/textUtils"
import useQuery from "@components/hooks/useQuery"
import { GlossaryPageMode } from "@pages/upload/Glossary/Glossary"

import RecordFormRow from "./RecordFormRowForm"

interface SynonymRowProps {
  synonym: Synonym
}

export const EditSynonymRow: React.FC<SynonymRowProps> = observer(
  ({ synonym }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = useQuery()
    const alert = useAlert()

    const deleteModal = useModal(ModalsTypes.DELETE_SYNONYM_MODAL)

    const {
      synonymsStore: { updateSynonym },
    } = useStore()

    const handleCancel = () => {
      navigate(
        constructComplexUrlWithParams(location.pathname, {
          glossaryMode: GlossaryPageMode.SYNONYM,
        })
      )
    }

    const handleSubmit = async ({
      title,
      items,
    }: {
      title: string
      items: string[]
    }) => {
      const error = await updateSynonym({
        id: synonym.id,
        mainWord: title,
        synonymsByMainWord: items,
      })

      if (error) alert.error(error)

      navigate(
        constructComplexUrlWithParams(location.pathname, {
          glossaryMode: GlossaryPageMode.SYNONYM,
        })
      )
    }

    useEffect(() => {
      const managingId = queryParams.get("managingId")
      const managementMode = queryParams.get("managementMode")
      const glossaryMode = queryParams.get("glossaryMode")

      if (
        managingId === synonym.id &&
        managementMode === ManagementMode.deleting &&
        glossaryMode
      ) {
        deleteModal.showModal({
          synonym,
          onClose: () =>
            navigate(
              constructComplexUrlWithParams(location.pathname, {
                glossaryMode,
              })
            ),
        })
      }
    }, [location])

    return (
      <RecordFormRow
        defaultTitle={synonym.mainWord}
        defaultItems={synonym.synonymsByMainWord}
        minItems={1}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    )
  }
)

export default EditSynonymRow
