import React from "react"

import Icon from "@components/ui/Icon/Icon"
import { SolutionData } from "@framework/types/solution"
import Button from "@components/ui/Button/Button"
import { convertFirstLetterToUpper } from "@utils/textUtils"
import Text from "@components/ui/Typography/Text"
import { getSolutionCoverImage } from "@framework/constants/search-results"

import styles from "./SolutionCard.module.sass"

type SolutionCardProps = {
  solution: SolutionData
  onClick?: (value: SolutionData) => void
}

const SolutionCard: React.FC<SolutionCardProps> = ({ solution, onClick }) => {
  const name = convertFirstLetterToUpper(solution.name)
  const description = convertFirstLetterToUpper(solution.description)

  const coverImage = getSolutionCoverImage(solution.key)

  return (
    <button
      className={styles.root}
      type="button"
      onClick={onClick ? () => onClick(solution) : undefined}
    >
      <img className={styles.illustration} src={coverImage} alt={name} />

      <div className={styles.body}>
        <Text
          className={styles.title}
          title={name}
          variant="h2"
          color="text100Color"
          weight="bold"
        >
          {name}
        </Text>

        <Text
          variant="body2"
          color="text70Color"
          className={styles.description}
        >
          {description}
        </Text>

        <div className={styles.cta}>
          <Button
            size="medium"
            color="primary"
            variant="contained"
            after={<Icon name="arrow-down" rotateAngle={-90} />}
          >
            Launch
          </Button>
        </div>
      </div>
    </button>
  )
}

export default SolutionCard
