import React from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"

import { useStore } from "@store/index"
import {
  SearchBlockContextProvider,
  SearchEntityContextProvider,
} from "@pages/search/SearchContext/SearchResultContext"
import { SearchDetailsContextProvider } from "@pages/search/SearchContext/SearchDetailsContext"
import SearchPassagesSidebar from "@pages/search/SearchAnswerSource/SourcePassagesSidebar"

import SearchFlowPassageContextProvider from "./SearchFlowPassageContextProvider"

export interface FactFinderSearchPassagesSidebarProps {
  onBack?: () => void
}

export const FactFinderSearchPassagesSidebar: React.FC<FactFinderSearchPassagesSidebarProps> =
  observer(({ onBack }) => {
    const {
      factFinderSolutionStore: { searchFlowStore },
    } = useStore()

    const { searchId, blockId, answerIndex } = useParams<{
      searchId: string
      blockId: string
      answerIndex?: string
    }>()

    if (searchId == null || blockId == null)
      throw new Error(
        "Source Passages Page requires searchId and blockId params"
      )

    return (
      <SearchEntityContextProvider
        searchEntity={searchFlowStore.getById(searchId)}
      >
        <SearchBlockContextProvider
          searchBlockId={blockId}
          answerIndex={answerIndex}
        >
          <SearchFlowPassageContextProvider>
            <SearchDetailsContextProvider>
              <SearchPassagesSidebar open onClose={onBack} />
            </SearchDetailsContextProvider>
          </SearchFlowPassageContextProvider>
        </SearchBlockContextProvider>
      </SearchEntityContextProvider>
    )
  })

export default FactFinderSearchPassagesSidebar
