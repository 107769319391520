import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry } from "rxjs/operators"
import { toStream } from "mobx-utils"

import { CloudEntity, DataConnectorSourceName } from "@framework/types/upload"
import uploadService from "@services/upload.service"
import { VirtualListChunk } from "@framework/types/utils"

export type FractionName = "total" | "connected" | "loading" | "failed"

export type FileCollectionFractions = Record<FractionName, number>

const PULLING_DELAY = 20_000
const RETRY_DELAY = 30_000
const CHUNK_SIZE = 120

export type Filter = {
  dataTypes?: string[]
  createdByUserIds?: string[]
  updatedByUserIds?: string[]
  createdAtRange?: { start: string; end: string }
  lastUpdatedAtRange?: { start: string; end: string }
}

type State = {
  path: string // folderL1/folderL2/folderL3 | ""

  isLoading: boolean

  data: CloudEntity[]

  total: number

  errorMessage: string

  reloadTimestamp: boolean

  query?: string

  filter: Filter

  fraction: FractionName

  requestedFrame: VirtualListChunk

  actualFrame: VirtualListChunk

  distribution: FileCollectionFractions

  readonly dataConnectorName: DataConnectorSourceName

  readonly dataConnectorId: string
}

/**
 * @class
 */
export class CloudEntitiesCollectionStore {
  state: State

  private loadingStream$?: Subscription

  // constructor

  constructor(
    dataConnectorName: DataConnectorSourceName,
    dataConnectorId: string
  ) {
    this.state = {
      path: "",

      isLoading: false,

      data: [],

      total: 0,

      errorMessage: "",

      reloadTimestamp: false,

      requestedFrame: { offset: 0, limit: CHUNK_SIZE },

      actualFrame: { offset: 0, limit: CHUNK_SIZE },

      query: "",

      filter: {},

      fraction: "total",

      distribution: { total: CHUNK_SIZE, loading: 0, connected: 0, failed: 0 },

      dataConnectorName,

      dataConnectorId,
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "total", () => {
      this.loadingStream$ = this.initStream()
    })

    onBecomeUnobserved(this.state, "total", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  initStream = () => {
    this.resetList()
    return from(
      toStream(
        () => [
          this.state.requestedFrame,
          this.state.path,
          this.state.query,
          this.state.filter,
          this.state.fraction,
          this.state.reloadTimestamp,
        ],
        true
      ) as ObservableInput<
        [VirtualListChunk, string, string, Filter, FractionName, boolean]
      >
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.errorMessage = ""
          })
        ),

        switchMap(([frame, path, query, filter, fraction]) =>
          uploadService.getCloudEntities$(this.state.dataConnectorId, {
            offset: frame.offset,
            limit: frame.limit,
            path,
            ...filter,
            status: fractionNameToStatusFilter(fraction),
            query,
          })
        ),

        tap((response) =>
          runInAction(() => {
            const { meta, data = [], count } = response.data

            this.state.data = [...data]

            this.state.actualFrame = { ...meta }

            this.state.total = meta?.total ?? CHUNK_SIZE

            this.state.distribution = {
              total: count.totalFolders,
              loading: count.runningFolders,
              failed: count.failedFolders,
              connected: count.uploadedFolders,
            }
          })
        ),

        tap(() =>
          runInAction(() => {
            this.state.isLoading = false
          })
        ),

        retry({
          delay: () => {
            runInAction(() => {
              this.state.errorMessage = "Loading Failed"
              this.state.isLoading = false
              this.state.data = []
            })
            return timer(RETRY_DELAY)
          },
        }),

        switchMap(() => timer(PULLING_DELAY)),

        tap(() => this.refresh())
      )
      .subscribe()
  }

  get getByIndex() {
    const { data } = this.state
    return (index: number): CloudEntity | null => {
      const { offset } = this.state.actualFrame
      return data[index - offset] ?? null
    }
  }

  load = async ({ startIndex }: { startIndex: number; stopIndex: number }) => {
    const chunkIndex = Math.floor(startIndex / CHUNK_SIZE) - 1

    const newOffset = Math.max(0, chunkIndex * CHUNK_SIZE)

    this.state.requestedFrame = {
      offset: newOffset,
      limit: CHUNK_SIZE * 3,
    }
  }

  search = (query: string = "") => {
    this.state.query = query
  }

  openDirectory = (path: string) => {
    this.setPath(this.state.path ? `${this.state.path}/${path}` : path)
  }

  setPath = (path: string) => {
    runInAction(() => {
      this.state.path = path
      this.resetList()
    })
  }

  resetPath = () => this.setPath("")

  resetList = () => {
    runInAction(() => {
      this.state.data = []
      this.state.requestedFrame = { offset: 0, limit: CHUNK_SIZE }
      this.state.actualFrame = { offset: 0, limit: CHUNK_SIZE }
      this.state.total = 0
    })
  }

  changeFraction = (value: FractionName) => {
    this.state.fraction = value
  }

  applyFilter = (filter: Filter) => {
    this.state.filter = { ...filter }
  }

  refresh = () => {
    this.state.reloadTimestamp = !this.state.reloadTimestamp
  }
}

export default CloudEntitiesCollectionStore

const fractionNameToStatusFilter = (fraction: FractionName) => {
  if (fraction === "total") return []
  if (fraction === "loading") return ["success", "assigned"]
  return [fraction]
}
