import React from "react"
import { observer } from "mobx-react-lite"
import find from "lodash/find"

import Text from "@components/ui/Typography/Text"
import { useStore } from "@store/index"
import Avatar from "@components/ui/Avatar/Avatar"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import {
  SearchBlockContextProvider,
  useUnstructuredSearchEntity,
} from "@pages/search/SearchContext/SearchResultContext"

import AnswerSummaryPassage from "./AnswerSummaryPassage"
import LoadingInfo from "./LoadingInfo"
import LoadingMessages from "./LoadingMessages"

import styles from "./AnswerSummary.module.sass"

type AnswerSummaryProps = {
  defaultQuery?: boolean
}

const AnswerSummary: React.FC<AnswerSummaryProps> = observer(
  ({ defaultQuery = false }) => {
    const {
      restrictionsStore: access,
      userStore: { user },
    } = useStore()

    const { searchEntity } = useUnstructuredSearchEntity()

    const { blocks, isLoading } = searchEntity

    const searchBlock = defaultQuery
      ? find(blocks, (it) => it.queryType === "DEFAULT")
      : find(
          blocks,
          (it) =>
            it instanceof SearchSummaryBlockStore && it.queryType === "ADVANCED"
        ) ??
        find(
          blocks,
          (it) =>
            it instanceof SearchSummaryBlockStore && it.queryType === "BASIC"
        )

    const ref = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" })
      }
    }, [])

    const isSummaryLoaded =
      searchBlock instanceof SearchSummaryBlockStore &&
      searchBlock.searchSummary.isLoaded

    return (
      <Text variant="caption1" color="text70Color" className={styles.flex1}>
        <div className={styles.avatarSummary}>
          <Avatar
            width={24}
            height={24}
            src={user?.avatarURL}
            name={`${user?.firstName} ${user?.lastName}`}
          />
          <Text variant="h4">{searchEntity.filter.searchQuery}</Text>
        </div>

        {!!searchBlock && (
          <SearchBlockContextProvider searchBlockId={searchBlock.id}>
            <AnswerSummaryPassage
              hidePostToExpert={defaultQuery && access.canAssignAnswerToExpert}
            />
          </SearchBlockContextProvider>
        )}

        {isLoading && !isSummaryLoaded && (
          <div className={styles.loadingMessages} ref={ref}>
            {searchBlock ? (
              <SearchBlockContextProvider searchBlockId={searchBlock.id}>
                <LoadingMessages />
              </SearchBlockContextProvider>
            ) : (
              <div className={styles.placeholder} />
            )}

            <LoadingInfo />
          </div>
        )}
      </Text>
    )
  }
)

export default AnswerSummary
