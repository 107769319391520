import React from "react"
import clsx from "clsx"

import { DocumentIconType } from "@framework/types/utils"
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from "@components/ui/Checkbox/CheckboxWithLabel"
import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"

import DocumentIcon from "../Icon/DocumentIcon"

import styles from "./CheckboxCard.module.sass"

export interface CheckboxCardProps extends CheckboxWithLabelProps {
  icon?: DocumentIconType | IconName
  withArrow?: boolean
  withIcon?: boolean
  Size?: "small" | "medium" | "big"
}

export const CheckboxCard: React.FC<CheckboxCardProps> = ({
  checked,
  className,
  icon,
  label,
  withArrow = false,
  withIcon = true,
  Size = "big",
  children,
  ...rest
}) => (
  <CheckboxWithLabel
    {...rest}
    label={
      <>
        {withIcon && (
          <DocumentIcon icon={icon ?? "global"} height={35} width={35} />
        )}
        <span className={styles.label}>{label}</span>
        {withArrow && <Icon name="arrow-down" rotateAngle={-90} />}
      </>
    }
    labelClassName={styles.body}
    className={clsx(
      styles.root,
      { [styles.checked]: checked },
      styles[`size-${Size}`],
      className
    )}
    checked={checked}
  />
)

export default CheckboxCard
