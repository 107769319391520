import React from "react"
import clsx from "clsx"

import Avatar, { AvatarProps } from "@components/ui/Avatar/Avatar"
import Icon from "@components/ui/Icon/Icon"

import styles from "./UserAvatar.module.sass"

export interface UserAvatarProps extends AvatarProps {
  onAvatarChange?: (newImage: File) => void
  accept?: string
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  className,
  src,
  onAvatarChange,
  accept,
  ...rest
}) => {
  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length || !onAvatarChange) return
    const file = e.target.files[0]
    onAvatarChange(file)
  }

  return (
    <div className={styles.root}>
      <Avatar {...rest} className={clsx(styles.avatar, className)} src={src} />

      <label htmlFor="avatar" className={styles.avatarEdit}>
        <input
          id="avatar"
          type="file"
          accept={accept}
          className={styles.avatarInput}
          onChange={handleFileChange}
          multiple={false}
        />
        <Icon name="camera" color="primary" />
      </label>
    </div>
  )
}

export default UserAvatar
