import React from "react"
import { useDropzone } from "react-dropzone"
import clsx from "clsx"

import { pluralize } from "@utils/textUtils"
import Text from "@components/ui/Typography/Text"
import { IconName } from "@components/ui/Icon/IconName"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { DocumentIconType } from "@framework/types/utils"

import styles from "./DocumentDropZone.module.sass"

export interface DocumentDropZoneProps {
  className?: string
  disabled?: boolean
  multiple?: boolean
  extensions?: string[]
  description?: string
  loading?: boolean
  icon?: IconName | DocumentIconType
  alternativeTitle?: React.ReactNode
  onDrop?: (files: File[]) => void
}

export const DocumentDropZone: React.FC<DocumentDropZoneProps> = ({
  className,
  multiple = true,
  disabled = false,
  icon = "img:folder_full",
  extensions,
  alternativeTitle,
  description,
  onDrop,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    disabled,
    accept: extensions,
  })

  const containerClasses = clsx(
    styles.root,
    isDragActive ? styles.active : "",
    { [styles.disabled]: disabled },
    className
  )

  return (
    <div {...getRootProps()} className={containerClasses}>
      <div className={styles.title}>
        {!alternativeTitle ? (
          <>
            <DocumentIcon
              className={styles.icon}
              icon={icon}
              color="primary-solid"
              height={50}
              width={50}
            />

            <Text weight="bold" align="center">
              Drag and drop your {pluralize("file", multiple)} here
            </Text>
          </>
        ) : (
          <>{alternativeTitle}</>
        )}

        {description && (
          <Text
            variant="inherit"
            color="text70Color"
            align="center"
            className={styles.description}
          >
            {description}
          </Text>
        )}
      </div>
      <input {...getInputProps()} />
    </div>
  )
}

export default DocumentDropZone
