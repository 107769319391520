import React from "react"
import clsx from "clsx"
import UIPagination, { ReactPaginateProps } from "react-paginate"

import Icon from "@components/ui/Icon/Icon"

import styles from "./Pagination.module.sass"

export interface PaginationProps extends ReactPaginateProps {
  className?: string
}

export const Pagination: React.FC<PaginationProps> = ({
  className,
  pageLinkClassName,
  activeLinkClassName,
  previousLinkClassName,
  nextLinkClassName,
  breakLinkClassName,
  ...rest
}) => (
  <UIPagination
    className={clsx(styles.root, className)}
    pageLinkClassName={clsx(styles.page, pageLinkClassName)}
    activeLinkClassName={clsx(styles.active, activeLinkClassName)}
    previousLinkClassName={clsx(styles.previousBtn, previousLinkClassName)}
    nextLinkClassName={clsx(styles.nextBtn, nextLinkClassName)}
    breakLinkClassName={clsx(styles.break, breakLinkClassName)}
    previousLabel={<Icon name="arrow-down" rotateAngle={90} />}
    nextLabel={<Icon name="arrow-down" rotateAngle={-90} />}
    {...rest}
  />
)

export default Pagination
