import { makeAutoObservable } from "mobx"

import { BaseUserData } from "@framework/types/user"

export class UserListStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading: boolean = false

  error: string | null = null

  users: BaseUserData[] = []

  get userMap() {
    return new Map(this.users.map((it) => [it.id, it]))
  }

  get getUserById() {
    return (userId: string | null) =>
      userId == null ? null : this.userMap.get(userId) ?? null
  }

  setUsers = (users: BaseUserData[]) => {
    this.users = users
  }
}

export default UserListStore
