import clsx from "clsx"
import React from "react"

import Text from "@components/ui/Typography/Text"

import styles from "../EntityCard.module.sass"

export interface BusinessCardProps {
  title: string
  avatar?: React.ReactNode
  status?: React.ReactNode
  inline?: boolean
}

export const BusinessCard: React.FC<BusinessCardProps> = ({
  title,
  avatar,
  status,
  inline = false,
  children,
}) => (
  <div className={styles.cardContainer}>
    <div className={clsx(styles.body, { [styles.inline]: inline })}>
      {avatar}
      <section className={styles.content}>
        {status}
        <Text
          variant="h4"
          color="text100Color"
          className={styles.title}
          title={title}
        >
          {title}
        </Text>
        <Text
          variant="body2"
          color="text50Color"
          className={styles.description}
        >
          {children}
        </Text>
      </section>
    </div>
  </div>
)

export default BusinessCard
