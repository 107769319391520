import React from "react"

import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"

import styles from "./Sidebar.module.sass"

export interface NavItemTooltipProps {
  disabled?: boolean
  text: React.ReactNode
  className?: string
}

export const NavItemTooltip: React.FC<NavItemTooltipProps> = ({
  children,
  text,
  disabled,
  className,
}) => (
  <Tooltip
    disabled={disabled}
    mode="onHover"
    className={className}
    content={
      <TooltipContainer
        placement="right"
        color="primary"
        className={styles.label}
      >
        {text}
      </TooltipContainer>
    }
  >
    {children}
  </Tooltip>
)

export default NavItemTooltip
