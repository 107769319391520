import React from "react"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import { useStore } from "@store/index"

import RoleContextMenu from "./RoleContextMenu"

import styles from "./index.module.sass"

interface RolesHeaderProps {}

export const RolesHeader: React.FC<RolesHeaderProps> = observer(() => {
  const { rolesStore, restrictionsStore: access } = useStore()

  return (
    <Row>
      <Column as="th" key="label" width="350px">
        Actions / Roles
      </Column>

      <Column as="th" key="menu" width="50px" />

      {rolesStore.roleIds.map((roleId) => {
        const role = rolesStore.roles[roleId]
        if (role == null) return null
        const isEditable = role.name !== "superAdmin" && access.canUpdateRole
        return (
          <Column as="th" justify="center" width="120px" key={role.id}>
            <div className={styles.checkboxLabel}>
              {role.name}
              {isEditable && <RoleContextMenu role={role} />}
            </div>
          </Column>
        )
      })}
    </Row>
  )
})

export default RolesHeader
