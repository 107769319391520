import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import AdminLayout from "@components/layout/AdminLayout/AdminLayout"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import BackButton from "@components/prototypes/BackButton"
import { ModalsTypes } from "@components/modals/constants"
import NotFound from "@components/ui/NotFound/NotFound"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store/index"

import AvatarsTable from "./components/Table"

import styles from "./Avatars.module.sass"

interface AvatarsProps {}

const Avatars: React.FC<AvatarsProps> = observer(() => {
  const navigate = useNavigate()

  const editModal = useModal(ModalsTypes.CREATE_AVATAR_MODAL)

  const {
    avatarAdminController: { loadAvatars },
  } = useController()

  const {
    avatarAdminStore: { isLoading, data, error },
    restrictionsStore: { canEditAvatar },
  } = useStore()

  useEffect(() => {
    if (!data && !isLoading && !error) loadAvatars()
  }, [])

  const handleCreateAvatar = () => editModal.showModal({})

  return (
    <AdminLayout>
      <EntityPageHeaderLayout
        className={styles.header}
        left={
          <BackButton onClick={() => navigate("/admin")}>Avatars</BackButton>
        }
        right={
          canEditAvatar && (
            <Button
              color="primary"
              before={<Icon name="plus" />}
              size="big"
              onClick={handleCreateAvatar}
            >
              Create Avatar
            </Button>
          )
        }
      />
      <LoadingCover isLoading={isLoading}>
        {data?.length ? (
          <AvatarsTable items={data} />
        ) : (
          <NotFound>No avatars found</NotFound>
        )}
      </LoadingCover>
    </AdminLayout>
  )
})

export default Avatars
