import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import * as yup from "yup"

import Button from "@components/ui/Button/Button"
import CodeInput from "@components/ui/CodeInput/CodeInput"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./ConfirmCodeForm.module.sass"

export interface ConfirmCodeFormType {
  verificationCode: string
}

export interface ConfirmCodeFormProps {
  codeLength?: number
  isLoading?: boolean
  className?: string
  errors?: ConfirmCodeFormType
  onSubmit: (form: ConfirmCodeFormType) => void
  onResend?: () => void
}

export const ConfirmCodeForm: React.FC<ConfirmCodeFormProps> = observer(
  ({ codeLength = 5, isLoading = false, errors, onSubmit, onResend }) => {
    const validationSchema = useMemo(() => {
      const lengthErrorMessage = `Verification code must be ${codeLength} symbols long`
      return yup.object().shape({
        verificationCode: yup
          .string()
          .required("Field is required")
          .min(codeLength, lengthErrorMessage)
          .max(codeLength, lengthErrorMessage),
      })
    }, [codeLength])

    const formik = useFormik<ConfirmCodeFormType>({
      initialValues: {
        verificationCode: "",
      },
      validationSchema,
      onSubmit,
    })

    useEffect(() => {
      if (errors) formik.setErrors(errors)
    }, [errors])

    return (
      <div className={styles.root}>
        <CodeInput
          name="verificationCode"
          className={styles.input}
          codeLength={codeLength}
          onChange={(value) => formik.setFieldValue("verificationCode", value)}
          value={formik.values.verificationCode}
          withError={
            !!(
              formik.touched.verificationCode && formik.errors.verificationCode
            )
          }
        />
        <Button
          color="primary"
          onClick={formik.submitForm}
          disabled={isLoading}
          after={isLoading && <Loader />}
        >
          Confirm
        </Button>
        {onResend && (
          <Button variant="slim" color="primary" onClick={onResend}>
            Resend Link
          </Button>
        )}
      </div>
    )
  }
)

export default ConfirmCodeForm
