import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { AvatarData } from "@framework/types/avatar"
import { useController, useStore } from "@store/index"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import HTMLText from "@components/ui/Typography/HTMLText"

import styles from "./InfoContainer.module.sass"

const alertMessages = [
  // "It may occasionally generate incorrect responses",
  // "It learns from user and expert feedback",
  // "Always validate the results provided by the avatar",
  "It may occasionally generate imperfect responses.",
  "It learns from each interaction, including user feedback and Expert Knowledge.",
  "Always validate the results provided by the avatar using the AI analysis trail and source documentation",
]

type InfoContainerProps = {
  avatar: AvatarData | null
}

const InfoContainer: React.FC<InfoContainerProps> = observer(({ avatar }) => {
  const {
    solutionsController: { loadAvatarSolutions },
  } = useController()
  const { solutionsStore } = useStore()

  useEffect(() => {
    if (avatar?.id) loadAvatarSolutions(avatar.id)
  }, [avatar?.id])

  return (
    <div className={styles.infoContainer}>
      <div className={clsx(styles.info, styles.fullWidth)}>
        <div className={styles.infoCard}>
          <Text
            variant="h4"
            className={styles.infoTitle}
            style={{ textAlign: "center" }}
          >
            Your Avatar uses GenAI and is not perfect. Please keep a few things
            in mind:
          </Text>
          <ul>
            {alertMessages.map((it) => (
              <li key={it}>
                <Icon name="alert" /> {it}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={styles.info}>
        <div className={styles.infoCard}>
          <div className={styles.infoTitle}>What’s {avatar?.name} Avatar</div>
          <HTMLText>{avatar?.description ?? "-"}</HTMLText>
        </div>
      </div>

      <div className={clsx(styles.info)}>
        <div className={clsx(styles.infoCard)}>
          <div className={styles.infoTitle}>
            Available Solutions For {avatar?.name}
          </div>
          <div className={styles.solutionsList}>
            {solutionsStore.isLoading ? (
              <Skeleton
                count={4}
                lineHeight={32}
                minWidth={100}
                spacing={8}
                rounded
              />
            ) : (
              <ul>
                {solutionsStore.solutions &&
                  solutionsStore.solutions.map((solution) => (
                    <React.Fragment key={solution.id}>
                      <li key={solution.id}>{solution.name}</li>
                      <p className={styles.description}>
                        {solution.description}
                      </p>
                    </React.Fragment>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

export default InfoContainer
