/* eslint-disable no-underscore-dangle */
import { makeAutoObservable, observable } from "mobx"

import ExternalCloudEntitiesStore from "./external-cloud-entities.store"

export class ExternalEntitiesStores {
  constructor() {
    makeAutoObservable(this)
  }

  errorMessage: string | null = null

  stores: Map<string, ExternalCloudEntitiesStore> = new Map()

  getStore = (dataConnectorId: string) => {
    const key = dataConnectorId

    const instance = this.stores.get(key)

    if (instance != null) return instance

    this.resetCollection(key)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.stores.get(key)!
  }

  resetCollection = (dataConnectorId: string) => {
    this.stores.set(
      dataConnectorId,
      observable(new ExternalCloudEntitiesStore({ dataConnectorId }))
    )
  }
}

export default ExternalEntitiesStores
