import React, { PropsWithChildren, ReactElement, FC } from "react"

import RootController from "./RootController"
import RootStore from "./RootStore"

export interface IProviderProps {
  store: RootStore
  controller: RootController
}

const StoreContext = React.createContext<IProviderProps | null>(null)

// StoreProvider to inject root store using react context
export const StoreProvider: FC<IProviderProps> = (
  props: PropsWithChildren<IProviderProps>
): ReactElement => {
  const { children, store, controller } = props
  const value = React.useMemo(
    () => ({ store, controller }),
    [store, controller]
  )
  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

// Hook to use store in any functional component
export const useStore = (): RootStore => {
  const context = React.useContext(StoreContext)
  if (context == null) throw new Error("Root store provider is missing")
  return context.store
}

export const useController = (): RootController => {
  const context = React.useContext(StoreContext)
  if (context == null) throw new Error("Root controller provider is missing")
  return context.controller
}

export default StoreProvider
