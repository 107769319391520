import React from "react"

import Col from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"

interface AvatarsTableHeaderProps {}

const AvatarsTableHeader: React.FC<AvatarsTableHeaderProps> = () => {
  const cols = [
    {
      key: "name",
      label: "Avatar",
      width: 450,
    },
    { key: "actions", width: 70 },
    {
      key: "users",
      label: "Users",
      width: 125,
    },
    {
      key: "experts",
      label: "Experts",
      width: 125,
    },
    {
      key: "admins",
      label: "Admins",
      width: 125,
    },
    { key: "privacy", label: "Privacy", width: 150 },
    { key: "createdAt", label: "Created Date", width: 150 },
    { key: "solutions", label: "Solutions", width: 150 },
  ]

  return (
    <Row>
      {cols.map(({ key, label, width }) => (
        <Col as="th" key={key} width={width ?? "auto"}>
          {label}
        </Col>
      ))}
    </Row>
  )
}

export default AvatarsTableHeader
