import React, { useState } from "react"
import without from "lodash/without"
import { useAlert } from "react-alert"
import groupBy from "lodash/groupBy"

import Table from "@components/ui/BaseTable/Table"
import { UserRequest, UserRequestStatuses } from "@framework/types/security"
import { useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import { countSuffix } from "@utils/numberUtils"
import Icon from "@components/ui/Icon/Icon"

import { UserRequestRow } from "./UserRequestRow"
import { UserRequestTableHeader } from "./UserRequestTableHeader"

import styles from "./index.module.sass"

export interface UserRequestTableProps {
  rows?: UserRequest[]
}

export const UserRequestsTable: React.FC<UserRequestTableProps> = React.memo(
  ({ rows = [] }) => {
    const alert = useAlert()
    const {
      userRequestStore: {
        updateUserRequest,
        updateUserRequests,
        isUserRequestsCollectionLoading: isLoading,
      },
    } = useStore()

    const [selectedRequests, setSelectedRequests] = useState<string[]>([])

    const isSelected = (id: string) => selectedRequests.includes(id)

    const isAllSelected = selectedRequests.length === rows.length

    const handleSelect = (id: string) => {
      setSelectedRequests((prev) =>
        prev.includes(id) ? without(prev, id) : [...prev, id]
      )
    }

    const handleApprove = async (id: string) => {
      const error = await updateUserRequest(id, UserRequestStatuses.ACCEPTED)
      if (error) alert.error("Failed to approve user request")
    }

    const handleDecline = async (id: string) => {
      const error = await updateUserRequest(id, UserRequestStatuses.DECLINED)
      if (error) alert.error("Failed to decline user request")
    }

    const handleApproveAll = async () => {
      const res = await updateUserRequests(
        selectedRequests,
        UserRequestStatuses.ACCEPTED
      )
      handleMultipleResponse(res, "approve")
    }

    const handleDeclineAll = async () => {
      const res = await updateUserRequests(
        selectedRequests,
        UserRequestStatuses.DECLINED
      )
      handleMultipleResponse(res, "decline")
    }

    const handleMultipleResponse = (res: any, action: string) => {
      if (res.status === "SUCCESS") {
        const fractions = groupBy(res.data, (it) =>
          it.error ? "FAILED" : "SUCCESS"
        )

        const totalRequested = selectedRequests.length
        const totalSucceeded = fractions.SUCCESS.length

        const message = `${totalRequested} / ${totalSucceeded} selected user request was successfully ${action}d`

        if (totalSucceeded === totalRequested) {
          alert.success(message)
          return
        }

        if (totalSucceeded > 0) {
          alert.info(message)
          return
        }
      }

      alert.error(`Failed to ${action} any of selected user requests`)
    }

    const handleSelectAll = () => {
      if (isAllSelected) setSelectedRequests([])
      else setSelectedRequests(rows.map((it) => it.id))
    }

    React.useEffect(() => {
      setSelectedRequests([])
    }, [rows.length])

    return (
      <div className={styles.root}>
        <Table
          header={
            <UserRequestTableHeader
              isAllSelected={isAllSelected}
              onSelectAll={handleSelectAll}
            />
          }
        >
          {rows.map((userRequest) => (
            <UserRequestRow
              userRequest={userRequest}
              selected={isSelected(userRequest.id)}
              onSelect={handleSelect}
              onApprove={handleApprove}
              onDecline={handleDecline}
              key={userRequest.id}
            />
          ))}
        </Table>

        <Templates.Header
          right={
            selectedRequests.length > 0 && (
              <>
                <Text color="text70Color">
                  {selectedRequests.length} request
                  {countSuffix(selectedRequests.length)} selected
                </Text>

                <Button
                  size="big"
                  color="primary"
                  before={<Icon name="user-follow" />}
                  onClick={handleApproveAll}
                  disabled={isLoading}
                >
                  Approve all
                </Button>

                <Button
                  size="big"
                  variant="outlined"
                  color="red"
                  before={<Icon name="user-unfollow" />}
                  onClick={handleDeclineAll}
                  disabled={isLoading}
                >
                  Decline all
                </Button>
              </>
            )
          }
        />
      </div>
    )
  }
)

export default UserRequestsTable
