/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import usePopper, { PopperPlacement } from "@components/hooks/usePopper"

import Popper from "./Popper"

import styles from "./DropDownContainer.module.scss"

type RenderCallback = (
  active: boolean,
  toggle: (e: React.MouseEvent, value?: boolean) => void
) => React.ReactNode

export interface DropDownContainerProps {
  placement?: PopperPlacement
  target?: React.ReactNode | RenderCallback
  children?: React.ReactNode | RenderCallback
  disabled?: boolean
  keepOpened?: boolean
  className?: string
}

export const DropDownContainer = observer(
  ({
    target,
    placement = "bottom-start",
    children,
    keepOpened = false,
    disabled = false,
    className,
  }: React.PropsWithChildren<DropDownContainerProps>) => {
    const [popperNode, setPepperNode] = useState<HTMLElement | null>(null)
    const [containerNode, setContainerNode] = useState<HTMLElement | null>(null)
    const { isActive, style, toggle } = usePopper(containerNode, popperNode, {
      placement,
      disabled,
    })

    return (
      <>
        <div
          ref={setContainerNode}
          className={clsx(styles.root, className)}
          role="button"
          tabIndex={0}
        >
          {typeof target === "function" ? target(isActive, toggle) : target}
        </div>
        <Popper
          ref={setPepperNode}
          style={style}
          isActive={isActive}
          onClick={keepOpened ? undefined : toggle}
        >
          {typeof children === "function"
            ? children(isActive, toggle)
            : children}
        </Popper>
      </>
    )
  }
)

export default DropDownContainer
