/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import { useCallback, useEffect, useState } from "react"

export interface ISelectable {
  selected: string[]
  isAllSelected: boolean
  isAnySelected: boolean
  isSelected: (id: string) => boolean
  select: (id: string) => void
  selectAll: () => void
  dropAll: () => void
}

type DefaultItem = { id: string }

const defSelected: string[] = []

const defaultGetter = (item: DefaultItem & any) => item?.id

export const useSelectable = <T extends any = DefaultItem>(
  items: T[],
  defaultSelected: string[] = defSelected,
  getter: (item: T) => string = defaultGetter
): ISelectable => {
  const [selected, setSelected] = useState<string[]>([...defaultSelected])

  useEffect(() => {
    setSelected((selected) =>
      selected.filter((selId) => items.find((it) => selId === getter(it)))
    )
  }, [items])

  useEffect(() => {
    setSelected([...defaultSelected])
  }, [defaultSelected])

  const isSelected = useCallback(
    (id: string) => selected.includes(id),
    [selected]
  )

  const selectAll = () => {
    setSelected(items.map(getter).filter((item) => !!item))
  }

  const dropAll = () => {
    setSelected([])
  }

  const select = (id: string) => {
    setSelected((selected) =>
      selected.includes(id)
        ? selected.filter((selId) => selId !== id)
        : [...selected, id]
    )
  }

  const isAnySelected = !!selected.length
  const isAllSelected = selected.length === items.length

  return {
    selected,
    isAllSelected,
    isAnySelected,
    isSelected,
    selectAll,
    dropAll,
    select,
  }
}

export default useSelectable
