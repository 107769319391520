import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo } from "react"
import differenceBy from "lodash/differenceBy"

import { SolutionData } from "@framework/types/solution"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import { useController, useStore } from "@store/index"

import BaseContainer from "../BaseContainer/BaseContainer"
import Option from "../Option/Option"
import useAvatarMembershipContext from "../MembershipContext/useAvatarMembershipContext"
import { BasicForm } from "../types"

interface SolutionsProps extends BasicForm {}

const DELETE_MODAL_TITLE = "Remove Solution"

const QUESTION_TEXT = "Are you sure you want to remove solution?"

const Solutions: React.FC<SolutionsProps> = observer(({ onAdd, onRemove }) => {
  const multiformContext = useAvatarMembershipContext()

  const { showModal } = useModal(
    ModalsTypes.DELETE_AVATAR_MEMBERSHIP_ITEM_MODAL
  )

  const {
    initialData: { productSolutions },
    membershipData: { productSolutionIdsToAdd },
    updateMembershipData,
    isEditing,
    handleEdit,
  } = multiformContext

  const {
    solutionsStore: { allSolutions, isLoading },
  } = useStore()

  const {
    solutionsController: { loadAllSolutions },
  } = useController()

  useEffect(() => {
    loadAllSolutions()
  }, [])

  const handleRemoveConfirm = async (id: string) => {
    await onRemove?.({ productSolutionIdsToDelete: [id] })
  }

  const handleItemClick = (id: string) => {
    if (isEditing) {
      updateMembershipData("productSolutionIdsToAdd", id)
    } else {
      showModal({
        onSubmit: () => handleRemoveConfirm(id),
        question: QUESTION_TEXT,
        title: DELETE_MODAL_TITLE,
      })
    }
  }

  const optionRenderer = (item: SolutionData) => (
    <Option
      key={item.id}
      label={item.name}
      icon="jigsaw-puzzle"
      onClick={() => handleItemClick(item.id)}
      checked={productSolutionIdsToAdd.includes(item.id)}
      isEdit={isEditing}
    />
  )

  const items = useMemo(
    () =>
      isEditing
        ? differenceBy(allSolutions, productSolutions ?? [], "id")
        : productSolutions ?? [],
    [productSolutions, allSolutions, isEditing]
  )

  return (
    <BaseContainer<SolutionData>
      items={items}
      isEditing={isEditing}
      isLoading={isLoading}
      entityKey="Solutions"
      renderer={optionRenderer}
      onSubmit={() => onAdd?.()}
      onEdit={handleEdit}
    />
  )
})

export default Solutions
