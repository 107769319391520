import { observer } from "mobx-react-lite"
import React from "react"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { useController, useStore } from "@store/index"

import styles from "./DeleteConfigModal.module.sass"

export interface DeleteConfigModalProps {
  configId: string
  configKey: string
  callback?: () => void
}

export const DeleteConfigModal: React.FC<DeleteConfigModalProps> = observer(
  ({ configId, configKey, callback }) => {
    const alert = useAlert()

    const modal = useModal(ModalsTypes.DELETE_INSTANCE_CONFIG_MODAL)

    const {
      appStore: { instanceConfig },
    } = useStore()
    const { appConfigController } = useController()

    const handleSubmit = async () => {
      const error = await appConfigController.removeInstanceConfigurationById(
        configId
      )
      if (error) {
        alert.error(error)
        return
      }

      alert.success(
        <>
          Instance configuration &quot;<b>{configKey}</b>
          &quot; was successfully deleted
        </>
      )

      callback?.()

      modal.hideModal()
    }

    const { isLoading } = instanceConfig

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={
              <>
                Are you sure you want to delete configuration &quot;
                <b>{configKey}</b>
                &quot;?
              </>
            }
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <ModalFooterContainer>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={modal.hideModal}
          >
            Cancel
          </Button>
          <Button color="red" disabled={isLoading} onClick={handleSubmit}>
            Delete {isLoading && <Loader />}
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  }
)

export default DeleteConfigModal
