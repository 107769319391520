import { makeAutoObservable } from "mobx"

import { ApiKey, ApiKeyWithSecret } from "@framework/types/api-keys"

export class ApiKeysStore {
  constructor() {
    makeAutoObservable(this)
  }

  apiKeys: ApiKey[] = []

  createdApiKey: ApiKeyWithSecret | null = null

  isLoading = false

  error: string | null = null

  setApiKeys = (keys: ApiKey[]) => {
    this.apiKeys = keys
  }

  setCreatedApiKey = (key: ApiKeyWithSecret | null) => {
    this.createdApiKey = key
  }

  setLoading = (value = true) => {
    this.isLoading = value
  }

  setError = (error: string | null = null) => {
    this.error = error
  }

  get getByIndex() {
    const data = this.apiKeys ?? []
    return (index: number): ApiKey | undefined => {
      return data[index]
    }
  }
}

export default ApiKeysStore
