import React from "react"
import { observer } from "mobx-react-lite"

import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import useAvatarKnowledgePreviewStore from "@store/avatar-knowledge/useAvatarKnowledgePreview"
import { useController } from "@store/index"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"
import DataSourceCategoryCard from "../AvatarKnowledgeSection/DataSourceCategoryCard"

import styles from "./AvailableKnowledge.module.sass"

const FormalKnowledgeSection: React.FC = observer(() => {
  const { avatar } = useActiveAvatar()
  const collection = useAvatarKnowledgePreviewStore(
    avatar.id
  ).dataCategoriesStore

  const { avatarKnowledgePreviewController } = useController()

  React.useEffect(() => {
    if (!avatar) return
    avatarKnowledgePreviewController.loadDataCategories(avatar.id)
  }, [avatar?.id])

  if (collection.isLoading && !collection.data) {
    return <Loader size="large" fluid />
  }

  if (collection.data.length === 0) {
    return <NotFound>No Data Types found</NotFound>
  }

  return (
    <div className={styles.grid}>
      {collection.data.map((category) => {
        return <DataSourceCategoryCard data={category} key={category.id} />
      })}
    </div>
  )
})

export default FormalKnowledgeSection
