import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import EditSelect from "./EditSelect"

import styles from "./index.module.sass"

export type EditSelectRowProps = {
  label: string
  optionName: string
}

const EditSelectRow: React.FC<EditSelectRowProps> = observer(
  ({ label, optionName }) => {
    return (
      <div className={clsx(styles.editSelectRow)}>
        <div>
          <EditSelect label={label} optionName={optionName} />
        </div>
        <div className={clsx(styles.statusWrapper)} />
      </div>
    )
  }
)

export default EditSelectRow
