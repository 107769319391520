import { makeAutoObservable } from "mobx"

import defaultErrorHandler from "@store/utils/error-handlers"
import solutionsService from "@services/solutions.service"

import SolutionsStore from "./solutions.store"

export class SolutionsController {
  solutionsStore: SolutionsStore

  constructor(injections: { solutionsStore: SolutionsStore }) {
    this.solutionsStore = injections.solutionsStore
    makeAutoObservable(this)
  }

  loadAvatarSolutions = async (avatarId: string) => {
    const store = this.solutionsStore
    try {
      store.isLoading = true
      store.errorMessage = ""

      const response = await solutionsService.getSolutions(avatarId)

      store.setSolutions(response.data.data?.productSolutions ?? [])
    } catch (error: any) {
      store.errorMessage = defaultErrorHandler(error, "loading solutions")
      return store.errorMessage
    } finally {
      store.isLoading = false
    }
    return null
  }

  loadAllSolutions = async () => {
    const store = this.solutionsStore
    try {
      store.allSolutionsLoading = true
      store.errorMessage = ""

      const response = await solutionsService.getAllProductSolutions()

      store.setAllSolutions(response.data.data ?? [])
    } catch (e: any) {
      store.errorMessage = "Unexpected error"
    } finally {
      store.allSolutionsLoading = false
    }
  }
}

export default SolutionsController
