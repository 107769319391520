import React, { useState } from "react"
import clsx from "clsx"

import { initArray } from "@utils/numberUtils"

import Icon from "../Icon/Icon"

import styles from "./Rating.module.sass"

const DEFAULT_STAR_COUNT = 5

interface RatingProps {
  onChange?: (rating: number) => void
  value?: number
  count?: number
  editable?: boolean
  className?: string
}

const Rating: React.FC<RatingProps> = ({
  onChange,
  value,
  count = DEFAULT_STAR_COUNT,
  editable = onChange != null,
  className,
}) => {
  const [hovered, setHovered] = useState<number>()

  const getEditProps = (idx: number) =>
    editable
      ? {
          onClick: () => onChange?.(idx + 1),
          onMouseEnter: () => setHovered(idx + 1),
          onMouseLeave: () =>
            setHovered((prev) => (idx + 1 === prev ? undefined : prev)),
        }
      : {}

  return (
    <div className={clsx(styles.root, className)}>
      {initArray(count, (idx) => {
        const rate = idx + 1
        const active = !!value && rate <= value
        const candidate = !!hovered && rate <= hovered
        return (
          <Icon
            key={idx}
            name={active ? "star-filled" : "star"}
            color={active ? "gold" : "default"}
            className={clsx(styles.star, { [styles.candidate]: candidate })}
            {...getEditProps(idx)}
          />
        )
      })}
    </div>
  )
}

export default Rating
