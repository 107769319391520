import { makeAutoObservable } from "mobx"

import { DataSourceFile } from "@framework/types/upload"

import EntityCollection from "../utils/EntityCollection"

export default class AvatarWebsitesKnowledgeStore {
  constructor() {
    makeAutoObservable(this)
  }

  collectionsMap: Map<string, EntityCollection<DataSourceFile>> = new Map()

  getCollection = (dataCategoryId: string) => {
    if (!this.collectionsMap.has(dataCategoryId))
      this.resetCollection(dataCategoryId)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.collectionsMap.get(dataCategoryId)!
  }

  resetCollection = (collectionKey: string) => {
    this.collectionsMap.set(collectionKey, new EntityCollection())
  }
}
