import React from "react"
import { observer } from "mobx-react-lite"

import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import Collapsable from "@components/ui/Collapsable/Collapsable"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"

import styles from "./AnswerSummary.module.sass"

type AnswerInsightsProps = {
  opened: boolean
}

const AnswerInsights: React.FC<AnswerInsightsProps> = observer(({ opened }) => {
  const { searchEntityBlock } = useSearchBlock(SearchSummaryBlockStore)
  return (
    <Collapsable open={opened}>
      <div className={styles.insights}>
        {searchEntityBlock.messages.map((insight, index) => (
          <div key={insight}>{insight}</div>
        ))}
      </div>
    </Collapsable>
  )
})

export default AnswerInsights
