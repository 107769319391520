import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { ExtendedResultType } from "@framework/constants/search-results"
import { Option } from "@framework/types/utils"

export type PathParamsType = {
  id: string
  tab: ExtendedResultType
}

export const useTabs = (options: Option<ExtendedResultType>[]) => {
  const { tab } = useParams<PathParamsType>()

  const [resultType, setResultType] = useState<ExtendedResultType | undefined>(
    tab
  )

  useEffect(() => {
    if (!options.length) return
    if (!tab || !options.find(({ name }) => name === tab))
      setResultType(options[0].name)
  }, [options, tab])

  return {
    resultType,
    setResultType,
  }
}

export default useTabs
