import React from "react"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import * as yup from "yup"

import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { TextInput, TextAreaInput } from "@components/ui/TextInput/TextInput"
import Templates from "@components/ui/Templates"
import Label from "@components/ui/Label/Label"
import List from "@components/ui/List/List"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"

import styles from "./EditTopicModal.module.sass"

export const validationSchema = yup.object({
  title: yup
    .string()
    .required("Filed is required")
    .transform((it) => (it === null ? undefined : it))
    .default("")
    .trim(),
  description: yup
    .string()
    .required("Filed is required")
    .transform((it) => (it === null ? undefined : it))
    .default("")
    .trim(),
})

export type EditTopicFormData = yup.InferType<typeof validationSchema>

export interface EditTopicFormProps {
  initialValue?: Partial<EditTopicFormData>
  onSubmit?: (form: EditTopicFormData) => Promise<void>
  onCancel?: () => void
}

export const EditTopicForm: React.FC<EditTopicFormProps> = observer(
  ({ initialValue, onSubmit, onCancel }) => {
    const [isLoading, setLoading] = React.useState(false)

    const handleSubmit = async (form: EditTopicFormData) => {
      setLoading(true)
      await onSubmit?.(validationSchema.cast(form))
      setLoading(false)
    }

    const formik = useFormik<EditTopicFormData>({
      initialValues: validationSchema.cast(initialValue, {
        stripUnknown: true,
      }),
      validationSchema,
      onSubmit: handleSubmit,
    })

    return (
      <form onSubmit={formik.handleSubmit}>
        <Templates.RollScript
          className={styles.root}
          gutter="24"
          footerSocket={
            <ModalFooterContainer>
              <Button
                disabled={isLoading}
                variant="outlined"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                type="submit"
                after={isLoading && <Loader />}
              >
                Save
              </Button>
            </ModalFooterContainer>
          }
        >
          <List gutter="24">
            <Label id="title" label="Topic">
              <TextInput
                placeholder="Enter Topic Name"
                value={formik.values.title}
                onChange={formik.handleChange}
                name="title"
                withError={!!(formik.touched.title && formik.errors.title)}
                onBlur={formik.handleBlur}
              />
            </Label>
            <Label id="description" label="Description">
              <TextAreaInput
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder="Add Description of the topic"
                className={styles.input}
                withError={
                  !!(formik.touched.description && formik.errors.description)
                }
                onBlur={formik.handleBlur}
                type="textarea"
              />
            </Label>
          </List>
        </Templates.RollScript>
      </form>
    )
  }
)

export default EditTopicForm
