/* eslint-disable no-underscore-dangle */
import { makeAutoObservable } from "mobx"

import { AppPatchNote } from "@framework/types/app"
import appService from "@services/app.service"

export class UpdatesStore {
  constructor() {
    makeAutoObservable(this)
  }

  isChangeLogLoading: boolean = false

  changeLog: AppPatchNote[] | null = null

  errorMessage: string | null = null

  get hasChanges() {
    return Boolean(this.changeLog?.length)
  }

  loadChangeLog = async () => {
    this.isChangeLogLoading = true
    try {
      const response = await appService.getChangeLog()

      if (response.status === 200) {
        this.changeLog = response.data.data
        return
      }
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.errorMessage = "Loading failed"
        return
      }
    } finally {
      this.isChangeLogLoading = false
    }
  }
}

export default UpdatesStore
