/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { ExtendedResultType } from "@framework/constants/search-results"
import { useStore } from "@store/index"
import NotFound from "@components/ui/NotFound/NotFound"
import MainBoxLayout from "@components/layout/MainBoxLayout/MainBoxLayout"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import SearchEntityStore from "@store/search/search-entity.store"

import { MixedResult } from "./MixedResult"
import { getAvailableOptions } from "./utils"

export type PathParamsType = {
  searchId: string
  blockId: string
  passageId: string
  tab: ExtendedResultType
}

export const FactFinderExtendedResult: React.FC = observer(() => {
  const { factFinderSolutionStore } = useStore()

  const { searchId, passageId, blockId } = useParams<PathParamsType>()

  if (!searchId || !passageId || !blockId)
    throw new Error(
      "searchId, blockId and passageId required for Extended Result Page"
    )

  const searchEntity = factFinderSolutionStore.searchFlowStore.getById(searchId)

  if (!(searchEntity instanceof SearchEntityStore))
    throw new Error("Unexpected SearchEntity store type")

  const searchBlock = searchEntity.getBlockById(blockId)

  if (!(searchBlock instanceof SearchSummaryBlockStore))
    throw new Error("Unexpected SearchBlockEntity store type")

  const answer = searchBlock?.searchAnswersData?.getAnswerById(passageId)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const options = useMemo(() => {
    if (!answer) return []
    return getAvailableOptions(answer)
  }, [answer])

  if (!answer)
    return (
      <MainBoxLayout backOnBgClick>
        <NotFound />
      </MainBoxLayout>
    )

  return <MixedResult answer={answer.value} options={options} />
})

export default FactFinderExtendedResult
