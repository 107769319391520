import clsx from "clsx"
import React from "react"

import styles from "./ScrollableContainer.module.sass"

export interface ScrollableContainerProps
  extends React.HTMLProps<HTMLDivElement> {
  className?: string
  ulProps?: React.HTMLProps<HTMLUListElement>
}

export const ScrollableContainer: React.FC<ScrollableContainerProps> = ({
  className,
  children,
  ulProps = {},
  ...rest
}) => (
  <div className={clsx(styles.root, className)} {...rest}>
    <ul className={styles.list} {...ulProps}>
      {children}
    </ul>
  </div>
)

export default ScrollableContainer
