import { observer } from "mobx-react-lite"
import React from "react"

import { AnswerViewType } from "@framework/constants/search-results"
import { AnswerItem } from "@framework/types/search"

import UpVoteAnswerControl from "./UpVoteAnswerControl"

export interface SearchAnswerControlsProps {
  question: string
  answer: AnswerItem
  type: AnswerViewType
}

export const SearchAnswerControls: React.FC<SearchAnswerControlsProps> =
  observer(({ question, answer, type }) => (
    <>
      <UpVoteAnswerControl
        id={answer.name}
        question={question}
        answer={answer.value}
        answerType={type}
      />
    </>
  ))

export default SearchAnswerControls
