import { useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useStore } from "@store/index"
import { Option } from "@framework/types/utils"
import { DataSourceName } from "@framework/types/upload"

import styles from "./index.module.sass"

export const defaultContextOptions: Option[] = []

export const adminContextOptions: Option[] = [
  {
    name: "delete",
    value: "Remove",
    icon: "trash-can",
    className: styles.redMenuItem,
  },
]

const useDataConnectionContextMenu = (props: {
  sourceName: DataSourceName
  isReadonly?: boolean
  afterAction?: () => void
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const {
    uploadSourceStore: { deleteSourceUpload },
  } = useStore()

  const handleContext = useCallback(
    async (id: string, name: string) => {
      if (name === "delete") await deleteSourceUpload(props.sourceName, id)

      props.afterAction?.()
    },
    [deleteSourceUpload, location.pathname, navigate, props.sourceName]
  )

  const options = useMemo(() => {
    if (props.isReadonly) return defaultContextOptions
    return [...defaultContextOptions, ...adminContextOptions]
  }, [props.isReadonly])

  return useMemo(
    () => ({
      options,
      onContext: handleContext,
    }),
    [handleContext, options]
  )
}

export default useDataConnectionContextMenu
