import { RenderedSection } from "react-virtualized/dist/es/Grid"

import { Point, Range } from "@framework/types/common"

export const maxPoint = (p1: Point, p2: Point) => {
  return {
    x: Math.max(p1.x, p2.x),
    y: Math.max(p1.y, p2.y),
  }
}

export const minPoint = (p1: Point, p2: Point): Point => {
  return {
    x: Math.min(p1.x, p2.x),
    y: Math.min(p1.y, p2.y),
  }
}

export const indexToAlphabetCode = (index: number): string => {
  if (index < 0) return ""
  return (
    indexToAlphabetCode(Math.floor(index / 26) - 1) +
    String.fromCharCode(65 + (index % 26))
  )
}

export const pointToCode = (range: Point) => {
  return `${indexToAlphabetCode(range.x)}${range.y + 1}`
}

export const rangeToCode = (range: Range<Point>) => {
  if (rangeSize(range) > 1)
    return `${pointToCode(range.start)}:${pointToCode(range.end)}`

  return pointToCode(range.start)
}

/**
 * @returns new range which is difference between ranges passed as an arguments
 */
export const intersection = (
  range1: Range<Point>,
  range2: Range<Point>
): Range<Point> => {
  return {
    start: maxPoint(range1.start, range2.start),
    end: minPoint(range1.end, range2.end),
  }
}

/**
 * @returns true when the point belongs to the the range
 */
export const includes = (range: Range<Point>, point: Point): boolean => {
  return (
    range.start.x <= point.x &&
    range.end.x >= point.x &&
    range.start.y <= point.y &&
    range.end.y >= point.y
  )
}

export const intersects = (
  range1: Range<Point>,
  range2: Range<Point>
): boolean => {
  return (
    includes(range1, range2.start) ||
    includes(range1, range2.end) ||
    includes(range2, range1.start) ||
    includes(range2, range1.end) ||
    (range1.start.x < range2.start.x &&
      range1.end.x > range2.end.x &&
      range1.start.y > range2.start.y &&
      range1.end.y < range2.end.y) ||
    (range1.start.x > range2.start.x &&
      range1.end.x < range2.end.x &&
      range1.start.y < range2.start.y &&
      range1.end.y > range2.end.y)
  )
}

export const contains = (
  range1: Range<Point>,
  range2: Range<Point>
): boolean => {
  return includes(range1, range2.start) && includes(range1, range2.end)
}

/**
 * @returns new valid range made of two passed points
 */
export const makeRange = (p1: Point, p2: Point): Range<Point> => ({
  start: minPoint(p1, p2),
  end: maxPoint(p1, p2),
})

/**
 * @returns return number of points inside a passed range
 */
export const rangeSize = (range: Range<Point>): number => {
  return (range.end.x - range.start.x + 1) * (range.end.y - range.start.y + 1)
}

export const cellIdFromPoint = (cell: Point) => {
  return `${cell.x}-${cell.y}`
}

export const cellIdToPoint = (cellId: string) => {
  const [x, y] = cellId.split("-")
  return { x: Number(x), y: Number(y) }
}

export const letterCodeToNumber = (letterCode: string) => {
  let n = 0

  for (let i = 0; i < letterCode.length; i += 1) {
    n = letterCode.charCodeAt(i) - 64 + n * 26
  }

  return n
}

export const refToPoint = (ref: string): Point => {
  const tokens = ref.replaceAll("$", "").match(/(^[a-zA-Z]+)|([0-9]+$)/g)
  if (tokens == null || tokens.length === 0)
    throw new Error("Incorrect ref format")

  return {
    x: letterCodeToNumber(tokens[0]) - 1,
    y: Number(tokens[1]) - 1,
  }
}

export const refToRange = (ref: string): Range<Point> => {
  if (ref.includes(":")) {
    const parts = ref.split(":")
    return makeRange(refToPoint(parts[0]), refToPoint(parts[1]))
  }
  return makeRange(refToPoint(ref), refToPoint(ref))
}

export const renderSectionToRange = (section: RenderedSection) => {
  return {
    start: {
      x: section.columnStartIndex,
      y: section.rowStartIndex,
    },
    end: {
      x: section.columnStopIndex,
      y: section.rowStopIndex,
    },
  }
}

export const forEachOfRange = (
  range: Range<Point>,
  callback: (x: number, y: number) => void
) => {
  const cols = range.end.x - range.start.x + 1
  const rows = range.end.y - range.start.y + 1

  for (let xi = 0; xi < cols; xi += 1) {
    for (let yi = 0; yi < rows; yi += 1) {
      callback(range.start.x + xi, range.start.y + yi)
    }
  }
}

export const validateOperand = (arg: unknown) => {
  if (typeof arg === "object") throw new Error("#VALUE!")
  return Number(arg)
}
