import { useFormik } from "formik"
import React, { useMemo } from "react"
import * as yup from "yup"

import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import TextInput from "@components/ui/TextInput/TextInput"
import UserAvatar from "@pages/profile/components/UserAvatar/UserAvatar"
import RadioItem from "@components/prototypes/RadioItem/RadioItem"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import Label from "@components/ui/Label/Label"
import Chip from "@components/ui/Chip/Chip"
import List from "@components/ui/List/List"
import { AccessType } from "@framework/types/avatar"
import { Option } from "@framework/types/utils"
import Editor from "@components/ui/Editor/Editor"

import styles from "./BasicInfoForm.module.sass"

export const validationSchema = yup.object({
  name: yup
    .string()
    .required("Field is required")
    .matches(/^[a-zA-Z\s]+$/i, { message: "Incorrect format" })
    .max(100)
    .min(3)
    .default("")
    .trim(),
  description: yup.string().default("").trim(),
  image: yup.mixed().nullable().notRequired(),
  accessType: yup
    .mixed<AccessType>()
    .oneOf(Object.values(AccessType))
    .required("Field is required")
    .default(AccessType.Public),
})

const accessTypeOptions: Option[] = [
  {
    value: AccessType.Private,
    name: "Private - Specific people only",
    description: "Can only be viewed or joined by invitation",
  },
  { value: AccessType.Public, name: "Public - Everyone" },
]

export type FormData = yup.InferType<typeof validationSchema>

export interface BasicInfoFormProps {
  initialValues?: Partial<FormData>
  isEdit?: boolean
  onSubmit?: (data: FormData) => void
  onCancel?: () => void
}

export const BasicInfoForm: React.FC<BasicInfoFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  isEdit,
}) => {
  const handleSubmit = (data: FormData) =>
    onSubmit?.(validationSchema.cast(data))

  const handleCancel = onCancel

  const formik = useFormik<FormData>({
    initialValues: {
      ...validationSchema.getDefault(),
      ...validationSchema.cast({ ...initialValues }, { stripUnknown: true }),
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  })

  const handleUpdateAvatar = async (file: File) => {
    formik.setFieldValue("image", file)
  }

  const coverImageSrc = useMemo(() => {
    const { image } = formik.values

    if (!image) return ""

    return typeof image === "string" ? image : URL.createObjectURL(image)
  }, [formik.values.image])

  const isPrivate = isEdit && initialValues?.accessType === AccessType.Private

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <Templates.RollScript
        gutter="32"
        footerSocket={
          <ModalFooterContainer>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              {isEdit ? "Save" : "Create"}
            </Button>
          </ModalFooterContainer>
        }
      >
        <List gutter="32">
          <UserAvatar
            src={coverImageSrc}
            accept=".png, .jpg, .jpeg"
            onAvatarChange={handleUpdateAvatar}
          />

          <Label id="name" label="Name of avatar" uppercase>
            <TextInput
              className={styles.input}
              placeholder="Enter name"
              value={formik.values.name}
              onChange={formik.handleChange}
              name="name"
              withError={!!(formik.touched.name && formik.errors.name)}
              onBlur={formik.handleBlur}
              after={
                formik.touched.name && formik.errors.name ? (
                  <div className={styles.after}>
                    <ErrorChip
                      messagePlacement="left"
                      message={formik.errors.name}
                    />
                  </div>
                ) : null
              }
            />
          </Label>

          <Label id="description" label="Add description" uppercase>
            <Editor
              onChange={(v) => formik.setFieldValue("description", v)}
              defaultValue={formik.values.description}
              className={styles.reachTextEditor}
              placeholder="Add Description of the Avatar"
            />
          </Label>

          <Label
            id="accessType"
            label="Visibility"
            description={
              isEdit ? (
                <Chip
                  variant="rounded"
                  color={isPrivate ? "gold" : "green"}
                  uppercase
                >
                  {initialValues?.accessType}
                </Chip>
              ) : (
                <Text variant="inherit" color="text70Color">
                  (cannot be changed once set)
                </Text>
              )
            }
          >
            {isEdit ? (
              <Text variant="inherit">
                {isPrivate
                  ? "Can only be viewed or joined by invitation"
                  : "Available to all the users"}
              </Text>
            ) : (
              <List>
                {accessTypeOptions.map(({ value, name, description }) => {
                  const handleOptionClick = () =>
                    formik.setFieldValue("accessType", value)
                  return (
                    <RadioItem
                      key={value}
                      checked={value === formik.values.accessType}
                      label={name}
                      description={description}
                      onClick={handleOptionClick}
                    />
                  )
                })}
              </List>
            )}
          </Label>
        </List>
      </Templates.RollScript>
    </form>
  )
}

export default BasicInfoForm
