/* eslint-disable jsx-a11y/no-redundant-roles */
import clsx from "clsx"
import React, { useCallback } from "react"

import useActiveElement from "@components/hooks/useIsActiveElement"

import styles from "./TextInput.module.sass"

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  before?: React.ReactNode
  after?: React.ReactNode
  inputClassName?: string
  withError?: boolean
  forwardRef?: any
}

export const TextInput: React.FC<TextInputProps> = ({
  value,
  before,
  after,
  className,
  inputClassName,
  withError,
  forwardRef,
  onChange,
  onFocus,
  onBlur,
  ...rest
}) => {
  const [isOpened, open, rootNode] = useActiveElement<HTMLDivElement>()

  const handleFocus = useCallback(
    (e) => {
      onFocus?.(e)
      open(true)
    },
    [onFocus, open]
  )

  const handleBlur = useCallback(
    (e) => {
      onBlur?.(e)
      open(false)
    },
    [onBlur]
  )

  return (
    <div
      className={clsx(
        styles.root,
        { [styles.withError]: withError, [styles.focused]: isOpened },
        className
      )}
      ref={rootNode}
    >
      {before}
      <input
        {...rest}
        ref={forwardRef}
        className={clsx(
          styles.input,
          {
            [styles.withBefore]: before,
            [styles.withAfter]: after,
          },
          inputClassName
        )}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {after}
    </div>
  )
}

export interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  before?: React.ReactNode
  after?: React.ReactNode
  inputClassName?: string
  withError?: boolean
}

export const TextAreaInput = React.forwardRef<
  HTMLTextAreaElement | null,
  TextAreaProps
>(
  (
    { before, after, className, inputClassName, withError, rows = 7, ...rest },
    externalRef
  ) => {
    return (
      <div
        className={clsx(
          styles.root,
          styles.area,
          { [styles.withError]: withError },
          className
        )}
      >
        {before}
        <textarea
          {...rest}
          ref={externalRef}
          className={clsx(
            styles.input,
            {
              [styles.withBefore]: before,
              [styles.withAfter]: after,
            },
            inputClassName
          )}
          rows={rows}
        />
        {after}
      </div>
    )
  }
)

export default TextInput
