import clsx from "clsx"
import React, {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useState,
} from "react"
import { SwipeEventData, useSwipeable } from "react-swipeable"

import useGlobalController from "@components/hooks/useGlobalScrollController"

import styles from "./SwipeableFooter.module.sass"

const ANIMATION_DURATION_SECONDS = 0.3

export interface SwipeableFooterProps {
  title: React.ReactNode
  defaultValue?: boolean
  className?: string
  onClose?: () => void
}

export const SwipeableFooter: React.FC<SwipeableFooterProps> = ({
  title,
  defaultValue,
  children,
  className,
  onClose,
}) => {
  const { switchScroll } = useGlobalController()

  const [isOpened, setOpened] = useState<boolean | undefined>(defaultValue)
  const [width, setWidth] = useState(0)

  const handleSwipe = (e: SwipeEventData) => {
    if (e.deltaY < 0) {
      return
    }
    setWidth(e.deltaY)
  }

  const handleSwipeDown = (e: SwipeEventData) => {
    if (!onClose || Math.abs(e.deltaY) < 30) {
      setWidth(0)
      return
    }
    onClose()
    setOpened(false)
  }

  const swipeProps = useSwipeable({
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    onSwiping: handleSwipe,
    onSwipedDown: handleSwipeDown,
  })

  useLayoutEffect(() => {
    if (isOpened) setWidth(0)
    switchScroll(isOpened)
  }, [isOpened])

  useLayoutEffect(() => () => switchScroll(false), [])

  useEffect(() => {
    if (defaultValue !== isOpened) setOpened(defaultValue)
  }, [defaultValue])

  const style = {
    "--swipe-shift": `${width}px`,
    "--animation-duration": `${ANIMATION_DURATION_SECONDS}s`,
  } as CSSProperties

  return (
    <div
      style={style}
      className={clsx(styles.root, {
        [styles.hidden]: !isOpened && isOpened != null,
        [styles.shown]: isOpened,
      })}
    >
      <div {...swipeProps} className={styles.header}>
        {!!onClose && <span className={styles.handle} />}
        {title}
      </div>

      <div className={clsx(styles.body, className)}>{children}</div>
    </div>
  )
}

export default SwipeableFooter
