import React from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react-lite"

export const ThemeGlobalStyles: React.FC<{
  config?: Record<string, string>
}> = observer(({ config }) => {
  const entries = config
    ? Object.entries(config).map<[string, string]>(([name, value]) => [
        `--${name}`,
        value,
      ])
    : []

  return ReactDOM.createPortal(
    <style>{`
        :root {
          ${renderStyleEntries(entries)}
        }
      `}</style>,
    document.head
  )
})

export default ThemeGlobalStyles

const renderStyleEntries = (styles: [string, string][]) =>
  styles.map((it) => it.join(": ")).join(";\n")
