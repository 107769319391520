import React from "react"
import { observer } from "mobx-react-lite"

import Row from "@components/ui/BaseTable/Row"
import Column from "@components/ui/BaseTable/Column"
import Table from "@components/ui/BaseTable/Table"
import Text from "@components/ui/Typography/Text"
import { SubscriptionOption } from "@framework/types/subscription"
import { useStore } from "@store"
import { SUBSCRIPTION_OPTION_LABELS } from "@framework/constants/subscription"

import EditSelectRow from "./EditSelectRow"
import StatusSwitch from "./StatusSwitch"

interface SubscriptionOptionsProps {
  optionName: string
}

const SubscriptionOptions: React.FC<SubscriptionOptionsProps> = observer(
  ({ optionName }) => {
    const { subscriptionStore } = useStore()
    const { editedSubscriptionData, isEditable } = subscriptionStore
    const label = SUBSCRIPTION_OPTION_LABELS[optionName]

    const selectedOptions: SubscriptionOption[] = (
      editedSubscriptionData as any
    )?.[optionName]

    return (
      <>
        {isEditable && <EditSelectRow label={label} optionName={optionName} />}
        <Table hideBorder>
          {selectedOptions.map((option) => {
            return (
              <Row key={option.id}>
                <Column width="50%" style={{ paddingLeft: "0px" }}>
                  <Text variant="h5" weight="bold">
                    {option.name}
                  </Text>
                </Column>
                <Column width="50%">
                  <StatusSwitch option={option} optionName={optionName} />
                </Column>
              </Row>
            )
          })}
        </Table>
      </>
    )
  }
)

export default SubscriptionOptions
