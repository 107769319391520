import clsx from "clsx"
import React from "react"
import ReactDOM from "react-dom"

import styles from "./Popper.module.scss"

export interface PopperProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean
}

export const Popper = React.forwardRef<HTMLDivElement, PopperProps>(
  ({ children, isActive = false, className, ...rest }, ref) => {
    if (!isActive) return null
    return ReactDOM.createPortal(
      <div {...rest} className={clsx(styles.root, className)} ref={ref}>
        {children}
      </div>,
      document.body
    )
  }
)

export default Popper
