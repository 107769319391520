import React from "react"
import difference from "lodash/difference"
import { observer } from "mobx-react-lite"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import { CreateApiKeyFormData } from "@components/modals/ApiKeysModal/CreateApiKeyModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { useController, useStore } from "@store/index"

interface ContextMenuProps {
  apiKeyId: string
}

const ContextMenu: React.FC<ContextMenuProps> = observer(({ apiKeyId }) => {
  const {
    restrictionsStore: access,
    apiKeysStore: { apiKeys },
  } = useStore()
  const {
    apiKeysController: { updateApiKey, deleteApiKey },
  } = useController()

  const { isOpened, handleToggle, setOpened } = useToggle()

  const apiKey = apiKeys.find((key) => key.id === apiKeyId)

  const createApiKeyModal = useModal(ModalsTypes.CREATE_API_KEY_MODAL)
  const deleteModal = useModal(ModalsTypes.DELETE_API_KEY_MODAL)

  if (!apiKey) return null

  const handleSubmit = async (form: CreateApiKeyFormData) => {
    const addPermissions = difference(
      form.selectedSolutions,
      apiKey.permissions
    )

    const removePermissions = difference(
      apiKey.permissions, // order is reversed
      form.selectedSolutions
    )

    updateApiKey(apiKey.id, {
      name: form.name,
      addPermissions,
      removePermissions,
    })
    createApiKeyModal.hideModal()
  }

  const handleUpdate = () =>
    createApiKeyModal.showModal({
      apiKey,
      onSubmit: handleSubmit,
    })

  const handleDelete = () =>
    deleteModal.showModal({
      apiKey,
      onSubmit: async () => {
        deleteApiKey(apiKey.id)
        deleteModal.hideModal()
      },
    })

  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      content={
        <TooltipContainer color="primary" placement="bottom">
          <MenuItem icon="key-2" onClick={handleUpdate} key="edit">
            Edit
          </MenuItem>

          {access.canDeleteUser && (
            <MenuItem
              color="red"
              icon="trash-can"
              onClick={handleDelete}
              key="logout"
            >
              Delete
            </MenuItem>
          )}
        </TooltipContainer>
      }
    >
      <IconButton size="medium" active={isOpened} onClick={handleToggle}>
        <Icon name="dots" />
      </IconButton>
    </Tooltip>
  )
})

export default ContextMenu
