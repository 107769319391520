import { makeAutoObservable } from "mobx"

import { AvatarDetailsData } from "@framework/types/avatar"

export class AvatarDetailsStore {
  constructor() {
    makeAutoObservable(this)
  }

  data: AvatarDetailsData | null = null

  error: string | null = null

  isLoading = false

  storeData = (data: AvatarDetailsData) => {
    this.data = data
  }
}

export default AvatarDetailsStore
