import { observer } from "mobx-react-lite"
import React from "react"
import ReactDOM from "react-dom"
import { Link } from "react-router-dom"

import Icon from "@components/ui/Icon/Icon"
import { Option } from "@framework/types/utils"
import { useStore } from "@store/index"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import Logo from "@components/prototypes/Logo/Logo"

import NavItem from "../MobileSideMenu/NavItem/NavItem"
import NavItemTooltip from "./NavItemTooltip"
import UserSettingsMenu from "./UserSettingsMenu"
import UIThemeMenu from "./UIThemeMenu"

import styles from "./Sidebar.module.sass"

type NavItemBadgeProps = {
  name: string
}

const NavItemBadge: React.FC<NavItemBadgeProps> = observer(
  ({ name }) =>
    // const {
    //   updateStore: { hasChanges },
    // } = useStore()
    // if (name === "/updates" && hasChanges)
    //   return <NotificationBadge className={styles.loader} />
    null
)

export interface SidebarProps {
  menuOptions: Option[]
  profileOptions?: Option[]
}

export const Sidebar: React.FC<SidebarProps> = observer(
  ({ menuOptions, profileOptions }) => {
    const theme = useAppConfig()

    const { restrictionsStore: access } = useStore()

    return ReactDOM.createPortal(
      <div className={styles.root}>
        <div className={styles.header}>
          <Link to="/home" className={styles.logo}>
            <Logo name={theme.copy.logo} />
          </Link>
        </div>
        <div className={styles.menu}>
          <NavItemTooltip text="Home" key="home">
            <NavItem to="/home" icon="home" className={styles.navItem} />
          </NavItemTooltip>

          {menuOptions.map(({ value, name, icon }) => (
            <NavItemTooltip text={value} key={name}>
              <NavItem
                to={name}
                icon={icon}
                className={styles.navItem}
                mark={<NavItemBadge name={name} />}
              />
            </NavItemTooltip>
          ))}
        </div>
        <div className={styles.footer}>
          {access.canSwitchTheme && <UIThemeMenu />}

          <NavItemTooltip text={theme.support.supportButtonName}>
            <a
              href={theme.support.supportPageURL}
              target="_blank"
              rel="noreferrer"
            >
              <Icon className={styles.helpButton} name="question-mark" />
            </a>
          </NavItemTooltip>

          <UserSettingsMenu menuOptions={profileOptions} />
        </div>
      </div>,
      document.body
    )
  }
)

export default Sidebar
