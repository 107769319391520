import React from "react"
import * as yup from "yup"
import { useFormik } from "formik"

import Button from "@components/ui/Button/Button"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"

import useAvatarScope from "../AvatarScopeContext/useAvatarScope"
import WelcomeCard from "./WelcomeCard"
import InfoContainer from "./InfoContainer"

import styles from "./Intro.module.sass"

export const validationSchema = yup.object({
  defaultValue: yup.bool().default(false),
})

export type FormData = yup.InferType<typeof validationSchema>

type IntroProps = {
  onHide: () => void
}

const Intro: React.FC<IntroProps> = ({ onHide }) => {
  const { avatar } = useAvatarScope()
  const formik = useFormik<FormData>({
    initialValues: validationSchema.getDefault(),
    validationSchema,
    onSubmit: (form: FormData) => {
      if (form.defaultValue) {
        localStorage.setItem("DONT_SHOW_INTRO_AGAIN", "true")
      }
      localStorage.setItem("INTRO_SHOWN", "false")
      onHide()
    },
  })

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <WelcomeCard avatar={avatar} />
        <InfoContainer avatar={avatar} />
      </div>
      <div className={styles.goHomeContainer}>
        <div>Switch between Avatars in Homepage to see more solutions</div>
        <Button
          color="primary"
          onClick={formik.submitForm}
          className={styles.btn}
        >
          Continue to Your Home Page
        </Button>
      </div>
      <div className={styles.goHomeContainer}>
        <CheckboxWithLabel
          checked={formik.values.defaultValue}
          onCheck={() =>
            formik.setFieldValue("defaultValue", !formik.values.defaultValue)
          }
          label="Don’t show me this again"
        />
      </div>
    </div>
  )
}

export default Intro
