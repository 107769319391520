import { makeAutoObservable } from "mobx"

import { ModalsTypes } from "@components/modals/constants"
import { ModalsFactoryProps } from "@components/modals/ModalsFactory"

type ModalsInstance = {
  id: ModalsTypes
  props: ModalsFactoryProps[ModalsTypes]
}

export class ModalStore {
  constructor() {
    makeAutoObservable(this)
  }

  modals: ModalsInstance[] = []

  activeModalId: ModalsTypes | null = null

  get isOpen() {
    return !!this.activeModalId
  }

  get modal() {
    if (!this.activeModalId) return undefined
    return this.modals.find((item) => item.id === this.activeModalId)
  }

  openModal = <T extends ModalsTypes>(id: T, props: ModalsFactoryProps[T]) => {
    const instance = this.modals.findIndex((item) => item.id === id)
    this.activeModalId = id
    if (instance >= 0) {
      this.modals[instance].props = props
    } else {
      this.modals.push({
        id,
        props,
      })
    }
  }

  closeModal = <T extends ModalsTypes>(id?: T) => {
    if (!id) {
      this.modals = []
      this.activeModalId = null
      return
    }

    const newModals = this.modals.filter((instance) => instance.id !== id)
    this.modals = newModals

    if (newModals.length === 0) {
      this.activeModalId = null
      return
    }

    this.activeModalId = newModals[newModals.length - 1].id
  }
}

export default ModalStore
