import { makeAutoObservable } from "mobx"
import clamp from "lodash/clamp"

import { forEachOfRange, intersection, refToRange } from "../utils"
import Range2D from "./Range2D"
import MatrixGrid from "./MatrixGrid"
import EditManager from "./EditManager"
import Resizer from "./Resizer"
import { CellValidationRule } from "./CellManager"

class MatrixStore {
  grid: MatrixGrid

  editor: EditManager

  renderTrigger: number = 0

  selectedRange: Range2D

  resizer: Resizer

  constructor() {
    this.selectedRange = new Range2D()
    this.grid = new MatrixGrid()
    this.resizer = new Resizer()
    this.editor = new EditManager({ context: this })

    makeAutoObservable(this)
  }

  init = (options: { initialValue?: string[][] }) => {
    if (options.initialValue) this.editor.initMatrix(options.initialValue)
  }

  render = () => {
    this.renderTrigger += 1
  }

  moveSelection = (xShift: number, yShift: number) => {
    const { x, y } = this.selectedRange.origin
    const { totalColumns, totalRows } = this.grid

    const point = {
      x: clamp(x + xShift, 0, totalColumns - 1),
      y: clamp(y + yShift, 0, totalRows - 1),
    }

    this.selectedRange.setSelection(point)
  }

  setValidation = (ref: string, validation: CellValidationRule) => {
    const range = refToRange(ref)

    const rect = intersection(this.grid.rect, range)

    forEachOfRange(rect, (x, y) => {
      this.editor.getCellAtPoint({ x, y }).setValidation(validation)
    })
  }
}

export default MatrixStore
