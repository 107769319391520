import React from "react"

import {
  DocumentIconType,
  getDocumentIconSrc,
  isDocumentIconName,
} from "@framework/types/utils"

import Icon from "./Icon"
import IconChip, { IconChipProps } from "../Chip/IconChip"
import { IconName } from "./IconName"

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  icon: DocumentIconType | IconName
  color?: IconChipProps["color"]
  src?: never
}

export const DocumentIcon: React.FC<Props> = ({
  icon,
  color = "primary",
  height = 45,
  width = 45,
  onClick,
  className,
  ...rest
}) => {
  if (isDocumentIconName(icon))
    return (
      <img
        alt={icon}
        height={height}
        width={width}
        {...rest}
        src={getDocumentIconSrc(icon)}
        className={className}
      />
    )
  return (
    <IconChip color={color} className={className} onClick={onClick}>
      <Icon name={icon} />
    </IconChip>
  )
}

export default DocumentIcon
