import { makeAutoObservable } from "mobx"

import { FeatureFlag } from "@framework/types/app"
import { defaultAppRestrictions } from "@framework/constants/app"

import AppStore from "./app.store"

export class FeaturesStore {
  appStore: AppStore

  constructor(injections: { appStore: AppStore }) {
    makeAutoObservable(this)

    this.appStore = injections.appStore
  }

  data: Map<string, FeatureFlag> = new Map()

  get features() {
    return Array.from(this.data.values())
  }

  isLoading = false

  error: string | null = null

  storeFeatures = (featureFlags: FeatureFlag[]) => {
    this.data = new Map(featureFlags.map((it) => [it.id, it]))
  }

  toggleFlag = (flagId: string, value: boolean) => {
    const flag = this.data.get(flagId)
    if (flag) flag.value = value
  }

  getFlagById = (flagId: string) => this.data.get(flagId)

  isFlagUsed = (flagId: string) => {
    const flag = this.data.get(flagId)
    if (flag == null) return false
    return (
      defaultAppRestrictions[flag.key as keyof typeof defaultAppRestrictions] !=
      null
    )
  }
}

export default FeaturesStore
