import React from "react"
import clsx from "clsx"

import styles from "./ScrollableContainer.module.sass"

export interface ScrollableContainerProps {
  className?: string
}

export const ScrollableContainer: React.FC<ScrollableContainerProps> = ({
  className,
  children,
}) => <div className={clsx(styles.root, className)}>{children}</div>

export default ScrollableContainer
