import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import Text from "@components/ui/Typography/Text"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import { useController, useStore } from "@store"

import styles from "./SubscriptionUpgradeModal.module.sass"

export interface SubscriptionUpgradeModalProps {}

export const SubscriptionUpgradeModal: React.FC<SubscriptionUpgradeModalProps> =
  observer(() => {
    const [description, setDescription] = useState("")
    const alert = useAlert()
    const { subscriptionController } = useController()
    const { subscriptionStore } = useStore()
    const { hideModal } = useModal(ModalsTypes.SUBSCRIPTION_UPGRADE_MODAL)

    const handleSubmit = async () => {
      const res = await subscriptionController.upgradeSubscription(description)
      if (res?.data?.message) {
        alert.success(res.data.message)
      } else {
        alert.error("Failed top send upgrade request")
      }
      hideModal()
    }

    return (
      <BaseModal
        title={<ModalTitle titleText="Upgrade Request Form" />}
        className={styles.root}
        containerClassName={styles.container}
        onClose={hideModal}
      >
        <div className={styles.body}>
          <Text className={styles.label}>NOTES TO NESH</Text>
          <TextAreaInput
            name="description"
            onChange={(e) =>
              setDescription((e.target as HTMLTextAreaElement).value)
            }
            onBlur={(e) =>
              setDescription((e.target as HTMLTextAreaElement).value)
            }
            placeholder="Add Description of the topic"
            className={styles.input}
            type="textarea"
          />
        </div>
        <ModalFooterContainer className={styles.footer}>
          <Button
            variant="outlined"
            onClick={hideModal}
            disabled={subscriptionStore.isLoading}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={!description || subscriptionStore.isLoading}
          >
            Submit
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  })

export default SubscriptionUpgradeModal
