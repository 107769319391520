import { makeAutoObservable, observable } from "mobx"
import cloneDeep from "lodash/cloneDeep"

import { Product } from "@framework/types/product"

export type Request = {
  companyIds: string[]
  excludeCompanyIds: string[]
  requirementText: string
  requirementFile: File
}

type State = {
  data: Product[]

  total: number

  error: string | null

  isLoading: boolean

  reloadTrigger: boolean

  request: Request | null
}

const defaultState: State = {
  data: [],
  total: 0,
  error: null,
  isLoading: false,
  reloadTrigger: false,
  request: null,
}

export default class RecommendedProductListStore {
  state: State

  constructor() {
    this.state = cloneDeep(defaultState)

    makeAutoObservable(this)
  }

  refresh = () => {
    this.state.reloadTrigger = !this.state.reloadTrigger
  }

  request = (request: Request) => {
    this.state.request = request
  }

  reset = (newState: Partial<State> = {}) => {
    this.state = observable({
      ...cloneDeep(defaultState),
      ...newState,
    })
  }

  update = (newState: Partial<State> = {}) => {
    this.state = observable({
      ...cloneDeep(this.state),
      ...newState,
    })
  }
}
