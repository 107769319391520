import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import mainRoutes from "@root/main.routes"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import Logo from "@components/prototypes/Logo/Logo"
import { useStore } from "@store"

import IEEEFooter from "./IEEEFooter"

import styles from "./IntroLayout.module.sass"

export interface IntroLayoutProps {
  className?: string
}

const IntroLayout: React.FC<IntroLayoutProps> = observer(
  ({ className, children }) => {
    const navigate = useNavigate()
    const theme = useAppConfig()

    const { authStore } = useStore()

    useEffect(() => {
      if (!authStore.isAuthorized) return

      if (authStore.restrictedLocation != null) {
        const { pathname } = authStore.restrictedLocation
        navigate(pathname, { replace: true, state: { reason: "LOGIN" } })
        authStore.setRestrictedLocation(null)
        return
      }

      navigate(mainRoutes.home(), { replace: true, state: { reason: "LOGIN" } })
    }, [authStore.isAuthorized])

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.body}>
          <div className={styles.intro}>
            <div
              className={clsx(
                styles.titleContainer,
                styles[`align-${theme.copy.align || "left"}`]
              )}
            >
              {!theme.copy.hideWelcomeTitle && (
                <h1 className={styles.title}>
                  Welcome to <Logo name={theme.copy.instanceName} />
                </h1>
              )}
              {!theme.copy.hideWelcomeSubtitle && (
                <p className={styles.subtitle}>
                  {theme.copy.welcomeSubtitleURL != null ? (
                    <a
                      href={theme.copy.welcomeSubtitleURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {theme.copy.welcomeSubtitle}
                    </a>
                  ) : (
                    <span>{theme.copy.welcomeSubtitle}</span>
                  )}
                </p>
              )}
            </div>
          </div>

          <div className={styles.formContainer}>{children}</div>
        </div>

        {theme.copy?.footer != null && (
          <>
            {theme.copy?.footer.type === "IEEE" && (
              <IEEEFooter data={theme.copy?.footer} />
            )}
          </>
        )}
      </div>
    )
  }
)

export default IntroLayout
