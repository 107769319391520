import React, { useMemo } from "react"
import sortBy from "lodash/sortBy"

import { useStore } from "@store/index"

import { transformUser } from "./ExpertSearch"

const useExpertsList = ({ avatarId }: { avatarId: string }) => {
  const {
    knowledgeStore: { expertsList, isExpertsLoading, loadExperts },
    userStore: { user },
  } = useStore()

  React.useEffect(() => {
    loadExperts(avatarId)
  }, [])

  const experts = useMemo(() => {
    if (!user || isExpertsLoading) return []

    return sortBy(
      expertsList.map((expert) =>
        expert.id === user.id
          ? transformUser(user, "You")
          : transformUser(expert)
      ),
      (expert) => expert.id !== user.id
    )
  }, [expertsList, isExpertsLoading, user])

  return { list: experts, isLoading: isExpertsLoading }
}

export default useExpertsList
