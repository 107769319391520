import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import AnswerTitle from "@components/prototypes/ResultsCard/AnswerSection/AnswerTitle"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import { AnswerSource } from "@store/search/dataTransformers"
import { useController } from "@store/index"

import styles from "./CollapsibleAnswerSource.module.sass"

export interface CollapseHeaderProps {
  data: AnswerSource
  className?: string
  open: boolean
  onToggle?: React.MouseEventHandler
}

export const CollapsibleAnswerSource: React.FC<CollapseHeaderProps> = observer(
  ({ data, className, open = false, onToggle }) => {
    const { dataConnectorController } = useController()

    const signAndOpenSourceURL = async (sourceUrl: string) => {
      const res = await dataConnectorController.getSignedDataURL(sourceUrl)

      if (res.status === "SUCCESS") window.open(res.data, "_blank")
    }

    const handleTitleClick =
      !!data.source && data.source !== "expert answer"
        ? () => signAndOpenSourceURL(data.source)
        : undefined

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.content}>
          <AnswerTitle title={data.sourceName} />
          <AnswerTitle
            title={data.source}
            variant="subtitle"
            onClick={handleTitleClick}
          >
            {data.displaySource || data.source}{" "}
            {handleTitleClick != null && <Icon name="external-link" />}
          </AnswerTitle>
          <section className={styles.chips}>
            <Chip color="green">Hits: {data.hits ?? 0}</Chip>
            <Chip color="secondary">Company: {data.company || "NA"}</Chip>
            <Chip color="secondary">Type: {data.sourceType || "NA"}</Chip>
            <Chip color="secondary">
              Source date: {data.sourceDate || "NA"}
            </Chip>
            <Chip color="blue">Created on: {data.createdDate || "NA"}</Chip>
            <Chip color="primary">
              Modified on: {data.modifiedDate || "NA"}
            </Chip>
            {!!data.fileSize && (
              <Chip color="gold">Size: {data.fileSize} MB</Chip>
            )}
          </section>
        </div>

        <div className={styles.control}>
          <IconButton size="big" onClick={onToggle}>
            <Icon name="arrow-down" rotateAngle={open ? 180 : 0} />
          </IconButton>
        </div>
      </div>
    )
  }
)

export default CollapsibleAnswerSource
