import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { useController, useStore } from "@store/index"
import QueryAttachment from "@store/search/query-attachment"
import DocumentDropZone from "@components/ui/DocumentDropZone/DocumentDropZone"
import Text from "@components/ui/Typography/Text"
import FileCard from "@components/ui/FileCard"

import styles from "./index.module.sass"

interface FileUploadProps {}

const extensions = [".pdf", ".doc", ".docx", ".ppt", ".pptx"]

const FileUpload: React.FC<FileUploadProps> = observer(() => {
  const alert = useAlert()

  const [pendingFiles, setPendingFiles] = React.useState<File[]>([])

  const {
    documentChatSolutionStore: { queryAttachments },
  } = useStore()

  const { documentChatSolutionController: controller } = useController()

  const extensionsString = extensions
    .map((item) => item.replace(".", ""))
    .join(", ")

  const handleDrop = async (files: File[]) => {
    const attachment = new QueryAttachment(files[0])
    try {
      setPendingFiles(files)
      await controller.createAttachment(attachment)
      setPendingFiles([])
    } catch (e) {
      await controller.deleteAttachments()
      alert.error("Could not add attachment, please try again later.")
    }
  }

  const handleRemove = (file: File) => {
    setPendingFiles((prev) => prev.filter((item) => item.name !== file.name))
    if (document) {
      controller.deleteAttachments()
    }
  }

  const description = `File type must be ${extensionsString}. Document length cannot exceed 500 pages and file size cannot exceed ${queryAttachments.attachmentsSizeLimitText}.`

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text color="inherit" align="center">
          Attach File
        </Text>
      </div>

      <DocumentDropZone
        icon="plus"
        extensions={extensions}
        description={description}
        className={styles.dropZone}
        disabled={!!pendingFiles.length}
        alternativeTitle={
          pendingFiles.length
            ? pendingFiles.map((item) => (
                <FileCard
                  key={item.name}
                  name={item.name}
                  onRemove={() => handleRemove(item)}
                  size="small"
                  loading
                />
              ))
            : undefined
        }
        onDrop={handleDrop}
        multiple={false}
      />
    </div>
  )
})

export default FileUpload
