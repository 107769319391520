import { makeAutoObservable } from "mobx"

import { BaseUserData } from "@framework/types/user"
import { PaginationListMeta } from "@framework/types/utils"

const PAGE_SIZE = 10

export class AdminUsersStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading: boolean = false

  error: string | null = null

  users: Map<string, BaseUserData> = new Map()

  pages: Map<number, string[]> = new Map()

  meta: PaginationListMeta = {
    query: "",
    pageNum: 0,
    pageSize: PAGE_SIZE,
    total: PAGE_SIZE,
    totalPages: 1,
  }

  get getPage() {
    return (pageNum: number) => this.pages.get(pageNum) ?? []
  }

  get getUserById() {
    return (userId: string | null) =>
      userId == null ? null : this.users.get(userId) ?? null
  }

  resetPages = () => {
    this.pages = new Map()
  }

  setLoading = (loading = true) => {
    this.isLoading = loading
  }

  setError = (error: string | null = null) => {
    this.error = error
  }

  updateUsers = (users: BaseUserData[], meta: PaginationListMeta) => {
    this.pages.set(
      meta.pageNum,
      users.map((it) => {
        this.users.set(it.id, it)
        return it.id
      })
    )

    this.meta = meta
  }

  updateUser = (user: BaseUserData) => {
    this.users.set(user.id, user)
  }
}

export default AdminUsersStore
