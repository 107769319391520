import React from "react"
import { observer } from "mobx-react-lite"

import { useMatrixContext } from "./MatrixContext"
import TextEditor from "./TextEditorCell/TextEditor"

type EditorCellProps = {}

const EditorCell: React.FC<EditorCellProps> = observer(() => {
  const { grid, selectedRange, editor } = useMatrixContext()

  const ref = React.useRef<HTMLInputElement>(null)

  const position = grid.getCellCoordinates(selectedRange.origin)

  React.useEffect(() => {
    const handler = (e: Event) => console.log(e)

    ref.current?.addEventListener("input", handler)
    return () => ref.current?.removeEventListener("input", handler)
  }, [])

  return (
    <div
      style={{
        position: "absolute",
        left: position.x,
        top: position.y,
        zIndex: 9,
      }}
    >
      {editor.activeCellState != null && (
        <TextEditor control={editor.activeCellState} />
      )}
    </div>
  )
})

export default EditorCell
