import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { Column, Header } from "./Table"

import styles from "./WebsiteRow.module.sass"

export const WebsitesTableHeader: React.FC<{}> = observer(() => {
  return (
    <Header className={clsx(styles.row)}>
      {/* icon */}
      <Column />

      <Column>Website URL</Column>

      {/* status */}
      <Column />

      <Column>Data Type</Column>

      <Column>Avatars</Column>

      <Column>Created At</Column>

      <Column>Created By</Column>

      {/* menu  */}
      <Column />
    </Header>
  )
})

export default WebsitesTableHeader
