import * as yup from "yup"
import groupBy from "lodash/groupBy"

import { AnswerData, AnswerItem } from "@framework/types/search"
import { AnswerResponseData } from "@services/search.service"
import { Option } from "@framework/types/utils"

const answerSourceScheme = yup
  .object({
    id: yup.lazy((_, options) =>
      yup
        .string()
        .required()
        .default(options.parent.source || options.parent.sourceName)
    ),
    sourceName: yup.string().required(),
    displaySource: yup.string(),
    hits: yup.number(),
    company: yup.string().nullable(),
    createdDate: yup.string().nullable(),
    source: yup.string().required().nullable(),
    sourceDate: yup.string().nullable(),
    sourceType: yup.string().nullable(),
    modifiedDate: yup.string().nullable(),
    fileSize: yup.number(), // MB
  })
  .camelCase()

// TODO move to model
export type AnswerSource = yup.InferType<typeof answerSourceScheme>

export const extractSources = (
  searchData: AnswerResponseData | null
): AnswerSource[] => {
  if (!searchData?.filesInfo) return []
  return searchData.filesInfo.map(
    (it) => answerSourceScheme.cast(it) as AnswerSource
  )
}

export const transformAnswers = (
  sourcesList: AnswerSource[],
  answers: AnswerItem[]
) => {
  if (!answers.length) return new Map()
  const groups = groupBy(answers, (it) => it.value.source)
  const row = new Map(
    sourcesList.map<[string, Option<string, AnswerData>[]]>(
      ({ id, source }) => {
        return [id, groups[source] ?? []]
      }
    )
  )
  return row
}

export default {}
