import React from "react"

import Text from "@components/ui/Typography/Text"
import LinkCard from "@components/ui/LinkCard/LinkCard"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { DataCategoryEntity } from "@framework/types/knowledge"
import { getDataSourceCategoryDescription } from "@framework/constants/upload"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"

export const DataSourceCategoryCard: React.FC<{
  data: DataCategoryEntity
}> = ({ data }) => {
  const { avatar } = useActiveAvatar()

  const desc = getDataSourceCategoryDescription(data.name)

  const redirectState = {
    backTo: "/home",
  }

  return (
    <LinkCard
      to={`data-source/${avatar.id}/${data.id}`}
      label={data.name}
      state={redirectState}
      icon={<DocumentIcon icon={desc.icon} />}
      description={
        <>
          <Text variant="caption3" color="text70Color" align="center">
            {data.totalFolders} folders • {data.totalFiles} files •{" "}
            {data.totalWebsites} websites
          </Text>
        </>
      }
    />
  )
}

export default DataSourceCategoryCard
