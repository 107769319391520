import clsx from "clsx"
import React from "react"

import { ColorType } from "@framework/types/utils"

import colorStyles from "./Button-color.module.sass"
import styles from "./Button-root.module.sass"
import sizeStyles from "./Button-size.module.sass"
import variantStyles from "./Button-variant.module.sass"

export const buttonSizeOptions = [
  "large",
  "big",
  "medium",
  "small",
  "tiny",
] as const

export const buttonVariantOptions = [
  "contained",
  "outlined",
  "text",
  "slim",
] as const

export type ButtonVariant = (typeof buttonVariantOptions)[number]

export type ButtonSize = (typeof buttonSizeOptions)[number]

export type ButtonColor = ColorType

export interface ButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "size"> {
  as?: "button"
  variant?: ButtonVariant
  color?: ButtonColor
  size?: ButtonSize
  noPadding?: boolean
  after?: React.ReactNode
  before?: React.ReactNode
  type?: "button" | "submit"
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  color = "default",
  variant = "contained",
  size = "large",
  as: Component = "button",
  noPadding = false,
  className,
  type = "button",
  before,
  after,
  ...rest
}) => (
  <Component
    {...rest}
    type={type}
    className={clsx(
      styles.root,
      variantStyles[variant],
      colorStyles[color],
      sizeStyles[size],
      noPadding ? styles.noPadding : "",
      className
    )}
    onClick={onClick}
  >
    {before != null && <span className={styles.before}>{before}</span>}
    {children}
    {after != null && <span className={styles.after}>{after}</span>}
  </Component>
)

export default Button
