import { makeAutoObservable } from "mobx"

import { SearchEntityStore } from "./search-entity.store"

export type SearchEntity = SearchEntityStore

export class SearchFlowStore {
  // injections

  // constructor

  constructor() {
    makeAutoObservable(this)
  }

  // state
  oldThreadId?: string

  flowSequence: string[] = []

  flowData: Map<string, SearchEntity> = new Map()

  // actions

  pushSearchInstance = (instance: SearchEntity) => {
    this.flowSequence.push(instance.id)
    this.flowData.set(instance.id, instance)
  }

  getById = (blockId: string) => {
    const res = this.findById(blockId)
    if (res == null) throw new Error("SearchFlow store is desynchronized")
    return res
  }

  findById = (searchId: string) => {
    return this.flowData.get(searchId)
  }

  hesSearchEntity = (searchId: string) => {
    return this.flowData.has(searchId)
  }

  reset = () => {
    this.flowSequence = []
    this.flowData = new Map()
    this.oldThreadId = undefined
  }

  setOldThreadId = (id: string) => {
    this.oldThreadId = id
  }

  isAnyLoading = () => {
    for (let i = this.flowSequence.length - 1; i >= 0; i -= 1) {
      if (this.flowData.get(this.flowSequence[i])?.isLoading) return true
    }
    return false
  }
}

export default SearchFlowStore
