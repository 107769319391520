import React from "react"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"

import styles from "./index.module.sass"

interface FilterSidebarHeaderProps {
  onClear: React.MouseEventHandler
}

const FilterSidebarHeader: React.FC<FilterSidebarHeaderProps> = ({
  onClear,
}) => {
  return (
    <div className={styles.root}>
      <Text variant="h3">Filter by</Text>
      <Button variant="slim" onClick={onClear}>
        Clear all
      </Button>
    </div>
  )
}

export default FilterSidebarHeader
