import React, { useMemo } from "react"
import clsx from "clsx"
import initials from "initials"

import { textToHSL, HSLtoString } from "@utils/colorUtils"

import Icon from "../Icon/Icon"
import { IconName } from "../Icon/IconName"

import styles from "./Avatar.module.sass"

export const textToColor = (name: string) => HSLtoString(textToHSL(name))

export type AvatarProps = {
  name?: string | null
  nameIsInitials?: boolean
  bgColor?: string
  active?: boolean
  src?: string
  icon?: IconName
  className?: string
  width?: number | string
  height?: number | string
  onClick?: () => void
}

export const Avatar: React.FC<AvatarProps> = ({
  name,
  nameIsInitials = false,
  active,
  bgColor,
  src,
  icon = "user",
  className,
  height,
  width,
  onClick,
}) => {
  const inits = useMemo(() => {
    if (!name) return null
    return nameIsInitials ? name : initials(name)
  }, [name, nameIsInitials])

  const backgroundColor = useMemo(() => {
    if (src) return undefined
    if (bgColor) return bgColor
    const colorSeed = name
    if (colorSeed) return textToColor(colorSeed)
    return undefined
  }, [src, bgColor, name])

  const buttonStyle = {
    backgroundColor,
    height,
    width,
    minHeight: height,
    minWidth: width,
  }

  return (
    <button
      tabIndex={-1}
      onClick={onClick}
      type="button"
      className={clsx(
        styles.root,
        "IUAvatar-root",
        {
          [styles.active]: active,
          [styles.clickable]: !!onClick,
        },
        className
      )}
      style={buttonStyle}
    >
      {src ? (
        <img className={styles.image} src={src} alt={src} />
      ) : (
        inits?.slice(0, 2)?.toUpperCase() || (
          <Icon className={styles.icon} name={icon} />
        )
      )}
    </button>
  )
}

export default Avatar
