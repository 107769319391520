import React from "react"

import { DataCategoryEntity } from "@framework/types/knowledge"
import Table from "@components/ui/BaseTable/Table"

import DatatypesTableHeader from "./DatatypesTableHeader"
import DatatypesTableRow from "./DatatypesTableRow"

import styles from "./index.module.sass"

interface DatatypesTableProps {
  items: DataCategoryEntity[]
  onAssignClick: (id: string) => void
}

const DatatypesTable: React.FC<DatatypesTableProps> = ({
  items = [],
  onAssignClick,
}) => {
  return (
    <div className={styles.root}>
      <Table header={<DatatypesTableHeader />}>
        {items.map((item) => (
          <DatatypesTableRow
            key={item.id}
            datatype={item}
            onAssignClick={() => onAssignClick(item.id)}
          />
        ))}
      </Table>
    </div>
  )
}

export default DatatypesTable
