import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { UserAction } from "@framework/types/security"
import { useController } from "@store/index"

import EditActionForm, {
  FormData,
  userActionFormSchema,
} from "./EditActionForm"

import styles from "./EditUserActionModal.module.sass"

export interface EditUserActionModalProps {
  action?: UserAction
}

export const EditUserActionModal: React.FC<EditUserActionModalProps> = observer(
  ({ action }) => {
    const [actionId] = React.useState(action?.id)
    const isEdit = actionId != null

    const alert = useAlert()

    const { actionsController } = useController()

    const modal = useModal(ModalsTypes.EDIT_USER_ACTION_MODAL)

    const handleSubmit = async (formData: FormData) => {
      modal.hideModal()
      const error = await (isEdit
        ? actionsController.updateAction(actionId, formData)
        : actionsController.createAction(formData))
      if (error) alert.error(error)
      else
        alert.success(
          <>
            New user action &quot;<b>{formData.name}</b>&quot; was added
          </>
        )
    }

    const initial = userActionFormSchema.cast(action, { stripUnknown: true })

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={isEdit ? "Edit user action" : "Create new user action"}
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <EditActionForm initialValues={initial} onSubmit={handleSubmit} />
      </BaseModal>
    )
  }
)

export default EditUserActionModal
