import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { Column, Header } from "./Table"

import styles from "./CloudFolderRow.module.sass"

export const CloudFoldersTableHeader: React.FC<{}> = observer(() => {
  return (
    <Header className={clsx(styles.row)}>
      {/* icon */}
      <Column />

      <Column>Name</Column>

      {/* files */}
      <Column />

      {/* status */}
      <Column />

      <Column>Data Type</Column>

      <Column>Avatars</Column>

      <Column>Created At</Column>

      <Column>Created By</Column>

      {/* menu  */}
      <Column />
    </Header>
  )
})

export default CloudFoldersTableHeader
