import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { useController, useStore } from "@store/index"

import EditInstanceConfigForm, { FormData } from "./EditInstanceConfigForm"

import styles from "./InstanceConfigModal.module.sass"

export interface CreateInstanceConfigModalProps {
  callback?: () => void
}

export const CreateInstanceConfigModal: React.FC<CreateInstanceConfigModalProps> =
  observer(({ callback }) => {
    const alert = useAlert()

    const {
      appStore: { instanceConfig },
    } = useStore()
    const { appConfigController } = useController()

    const modal = useModal(ModalsTypes.CREATE_INSTANCE_CONFIG_MODAL)

    const handleSubmit = async (formData: FormData) => {
      const error = await appConfigController.createInstanceConfiguration(
        formData.key,
        {}
      )
      if (error) {
        alert.error(error)
        return
      }
      alert.success(
        <>
          New configuration &quot;<b>{formData.key}</b>
          &quot; was successfully created
        </>
      )

      callback?.()

      modal.hideModal()
    }

    return (
      <BaseModal
        title={<ModalTitle titleText="Create new config" />}
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <EditInstanceConfigForm
          isLoading={instanceConfig.isLoading}
          onSubmit={handleSubmit}
        />
      </BaseModal>
    )
  })

export default CreateInstanceConfigModal
