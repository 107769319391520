import UserRolesController from "./user/roles.controller."
import UserActionsController from "./user/actions.controller"
import { RootStore } from "./RootStore"
import AdminUsersController from "./user/admin-users.controller"
import AvatarKnowledgeController from "./avatar-knowledge/knowledge.controller"
import AvatarKnowledgePreviewController from "./avatar-knowledge/avatar-knowledge-preview.controller"
import DataConnectorController from "./upload/data-connector-controller.store"
import FactFinderSolutionController from "./search/fact-finder-solution/fact-finder-solution.controller"
import AppConfigController from "./app/app-config.controller."
import AvatarController from "./avatars/avatar.controller"
import DataTypesController from "./upload/data-types-controller.store"
import UploadFileController from "./upload/upload-file.controller"
import ExpertInsightsController from "./knowledge/expert-insights.controller"
import AnalyticsController from "./analytics/analytics.controller"
import SolutionsController from "./solutions/solutions.controller"
import FeedbackController from "./feedback/feedback.cotroller"
import AvatarAdminController from "./avatar-admin/avatar-admin.controller"
import UserListController from "./user/user-list.controller"
import KnowledgeDatatypeController from "./knowledge-datatypes/knowledge-datatype.controller"
import DocumentChatSolutionController from "./search/document-chat-solution/document-chat-solution.controller"
import ProductComparisonController from "./product-comparison/product-comparison.controller"
import ApiKeysController from "./user/api-keys.controller"
import SubscriptionController from "./subscription/subscription.controller"
import UnifiedMatrixController from "./unified-matrix/unified-matrix.controller"

class RootController {
  constructor(rootStore: RootStore) {
    this.rolesController = new UserRolesController({
      actionsStore: rootStore.actionsStore,
      rolesStore: rootStore.rolesStore,
      relationsStore: rootStore.rolesActionsStore,
    })

    this.actionsController = new UserActionsController({
      actionsStore: rootStore.actionsStore,
    })

    this.adminUsersController = new AdminUsersController(rootStore)

    this.userListController = new UserListController(rootStore)

    this.dataConnectorController = new DataConnectorController(rootStore)

    this.avatarKnowledgeController = new AvatarKnowledgeController({
      knowledgeStore: rootStore.avatarExpertKnowledgeStore,
      avatarsStore: rootStore.avatarsStore,
      websitesKnowledgeStore: rootStore.avatarWebsiteKnowledgeStore,
      uploadedFilesGroupsStore: rootStore.avatarUploadedFilesGroupsStore,
      uploadedFilesKnowledgeStore: rootStore.avatarUploadedFilesKnowledgeStore,
      dataConnectorsGroupsStore: rootStore.avatarDataConnectorsGroupsStore,
      dataConnectorsStore: rootStore.avatarDataConnectorsStore,
      dataConnectorFoldersStore: rootStore.avatarDataConnectorFoldersStore,
      dataConnectorFilesStore: rootStore.avatarDataConnectorFilesStore,
    })

    this.avatarKnowledgePreviewController =
      new AvatarKnowledgePreviewController(rootStore)

    this.factFinderSolutionController = new FactFinderSolutionController(
      rootStore
    )

    this.documentChatSolutionController = new DocumentChatSolutionController(
      rootStore
    )

    this.appConfigController = new AppConfigController(rootStore)

    this.avatarController = new AvatarController(rootStore)

    this.avatarAdminController = new AvatarAdminController(rootStore)

    this.datatypesController = new DataTypesController(rootStore)

    this.fileUploadingController = new UploadFileController({
      fileUploadingStore: rootStore.fileUploadingStore,
    })

    this.expertInsightsController = new ExpertInsightsController({
      expertInsightsStore: rootStore.expertInsightsStore,
    })

    this.analyticsController = new AnalyticsController({
      avatarsStore: rootStore.avatarsStore,
      usageAnalyticsStores: rootStore.usageAnalyticsStore,
    })

    this.solutionsController = new SolutionsController({
      solutionsStore: rootStore.solutionsStore,
    })

    this.knowledgeDatatypesController = new KnowledgeDatatypeController(
      rootStore
    )

    this.feedbackController = new FeedbackController()

    this.productComparisonController = new ProductComparisonController(
      rootStore
    )
    this.apiKeysController = new ApiKeysController(rootStore)
    this.subscriptionController = new SubscriptionController(rootStore)

    this.unifiedMatrixController = new UnifiedMatrixController(rootStore)
  }

  rolesController: UserRolesController

  actionsController: UserActionsController

  adminUsersController: AdminUsersController

  userListController: UserListController

  dataConnectorController: DataConnectorController

  avatarKnowledgePreviewController: AvatarKnowledgePreviewController

  avatarKnowledgeController: AvatarKnowledgeController

  factFinderSolutionController: FactFinderSolutionController

  documentChatSolutionController: DocumentChatSolutionController

  appConfigController: AppConfigController

  avatarController: AvatarController

  avatarAdminController: AvatarAdminController

  datatypesController: DataTypesController

  knowledgeDatatypesController: KnowledgeDatatypeController

  fileUploadingController: UploadFileController

  expertInsightsController: ExpertInsightsController

  analyticsController: AnalyticsController

  solutionsController: SolutionsController

  feedbackController: FeedbackController

  productComparisonController: ProductComparisonController

  apiKeysController: ApiKeysController

  subscriptionController: SubscriptionController

  unifiedMatrixController: UnifiedMatrixController
}

export default RootController
