import QueryAttachmentsStore from "@store/search/query-attachments.store"

import AppStore from "./app/app.store"
import FeaturesStore from "./app/features.store"
import AuthStore from "./auth/auth.store"
import AvatarDataConnectorFilesStore from "./avatar-knowledge/avatar-data-connector-files.store"
import AvatarDataConnectorFoldersStore from "./avatar-knowledge/avatar-data-connector-folders.store"
import AvatarDataConnectorsGroupsStore from "./avatar-knowledge/avatar-data-connectors-groups.store"
import AvatarDataConnectorsStore from "./avatar-knowledge/avatar-data-connectors.store"
import AvatarExpertKnowledgeStore from "./avatar-knowledge/avatar-expert-knowledge.store"
import AvatarUploadedFilesGroupsStore from "./avatar-knowledge/avatar-uploaded-files-groups.store"
import AvatarUploadedFilesKnowledgeStore from "./avatar-knowledge/avatar-uploaded-files-knowledge.store"
import AvatarWebsitesKnowledgeStore from "./avatar-knowledge/avatar-websites-knowledge.store"
import KnowledgePreviewStore from "./avatar-knowledge/knowledge-preview.store"
import AvatarDetailsStore from "./avatars/avatar.store"
import AvatarsStore from "./avatars/avatars.store"
import ExpertStore from "./expert/expert.store"
import KnowledgeStore from "./knowledge/knowledge.store"
import ModalStore from "./modals/modal.store"
import RestrictionsStore from "./restrictions/restrictions.store"
import SearchSuggestionStore from "./search/search-suggestions.store"
import AcronymsStore from "./glossary/acronyms.store"
import SynonymsStore from "./glossary/synonyms.store"
import UpdatesStore from "./updates/updates.store"
import CloudFolderPreviewsStore from "./upload/cloud-folder-previews.store"
import DataConnectorPreviewsStore from "./upload/data-connector-preview.store"
import ExternalFoldersStores from "./upload/data-folders.store"
import DataTypesStore from "./upload/data-types.store"
import UploadSourceStore from "./upload/upload-source.store"
import UploadStore from "./upload/upload.store"
import RolesActionsRelationsStore from "./user/roles-actions.store"
import UserActionsStore from "./user/user-actions.store"
import UserRequestStore from "./user/user-request.store"
import UserRolesStore from "./user/user-roles.store"
import UserSetupStore from "./user/user-setup.store"
import UserStore from "./user/user.store"
import AdminUsersStore from "./user/admin-users.store"
import FileUploadingStore from "./upload/file-uploading.store"
import ExpertInsightsStore from "./knowledge/expert-insights.store"
import UsageAnalyticsRootStore from "./analytics/usage-analytics-root.store"
import SolutionsStore from "./solutions/solutions.store"
import AvatarAdminStore from "./avatar-admin/avatar-admin.store"
import KnowledgeDatatypeDetailsStore from "./knowledge-datatypes/knowledge-datatype.store"
import AllDataTypesStore from "./upload/all-data-types.store"
import UserListStore from "./user/user-list.store"
import AllUsersStore from "./user/all-users.store"
import DocumentChatSolutionStore from "./search/document-chat-solution/document-chat-solution.store"
import FactFinderSolutionStore from "./search/fact-finder-solution/fact-finder-solution.store"
import ProductComparisonStore from "./product-comparison/product-comparison.store"
import ApiKeysStore from "./user/api-keys.store"
import ApiUsageAnalyticsStore from "./api-analytics/api-usage-analytics.store"
import SubscriptionStore from "./subscription/subscription.store"
import UsageAnalyticsStore from "./analytics/usage-analytics.store"
import UnifiedMatrixStore from "./unified-matrix/unified-matrix.store"

export class RootStore {
  appStore = new AppStore()

  restrictionsStore = new RestrictionsStore(this)

  avatarsStore = new AvatarsStore()

  avatarDetailsStore = new AvatarDetailsStore()

  avatarAdminStore = new AvatarAdminStore()

  userStore = new UserStore()

  authStore = new AuthStore(this)

  userSetupStore = new UserSetupStore(this)

  queryAttachments = new QueryAttachmentsStore()

  documentChatSolutionStore = new DocumentChatSolutionStore()

  factFinderSolutionStore = new FactFinderSolutionStore()

  dataConnectorPreviewsStore = new DataConnectorPreviewsStore()

  cloudFolderPreviews = new CloudFolderPreviewsStore()

  knowledgePreviewStore = new KnowledgePreviewStore()

  avatarExpertKnowledgeStore = new AvatarExpertKnowledgeStore()

  avatarUploadedFilesGroupsStore = new AvatarUploadedFilesGroupsStore()

  avatarUploadedFilesKnowledgeStore = new AvatarUploadedFilesKnowledgeStore()

  avatarWebsiteKnowledgeStore = new AvatarWebsitesKnowledgeStore()

  avatarDataConnectorsGroupsStore = new AvatarDataConnectorsGroupsStore()

  avatarDataConnectorsStore = new AvatarDataConnectorsStore()

  avatarDataConnectorFoldersStore = new AvatarDataConnectorFoldersStore()

  avatarDataConnectorFilesStore = new AvatarDataConnectorFilesStore()

  searchSuggestionStore = new SearchSuggestionStore(this)

  foldersStore = new ExternalFoldersStores()

  dataConnectorFoldersStore = new CloudFolderPreviewsStore()

  uploadStore = new UploadStore(this)

  uploadSourceStore = new UploadSourceStore(this)

  updateStore = new UpdatesStore()

  knowledgeStore = new KnowledgeStore()

  expertStore = new ExpertStore(this)

  actionsStore = new UserActionsStore()

  rolesStore = new UserRolesStore()

  rolesActionsStore = new RolesActionsRelationsStore()

  adminUsersStore = new AdminUsersStore()

  userListStore = new UserListStore()

  allUserStore = new AllUsersStore()

  featuresStore = new FeaturesStore(this)

  datatypesStore = new DataTypesStore()

  allDatatypesStore = new AllDataTypesStore()

  acronymsStore = new AcronymsStore()

  synonymsStore = new SynonymsStore()

  userRequestStore = new UserRequestStore()

  fileUploadingStore = new FileUploadingStore()

  expertInsightsStore = new ExpertInsightsStore()

  usageAnalyticsStore = new UsageAnalyticsStore()

  apiUsageAnalyticsStore = new ApiUsageAnalyticsStore()

  solutionsStore = new SolutionsStore()

  knowledgeDatatypeDetailsStore = new KnowledgeDatatypeDetailsStore()

  productComparisonStore = new ProductComparisonStore()

  apiKeysStore = new ApiKeysStore()

  subscriptionStore = new SubscriptionStore()

  unifiedMatrixStore = new UnifiedMatrixStore()
}

export default RootStore
