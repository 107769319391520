import { useCallback, useMemo } from "react"

export const useGlobalController = () => {
  const switchScroll = useCallback((disable: boolean = false) => {
    if (disable) {
      document.documentElement.style.overflow = "hidden"
      document.documentElement.style.paddingRight = "0.8em"
    } else {
      document.documentElement.style.overflow = "auto scroll"
      document.documentElement.style.paddingRight = "initial"
    }
  }, [])

  return useMemo(
    () => ({
      switchScroll,
      // other global stuff
    }),
    [switchScroll]
  )
}

export default useGlobalController
