import React, { useState, useEffect, useRef } from "react"

import usePrevious from "./usePrevious"

type ReturnTuple<R extends HTMLElement> = [
  boolean,
  (open: boolean) => void,
  React.Ref<R>
]

type Options = {
  onToggle?: ((isOpened: boolean) => void) | null
  disabled?: boolean
  defaultValue?: boolean
}

export const useActiveElement = <R extends HTMLElement>({
  onToggle,
  disabled,
  defaultValue,
}: Options = {}): ReturnTuple<R> => {
  const nodeRef = useRef<R>(null)
  const node = nodeRef.current

  const [isOpened, setIsOpened] = useState<boolean>(false)
  const prev = usePrevious(isOpened)

  useEffect(() => {
    if (isOpened && node) {
      const handleClick = (e: Event) => {
        if (!node.contains(e.target as Node)) {
          setIsOpened(false)
        }
      }
      document.addEventListener("mouseup", handleClick)
      return () => {
        document.removeEventListener("mouseup", handleClick)
      }
    }
    return undefined
  }, [isOpened, node, onToggle])

  useEffect(() => {
    if (prev != null && prev !== isOpened) {
      onToggle?.(!!isOpened)
    }
  }, [isOpened])

  useEffect(() => {
    setIsOpened(!disabled && !!defaultValue)
  }, [])

  return [isOpened, setIsOpened, nodeRef]
}

export default useActiveElement
