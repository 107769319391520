import React, { useState } from "react"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import { ApiKey } from "@framework/types/api-keys"

import styles from "./DeleteApiKeyModal.module.sass"

export interface DeleteApiKeyModalProps {
  apiKey: ApiKey
  onSubmit?: () => Promise<void>
  onHide?: () => void
}

const DeleteApiKeyModal: React.FC<DeleteApiKeyModalProps> = ({
  apiKey,
  onSubmit,
  onHide,
}) => {
  const { hideModal } = useModal(ModalsTypes.DELETE_API_KEY_MODAL)
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    await onSubmit?.()
    setLoading(false)
  }

  const handleClose = () => {
    hideModal()
    onHide?.()
  }

  return (
    <BaseModal
      title={<ModalTitle titleText="Delete API Key" />}
      onClose={handleClose}
      className={styles.root}
    >
      <Templates.RollScript
        footerSocket={
          <ModalFooterContainer>
            <Button
              disabled={isLoading}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="red"
              type="submit"
              after={isLoading && <Loader />}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </ModalFooterContainer>
        }
        bodyClassName={styles.container}
      >
        <div className={styles.warning}>
          <Icon name="alert" color="gold" className={styles.alertIcon} />
          <Text variant="h3" weight="bold">
            Are you sure?
          </Text>
        </div>
        <Text variant="caption1">
          Deleting API key <strong>{apiKey.name}</strong> will immediately
          revoke it’s access and the access of any clients using it
        </Text>
      </Templates.RollScript>
    </BaseModal>
  )
}

export default DeleteApiKeyModal
