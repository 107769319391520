import React from "react"

import Avatar from "@components/ui/Avatar/Avatar"
import Text from "@components/ui/Typography/Text"
import { AvatarData } from "@framework/types/avatar"
import { useStore } from "@store/index"

import styles from "./Intro.module.sass"

type WelcomeCardProps = {
  avatar: AvatarData | null
}

const WelcomeCard: React.FC<WelcomeCardProps> = ({ avatar }) => {
  const {
    avatarsStore: { avatars },
    userStore: { user },
  } = useStore()

  return (
    <div className={styles.welcomeCard}>
      <div className={styles.welcomeText}>Welcome {user?.firstName}</div>
      <Text variant="h2" align="center">
        Your Primary Avatar
      </Text>
      <div>
        <div className={styles.avatar}>
          <Avatar src={avatar?.imageUrl} width={32} height={32} />
          <Text variant="h1" weight="bold">
            {avatar?.name}
          </Text>
        </div>
        <Text variant="caption2" color="text50Color" align="center">
          Members of your department primarily use this avatar.
        </Text>
      </div>

      <div>
        <div>Other Avatars Available To You</div>
        <div className={styles.avatarListContainer}>
          {avatars?.map((av) => {
            if (av.id === avatar?.id) return null
            return (
              <span className={styles.avatarList} key={av.id}>
                {av.name}
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default WelcomeCard
