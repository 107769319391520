import { ColorSchemeOption } from "@framework/types/theme"
import defaultDarkTheme from "@root/theme/nesh-dark-theme.json"
import ieeeLightTheme from "@root/theme/ieee-theme.json"
import blueOriginLightTheme from "@root/theme/blue-theme.json"

export const allAppColorSchemeOptions: ColorSchemeOption[] = [
  defaultDarkTheme,
  ieeeLightTheme,
  blueOriginLightTheme,
]

export const defaultColorScheme = allAppColorSchemeOptions[0]
