import { makeAutoObservable } from "mobx"

import { DataConnectorFile } from "@framework/types/upload"

import EntityCollection from "../utils/EntityCollection"

export default class AvatarDataConnectorFilesStore {
  constructor() {
    makeAutoObservable(this)
  }

  collectionsMap: Map<string, EntityCollection<DataConnectorFile>> = new Map()

  getCollection = (folderId: string) => {
    if (!this.collectionsMap.has(folderId)) this.resetCollection(folderId)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.collectionsMap.get(folderId)!
  }

  resetCollection = (collectionKey: string) => {
    this.collectionsMap.set(collectionKey, new EntityCollection())
  }
}
