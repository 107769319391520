import React from "react"
import SpeechRecognition from "react-speech-recognition"
import { EditorState, Modifier } from "draft-js"
import cloneDeep from "lodash/cloneDeep"

const speechRecognizer = SpeechRecognition.getRecognition()

export const useSpeechEditor = ({
  value,
  onChange,
}: {
  value: EditorState
  onChange: (editorState: EditorState) => void
}) => {
  const [inProgress, setInProgress] = React.useState<boolean>(false)

  const baseStateRef = React.useRef<EditorState | null>(null)

  const initialStateRef = React.useRef<EditorState>(value)
  initialStateRef.current = value

  React.useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition) return undefined
    if (speechRecognizer == null) return undefined

    // handlers

    const handleStart = () => {
      setInProgress(true)
      const newState = insertEditorValue(
        " ",
        cloneDeep(initialStateRef.current)
      )

      baseStateRef.current = newState

      onChange(newState)
    }

    const handleResult = (e: SpeechRecognitionEvent) => {
      if (baseStateRef.current == null) return

      const value = [...e.results].map((it) => it[0].transcript).join()

      onChange(insertEditorValue(value, cloneDeep(baseStateRef.current)))
    }

    const handleEnd = () => {
      setInProgress(false)
      speechRecognizer.abort()
    }

    // subscriptions

    speechRecognizer.addEventListener("start", handleStart)
    speechRecognizer.addEventListener("result", handleResult)
    speechRecognizer.addEventListener("end", handleEnd)

    return () => {
      speechRecognizer.removeEventListener("start", handleStart)
      speechRecognizer.removeEventListener("result", handleResult)
      speechRecognizer.removeEventListener("end", handleEnd)

      stopRecording()
    }
  }, [])

  const startRecording = () => {
    if (speechRecognizer == null) return
    speechRecognizer.continuous = true
    speechRecognizer.lang = "en"
    speechRecognizer.start()
  }

  const stopRecording = () => {
    if (speechRecognizer == null) return
    speechRecognizer.stop()
    speechRecognizer.continuous = false
  }

  const toggle = () => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition) return
    if (speechRecognizer == null) return

    if (inProgress) stopRecording()
    else startRecording()
  }

  return {
    toggle,
    listening: inProgress,
  }
}

const insertEditorValue = (value: string, editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent()
  const currentSelection = editorState.getSelection()

  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    value
  )

  return EditorState.push(editorState, newContent, "insert-characters")
}

export default useSpeechEditor
