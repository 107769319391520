import React from "react"
import {
  AlertProviderProps,
  Provider,
  transitions,
  positions,
} from "react-alert"

import AlertPopupTemplate from "./AlertTemplate/AlertPopupTemplate"

import styles from "./AlertProvider.module.sass"

const options: AlertProviderProps = {
  className: styles.alertTemplateContainer,
  position: positions.TOP_RIGHT,
  timeout: 60000,
  offset: "2px 0",
  transition: transitions.FADE,
  template: AlertPopupTemplate,
}

const AlertProvider: React.FC<any> = ({ children }) => (
  <Provider {...options}>{children}</Provider>
)

export default AlertProvider
