import React from "react"

import Icon from "@components/ui/Icon/Icon"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import {
  PlacementType,
  TooltipContainer,
} from "@components/ui/Tooltip/TooltipContainer"
import Chip from "@components/ui/Chip/Chip"

import styles from "./ErrorChip.module.sass"

export interface ErrorChipProps {
  message: string
  messagePlacement?: PlacementType
}

export const ErrorChip: React.FC<ErrorChipProps> = ({
  message,
  messagePlacement = "bottom",
}) => (
  <Tooltip
    content={
      <TooltipContainer
        className={styles.errorMessage}
        color="primary"
        placement={messagePlacement}
      >
        <span>{message}</span>
      </TooltipContainer>
    }
    mode="onHover"
  >
    <Chip
      color="red"
      before={<Icon name="info" className={styles.errorIcon} />}
    >
      Error
    </Chip>
  </Tooltip>
)

export default ErrorChip
