import React from "react"
import { observer } from "mobx-react-lite"

import Loader from "@components/ui/Loader/BarLoader"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import Logo from "@components/prototypes/Logo/Logo"

import styles from "./LoadingPlaceholder.module.sass"

const LoadingPlaceholder: React.FC = observer(({ children }) => {
  const theme = useAppConfig()

  return (
    <div className={styles.root} id="global_loader">
      <span className={styles.content}>
        <h1 className={styles.title}>
          <Logo name={theme.copy.logo} />
          <Loader size="huge" />
        </h1>
      </span>
    </div>
  )
})

export default LoadingPlaceholder
