import React from "react"

import Table from "@components/ui/BaseTable/Table"
import { SUBSCRIPTION_OPTIONS_KEYS } from "@framework/constants/subscription"

import ConnectorOptions from "./ConnectorOptions"

const Connectors: React.FC = () => {
  return (
    <Table hideBorder>
      <ConnectorOptions
        optionName={SUBSCRIPTION_OPTIONS_KEYS.CONNECTORS_BASIC}
      />
      <ConnectorOptions
        optionName={SUBSCRIPTION_OPTIONS_KEYS.CONNECTORS_PREMIUM}
        showTopBorder
      />
      <ConnectorOptions
        optionName={SUBSCRIPTION_OPTIONS_KEYS.CONNECTORS_CUSTOM}
        showTopBorder
      />
    </Table>
  )
}
export default Connectors
