import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"

import usePopper from "@components/hooks/usePopper"
import Popper from "@components/ui/Dropdown/Popper"
import Box from "@components/ui/Dropdown/Box"
import ListItem from "@components/ui/ListItem/ListItem"
import { filterByQuery } from "@utils/textUtils"

import { useMatrixContext } from "../MatrixContext"
import HighlightedText from "../parser/FormulaPreview"
import CellEditorState from "../state/CellEditorState"
import RichTextarea from "./RichTextarea"

import styles from "./TextEditor.module.sass"

type TextEditorProps = {
  control: CellEditorState
}

const TextEditor: React.FC<TextEditorProps> = observer(({ control }) => {
  const { grid, selectedRange, editor } = useMatrixContext()

  const [popperNode, setPepperNode] = React.useState<HTMLElement | null>(null)
  const [containerNode, setContainerNode] = React.useState<HTMLElement | null>(
    null
  )

  const inputRef = React.useRef<HTMLTextAreaElement | null>(null)

  const index = selectedRange.origin

  const cell = editor.getCellAtPoint(index)

  const { isActive, style } = usePopper(containerNode, popperNode, {
    placement: "bottom-start",
    disabled: cell.validation == null,
    defaultValue: cell.validation != null && control.autoFocusCell,
  })

  React.useEffect(() => {
    const node = inputRef.current
    if (node && control.autoFocusCell) {
      node.focus()
      node.selectionStart = node.value.length
      node.selectionEnd = node.value.length
    }
  }, [containerNode])

  return (
    <div className={clsx(styles.root)} ref={setContainerNode}>
      <RichTextarea
        ref={inputRef}
        className={clsx(styles.textarea)}
        value={control.input}
        onChange={(e: any) => {
          control.setInput(e.target.value)
        }}
        style={{
          minWidth: grid.getCellWidth(index.x),
          maxWidth: Math.max(300, grid.getCellWidth(index.x)),
          minHeight: grid.getCellHeight(index.y),
          maxHeight: "50vh",
        }}
      >
        <HighlightedText value={control.input} tree={control.formula} />
      </RichTextarea>

      <Popper ref={setPepperNode} style={style} isActive={isActive}>
        {cell.validation?.list != null && (
          <OptionsList
            control={control}
            options={cell.validation?.list}
            onSelect={(it) => {
              control.setInput(it)
              editor.submitCell()
            }}
          />
        )}
      </Popper>
    </div>
  )
})

export default TextEditor

interface OptionsListProps {
  control: CellEditorState
  options: string[]
  onSelect?: (option: string) => void
}

const OptionsList: React.FC<OptionsListProps> = observer(
  ({ control, options, onSelect }) => {
    const query = control.input

    const filtered = React.useMemo(() => {
      if (!control.touched) return options
      return filterByQuery(options, query)
    }, [options, query])

    if (!filtered.length) return null
    return (
      <Box color="primary" className={styles.dropdown}>
        {filtered.map((it) => (
          <ListItem key={it} onClick={() => onSelect?.(it)}>
            {it}
          </ListItem>
        ))}
      </Box>
    )
  }
)
