import React from "react"

import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import List from "@components/ui/List/List"
import ListItem from "@components/ui/ListItem/ListItem"
import Loader from "@components/ui/Loader/BarLoader"
import ScrollableContainer from "@components/ui/ScrollableContainer/ScrollableContainer"
import TextInput from "@components/ui/TextInput/TextInput"
import UserCard from "@components/ui/UserCard/UserCard"
import { BaseUserData } from "@framework/types/user"
import NotFound from "@components/ui/NotFound/NotFound"
import useSearch from "@components/hooks/useSearch"

export interface ExpertUserData extends BaseUserData {
  fullName: string
  status?: string
}

export const transformUser = (
  user: BaseUserData,
  status?: string
): ExpertUserData => ({
  ...user,
  fullName: `${user.firstName} ${user.lastName}`,
  status,
})

export interface ExpertSearchProps {
  items: ExpertUserData[]
  value: string[]
  selectedByDefault?: string[]
  isLoading?: boolean
  onChange: (selected: string[]) => void
}

export const ExpertSearch: React.FC<ExpertSearchProps> = ({
  items,
  value,
  selectedByDefault,
  isLoading,
  onChange: onSelect,
}) => {
  const [searchProps] = useSearch()

  const isSelected = (itemId: string) =>
    value.includes(itemId) || wasSelected(itemId)

  const wasSelected = (itemId: string) => selectedByDefault?.includes(itemId)

  const toggleHandler = (itemId: string) => () => {
    onSelect(
      isSelected(itemId)
        ? value.filter((prevExpertId) => prevExpertId !== itemId)
        : [...value, itemId]
    )
  }

  const filteredExperts = items.filter(({ fullName, email }) => {
    const query = searchProps.value.toLowerCase()
    return (
      fullName.toLowerCase().includes(query) ||
      email.toLocaleLowerCase().includes(query)
    )
  })

  const renderStatus = (user: ExpertUserData) =>
    user.status && (
      <Chip color="blue-solid" variant="rounded">
        {user.status}
      </Chip>
    )

  if (isLoading) return <Loader size="medium" fluid />

  return (
    <List gutter="16">
      <TextInput
        {...searchProps}
        placeholder="Search"
        before={<Icon name="search" />}
        autoFocus
      />

      <ScrollableContainer>
        <List gutter="8">
          {filteredExperts.length > 0 ? (
            filteredExperts.map((item) => (
              <ListItem
                disabled={wasSelected(item.id)}
                onClick={toggleHandler(item.id)}
                active={isSelected(item.id)}
                key={item.id}
              >
                <UserCard
                  fullName={item.fullName}
                  status={renderStatus(item)}
                  isAddedEarlier={wasSelected(item.id)}
                  metaInfo={item.email}
                />
              </ListItem>
            ))
          ) : (
            <NotFound>
              {searchProps.value
                ? "No experts found by query"
                : "No experts found"}
            </NotFound>
          )}
        </List>
      </ScrollableContainer>
    </List>
  )
}

export default ExpertSearch
