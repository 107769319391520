import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useMemo } from "react"

import Header from "@components/containers/Header/Header"
import MobileSideMenu from "@components/containers/MobileSideMenu/MobileSideMenu"
import Sidebar from "@components/containers/Sidebar/Sidebar"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { Option } from "@framework/types/utils"
import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"

import styles from "./MainLayout.module.sass"

export interface MainLayoutProps {
  className?: string
  /**
   * temporary param for container refactoring
   */
  noPadding?: boolean
}

export const MainLayout: React.FC<MainLayoutProps> = observer(
  ({ className, children, noPadding = false }) => {
    const { restrictionsStore: access } = useStore()

    const isTablet = useMediaQuery(AppMediaQueries.minTablet)

    const allowedNavOptions = useMemo(() => {
      const navList: Option[] = []

      if (access.areProductSolutionsAvailable)
        navList.push({
          name: mainRoutes.solutions(),
          value: "Solutions",
          icon: "jigsaw-puzzle",
        })

      if (access.isDataSourcesAvailable)
        navList.push({
          name: mainRoutes.upload(),
          value: "Data Manager",
          icon: access.canEditDataSource ? "upload-cloud" : "cloud",
        })

      if (
        access.isAppUsageAnalyticsAvailable ||
        access.isAPIUsageAnalyticsAvailable
      )
        navList.push({
          name: mainRoutes.analytics(),
          value: "Analytics",
          icon: "stats-random",
        })

      if (access.isQuestionsSectionAvailable)
        navList.push({
          name: mainRoutes.questions(),
          value: "Questions",
          icon: "questions-chat",
        })

      navList.push({
        name: mainRoutes.experts(),
        value: "Experts",
        icon: "users",
      })

      if (access.isExpertInsightsAvailable)
        navList.push({
          name: mainRoutes.expertInsights(),
          value: "Expert Insights",
          icon: "user-insight",
        })

      if (access.isLatestUpdatesAvailable)
        navList.push({
          name: mainRoutes.updates(),
          value: "Latest Updates",
          icon: "loader",
        })

      if (access.isAdminPanelAvailable)
        navList.push({
          name: mainRoutes.adminPanel(),
          value: "Admin panel",
          icon: "terminal-window-line",
        })

      return navList
    }, [
      access.isAvatarsAvailable,
      access.isDataSourcesAvailable,
      access.canEditDataSource,
      access.isQuestionsSectionAvailable,
      access.isLatestUpdatesAvailable,
    ])

    const allowedProfileOptions = useMemo(() => {
      const navList: Option[] = []

      navList.push({ value: "Settings", name: "profile", icon: "settings" })

      return navList
    }, [])

    return (
      <div className={clsx(styles.root, { [styles.noPadding]: noPadding })}>
        {isTablet ? (
          <Header
            renderMenu={(isOpened) => (
              <MobileSideMenu
                isOpened={isOpened}
                menuOptions={allowedNavOptions}
                profileOptions={allowedProfileOptions}
              />
            )}
          />
        ) : (
          <div className={styles.sidebar}>
            <Sidebar
              menuOptions={allowedNavOptions}
              profileOptions={allowedProfileOptions}
            />
          </div>
        )}
        <div className={clsx(styles.content, className)}>{children}</div>
      </div>
    )
  }
)

export default MainLayout
