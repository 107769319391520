import { AxiosResponse } from "axios"

import { SolutionEntity } from "@framework/types/solution"

import { AvatarDetailsData } from "../framework/types/avatar"
import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export interface GetSolutionsResponse extends DefaultSuccessResponse {
  data: AvatarDetailsData
}

export interface GetProductSolutionResponse {
  data: SolutionEntity[]
}

class SolutionAPI extends HttpService {
  getSolutions = (
    channelId: string
  ): Promise<AxiosResponse<GetSolutionsResponse>> =>
    this.get("ts/channel", true, { channelId })

  getAllProductSolutions = (): Promise<
    AxiosResponse<GetProductSolutionResponse>
  > => this.get("ts/admin/product-solution/all")
}

export default new SolutionAPI()
