import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import interceptor from "@services/interceptors/auth.Interceptor"
import { useStore } from "@store/index"

import LoadingPlaceholder from "./LoadingPlaceholder"

export interface SecurityProviderProps {}

const REFRESH_TOKEN_DELAY = 5 * 60 * 1000

const SecurityProvider: React.FC<SecurityProviderProps> = observer(
  ({ children }) => {
    const rootStore = useStore()
    const { authStore } = rootStore
    const { isTokenChecked, isAuthorized, checkAuthority } = authStore

    useEffect(() => interceptor(rootStore), [rootStore])

    useEffect(() => {
      if (!isTokenChecked) {
        checkAuthority()
      }

      if (isAuthorized && isTokenChecked) {
        const intervalId = setInterval(() => {
          checkAuthority()
        }, REFRESH_TOKEN_DELAY)

        return () => clearInterval(intervalId)
      }

      return undefined
    }, [isAuthorized])

    if (!isTokenChecked) return <LoadingPlaceholder />

    return <>{children}</>
  }
)

export default SecurityProvider
