import React from "react"
import moment from "moment"
import clsx from "clsx"

import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import UserCard from "@components/ui/UserCard/UserCard"
import { US_DATE_TIME_FORMAT } from "@framework/constants/global"
import ContentOverflowContainer from "@components/ui/ContentOverflowContainer/ContentOverflowContainer"

import styles from "./Email.module.sass"

interface EmailPreviewProps {
  onShowMore?: React.MouseEventHandler
}

export const EmailPreview: React.FC<EmailPreviewProps> = ({ onShowMore }) => {
  return (
    <Templates.RollScript
      className={clsx(styles.root, styles.preview)}
      headerSocket={
        <Templates.Header
          className={styles.header}
          left={
            <UserCard
              fullName="Email Subject"
              metaInfo={moment().format(US_DATE_TIME_FORMAT)}
            />
          }
        />
      }
    >
      <ContentOverflowContainer onReadMore={onShowMore}>
        <Text variant="body3" color="text70Color" className={styles.body}>
          <p>Hi [Recipient&apos;s Name],</p>

          <br />

          <p>
            I hope this email finds you well. I wanted to reach out to discuss
            the upcoming project and share some important updates. We have made
            significant progress in refining the design and incorporating your
            feedback.
          </p>

          <br />

          <p>
            During our recent team meeting, we identified a few key action items
            that need to be addressed:
          </p>

          <br />

          <ol>
            <li>
              Review and finalize the color palette for the user interface.
            </li>
            <li>Conduct user testing to gather feedback on the prototype.</li>
            <li>
              Schedule a follow-up meeting to discuss the implementation
              timeline.
            </li>
          </ol>

          <br />

          <p>
            Attached to this email, you will find the latest version of the
            project proposal document for your review. Please take some time to
            go through it and provide any additional input or suggestions you
            may have.
          </p>
        </Text>
      </ContentOverflowContainer>
    </Templates.RollScript>
  )
}

export default EmailPreview
