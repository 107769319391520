import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import NotFound from "@components/ui/NotFound/NotFound"
import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import Button from "@components/ui/Button/Button"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import { DataConnectorSourceName } from "@framework/types/upload"
import Icon from "@components/ui/Icon/Icon"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import mainRoutes from "@root/main.routes"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import useDataConnectionContextMenu from "@pages/upload/connectors/Sources/hooks/useDataConnectionContextMenu"
import ContextMenu from "@pages/upload/components/DocumentUploadCard/ContextMenu"
import { capitalizeFirstLetter } from "@utils/textUtils"

import DataConnectorAccountItem from "./DataConnectorAccountItem"
import ModalTitle from "../components/ModalTitle/ModalTitle"

import styles from "./index.module.sass"

const DC_ACCOUNT_CHOICE_KEY_PREFIX = "DC_ACCOUNT_CHOICE"

const getKey = (sourceName: DataConnectorSourceName) =>
  `${DC_ACCOUNT_CHOICE_KEY_PREFIX}_${sourceName.toUpperCase()}`

const rememberChoice = (
  sourceName: DataConnectorSourceName,
  accountId: string
) => localStorage.setItem(getKey(sourceName), accountId)

const forgetChoice = (sourceName: DataConnectorSourceName) =>
  localStorage.removeItem(getKey(sourceName))

const getLatestChoice = (sourceName: DataConnectorSourceName) =>
  localStorage.getItem(getKey(sourceName))

export interface SelectDataConnectorAccountModalProps {
  sourceName: DataConnectorSourceName
  autoSelect?: boolean
}

export const SelectDataConnectorAccountModal: React.FC<SelectDataConnectorAccountModalProps> =
  observer(({ sourceName, autoSelect }) => {
    const navigate = useNavigate()

    const modal = useModal(ModalsTypes.SELECT_DATA_CONNECTOR_ACCOUNT_MODAL)

    const { uploadStore, restrictionsStore: access } = useStore()

    const store = uploadStore.getDataConnectorAccountsStoreByName(sourceName)

    const [remember, setRemember] = React.useState(
      () => !!getLatestChoice(sourceName)
    )

    // const [searchProps, searchHelpers] = useSearch()

    const desc = getDataSourceNodeContextProps(sourceName)

    const selectHandler = (accountId: string) => () => {
      if (remember) rememberChoice(sourceName, accountId)
      else forgetChoice(sourceName)

      navigate(mainRoutes.upload([sourceName, accountId]))
      modal.hideModal()
    }

    const handleConnect = () => {
      navigate(mainRoutes.upload([sourceName, "add"]))
      modal.hideModal()
    }

    const isReadonly = !access.canEditDataSource
    const collection = store.state

    const hasItems = collection.data.length > 0

    const contextMenuProps = useDataConnectionContextMenu({
      sourceName,
      isReadonly,
      afterAction: store.refresh,
    })

    React.useEffect(() => {
      const rememberedId = getLatestChoice(sourceName)

      if (autoSelect && rememberedId) selectHandler(rememberedId)()
    }, [autoSelect])

    const title = `Select ${capitalizeFirstLetter(desc.itemName)}`

    return (
      <BaseModal
        className={styles.root}
        containerClassName={styles.container}
        title={<ModalTitle titleText={title} />}
        onClose={() => modal.hideModal()}
      >
        <Templates.RollScript
          headerSocket={
            hasItems && (
              <div className={styles.header}>
                {/* <TextInput
                  {...searchProps}
                  before={<Icon name="search" />}
                  after={
                    !!searchProps.value && (
                      <Icon
                        name="cross"
                        onClick={() => searchHelpers.setQuery("")}
                      />
                    )
                  }
                  placeholder="Search..."
                /> */}

                <CheckboxWithLabel
                  className={styles.checkbox}
                  label="Remember my choice"
                  checked={remember}
                  onCheck={() => setRemember((prev) => !prev)}
                />
              </div>
            )
          }
          footerSocket={
            !isReadonly && (
              <List align="center" className={styles.footer}>
                <Button
                  variant="outlined"
                  size="big"
                  before={<Icon name="plus" />}
                  onClick={handleConnect}
                >
                  Connect new {desc.itemName}
                </Button>
              </List>
            )
          }
        >
          <List gutter="0" align="stretch">
            {!collection.data.length ? (
              collection.isLoading ? (
                <Loader size="large" fluid />
              ) : (
                <NotFound>
                  {collection.errorMessage || (
                    <>
                      There are none {desc.label}
                      <br />
                      connected yet
                    </>
                  )}
                </NotFound>
              )
            ) : (
              collection.data.map((it, idx) => (
                <React.Fragment key={it.id}>
                  {idx !== 0 && <div className={styles.divider} />}
                  <DataConnectorAccountItem
                    data={it}
                    onClick={selectHandler(it.id)}
                    control={
                      !!contextMenuProps.options.length && (
                        <ContextMenu id={it.id} {...contextMenuProps} />
                      )
                    }
                  />
                </React.Fragment>
              ))
            )}
          </List>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default SelectDataConnectorAccountModal
