import moment from "moment"
import { nanoid } from "nanoid"

import { validExtensions } from "@framework/constants/upload"
import { CloudEntity } from "@framework/types/upload"
import { pickRandom } from "@utils/collections"
import { initArrayByLength, randomNumber } from "@utils/numberUtils"
import { runTask } from "@utils/promise"

const MIN_DIRECTORY_SIZE = 5
const MAX_DIRECTORY_SIZE = 5
const MAX_TREE_SIZE = 5

export const makeCloudEntity = (options: {
  index: number
  parentId: string | null
  path: string
}): CloudEntity => {
  const type = randomNumber(0, 100) > 95 ? "dir" : "file"

  const name =
    type === "file"
      ? `File ${options.index}.${pickRandom(validExtensions)}`
      : `Folder ${options.index}`

  return {
    id: nanoid(),
    path: options.path,
    name,
    type,
    status: "inherit",
    cloudId: nanoid(),
    parentId: options.parentId,
    createdBy: nanoid(),
    updatedBy: nanoid(),
    createdAt: moment().subtract(randomNumber(10, 100), "days").toString(),
    lastUpdatedAt: moment().subtract(randomNumber(1, 10), "days").toString(),
    error: null,
    knowledgeDataTypeId: nanoid(),
    dataConnectorId: nanoid(),
    isUploaded: false,
  }
}

export const mockCloudDirectoryTree = async () => {
  const directories: [string, CloudEntity[]][] = []

  await mockCloudDirectoryList({
    size: MAX_DIRECTORY_SIZE,
    directories,
    level: 0,
    parentId: null,
    parentPath: "",
  })

  return Object.fromEntries(directories)
}

const mockCloudDirectoryList = async (options: {
  size: number
  directories: [string, CloudEntity[]][]
  level: number
  parentId: string | null
  parentPath: string
}) => {
  const list = await Promise.all(
    initArrayByLength(options.size).map(async (index) => {
      const entity = makeCloudEntity({
        index,
        path: options.parentPath,
        parentId: options.parentId,
      })

      if (entity.type === "dir" && options.level < MAX_TREE_SIZE) {
        await runTask(() =>
          mockCloudDirectoryList({
            size: randomNumber(
              MIN_DIRECTORY_SIZE,
              Math.ceil(MAX_DIRECTORY_SIZE / (options.level + 1))
            ),
            parentPath: entity.path
              ? `${entity.path}/${entity.name}`
              : entity.name,
            parentId: entity.id,
            directories: options.directories,
            level: options.level + 1,
          })
        )
      }

      return entity
    })
  )

  options.directories.push([options.parentPath, list])
}
