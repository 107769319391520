import React from "react"

const HTMLText: React.FC<
  React.AllHTMLAttributes<HTMLDivElement> & { children?: string }
> = ({ children = "", ...restProps }) => {
  // eslint-disable-next-line react/no-danger
  return <div {...restProps} dangerouslySetInnerHTML={{ __html: children }} />
}

export default HTMLText
