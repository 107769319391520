import React, { useState } from "react"

import TextInput, { TextInputProps } from "../TextInput/TextInput"
import Icon from "../Icon/Icon"

import styles from "./PasswordField.module.sass"

const PasswordField: React.FC<TextInputProps> = ({ after, ...props }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextInput
      {...props}
      type={showPassword ? "" : "password"}
      after={
        <div className={styles.after}>
          {after}
          <Icon
            name={showPassword ? "eye-close" : "eye"}
            className={styles.eyeIcon}
            onClick={() => setShowPassword(!showPassword)}
          />
        </div>
      }
    />
  )
}

export default PasswordField
