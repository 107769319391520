import React, { useState, useRef, useEffect } from "react"
import clsx from "clsx"

import usePopper from "@components/hooks/usePopper"

import Button from "../Button/Button"
import Icon from "../Icon/Icon"
import Chip from "../Chip/Chip"
import Loader from "../Loader/BarLoader"

import styles from "./MultiSelect.module.sass"

interface Option {
  id: string
  name: string
  value: string
}

interface MultiSelectProps {
  defaultSelectedItems?: string[]
  isLoading?: boolean
  onAddNew: (name: string) => void
  onSelectionChange?: (selectedItems: string[]) => void
  options: Option[]
  placeholder?: string
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  placeholder = "Select...",
  onAddNew,
  isLoading,
  defaultSelectedItems = [],
  onSelectionChange,
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [popperNode, setPepperNode] = useState<HTMLElement | null>(null)
  const [containerNode, setContainerNode] = useState<HTMLElement | null>(null)
  const [isAddingNew, setIsAddingNew] = useState(false)
  const [newOptionName, setNewOptionName] = useState("")

  const { isActive, toggle } = usePopper(containerNode, popperNode, {
    placement: "bottom-start",
    disabled: false,
  })

  useEffect(() => {
    setSelectedItems(defaultSelectedItems)
  }, [defaultSelectedItems])

  const handleSelectItem = (itemId: string) => {
    setSelectedItems((prevSelectedItems) => {
      const newItems = prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((i) => i !== itemId)
        : [...prevSelectedItems, itemId]
      if (onSelectionChange) onSelectionChange(newItems)
      return newItems
    })
  }

  const handleAddNewOption = async () => {
    await onAddNew(newOptionName)
    setIsAddingNew(false)
    setNewOptionName("")
  }

  return (
    <div className={styles.multiSelect} ref={setContainerNode}>
      <div
        className={clsx(styles.selectBox, { [styles.addBorder]: isActive })}
        role="button"
        tabIndex={0}
        onClick={toggle}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            toggle(e as any as React.MouseEvent)
          }
        }}
      >
        <div className={styles.selectedItems}>
          {selectedItems.length > 0 ? (
            selectedItems.map((itemId) => {
              const selectedItem = options.find(
                (option) => option.id === itemId
              )
              return (
                <Chip
                  key={itemId}
                  color="secondary"
                  className={clsx(styles.selectedItem)}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSelectItem(itemId)
                  }}
                >
                  {selectedItem?.name}
                  <Icon name="cross" />
                </Chip>
              )
            })
          ) : (
            <span className={styles.placeholder}>{placeholder}</span>
          )}
        </div>
        <Icon
          name="arrow-down"
          className={clsx(styles.arrow, { [styles.open]: isActive })}
        />
      </div>
      {isActive && (
        <div className={styles.dropdownMenu} ref={setPepperNode}>
          {options.map((option) => (
            <div
              role="button"
              key={option.id}
              className={clsx(styles.option, {
                [styles.selected]: selectedItems.includes(option.id),
              })}
              onClick={() => handleSelectItem(option.id)}
              onKeyPress={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  handleSelectItem(option.id)
                }
              }}
              tabIndex={0}
            >
              {option.value}
              {selectedItems.includes(option.id) && (
                <Icon color="primary" name="done" />
              )}
            </div>
          ))}
          <div className={styles.addNewContainer}>
            {isAddingNew ? (
              <div className={styles.addNewInputContainer}>
                <input
                  type="text"
                  value={newOptionName}
                  onChange={(e) => setNewOptionName(e.target.value)}
                  placeholder="New option name"
                  className={styles.addNewInput}
                />
                {isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <Button
                      variant="text"
                      size="tiny"
                      onClick={handleAddNewOption}
                    >
                      <Icon
                        name="done"
                        color="green"
                        className={clsx(styles.checkIcon)}
                      />
                    </Button>
                    <Button
                      variant="text"
                      size="tiny"
                      onClick={() => setIsAddingNew(false)}
                    >
                      <Icon
                        name="cross"
                        color="red"
                        className={clsx(styles.crossIcon)}
                      />
                    </Button>
                  </>
                )}
              </div>
            ) : (
              <Button
                variant="text"
                size="tiny"
                onClick={() => setIsAddingNew(true)}
              >
                <Icon name="plus" className={clsx(styles.plusIcon)} /> Add New
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default MultiSelect
