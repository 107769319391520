import React from "react"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"

import EditArticleForm, { EditArticleFormData } from "./EditArticleForm"

import styles from "./EditTopicModal.module.sass"

export interface EditArticleModalProps {
  initialValue?: any
  onSubmit?: (data: EditArticleFormData) => Promise<void>
}

export const EditArticleModal: React.FC<EditArticleModalProps> = observer(
  ({ initialValue, onSubmit }) => {
    const { hideModal } = useModal(ModalsTypes.EDIT_EXPERT_ARTICLE_MODAL)

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={initialValue == null ? "Add Article" : "Edit Article"}
          />
        }
        onClose={hideModal}
        className={styles.modalRoot}
      >
        <EditArticleForm
          initialValue={initialValue}
          onSubmit={onSubmit}
          onCancel={hideModal}
        />
      </BaseModal>
    )
  }
)

export default EditArticleModal
