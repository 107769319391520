import { makeAutoObservable } from "mobx"

import { SolutionData } from "@framework/types/solution"

export class SolutionsStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading: boolean = false

  allSolutionsLoading: boolean = false

  errorMessage: string = ""

  solutions: SolutionData[] | null = null

  allSolutions: SolutionData[] | null = null

  solution: SolutionData | null = null

  setSolutions = (solutions: SolutionData[]) => {
    this.solutions = solutions
  }

  setSolution = (solution: SolutionData) => {
    this.solution = solution
  }

  setAllSolutions = (solutions: SolutionData[]) => {
    this.allSolutions = solutions
  }

  get getByIndex() {
    const data = this.solutions ?? []
    return (index: number) => {
      return data[index]
    }
  }
}

export default SolutionsStore
