import { makeAutoObservable } from "mobx"
import sortBy from "lodash/sortBy"

import { BaseUserRole } from "@framework/types/security"
import userService, {
  CreateRoleRequestData,
  UpdateRoleActionsRequestData,
  UpdateRoleNameRequestData,
} from "@services/user.service"
import defaultErrorHandler from "@store/utils/error-handlers"

import RolesActionsRelationsStore from "./roles-actions.store"
import UserActionsStore from "./user-actions.store"
import UserRolesStore from "./user-roles.store"

export class UserRolesController {
  relationsStore: RolesActionsRelationsStore

  rolesStore: UserRolesStore

  actionsStore: UserActionsStore

  constructor(injections: {
    actionsStore: UserActionsStore
    rolesStore: UserRolesStore
    relationsStore: RolesActionsRelationsStore
  }) {
    this.actionsStore = injections.actionsStore
    this.rolesStore = injections.rolesStore
    this.relationsStore = injections.relationsStore
    makeAutoObservable(this)
  }

  isLoading = false

  error: string | null = null

  protected loadAllRoles = async () => {
    const response = await userService.getAllRoles()

    const sortedRoles = sortBy(response.data.data ?? [], "name")

    const roles: Record<string, BaseUserRole> = {}
    const rolesActions = sortedRoles.map<[string, string[]]>((role) => {
      const { userActions, ...baseRole } = role
      roles[role.id] = baseRole
      const actionIds = userActions.map((it) => it.id)
      return [role.id, actionIds]
    }, {})

    this.rolesStore.setRoles(roles)
    this.relationsStore.setRelations(rolesActions)
  }

  init = async () => {
    try {
      this.rolesStore.setLoading()
      this.rolesStore.setError()

      await this.loadAllRoles()
    } catch (error) {
      this.rolesStore.setError(defaultErrorHandler(error))
    } finally {
      this.rolesStore.setLoading(false)
    }
    return this.rolesStore.error
  }

  createRole = async (data: CreateRoleRequestData) => {
    try {
      this.rolesStore.setLoading()
      this.rolesStore.setError()

      await userService.createRole(data)
      await this.loadAllRoles()
    } catch (error) {
      this.rolesStore.setError(defaultErrorHandler(error, "creating role"))
    } finally {
      this.rolesStore.setLoading(false)
    }
    return this.rolesStore.error
  }

  updateRole = async (roleId: string, data: UpdateRoleNameRequestData) => {
    try {
      this.rolesStore.setLoading()
      this.rolesStore.setError()

      await userService.updateRole(roleId, data)
      await this.loadAllRoles()
    } catch (error) {
      this.rolesStore.setError(defaultErrorHandler(error, "updating role"))
    } finally {
      this.rolesStore.setLoading(false)
    }
    return this.rolesStore.error
  }

  removeRole = async (roleId: string) => {
    try {
      this.rolesStore.setLoading()
      this.rolesStore.setError()

      await userService.deleteRole(roleId)
      await this.loadAllRoles()
    } catch (error) {
      this.rolesStore.setError(defaultErrorHandler(error, "deleting role"))
    } finally {
      this.rolesStore.setLoading(false)
    }
    return this.rolesStore.error
  }

  cancelChanges = async () => {
    this.relationsStore.setTemp()
  }

  protected updateRoleActions = async (
    roleId: string,
    changedActions: Map<string, boolean>
  ) => {
    try {
      const data = Array.from(
        changedActions.entries()
      ).reduce<UpdateRoleActionsRequestData>(
        (acc, [key, change]) => {
          if (change) acc.newUserActionIds.push(key)
          else acc.deletedUserActionIds.push(key)
          return acc
        },
        {
          newUserActionIds: [],
          deletedUserActionIds: [],
        }
      )
      const response = await userService.updateRole(roleId, data)
      return { status: "SUCCESS", data: response.data.data }
    } catch (error) {
      return {
        status: "FAILED",
        error: defaultErrorHandler(error),
        data: roleId,
      }
    }
  }

  applyChanges = async () => {
    try {
      this.rolesStore.setLoading()
      this.rolesStore.setError()

      const roleChanges = Array.from(this.relationsStore.changes.entries())
      const response = await Promise.all(
        roleChanges.map(([roleName, roleChanges]) =>
          this.updateRoleActions(roleName, roleChanges)
        )
      )

      await this.loadAllRoles()

      const totalUpdates = response.length
      const totalFailed = response.filter((it) => it.status === "FAILED").length
      if (totalFailed > 0)
        return `Failed  to update ${totalFailed} of ${totalUpdates} roles`
    } catch (error) {
      this.rolesStore.setError(defaultErrorHandler(error, "updating roles"))
    } finally {
      this.rolesStore.setLoading(false)
    }
    return this.rolesStore.error
  }
}

export default UserRolesController
