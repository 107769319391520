import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import { useStore } from "@store/index"
import mainRoutes from "@root/main.routes"

import TopExpertList from "./TopExpertList"
import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"

import styles from "./TopExperts.module.sass"

const TopExperts: React.FC = observer(() => {
  const { expertStore } = useStore()
  const { avatar } = useActiveAvatar()
  const { experts, loadRankedExperts } = expertStore
  const navigate = useNavigate()

  React.useEffect(() => {
    if (avatar?.id) loadRankedExperts(avatar.id)
  }, [avatar?.id])

  return (
    <div className={styles.root}>
      <Text variant="h4" weight="bold">
        Top Experts
      </Text>

      <TopExpertList />

      {!!experts?.length && (
        <Button
          onClick={() => navigate(mainRoutes.experts())}
          size="small"
          variant="outlined"
          color="primary"
          className={styles.viewAll}
        >
          View All
        </Button>
      )}
    </div>
  )
})

export default TopExperts
