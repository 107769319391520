import { makeAutoObservable } from "mobx"

import {
  DataConnectorSourceName,
  DataSourceFile,
} from "@framework/types/upload"

import EntityCollection from "../utils/EntityCollection"

export default class AvatarDataConnectorsStore {
  constructor() {
    makeAutoObservable(this)
  }

  collectionsMap: Map<string, EntityCollection<DataSourceFile>> = new Map()

  getCollection = (
    dataCategoryId: string,
    dataConnectorType: DataConnectorSourceName | string
  ) => {
    const key = `${dataCategoryId}_${dataConnectorType}`
    if (!this.collectionsMap.has(key)) this.resetCollections(key)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.collectionsMap.get(key)!
  }

  resetCollections = (collectionKey: string) => {
    this.collectionsMap.set(collectionKey, new EntityCollection())
  }
}
