import React from "react"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ExpertSubtopicDTO, ExpertTopicDTO } from "@framework/types/knowledge"
import Text from "@components/ui/Typography/Text"
import List from "@components/ui/List/List"

import EditSubtopicForm, { EditSubtopicFormData } from "./EditSubtopicForm"

import styles from "./EditTopicModal.module.sass"

export interface EditSubtopicModalProps {
  topic: ExpertTopicDTO
  initialValue?: ExpertSubtopicDTO
  onSubmit?: (data: EditSubtopicFormData) => Promise<void>
}

export const EditSubtopicModal: React.FC<EditSubtopicModalProps> = observer(
  ({ initialValue, topic, onSubmit }) => {
    const { hideModal } = useModal(ModalsTypes.EDIT_EXPERT_SUBTOPIC_MODAL)

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={initialValue == null ? "Add Subtopic" : "Edit Subtopic"}
          />
        }
        onClose={hideModal}
      >
        <List gutter="16">
          <List gutter="4">
            <Text variant="h5" upperCase>
              Topic
            </Text>
            <Text variant="body2" weight="bold">
              {topic.title}
            </Text>
          </List>

          <hr className={styles.divider} />

          <EditSubtopicForm
            initialValue={initialValue}
            onSubmit={onSubmit}
            onCancel={hideModal}
          />
        </List>
      </BaseModal>
    )
  }
)

export default EditSubtopicModal
