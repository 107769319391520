import sortBy from "lodash/sortBy"
import moment from "moment"
import type Moment from "moment"

import { DEFAULT_DATE_FORMAT } from "@framework/constants/global"
import { DateRange } from "@framework/types/common"

export const renderDatePeriodTuple = ([start, end]: [Date, Date]) => {
  return `${moment(start).format(DEFAULT_DATE_FORMAT)} - ${moment(end).format(
    DEFAULT_DATE_FORMAT
  )}`
}

export const renderDatePeriodTupleToRange = (
  [start, end]: [Date, Date],
  format: string = DEFAULT_DATE_FORMAT
): DateRange => {
  return {
    start: moment(start).format(format),
    end: moment(end).format(format),
  }
}

export const sortDatePeriodTuple = <T extends [Date, Date] | Date[]>(
  period: T
): T => {
  return sortBy(period, (it) => moment(it).unix()) as T
}

export const renderDatePeriod = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}) => {
  return `${moment(startDate).format(DEFAULT_DATE_FORMAT)} - ${moment(
    endDate
  ).format(DEFAULT_DATE_FORMAT)}`
}

const durationSequence = ["d", "h", "min", "s", "ms"] as const

type TimePrecision = (typeof durationSequence)[number]

export const renderDuration = (
  milliseconds: number,
  precision: TimePrecision = "s"
) => {
  const slices: Record<TimePrecision, number> = {
    d: Math.floor(milliseconds / 86_400_000),
    h: Math.floor(milliseconds / 3_600_000) % 24,
    min: Math.floor(milliseconds / 60_000) % 60,
    s: Math.floor(milliseconds / 1_000) % 60,
    ms: Math.round(milliseconds % 1_000),
  }

  const precisionLastIndex = durationSequence.findIndex(
    (it) => it === precision
  )

  return durationSequence
    .reduce<string>((acc, it, idx) => {
      if (idx <= precisionLastIndex && slices[it] > 0)
        return `${acc} ${slices[it]}${it}`

      return acc
    }, "")
    .trim()
}

export const getLastNDaysRange = (days: number): [Date, Date] => {
  const nowMoment = moment()

  const from = nowMoment
    .clone()
    .subtract(days - 1, "days")
    .startOf("day")
    .toDate()

  const to = nowMoment.clone().endOf("day").toDate()

  return [from, to]
}

export const getLastPeriodRange = (
  rangeSize: number,
  unit: Moment.unitOfTime.Diff
): [Date, Date] => {
  const nowMoment = moment()

  const from = nowMoment
    .clone()
    .subtract(rangeSize, unit)
    .startOf(unit)
    .toDate()

  const to = nowMoment.clone().subtract(1, unit).endOf(unit).toDate()

  return [from, to]
}

export const getCurrentPeriodRange = (
  unit: Moment.unitOfTime.Diff
): [Date, Date] => {
  const nowMoment = moment()

  const from = nowMoment.clone().startOf(unit).toDate()
  const to = nowMoment.toDate()

  return [from, to]
}
