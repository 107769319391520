import clsx from "clsx"
import React from "react"

import styles from "./ListItem.module.sass"

export interface ListItemProps extends React.HTMLProps<HTMLDivElement> {
  active?: boolean
  sticky?: boolean
  color?: "primary" | "secondary"
}

export const ListItem = ({
  children,
  className,
  tabIndex,
  role,
  active = false,
  sticky = false,
  color = "primary",
  onKeyPress,
  onClick,
  onDoubleClick,
  disabled,
  ...rest
}: ListItemProps) => {
  const rootClassName = clsx(
    styles.item,
    styles[`color-${color}`],
    {
      [styles.sticky]: sticky,
      [styles.active]: active,
      [styles.clickable]: !!onClick || !!onDoubleClick,
      [styles.disabled]: !!disabled,
    },
    className
  )
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={rootClassName}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      tabIndex={tabIndex}
      onKeyPress={onKeyPress}
      role={role}
      {...rest}
    >
      {children}
    </div>
  )
}

export default ListItem
