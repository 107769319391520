import React from "react"
import { observer } from "mobx-react-lite"

import Spreadsheet from "@components/ui/Spreadsheet/Spreadsheet"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store"

import styles from "./index.module.sass"

const UnifiedMatrix: React.FC<any> = observer(() => {
  const {
    unifiedMatrixStore: { matrixStore, companiesStore },
  } = useStore()

  const { unifiedMatrixController } = useController()

  React.useEffect(() => {
    matrixStore.editor.functionManager.registerFunction({
      name: "THEME",
      transformArgs: (args: any[]) => {
        if (args.length > 3)
          throw new Error("Function THEME expects tree arguments maximum")

        if (args.length < 2)
          throw new Error("Function THEME expects at least two arguments")

        if (typeof args[0] !== "string" || !args[0])
          throw new Error("Function THEME expects string as first argument")

        if (typeof args[1] !== "string" || !args[1])
          throw new Error("Function THEME expects string as second argument")

        if (!(args[2] == null || (typeof args[2] === "string" && !!args[2])))
          throw new Error("Function THEME expects string as third argument")

        return args
      },
      handler: async (...args: [string, string, string | undefined]) => {
        const response = await unifiedMatrixController.generateThemes(...args)

        if (response.status === "FAILED")
          throw new Error("Failed to generate themes for unknown reason")

        return response.data
      },
    })

    unifiedMatrixController.fetchCompanies()
  }, [])

  React.useEffect(() => {
    if (companiesStore.companies != null) {
      matrixStore.setValidation("A2:A100", {
        type: "OPTION",
        list: companiesStore.companies.map((it) => it.name),
      })
    }
  }, [companiesStore.companies])

  return (
    <div className={styles.root}>
      <Templates.Header
        className={styles.header}
        left={
          <List overflow="initial" gutter="0">
            <Text color="text50Color" variant="caption2">
              Workflow
            </Text>
            <Text color="text100Color" variant="h3">
              Theme Generation
            </Text>
          </List>
        }
        right={
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            before={<Icon name="plus" />}
          >
            Workflow
          </Button>
        }
      />
      <div className={styles.body}>
        <Spreadsheet instance={matrixStore} />
      </div>
    </div>
  )
})

export default UnifiedMatrix
