import { makeAutoObservable } from "mobx"

import { ExpertSubtopicDTO } from "@framework/types/knowledge"

import EntityCollection from "../utils/EntityCollection"

export default class ExpertSubtopicsStore {
  constructor() {
    makeAutoObservable(this)

    this.collectionsMap = new Map()
  }

  collectionsMap: Map<string, EntityCollection<ExpertSubtopicDTO>>

  getCollection = (topicId: string) => {
    if (!this.collectionsMap.has(topicId)) this.resetCollection(topicId)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.collectionsMap.get(topicId)!
  }

  resetCollection = (collectionKey: string) => {
    this.collectionsMap.set(collectionKey, new EntityCollection())
  }
}
