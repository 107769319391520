import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { useSearchFlowPassageContext } from "@pages/search/SearchContext/SearchPassageContext"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import { AnswerViewType } from "@framework/constants/search-results"

import DefaultControlButton from "./DefaultControlButton"
import { AnswerControlProps } from "./AnswerControl"

import styles from "./AnswerControl.module.sass"

export interface UpVoteAnswerControlProps extends AnswerControlProps {
  answerType: AnswerViewType
}

export const UpVoteAnswerControl: React.FC<UpVoteAnswerControlProps> = observer(
  ({ id, answer, answerType }) => {
    const alert = useAlert()

    const [isUserVoted, setUpVote] = useState(!!answer.current_user_vote)
    const [totalUpvote, setTotalUpvote] = useState(answer.vote || 0)
    const { avatar } = useActiveAvatar()

    const { upVotePassage } = useSearchFlowPassageContext()

    const handleUpVote = async (e: any) => {
      e.stopPropagation()
      const newVote = !answer.current_user_vote

      setUpVote(newVote)
      setTotalUpvote((v) => v + (newVote ? +1 : -1))

      const error = await upVotePassage(id, newVote, answerType, avatar.id)

      if (error) {
        alert.error(error)
        setUpVote(!newVote)
        setTotalUpvote((v) => v + (newVote ? -1 : +1))
      }
    }

    return (
      <div className={styles.upvoteContainer}>
        <DefaultControlButton
          title={isUserVoted ? "Cancel Upvote" : "Move up"}
          icon={isUserVoted ? "big-arrow-up-filled" : "big-arrow-up"}
          onClick={handleUpVote}
        />
        {totalUpvote !== 0 && (
          <Tooltip
            className={styles.upvoteCounter}
            content={
              <TooltipContainer placement="top">Upvotes</TooltipContainer>
            }
            mode="onHover"
          >
            {totalUpvote !== 0 ? totalUpvote : ""}
          </Tooltip>
        )}
      </div>
    )
  }
)

export default UpVoteAnswerControl
