import React from "react"
import clsx from "clsx"

import styles from "./Drawer.module.sass"

export interface DrawerProps {
  position?: "top" | "right" | "bottom" | "left"
  className?: string
  open: boolean
}

export const Drawer: React.FC<DrawerProps> = ({
  open,
  position = "right",
  className,
  children,
}) => (
  <div className={clsx(styles.root, open ? styles.visible : styles.hidden)}>
    <div
      className={clsx(
        styles.sidebar,
        styles[`position-${position}`],
        className
      )}
    >
      {children}
    </div>
  </div>
)

export default Drawer
