import { AnswerDataType, AnswerItem } from "@framework/types/search"
import {
  AnswerViewType,
  extendedResultTypeOptions,
} from "@framework/constants/search-results"
import { testAnswerType } from "@store/search/utils"

export const answerTypeMapper: Record<AnswerViewType, AnswerDataType[]> = {
  Text: ["summaries"],
  Image: ["image"],
  Table: ["quants", "discard"],
}

export const getAvailableOptions = (answer: AnswerItem) => {
  const { screenshots, images } = answer.value

  return extendedResultTypeOptions.filter(({ name }) => {
    if (name === "Image")
      return testAnswerType(answer, "image")
        ? !!answer.value.image_url
        : !!images?.length
    if (name === "Original") return !!screenshots?.length
    if (answerTypeMapper[name].includes(answer.value.data_type)) return true
    return false
  })
}
