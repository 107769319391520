import { observer } from "mobx-react-lite"
import React from "react"

import { useController } from "@store"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Text from "@components/ui/Typography/Text"
import useAvatarKnowledgePreviewStore from "@store/avatar-knowledge/useAvatarKnowledgePreview"
import LinkCard from "@components/ui/LinkCard/LinkCard"
import Icon from "@components/ui/Icon/Icon"
import IconChip from "@components/ui/Chip/IconChip"
import useTopicsCollection from "@pages/expert-insights/Topics/hooks/useTopicsCollection"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"

import styles from "./AvailableKnowledge.module.sass"

export const ExpertKnowledgeSection: React.FC = observer(() => {
  const { avatar } = useActiveAvatar()

  const questionsCollection = useAvatarKnowledgePreviewStore(
    avatar.id
  ).expertKnowledgeStore

  const topicsCollection = useTopicsCollection(avatar.id)

  const hasData = !!questionsCollection.meta.total || topicsCollection.total > 0

  const defaultRedirectState = {
    backTo: "/home",
    avatar: avatar?.id,
  }

  const { avatarKnowledgePreviewController, expertInsightsController } =
    useController()

  React.useEffect(() => {
    if (!avatar) return
    avatarKnowledgePreviewController.loadExpertAnswers(avatar.id)
    expertInsightsController.loadTopicList(avatar.id, "", false, "view")
  }, [avatar?.id])

  if (
    (questionsCollection.isLoading || topicsCollection.isLoading) &&
    !hasData
  ) {
    return <Loader size="large" fluid />
  }

  if (!hasData) {
    return <NotFound />
  }

  return (
    <>
      <div className={styles.grid}>
        {questionsCollection.data.length > 0 && (
          <LinkCard
            label="Questions and Answers"
            to={`expert-source/${avatar.id}`}
            state={defaultRedirectState}
            icon={
              <IconChip color="primary">
                <Icon name="questions-chat" />
              </IconChip>
            }
            description={
              <>
                <Text variant="caption3" color="text70Color" align="center">
                  {questionsCollection.meta.total} questions
                </Text>
              </>
            }
          />
        )}

        {topicsCollection.total > 0 && (
          <LinkCard
            label="Expert Insights"
            to={`expert-insights/${avatar.id}`}
            state={defaultRedirectState}
            icon={
              <IconChip color="primary">
                <Icon name="user-insight" />
              </IconChip>
            }
            description={
              <>
                <Text variant="caption3" color="text70Color" align="center">
                  {topicsCollection.total} topics
                </Text>
              </>
            }
          />
        )}
      </div>
    </>
  )
})

export default ExpertKnowledgeSection
