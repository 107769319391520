export const CONNECTORS = [
  "Google Drive",
  "Box",
  "One Drive",
  "Dropbox",
  "Manual Upload",
  "Email Forward",
  "Sharepoint Cloud",
  "Confluence Cloud",
  "Confluence Server",
  "Slack",
  "Teams",
  "Salesforce",
]

export const SUPPORT = ["Basic", "Premium"]

export const ENVIRONMENT = ["Staging", "Production"]

export const PLATFORM_HOSTING = ["Cloud", "VPC"]

export const SUBSCRIPTION_OPTIONS_KEYS = {
  CONNECTORS_BASIC: "connectorsBasic",
  CONNECTORS_PREMIUM: "connectorsPremium",
  CONNECTORS_CUSTOM: "connectorsCustom",
  PLATFORM_HOSTING: "platformHosting",
  ENVIRONMENTS: "environments",
  SUPPORT: "support",
  API_QUOTA: "apiQuota",
  DATA_QUOTA: "dataQuota",
  NUMBER_OF_USERS: "numberOfUsers",
}

export const SUBSCRIPTION_OPTION_LABELS: { [key: string]: string } = {
  connectorsBasic: "Basic",
  connectorsPremium: "Premium",
  connectorsCustom: "Custom",
  platformHosting: "None",
  environments: "None",
  support: "None",
  apiQuota: "calls / month",
  dataQuota: "GB",
  numberOfUsers: "",
}
