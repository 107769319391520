import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { useController, useStore } from "@store/index"
import TextInput from "@components/ui/TextInput/TextInput"
import { SUBSCRIPTION_OPTION_LABELS } from "@framework/constants/subscription"

import StatusText from "./StatusText"

import styles from "./index.module.sass"

export interface SimpleTableProps {
  optionName: string
}

export const EditInput: React.FC<SimpleTableProps> = observer(
  ({ optionName }) => {
    const { subscriptionStore } = useStore()
    const { subscriptionController } = useController()
    const { data, isEditable } = subscriptionStore
    const defaultValue = data?.[optionName as keyof typeof data] as string
    const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      subscriptionController.updateEditData(optionName, value)
    }
    const label = SUBSCRIPTION_OPTION_LABELS[optionName]

    return isEditable ? (
      <div className={styles.textInputWrapper}>
        <TextInput
          name={optionName}
          defaultValue={defaultValue}
          onChange={onTextChange}
          onBlur={onTextChange}
          type="number"
          after={<div className={clsx(styles.inputEnd)}>{label}</div>}
          className={styles.textInputCustom}
        />
      </div>
    ) : (
      <StatusText text={`${defaultValue} ${label}`} />
    )
  }
)

export default EditInput
