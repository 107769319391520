import React from "react"
import { useNavigate } from "react-router-dom"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"
import MeetingPreview from "../InformalKnowledgeDetails/MeetingPreview"

import styles from "./KnowledgeSection.module.sass"

export const MeetingsPreview: React.FC = () => {
  const navigate = useNavigate()
  const { avatar } = useActiveAvatar()

  const handleShowAll = () =>
    navigate("meetings", {
      state: {
        backTo: "/home",
        avatar: avatar?.id,
      },
    })

  return (
    <div className={styles.preview}>
      <MeetingPreview onShowMore={handleShowAll} />
      <MeetingPreview onShowMore={handleShowAll} />
    </div>
  )
}

export default MeetingsPreview
