import React from "react"

import Col from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"

interface ApiKeysHeaderProps {}

const ApiKeysTableHeader: React.FC<ApiKeysHeaderProps> = () => {
  return (
    <Row>
      <Col as="th" key="name" align="center">
        Key Name
      </Col>
      <Col as="th" key="actions" width="1%" />
      <Col as="th" key="apiKeys" align="center">
        Api Keys
      </Col>
      <Col as="th" key="createdAt" align="center" justify="center">
        Created At
      </Col>
    </Row>
  )
}

export default ApiKeysTableHeader
