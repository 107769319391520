/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"
import { NavLink, NavLinkProps } from "react-router-dom"

import { Icon } from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"

import styles from "./NavItem.module.sass"

export interface NavItemProps extends NavLinkProps {
  to: string
  icon?: IconName
  label?: string
  mark?: React.ReactNode
  className?: string
  onClick?: () => void
}

export const NavItem: React.FC<NavItemProps> = ({
  to,
  icon,
  label,
  mark,
  className,
  onClick,
  ...rest
}) => (
  <NavLink
    to={to}
    onClick={onClick}
    className={({ isActive }) =>
      clsx(styles.root, { [styles.active]: isActive }, className)
    }
    {...rest}
  >
    <span className={styles.icon}>
      <Icon name={icon} />
      <span className={styles.badge}>{mark}</span>
    </span>
    {label && <span className={styles.label}>{label}</span>}
  </NavLink>
)

export default NavItem
