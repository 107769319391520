import React from "react"

import BaseModal from "../components/BaseModal/BaseModal"
import { ModalsTypes } from "../constants"
import useModal from "../useModal"

import styles from "./SuccessMessageModal.module.sass"

export interface SuccessMessageModalProps {
  message: React.ReactNode
}

export const SuccessMessageModal: React.FC<SuccessMessageModalProps> = ({
  message,
}) => {
  const { hideModal } = useModal(ModalsTypes.SUCCESS_MESSAGE_MODAL)

  return (
    <BaseModal containerClassName={styles.root} onClose={hideModal}>
      <img
        className={styles.doneIcon}
        src="/images/success-icon.svg"
        alt="success-icon"
      />
      {message}
    </BaseModal>
  )
}

export default SuccessMessageModal
