import React, { useEffect } from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./EditInstanceConfigForm.module.sass"

export const formSchema = yup.object({
  key: yup
    .string()
    .required("Filed is required")
    .matches(/^[a-zA-Z\\-]+$/, "Must be a word in kebab case")
    .min(3, "Must be at least 3 chars length")
    .max(20, "Must be 64 chars maximum")
    .default("")
    .trim(),
})

export type FormData = yup.InferType<typeof formSchema>

interface EditInstanceConfigFormProps {
  isLoading?: boolean
  className?: string
  errors?: FormData
  initialValues?: FormData
  onSubmit: (form: FormData) => void
}

const EditInstanceConfigForm: React.FC<EditInstanceConfigFormProps> = ({
  errors,
  isLoading,
  initialValues = formSchema.getDefault(),
  onSubmit,
}) => {
  const formik = useFormik<FormData>({
    initialValues,
    validationSchema: formSchema,
    onSubmit: (form: FormData) => {
      onSubmit(formSchema.cast(form))
    },
  })

  useEffect(() => {
    if (errors) formik.setErrors(errors)
  }, [errors])

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <div className={styles.form}>
        <TextInput
          autoFocus
          name="key"
          placeholder="Enter role name..."
          value={formik.values.key}
          withError={!!(formik.touched.key && formik.errors.key)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          after={
            formik.touched.key &&
            formik.errors.key && (
              <div className={styles.after}>
                <ErrorChip
                  message={formik.errors.key}
                  messagePlacement="left"
                />
              </div>
            )
          }
        />
        <Button type="submit" color="primary" disabled={isLoading}>
          Save {isLoading && <Loader />}
        </Button>
      </div>
    </form>
  )
}

export default EditInstanceConfigForm
