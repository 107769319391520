import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry } from "rxjs/operators"
import { toStream } from "mobx-utils"

import { ConnectedWebsite } from "@framework/types/upload"
import uploadService from "@services/upload.service"
import { VirtualListChunk } from "@framework/types/utils"

export type FractionName = "total" | "uploaded" | "running" | "failed"

export type FileCollectionFractions = Record<FractionName, number>

const PULLING_DELAY = 20_000
const RETRY_DELAY = 30_000
const CHUNK_SIZE = 120

export type Filter = {
  dataTypes?: string[]
  createdByUserIds?: string[]
  updatedByUserIds?: string[]
  createdAtRange?: { start: string; end: string }
  lastUpdatedAtRange?: { start: string; end: string }
}

type State = {
  isLoading: boolean

  data: ConnectedWebsite[]

  total: number

  errorMessage: string

  reloadTimestamp: boolean

  query?: string

  filter: Filter

  fraction: FractionName

  requestedFrame: VirtualListChunk

  actualFrame: VirtualListChunk

  distribution: FileCollectionFractions
}

/**
 * @class
 */
export class WebsitesCollectionStore {
  state: State

  private loadingStream$?: Subscription

  // constructor

  constructor() {
    this.state = {
      isLoading: false,

      data: [],

      total: 0,

      errorMessage: "",

      reloadTimestamp: false,

      requestedFrame: { offset: 0, limit: CHUNK_SIZE },

      actualFrame: { offset: 0, limit: CHUNK_SIZE },

      query: "",

      filter: {},

      fraction: "total",

      distribution: { total: CHUNK_SIZE, running: 0, uploaded: 0, failed: 0 },
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "total", () => {
      this.loadingStream$ = this.initStream()
    })

    onBecomeUnobserved(this.state, "total", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  initStream = () => {
    return from(
      toStream(
        () => [
          this.state.requestedFrame,
          this.state.query,
          this.state.filter,
          this.state.fraction,
          this.state.reloadTimestamp,
        ],
        true
      ) as ObservableInput<
        [VirtualListChunk, string, Filter, FractionName, boolean]
      >
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.errorMessage = ""
          })
        ),

        switchMap(([frame, query, filter, fraction]) =>
          uploadService.getConnectedWebsites$({
            offset: frame.offset,
            limit: frame.limit,
            ...filter,
            status: fractionNameToStatusFilter(fraction),
            query,
          })
        ),

        tap((response) =>
          runInAction(() => {
            const { meta, data = [], count } = response.data

            this.state.data = [...data]

            this.state.actualFrame = { ...meta }

            this.state.total = meta?.total ?? CHUNK_SIZE

            this.state.distribution = {
              total: count.totalWebsites,
              running: count.runningWebsites,
              failed: count.failedWebsites,
              uploaded: count.uploadedWebsites,
            }
          })
        ),

        tap(() =>
          runInAction(() => {
            this.state.isLoading = false
          })
        ),

        retry({
          delay: () => {
            runInAction(() => {
              this.state.errorMessage = "Loading Failed"
              this.state.isLoading = false
              this.state.data = []
            })
            return timer(RETRY_DELAY)
          },
        }),

        switchMap(() => timer(PULLING_DELAY)),

        tap(() => this.refresh())
      )
      .subscribe()
  }

  get getByIndex() {
    const { data } = this.state
    return (index: number) => {
      const { offset } = this.state.actualFrame
      return data[index - offset] ?? null
    }
  }

  load = async ({
    startIndex,
    stopIndex,
  }: {
    startIndex: number
    stopIndex: number
  }) => {
    const { offset, limit } = this.state.requestedFrame

    const leftBoundary = offset
    const rightBoundary = offset + limit

    // ignore if chunk is loading already
    if (leftBoundary < startIndex && stopIndex < rightBoundary) return

    const shift = Math.ceil(limit / 2)

    // extends chunk size
    if (startIndex < leftBoundary && rightBoundary < stopIndex) {
      this.state.requestedFrame = {
        offset: offset - shift,
        limit: limit + shift,
      }
    }

    if (startIndex < leftBoundary) {
      this.state.requestedFrame = { offset: offset - shift, limit }
    }

    if (rightBoundary < stopIndex) {
      this.state.requestedFrame = { offset: offset + shift, limit }
    }
  }

  search = (query: string = "") => {
    this.state.query = query
  }

  changeFraction = (value: FractionName) => {
    this.state.fraction = value
  }

  applyFilter = (filter: Filter) => {
    this.state.filter = { ...filter }
  }

  refresh = () => {
    this.state.reloadTimestamp = !this.state.reloadTimestamp
  }
}

export default WebsitesCollectionStore

const fractionNameToStatusFilter = (fraction: FractionName) => {
  if (fraction === "total") return []
  if (fraction === "uploaded") return ["success", "assigned"]
  return [fraction]
}
