import React from "react"
import { autorun, makeAutoObservable, runInAction, toJS } from "mobx"
import find from "lodash/find"

import { Point, Range } from "@framework/types/common"
import { filterByQuery } from "@utils/textUtils"

import { Parser, ParseResult, tokenize } from "../parser"
import { refToRange } from "../utils"
import { normalizeFormula } from "../parser/utils"
import EditManager from "./EditManager"
import FormulaExecutor from "./FormulaExecutor"

type OptionValidation = {
  type: "OPTION"
  list: string[]
}

export type CellValidationRule = OptionValidation // other...

const formulaParser = new Parser()

type State = {
  input: any

  value: any

  formula: ParseResult | null

  isLoading: boolean

  error?: string | null
}

class CellManager {
  // injections

  manager: EditManager

  // state
  id: string

  state: State

  validation: OptionValidation | null = null

  task: FormulaExecutor | null = null

  constructor(options: { id: string; manager: EditManager }) {
    this.id = options.id
    this.manager = options.manager

    this.state = {
      input: "",
      value: "",
      error: null,
      formula: null,
      isLoading: false,
    }

    makeAutoObservable(this)

    // autorun(() => console.log(toJS(this)))
  }

  get value() {
    return this.state.value
  }

  apply = async () => {
    try {
      this.state.formula = null
      this.state.error = null
      this.state.isLoading = true

      const inputValue = this.state.input

      if (inputValue && this.validation != null) {
        if (this.validation.type === "OPTION" && this.validation.list.length) {
          this.state.isLoading = false

          const option = filterByQuery(this.validation.list, inputValue)

          if (option.length === 1) {
            const onlyOption = option[0]
            this.state.value = onlyOption
            this.state.input = onlyOption
            return
          }

          const exactMatch = find(
            option,
            (it) => it.toLowerCase() === inputValue.toLowerCase()
          )

          if (exactMatch != null) {
            this.state.value = exactMatch
            this.state.input = exactMatch
            return
          }

          this.state.input = ""
          this.state.value = ""
          return
        }
      }

      if (!inputValue?.startsWith("=")) {
        this.state.value = inputValue
        this.state.isLoading = false
        return
      }

      const tokens = tokenize(inputValue.substring(1))

      this.state.formula = formulaParser.parse(tokens)

      if (!this.manager.isValidateRefs(this.id, this.state.formula.refs))
        throw new Error("#REF!")

      const formulaTask = new FormulaExecutor({
        manager: this.manager,
        formula: this.state.formula,
      })

      this.task = formulaTask

      await formulaTask.run()

      if (this.task.status === "completed") {
        runInAction(() => {
          this.state.value = formulaTask.result
          this.state.isLoading = false
        })
        return
      }

      if (this.task.status === "failed") {
        runInAction(() => {
          this.state.value = ""
          this.state.error = formulaTask.error
          this.state.isLoading = false
        })
        return
      }
    } catch (error: any) {
      runInAction(() => {
        this.state.error =
          typeof error.message === "string" ? error.message : "Unexpected error"
        this.state.isLoading = false
      })
    }
  }

  setInput = (newInput: any = "") => {
    // eslint-disable-next-line eqeqeq
    if (this.state.input == newInput && this.state.error == null) return

    this.state = {
      input: newInput.trim(),
      isLoading: false,
      formula: null,
      error: null,
      value: null,
    }

    this.apply()
  }

  setValidation = (validation: CellValidationRule) => {
    this.validation = { ...validation }

    this.apply()
  }

  cleanUp = () => this.setInput("")
}

export default CellManager
