import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"

import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import List from "@components/ui/List/List"
import { Synonym } from "@framework/types/glossary"
import { ManagementMode } from "@framework/types/utils"
import { constructComplexUrlWithParams } from "@utils/textUtils"
import { useStore } from "@store/index"
import { GlossaryPageMode } from "@pages/upload/Glossary/Glossary"

import Column from "../Table/Column"
import Row from "../Table/Row"

import styles from "./index.module.sass"

interface SynonymRowProps {
  synonym: Synonym
}

export const SynonymRow: React.FC<SynonymRowProps> = observer(({ synonym }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { restrictionsStore: access } = useStore()

  const deleteModal = useModal(ModalsTypes.DELETE_SYNONYM_MODAL)

  const handleEdit = () => {
    navigate(
      constructComplexUrlWithParams(location.pathname, {
        glossaryMode: GlossaryPageMode.SYNONYM,
        managementMode: ManagementMode.editing,
        managingId: synonym.id,
      })
    )
  }

  const handleDelete = () => {
    deleteModal.showModal({
      synonym,
      onClose: () =>
        navigate(
          constructComplexUrlWithParams(location.pathname, {
            glossaryMode: GlossaryPageMode.SYNONYM,
          })
        ),
    })
  }

  return (
    <Row className={styles.row} highlightable>
      <Column justify="center" align="center">
        <span className={styles.text}>{synonym.mainWord}</span>
      </Column>

      <Column align="center" key={`synonyms by main word-${synonym.id}`}>
        <List
          direction="row"
          gutter="8"
          wrap="wrap"
          align="center"
          overflow="initial"
        >
          {synonym.synonymsByMainWord.length
            ? synonym.synonymsByMainWord.map((value, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Chip color="blue" uppercase key={idx}>
                  <span className={styles.text}>{value}</span>
                </Chip>
              ))
            : "-"}
        </List>
      </Column>

      <Column key={`context-menu-${synonym.id}`} align="start" justify="end">
        {access.canEditGlossary && (
          <List
            direction="row"
            gutter="8"
            justify="flex-end"
            className={styles.showOnHover}
          >
            <>
              <Button
                variant="outlined"
                size="medium"
                noPadding
                onClick={handleEdit}
                title="Edit"
              >
                <Icon name="edit" />
              </Button>
              <Button
                variant="outlined"
                size="medium"
                color="red"
                onClick={handleDelete}
                noPadding
                title="Delete"
              >
                <Icon name="trash-can" />
              </Button>
            </>
          </List>
        )}
      </Column>
    </Row>
  )
})

export default SynonymRow
