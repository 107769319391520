import React, { useEffect, useState } from "react"
import clsx from "clsx"

import useToggle from "@components/hooks/useToggle"

import ImagePreview from "./components/ImagePreview"
import Icon from "../Icon/Icon"

import styles from "./AnswerOriginalView.module.sass"

const previewMock = [
  "http://placekitten.com/570/800",
  "http://placekitten.com/571/801",
  "http://placekitten.com/572/802",
  "http://placekitten.com/573/803",
  "http://placekitten.com/570/800",
  "http://placekitten.com/571/801",
  "http://placekitten.com/572/802",
  "http://placekitten.com/573/803",
]

interface PDFViewerProps {
  images: string[]
}

const AnswerOriginalView: React.FC<PDFViewerProps> = ({
  images = previewMock,
}) => {
  const [scale, setScale] = useState<number>(90)
  const [image, setImage] = useState<string>()
  const { isOpened, handleToggle } = useToggle()

  useEffect(() => {
    if (images.length) setImage(images[0])
  }, [images])

  const handleScaleUp = () => {
    setScale((currScale) => (currScale < 170 ? currScale + 10 : currScale))
  }

  const handleScaleDown = () => {
    setScale((currScale) => (currScale > 20 ? currScale - 10 : currScale))
  }

  useEffect(() => {
    setScale(90)
  }, [image])

  return (
    <div className={clsx(styles.root, { [styles.fullscreen]: isOpened })}>
      <div className={styles.view}>
        <div className={styles.imageContainer}>
          <img
            style={{
              width: isOpened ? `${scale}%` : "100%",
              maxHeight: isOpened ? "auto" : "100%",
            }}
            src={image}
            alt={image}
          />
        </div>
        <div className={styles.control}>
          <Icon
            onClick={handleToggle}
            className={styles.controlButton}
            name={isOpened ? "minimize" : "maximize"}
          />
          {isOpened && (
            <>
              <Icon
                onClick={handleScaleUp}
                className={styles.controlButton}
                name="zoom-in"
              />
              <Icon
                onClick={handleScaleDown}
                className={styles.controlButton}
                name="zoom-out"
              />
            </>
          )}
        </div>
      </div>

      <div className={styles.sidebar}>
        <div className={styles.previewList}>
          {images.map((item) => (
            <ImagePreview
              src={item}
              key={item}
              checked={image === item}
              onClick={() => setImage(item)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AnswerOriginalView
