import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

import { AnswerItem } from "@framework/types/search"
import { AnswerResponseData } from "@services/search.service"

export class SearchAnswersDataStore {
  // injections

  // constructor

  constructor(config: { rawAnswer: AnswerResponseData }) {
    this.rawAnswer = config.rawAnswer

    makeAutoObservable(this)
  }

  // state

  rawAnswer: AnswerResponseData

  get allAnswers(): AnswerItem[] {
    if (!this.rawAnswer) return []
    return this.rawAnswer.answer.answers.map((item, index) => ({
      name: nanoid(),
      index,
      value: item,
    }))
  }

  get getAnswerById() {
    const { allAnswers } = this
    return (passageId: string) =>
      allAnswers.find((item) => item.name === passageId)
  }

  // actions
}

export default SearchAnswersDataStore
