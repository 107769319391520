import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { DataConnectorContentName } from "@framework/types/upload"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"

import { Column, Header } from "./Table"

import styles from "./FileRow.module.sass"

export const FilesTableHeader: React.FC<{
  sourceName?: DataConnectorContentName
}> = observer(({ sourceName = "file" }) => {
  const sourceTypeDescription = getDataSourceNodeContextProps(sourceName)

  return (
    <Header className={clsx(styles.row)}>
      {/* icon */}
      <Column />

      <Column>{sourceTypeDescription.label} name</Column>

      {/* status */}
      <Column />

      <Column>Data Type</Column>

      <Column>Avatars</Column>

      <Column>Created At</Column>

      <Column>Created By</Column>

      {/* menu  */}
      <Column />
    </Header>
  )
})

export default FilesTableHeader
