/* eslint-disable no-underscore-dangle */
import { makeAutoObservable, observable } from "mobx"

import ExternalCloudFoldersStore from "./external-cloud-folders.store"

export class ExternalFoldersStores {
  constructor() {
    makeAutoObservable(this)
  }

  errorMessage: string | null = null

  stores: Map<string, ExternalCloudFoldersStore> = new Map()

  getStore = (dataConnectorId: string) => {
    const key = dataConnectorId

    const instance = this.stores.get(key)

    if (instance != null) return instance

    this.resetCollection(key)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.stores.get(key)!
  }

  resetCollection = (dataConnectorId: string) => {
    this.stores.set(
      dataConnectorId,
      observable(new ExternalCloudFoldersStore({ dataConnectorId }))
    )
  }
}

export default ExternalFoldersStores
