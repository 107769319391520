import React, { ReactNode } from "react"
import clsx from "clsx"
import { AlertComponentPropsWithStyle } from "react-alert"

import Icon from "@components/ui/Icon/Icon"

import styles from "./AlertPopupTemplate.module.sass"

const messageConfig = {
  info: {
    iconSrc: "/images/success-icon.svg",
  },
  success: {
    iconSrc: "/images/success-icon.svg",
  },
  error: {
    iconSrc: "/images/error-icon.svg",
  },
}

export interface AlertPopupTemplateProps extends AlertComponentPropsWithStyle {
  message: any | ReactNode
  className?: string
}

const AlertPopupTemplate: React.FC<AlertPopupTemplateProps> = ({
  options,
  message,
  style,
  className,
  close,
}) => {
  const config = options.type ? messageConfig[options.type] : messageConfig.info

  return (
    <div style={style} className={clsx(styles.root, className)}>
      {message && (
        <div className={styles.alert}>
          <img
            className={styles.doneIcon}
            src={config.iconSrc}
            alt={config.iconSrc}
          />
          <p className={styles.message} title={message}>
            {message}
          </p>
          <Icon name="cross" onClick={close} className={styles.closeButton} />
        </div>
      )}
    </div>
  )
}

export default AlertPopupTemplate
