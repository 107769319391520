import React from "react"

import { AnswerItem } from "@framework/types/search"
import { ExtendedResultType } from "@framework/constants/search-results"
import { TextAnswerSection } from "@components/prototypes/ResultsCard/AnswerSection/AnswerSection"
import ResultsCard from "@components/prototypes/ResultsCard/ResultsCard"
import useAvatarScope from "@pages/search/AvatarScopeContext/useAvatarScope"
import ExpertBadge from "@components/prototypes/ResultsCard/ExpertBadge/ExpertBadge"

import SearchAnswerControls from "../AnswerControl/SearchAnswerControls"
import AnswerControl from "./AnswerControl"
import usePassageChips from "./usePassageChips"

export interface TextResultsCardProps {
  id: string
  answer: AnswerItem<"summaries">
  question: string
  color?: "primary" | "transparent"
  className?: string
  onTitleClick?: React.MouseEventHandler
  onReadMoreClick?: React.MouseEventHandler
  maxLines?: number
  onOpenExtendedView?: (tab?: ExtendedResultType) => void
}

export const TextResultsCard: React.FC<TextResultsCardProps> = ({
  id,
  answer: answerItem,
  question,
  color,
  className,
  onTitleClick,
  onReadMoreClick,
  maxLines,
  onOpenExtendedView,
}) => {
  const chips = usePassageChips(answerItem)

  const answer = answerItem.value
  const isExpertAnswer = answer.source_type === "expert_answer"

  return (
    <ResultsCard
      id={id}
      type="horizontal"
      chips={chips}
      expertSocket={
        answer.expert_name != null && (
          <ExpertBadge
            title={answer.expert_name}
            src={answer.expert_image_url}
          />
        )
      }
      color={color}
      className={className}
      answerControl={
        <AnswerControl
          answer={answer}
          viewType="Text"
          handleExtendResult={onOpenExtendedView}
        />
      }
      answerSocket={
        <TextAnswerSection
          title={answer.source_name}
          onTitleClick={onTitleClick}
          onReadMoreClick={onReadMoreClick}
          isExpertAnswer={isExpertAnswer}
          content={answer.value}
          maxLines={maxLines}
        />
      }
      controllers={
        <SearchAnswerControls
          question={question}
          answer={answerItem}
          type="Text"
        />
      }
      key={id}
    />
  )
}

export default TextResultsCard
