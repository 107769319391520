/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"

export interface LogoProps extends React.HTMLProps<HTMLElement> {
  name?: string // "NESH" | "ASKIEEE" | other
}

export const Logo: React.FC<LogoProps> = ({ name = "NESH" }) => {
  if (name === "NESH") return <b>NESH</b>
  if (name === "ASKIEEE")
    return (
      <>
        <i>Ask</i>
        <b>IEEE</b>
      </>
    )

  return <>{name}</>
}

export default Logo
