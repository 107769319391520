import React from "react"

import BaseModal, {
  BaseModalProps,
} from "@components/modals/components/BaseModal/BaseModal"

import styles from "./index.module.sass"

interface ModalContainerProps extends BaseModalProps {}

const ModalContainer: React.FC<ModalContainerProps> = ({
  children,
  ...rest
}) => {
  return (
    <BaseModal
      {...rest}
      className={styles.root}
      containerClassName={styles.container}
    >
      {children}
    </BaseModal>
  )
}

export default ModalContainer
