import React from "react"
import isEmpty from "lodash/isEmpty"

import DocumentDropZone from "@components/ui/DocumentDropZone/DocumentDropZone"
import Loader from "@components/ui/Loader/BarLoader"
import { pluralize } from "@utils/textUtils"

import BaseModal from "../components/BaseModal/BaseModal"
import { ModalsTypes } from "../constants"
import useModal from "../useModal"
import ModalTitle from "../components/ModalTitle/ModalTitle"

import styles from "./UploadFilesModal.module.sass"

const DEFAULT_SUCCESS_MESSAGE = "Files successfully added to upload"

export interface UploadFilesModalProps {
  title?: string
  // following placeholders are available for text:
  // - $1 - Comma seperated values for extensions (ex - pdf, xml, json)
  text?: string
  extensions?: string[]
  multipleFiles?: boolean
  onUpload: (files: File[]) => Promise<boolean>
  isLoading?: boolean
  showOnSuccessModal?: boolean
}

export const UploadFilesModal: React.FC<UploadFilesModalProps> = ({
  title = "Upload your files",
  text,
  extensions = [],
  multipleFiles,
  isLoading,
  onUpload,
  showOnSuccessModal = true,
}) => {
  const { hideModal } = useModal(ModalsTypes.UPLOAD_FILES_MODAL)
  const { showModal } = useModal(ModalsTypes.SUCCESS_MESSAGE_MODAL)

  const handleUpload = (files: File[]) => {
    onUpload(files).then((ret) => {
      if (!ret) return
      hideModal()
      if (showOnSuccessModal) showModal({ message: DEFAULT_SUCCESS_MESSAGE })
    })
  }

  const handleClose = () => {
    hideModal()
  }

  const modalExtensionsText = !isEmpty(extensions)
    ? extensions
        .map((ex) => {
          return ex.replace(".", "")
        })
        .join(", ")
    : "pdf, pptx, docx, xlsx"

  const modalText = text
    ? text.replace("$1", modalExtensionsText)
    : `${pluralize("File", multipleFiles)} should be ${modalExtensionsText}`

  return (
    <BaseModal
      className={styles.root}
      containerClassName={styles.container}
      onClose={handleClose}
    >
      <ModalTitle titleText={title} subtitleText={modalText} />

      {isLoading ? (
        <Loader size="large" primary />
      ) : (
        <DocumentDropZone
          multiple={multipleFiles}
          onDrop={handleUpload}
          extensions={extensions}
        />
      )}
    </BaseModal>
  )
}

export default UploadFilesModal
