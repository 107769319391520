import React from "react"
import { useNavigate } from "react-router-dom"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"

import styles from "./ProductCard.module.sass"

const ProductCard: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text variant="h4">Clearsol 400</Text>
        <Text variant="h5">Manufacturer name</Text>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.details}>
          <Text variant="h6" color="text50Color">
            Industry
          </Text>
          <Text variant="h6">Automobile</Text>
        </div>
        <div className={styles.details}>
          <Text variant="h6" color="text50Color">
            Properties
          </Text>
          <Text variant="h6">Boiling Point: 145deg</Text>
          <Text variant="h6">Flash Point: 45deg</Text>
        </div>
      </div>

      <div className={styles.ctaContainer}>
        <div className={styles.details}>
          <Text variant="h6" color="text50Color">
            Region
          </Text>
          <Text variant="h6">Mexico</Text>
        </div>
        <Button
          variant="text"
          size="tiny"
          after={<Icon name="arrow-down" rotateAngle={-90} />}
          onClick={() =>
            navigate(`/solutions/products/11111111-1111-1111-1111-111111111111`)
          }
        >
          View
        </Button>
      </div>
    </div>
  )
}

export default ProductCard
