import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import { useAlert } from "react-alert"

import Table from "@components/ui/BaseTable/Table"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store/index"

import RolesActionRow from "./RolesActionRow"
import RolesHeader from "./RolesHeader"

import styles from "./index.module.sass"

export interface SimpleTableProps {
  className?: string
}

export const RolesActionsTable: React.FC<SimpleTableProps> = observer(
  ({ className }) => {
    const { rolesController } = useController()
    const {
      actionsStore,
      rolesActionsStore,
      restrictionsStore: access,
    } = useStore()

    const alert = useAlert()

    const { isLoading } = actionsStore
    const { totalChanges } = rolesActionsStore

    const handleCancel = () => {
      rolesController.cancelChanges()
    }

    const handleApplyChanges = async () => {
      const error = await rolesController.applyChanges()
      if (error) alert.error(error)
      else alert.success("All changes was successfully applied")
    }

    const hasChanges = totalChanges > 0 && access.canUpdateRole

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.container}>
          <Table header={<RolesHeader />}>
            {actionsStore.actionIds.map((actionId) => (
              <RolesActionRow actionId={actionId} key={actionId} />
            ))}
          </Table>
        </div>

        <div className={styles.control}>
          {hasChanges && (
            <>
              <Button
                onClick={handleCancel}
                disabled={isLoading || !hasChanges}
                variant="outlined"
                color="default"
              >
                Discard changes
              </Button>
              <Button
                onClick={handleApplyChanges}
                disabled={isLoading || !hasChanges}
                color="primary"
              >
                {`Apply changes (${totalChanges})`}
              </Button>
            </>
          )}
        </div>
      </div>
    )
  }
)

export default RolesActionsTable
