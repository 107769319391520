import React from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react-lite"

import SelectionRange from "./SelectionRange"
import SelectionSquare from "./SelectionSquare"
import { useMatrixContext } from "./MatrixContext"
import EditorCell from "./EditorCell"

export const SelectionLayer: React.FC = observer(({ children }) => {
  const state = useMatrixContext()

  const isSelectedEditing = state.editor.isCellEditing(
    state.selectedRange.origin
  )

  if (state.grid.gridElement == null) return null
  return ReactDOM.createPortal(
    <>
      <SelectionSquare cell={state.selectedRange} />

      {state.selectedRange.active && state.selectedRange.isRange && (
        <SelectionRange range={state.selectedRange.range} solid />
      )}

      {state.editor.activeCellState?.refEntries.map(([name, range]) => (
        <SelectionRange
          range={range}
          solid={false}
          key={name}
          color="rgb(255 190 78)"
          bgColor="rgb(255 190 78 / 0.2)"
        />
      ))}

      {isSelectedEditing && <EditorCell key={state.editor.activeCellId} />}

      {children}
    </>,
    state.grid.gridElement
  )
})

export default SelectionLayer
