import React from "react"
import { observer } from "mobx-react-lite"

import NotFound from "@components/ui/NotFound/NotFound"
import CollapsibleContainer from "@pages/home/SearchResults/components/CollapsibleContainer/CollapsibleContainer"
import ResultContainer from "@pages/home/SearchResults/components/Result/ResultContainer"
import AnswerList from "@pages/home/SearchResults/components/Result/AnswerList"
import { AnswerSource } from "@store/search/dataTransformers"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"

import { useSearchBlock } from "../SearchContext/SearchResultContext"

export interface SourceResultItemProps {
  id: string
  data: AnswerSource
}

export const SourceResultItem: React.FC<SourceResultItemProps> = observer(
  ({ id, data }) => {
    const { searchEntityBlock, searchEntity } = useSearchBlock(
      SearchSummaryBlockStore
    )

    const { searchSources } = searchEntityBlock

    const items = searchSources?.sourceAnswers.get(id)

    return (
      <CollapsibleContainer data={data}>
        <ResultContainer spacing="small">
          <AnswerList
            items={items}
            query={searchEntity.filter.searchQuery}
            notFound={<NotFound>No answer was found in this source</NotFound>}
          />
        </ResultContainer>
      </CollapsibleContainer>
    )
  }
)

export default SourceResultItem
