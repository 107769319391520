import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import { useMatrixContext } from "./MatrixContext"
import { AxisName } from "./types"
import { indexToAlphabetCode } from "./utils"

import styles from "./AxisCell.module.sass"

type AxisCellProps = {
  axis?: AxisName
  index: number
  height?: React.CSSProperties["height"]
  width?: React.CSSProperties["width"]
  children?: null
  className?: string
}

const AxisCell: React.FC<AxisCellProps> = observer(
  ({ index, height, width, axis = "x" }) => {
    const state = useMatrixContext()

    const isColumn = axis === "x"

    const label = renderLabel(index, isColumn)

    const isSelected = (
      isColumn
        ? state.selectedRange.isColumnSelected
        : state.selectedRange.isRowSelected
    )(index)

    return (
      <div
        className={clsx(styles.root, styles[axis], {
          [styles.selected]: isSelected,
        })}
        style={{ height, width, overflow: "hidden" }}
        role="button"
      >
        {label}
      </div>
    )
  }
)

export default AxisCell

const renderLabel = (index: number, alphabetic: boolean) => {
  if (alphabetic) return indexToAlphabetCode(index)
  return (index + 1).toString()
}
