import clsx from "clsx"
import React from "react"

import styles from "./index.module.sass"

export enum TableDisplayModes {
  table = "tbody_table",
  tableRowGroup = "tbody_table-row-group",
}

export interface TableProps {
  header?: React.ReactNode
  hideBorder?: boolean
  disabled?: boolean
  className?: string
  displayMode?: TableDisplayModes
}

export const Table: React.FC<TableProps> = ({
  header,
  children,
  disabled = false,
  hideBorder,
  className,
  displayMode = TableDisplayModes.tableRowGroup,
}) => (
  <div
    className={clsx(
      styles.root,
      {
        [styles.withBorder]: !hideBorder,
        [styles.disabled]: disabled,
        [styles.tbody_table]: displayMode === TableDisplayModes.table,
        [styles["tbody_table-row-group"]]:
          displayMode === TableDisplayModes.tableRowGroup,
      },
      className
    )}
  >
    <table>
      <thead>{header}</thead>
      <tbody>{children}</tbody>
    </table>
  </div>
)

export default Table
