import React from "react"
import { observer } from "mobx-react-lite"
import { FormikContext, useFormik } from "formik"
import * as yup from "yup"

import TextInput from "@components/ui/TextInput/TextInput"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Label from "@components/ui/Label/Label"
import Templates from "@components/ui/Templates"

import styles from "./InviteUserForm.module.sass"

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Field is required")
    .email("Must be a valid email address")
    .default(""),
})

type FormData = yup.InferType<typeof validationSchema>

interface InviteUserEmailProps {
  initialValues?: any
  onSubmit: (data: FormData) => void
}

export const InviteUserEmail: React.FC<InviteUserEmailProps> = observer(
  ({ initialValues, children, onSubmit }) => {
    const formik = useFormik<FormData>({
      initialValues: validationSchema.cast(initialValues ?? {}, {
        stripUnknown: true,
      }),
      validationSchema,
      onSubmit,
    })

    return (
      <FormikContext.Provider value={formik}>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <Templates.RollScript footerSocket={children}>
            <Label id="Email" label="Email">
              <TextInput
                autoFocus
                name="email"
                placeholder="Enter email address"
                value={formik.values.email}
                withError={!!(formik.touched.email && formik.errors.email)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                after={
                  formik.touched.email &&
                  formik.errors.email && (
                    <div className={styles.after}>
                      <ErrorChip
                        message={formik.errors.email}
                        messagePlacement="left"
                      />
                    </div>
                  )
                }
              />
            </Label>
          </Templates.RollScript>
        </form>
      </FormikContext.Provider>
    )
  }
)

export default InviteUserEmail
