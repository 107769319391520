/* eslint-disable camelcase */

import config from "@root/config"

export const sharepointAccessScope = [
  "Directory.AccessAsUser.All",
  "Files.Read",
  "Files.Read.All",
  "Group.Read.All",
  "offline_access",
  "Sites.Read.All",
  "User.Read",
  "User.ReadBasic.All",
].join(" ")

export const readonlyAccessScope = "User.Read"

export type GetAccessRequest = {
  client_id: string
  redirect_uri: string
  state?: string
  scope?: string
}

// more info here https://learn.microsoft.com/en-us/graph/auth-v2-user
interface AuthorizeRequestParams extends GetAccessRequest {
  scope: string
  response_type: "code" | "token"
  response_mode: "query"
}

type State = {
  apiURL: string
}

class MicrosoftAPI {
  state: State

  constructor(options?: { apiURL?: string }) {
    this.state = {
      apiURL: options?.apiURL || config.MICROSOFT_OAUTH_API_URL,
    }
  }

  getURL = (url: string) => `${this.state.apiURL}/${url}`

  getAuthCodeLink = (params: GetAccessRequest) => {
    const requestParams: Record<keyof AuthorizeRequestParams, string> = {
      response_type: "code",
      response_mode: "query",
      scope: readonlyAccessScope,
      state: "",
      ...params,
    }
    const searchParams = new URLSearchParams(requestParams).toString()
    return `${this.getURL("authorize")}?${searchParams}`
  }
}

export default MicrosoftAPI
