export const randomNumber = (min: number, max: number) => {
  const range = max - min
  return Math.floor(Math.random() * range) + min
}

export const randomBool = () => Math.random() > 0.5

export const initArrayByLength = (length: number) =>
  Array.from(Array(length).keys())

export const initArray = <T>(length: number, mapper: (idx: number) => T): T[] =>
  initArrayByLength(length).map(mapper)

export const randomNumbersArray = (
  length: number,
  max: number,
  min: number = 0
) => initArrayByLength(length).map(() => randomNumber(min, max))

export const countSuffix = (count: number, suffix: string = "s") => {
  if (count === 1) return ""
  return suffix
}

export const normalizeNumber = (hash: number, min: number, max: number) =>
  Math.floor((hash % (max - min)) + min)

const formatter = Intl.NumberFormat("en", { notation: "compact" })

export const renderShortNumber = (value: number) => {
  if (value == null || Number.isNaN(value)) return "-"
  return formatter.format(value)
}

export const renderLargeNumber = (x: string | number = 0, fixed: number = 0) =>
  Number(x)
    .toFixed(fixed)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
