import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import styles from "./EntityPageHeaderLayout.module.sass"

export interface EntityPageHeaderLayoutProps {
  left?: React.ReactNode
  right?: React.ReactNode
  className?: string
}

export const EntityPageHeaderLayout: React.FC<EntityPageHeaderLayoutProps> =
  observer(({ left, right, children, className }) => {
    return (
      <div className={clsx(styles.root, className)}>
        <section className={styles.left}>{left}</section>
        <section className={styles.center}>{children}</section>
        <section className={styles.right}>{right}</section>
      </div>
    )
  })

export default EntityPageHeaderLayout
