import React from "react"
import { NavLink, useLocation } from "react-router-dom"

const MarkdownNavLink: React.FC<React.AllHTMLAttributes<HTMLAnchorElement>> = ({
  href = "/",
  ...restProps
}) => {
  const location = useLocation()
  return (
    <NavLink to={href} {...restProps} state={{ backTo: location.pathname }} />
  )
}

export default MarkdownNavLink
