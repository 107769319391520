import { makeAutoObservable } from "mobx"

import createMatrix from "@components/ui/Spreadsheet/createMatrix"
import MatrixStore from "@components/ui/Spreadsheet/state/MatrixStore"

import CompaniesStore from "./companies.store"

export default class UnifiedMatrixStore {
  private instance: MatrixStore | null

  companiesStore: CompaniesStore

  constructor() {
    this.instance = null
    this.companiesStore = new CompaniesStore()

    makeAutoObservable(this)
  }

  get matrixStore(): MatrixStore {
    if (this.instance == null)
      this.instance = createMatrix({
        initialValue: [["Company", "Category", "Business Unit", "Pain points"]],
      })
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.instance!
  }
}
