import React, { memo } from "react"

import Table from "@components/ui/BaseTable/Table"
import { AvatarDetailsData } from "@framework/types/avatar"

import AvatarsTableHeader from "./AvatarsTableHeader"
import AvatarsTableRow from "./AvatarsTableRow"

import styles from "./index.module.sass"

interface AvatarsTableProps {
  items?: AvatarDetailsData[]
}

const AvatarsTable: React.FC<AvatarsTableProps> = memo(({ items = [] }) => {
  return (
    <div className={styles.root}>
      <Table header={<AvatarsTableHeader />}>
        {items.map((item) => (
          <AvatarsTableRow key={item.id} avatar={item} />
        ))}
      </Table>
    </div>
  )
})

export default AvatarsTable
