import React from "react"
import { observer } from "mobx-react-lite"

import Collapsable from "@components/ui/Collapsable/Collapsable"
import useCollapsible from "@components/ui/Collapsable/useCollapsible"
import { AnswerSource } from "@store/search/dataTransformers"

import CollapsibleAnswerSource from "./CollapsibleAnswerSource"

import styles from "./CollapsibleContainer.module.sass"

export interface CollapsibleContainerProps {
  data: AnswerSource
}

export const CollapsibleContainer: React.FC<CollapsibleContainerProps> =
  observer(({ data, children }) => {
    const collapsible = useCollapsible()
    const isOpened = collapsible.isActive(data.id)
    const handleToggle = () => collapsible.toggle(data.id)

    return (
      <div className={styles.root}>
        <CollapsibleAnswerSource
          data={data}
          open={isOpened}
          onToggle={handleToggle}
        />
        <Collapsable open={isOpened}>
          <div className={styles.body}>{children}</div>
        </Collapsable>
      </div>
    )
  })

export default CollapsibleContainer
