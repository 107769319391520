import { makeAutoObservable } from "mobx"

import TargetCompaniesStore from "@store/target-companies/target-companies.store"
import RequirementsStore from "@store/company-themes/requirements.store"
import CompanyThemesStore from "@store/company-themes/company-themes.store"
import ProductFilterStore from "@store/product-filter/product-filter.store"
import SelectedProductsStore from "@store/product/selected-products.store"
import ProductListStore from "@store/product/product-list.store"
import ProductStore from "@store/product/product.store"
import RecommendedProductListStore from "@store/product/recommended-product-list.store"
import ProductsViewStore from "@store/product/products.store"

import MultiStepStore from "./multiform.store"
import ProductComparisonSummaryStore from "./product-comparison-summary.store"

export default class ProductComparisonStore {
  multiformStore: MultiStepStore

  targetCompaniesStore: TargetCompaniesStore

  companyThemesStore: CompanyThemesStore

  requirementsStore: RequirementsStore

  productStore: ProductStore

  productFilterStore: ProductFilterStore

  baseRecommendedProductsStore: RecommendedProductListStore

  baseProductsStore: ProductListStore

  baseProductsViewStore: ProductsViewStore

  targetRecommendedProductsStore: RecommendedProductListStore

  targetProductsStore: ProductListStore

  targetProductsViewStore: ProductsViewStore

  selectedProductsStore: SelectedProductsStore

  summaryStore: ProductComparisonSummaryStore

  constructor() {
    this.multiformStore = new MultiStepStore({ steps: tabs })
    this.targetCompaniesStore = new TargetCompaniesStore()
    this.companyThemesStore = new CompanyThemesStore()
    this.requirementsStore = new RequirementsStore()
    this.productFilterStore = new ProductFilterStore()
    this.productStore = new ProductStore()

    this.baseRecommendedProductsStore = new RecommendedProductListStore()
    this.baseProductsStore = new ProductListStore()
    this.baseProductsViewStore = new ProductsViewStore({
      productsStore: this.baseProductsStore,
      recommendedProductsStore: this.baseRecommendedProductsStore,
    })

    this.targetProductsStore = new ProductListStore()
    this.targetRecommendedProductsStore = new RecommendedProductListStore()
    this.targetProductsViewStore = new ProductsViewStore({
      productsStore: this.targetProductsStore,
      recommendedProductsStore: this.targetRecommendedProductsStore,
    })

    this.selectedProductsStore = new SelectedProductsStore()
    this.summaryStore = new ProductComparisonSummaryStore()

    makeAutoObservable(this)
  }

  get isBaseCompanyValid() {
    return !!this.targetCompaniesStore.baseCompany.id
  }
}

export enum Steps {
  TargetCompany = "target-company",
  Themes = "themes",
  Requirement = "requirement",
  MyProducts = "my-products",
  CompetitorProducts = "competitor-products",
  Summary = "summary",
}

const tabs = [
  { name: Steps.TargetCompany, value: "Target Company" },
  { name: Steps.Themes, value: "Themes" },
  { name: Steps.Requirement, value: "Product Requirement" },
  { name: Steps.MyProducts, value: "My Products" },
  { name: Steps.CompetitorProducts, value: "Competitor Products" },
  { name: Steps.Summary, value: "Summary" },
]
