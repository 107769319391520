import React from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

import { countSuffix } from "@utils/numberUtils"
import NotFound from "@components/ui/NotFound/NotFound"
import ResultContainer from "@pages/home/SearchResults/components/Result/ResultContainer"
import CollapseAllButton from "@pages/home/SearchResults/components/CollapsibleContainer/CollapseAllButton"
import Loader from "@components/ui/Loader/BarLoader"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"

import SourceResultItem from "./SourceResultItem"
import { useSearchBlock } from "../SearchContext/SearchResultContext"

import styles from "./SourceOrientedResults.module.sass"

export const SourceOrientedResults: React.FC = observer(() => {
  const { searchEntityBlock } = useSearchBlock(SearchSummaryBlockStore)

  const { searchSources } = searchEntityBlock

  const totalSources = searchSources?.sortedSources.length ?? 0
  const title = `Total ${totalSources} source${countSuffix(totalSources)}`

  return (
    <div className={styles.root}>
      {searchSources == null ? (
        <NotFound>No answer was found</NotFound>
      ) : searchSources.sortedSources.length ? (
        <ResultContainer
          title={title}
          titleSticky
          control={<CollapseAllButton />}
        >
          {searchSources.sortedSources.map((item) => (
            <SourceResultItem id={item.id} data={item} key={item.id} />
          ))}
        </ResultContainer>
      ) : (
        <NotFound>
          No answer was found. Please try another question or discover&nbsp;
          <Link to="/home">suggested questions</Link>
        </NotFound>
      )}
    </div>
  )
})

export default SourceOrientedResults
