import { makeAutoObservable } from "mobx"
import sortBy from "lodash/sortBy"

import { PaginationParams } from "@framework/types/utils"
import AvatarsStore from "@store/avatars/avatars.store"
import {
  DataConnectorSourceName,
  DataSourceStatus,
  DataSourceStatusEntity,
  FileDataSourceName,
} from "@framework/types/upload"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import uploadService from "@services/upload.service"
import knowledgeService from "@services/knowledge.service"

import AvatarExpertKnowledgeStore from "./avatar-expert-knowledge.store"
import AvatarWebsitesKnowledgeStore from "./avatar-websites-knowledge.store"
import AvatarUploadedFilesKnowledgeStore from "./avatar-uploaded-files-knowledge.store"
import AvatarDataConnectorsGroupsStore from "./avatar-data-connectors-groups.store"
import AvatarDataConnectorsStore from "./avatar-data-connectors.store"
import AvatarDataConnectorFoldersStore from "./avatar-data-connector-folders.store"
import AvatarDataConnectorFilesStore from "./avatar-data-connector-files.store"
import AvatarUploadedFilesGroupsStore from "./avatar-uploaded-files-groups.store"

export default class AvatarKnowledgeController {
  expertAnswersStore: AvatarExpertKnowledgeStore

  uploadedFilesGroupsStore: AvatarUploadedFilesGroupsStore

  uploadedFilesStore: AvatarUploadedFilesKnowledgeStore

  websitesStore: AvatarWebsitesKnowledgeStore

  dataConnectorsGroupsStore: AvatarDataConnectorsGroupsStore

  dataConnectorsStore: AvatarDataConnectorsStore

  dataConnectorFoldersStore: AvatarDataConnectorFoldersStore

  dataConnectorFilesStore: AvatarDataConnectorFilesStore

  avatarsStore: AvatarsStore

  constructor(injections: {
    knowledgeStore: AvatarExpertKnowledgeStore
    avatarsStore: AvatarsStore
    websitesKnowledgeStore: AvatarWebsitesKnowledgeStore
    uploadedFilesGroupsStore: AvatarUploadedFilesGroupsStore
    uploadedFilesKnowledgeStore: AvatarUploadedFilesKnowledgeStore
    dataConnectorsGroupsStore: AvatarDataConnectorsGroupsStore
    dataConnectorsStore: AvatarDataConnectorsStore
    dataConnectorFoldersStore: AvatarDataConnectorFoldersStore
    dataConnectorFilesStore: AvatarDataConnectorFilesStore
  }) {
    this.expertAnswersStore = injections.knowledgeStore
    this.avatarsStore = injections.avatarsStore
    this.websitesStore = injections.websitesKnowledgeStore
    this.uploadedFilesGroupsStore = injections.uploadedFilesGroupsStore
    this.uploadedFilesStore = injections.uploadedFilesKnowledgeStore
    this.dataConnectorsGroupsStore = injections.dataConnectorsGroupsStore
    this.dataConnectorsStore = injections.dataConnectorsStore
    this.dataConnectorFoldersStore = injections.dataConnectorFoldersStore
    this.dataConnectorFilesStore = injections.dataConnectorFilesStore

    makeAutoObservable(this)
  }

  loadExpertAnswersList = async (
    avatarId: string,
    params?: PaginationParams
  ) => {
    const answersList = this.expertAnswersStore.getAnswersCollection(avatarId)
    try {
      answersList.isLoading = true
      answersList.loadingError = null

      const avatar = this.avatarsStore.getAvatarById(avatarId)
      if (avatar == null) throw new Error("AVATAR_NOT_FOUND")

      const response = await knowledgeService.getExpertAnswersByAvatarId(
        avatar.id,
        params
      )

      answersList.appendFromResponse(response.data.data, response.data.meta)
    } catch (e) {
      answersList.loadingError = "Unexpected error"
    } finally {
      answersList.isLoading = false
    }
  }

  loadUploadedFilesGroups = async (dataCategoryId: string) => {
    const collection =
      this.uploadedFilesGroupsStore.getCollection(dataCategoryId)
    try {
      collection.isLoading = true
      collection.error = null

      const response = await uploadService.getUploadedFilesGroups(
        dataCategoryId
      )

      collection.setData(
        response.data.data?.map(normalizeDataConnectorStatuses)
      )
    } catch (e) {
      collection.error = "Unexpected error"
    } finally {
      collection.isLoading = false
    }
  }

  loadUploadedFilesList = async (
    dataCategoryId: string,
    sourceGroup: FileDataSourceName | string,
    params?: PaginationParams
  ) => {
    const collection = this.uploadedFilesStore.getCollection(
      dataCategoryId,
      sourceGroup
    )
    try {
      collection.isLoading = true
      collection.error = null

      const response = await uploadService.getUploadedFilesByCategoryId(
        dataCategoryId,
        sourceGroup,
        params
      )

      collection.setData(response.data.data, response.data.meta)
    } catch (e) {
      collection.error = "Unexpected error"
    } finally {
      collection.isLoading = false
    }
  }

  loadWebsiteList = async (
    dataCategoryId: string,
    params?: PaginationParams
  ) => {
    const collection = this.websitesStore.getCollection(dataCategoryId)
    try {
      collection.isLoading = true
      collection.error = null

      const response = await uploadService.getWebsitesByCategoryId(
        dataCategoryId,
        params
      )
      collection.setData(response.data.data, response.data.meta)
    } catch (e) {
      collection.error = "Unexpected error"
    } finally {
      collection.isLoading = false
    }
  }

  loadDataConnectorsGroupsList = async (dataCategoryId: string) => {
    const collection =
      this.dataConnectorsGroupsStore.getCollection(dataCategoryId)

    try {
      collection.isLoading = true
      collection.error = null

      const response = await uploadService.getDataConnectorGroupsByCategoryId(
        dataCategoryId
      )

      collection.setData(
        sortBy(
          response.data.data?.map(normalizeDataConnectorStatuses),
          ({ name }) => getDataSourceNodeContextProps(name).label
        )
      )
    } catch (e) {
      collection.error = "Unexpected error"
    } finally {
      collection.isLoading = false
    }
  }

  loadDataConnectorsList = async (
    dataCategoryId: string,
    dataConnectorType: DataConnectorSourceName
  ) => {
    const collection = this.dataConnectorsStore.getCollection(
      dataCategoryId,
      dataConnectorType
    )

    try {
      collection.isLoading = true
      collection.error = null

      const response = await uploadService.getDataConnectorsByCategoryId(
        dataCategoryId,
        dataConnectorType
      )

      collection.setData(response.data.data)
    } catch (e) {
      collection.error = "Unexpected error"
    } finally {
      collection.isLoading = false
    }
  }

  loadDataConnectorFoldersList = async (
    dataCategoryId: string,
    dataConnectorId: string,
    params?: PaginationParams
  ) => {
    const collection = this.dataConnectorFoldersStore.getCollection(
      dataCategoryId,
      dataConnectorId
    )

    try {
      collection.isLoading = true
      collection.error = null

      const response = await uploadService.getDataConnectorFoldersByCategoryId(
        dataCategoryId,
        dataConnectorId,
        params
      )

      collection.setData(response.data.data)
    } catch (e) {
      collection.error = "Unexpected error"
    } finally {
      collection.isLoading = false
    }
  }

  loadDataConnectorFilesList = async (
    folderId: string,
    params?: PaginationParams
  ) => {
    const collection = this.dataConnectorFilesStore.getCollection(folderId)

    try {
      collection.isLoading = true
      collection.error = null

      const response = await uploadService.getDataConnectorFileList(
        folderId,
        params
      )

      collection.setData(response.data.data.items, response.data.meta)
    } catch (e) {
      collection.error = "Unexpected error"
    } finally {
      collection.isLoading = false
    }
  }
}

export const normalizeDataConnectorStatuses = (
  data: DataSourceStatus
): DataSourceStatusEntity => {
  return { id: data.name, ...data }
}
