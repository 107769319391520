import { makeAutoObservable } from "mobx"

import { BaseUserRole } from "@framework/types/security"

export class UserRolesStore {
  constructor() {
    makeAutoObservable(this)
  }

  roles: Record<string, BaseUserRole> = {}

  isLoading = false

  error: string | null = null

  setRoles = (roles: Record<string, BaseUserRole> = {}) => {
    this.roles = roles
  }

  setLoading = (value = true) => {
    this.isLoading = value
  }

  setError = (error: string | null = null) => {
    this.error = error
  }

  get roleIds() {
    return Object.keys(this.roles)
  }
}

export default UserRolesStore
