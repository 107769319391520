import React from "react"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"

import styles from "./AdminLayout.module.sass"

const AdminLayout: React.FC = ({ children }) => {
  return (
    <MainLayout>
      <Container>
        <div className={styles.root}>{children}</div>
      </Container>
    </MainLayout>
  )
}

export default AdminLayout
