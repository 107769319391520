import React from "react"
import { observer } from "mobx-react-lite"

import Table from "@components/ui/BaseTable/Table"
import { ApiKey } from "@framework/types/api-keys"

import ApiKeysTableHeader from "./ApiKeysTableHeader"
import ApiKeysTableRow from "./ApiKeysTableRow"

import styles from "./index.module.sass"

interface ApiKeysTableProps {
  items?: ApiKey[]
}

const ApiKeysTable: React.FC<ApiKeysTableProps> = observer(({ items = [] }) => {
  return (
    <div className={styles.root}>
      <Table header={<ApiKeysTableHeader />}>
        {items.map((item) => (
          <ApiKeysTableRow key={item.id} apiKeyId={item.id} />
        ))}
      </Table>
    </div>
  )
})

export default ApiKeysTable
