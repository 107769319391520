import React from "react"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { UserActionEntity } from "@framework/types/security"

interface ActionContextMenuProps {
  action: UserActionEntity
}

const ActionContextMenu = React.memo<ActionContextMenuProps>(({ action }) => {
  const { isOpened, handleToggle, setOpened } = useToggle()

  const modal = useModal(ModalsTypes.EDIT_USER_ACTION_MODAL)
  const deleteActionModal = useModal(ModalsTypes.DELETE_USER_ACTION_MODAL)
  const handleEdit = () => {
    modal.showModal({ action })
  }

  const handleDelete = () => {
    deleteActionModal.showModal({ action })
  }

  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      content={
        <TooltipContainer color="primary" placement="bottom">
          <MenuItem icon="edit" onClick={handleEdit} key="edit">
            Edit action
          </MenuItem>

          <MenuItem
            color="red"
            icon="trash-can"
            onClick={handleDelete}
            key="logout"
          >
            Delete action
          </MenuItem>
        </TooltipContainer>
      }
    >
      <IconButton size="medium" active={isOpened} onClick={handleToggle}>
        <Icon name="dots" />
      </IconButton>
    </Tooltip>
  )
})

export default ActionContextMenu
