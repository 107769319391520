import { makeAutoObservable } from "mobx"

import SelectableGrid from "@store/utils/SelectableGrid"

export class RolesActionsRelationsStore {
  constructor() {
    this.active = new SelectableGrid()
    this.changes = new Map()
    makeAutoObservable(this)
  }

  active: SelectableGrid

  changes: Map<string, Map<string, boolean>>

  get wasChanged() {
    const { changes } = this
    return (roleId: string, actionId: string) =>
      !!changes.get(roleId)?.has?.(actionId)
  }

  get isSelected() {
    const { isSelected } = this.active
    return (roleId: string, actionId: string) => isSelected(roleId, actionId)
  }

  get totalChanges() {
    return this.changes.size === 0
      ? 0
      : Array.from(this.changes.values()).reduce((acc, it) => it.size + acc, 0)
  }

  /**
   * [roleId, actionId[]][]
   */
  setRelations = (roles: [string, string[]][]) => {
    this.active = new SelectableGrid(roles)
    this.changes = new Map()
  }

  toggle = (roleId: string, actionId: string) => {
    const roles = this.changes.get(roleId) ?? new Map()
    if (roles.has(actionId)) roles.delete(actionId)
    else roles.set(actionId, !this.isSelected(roleId, actionId))
    this.changes.set(roleId, roles)
  }

  setTemp = (newTemp: Map<string, Map<string, boolean>> = new Map()) => {
    this.changes = newTemp
  }
}

export default RolesActionsRelationsStore
