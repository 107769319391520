import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import { useController, useStore } from "@store/index"

import styles from "./DeleteFlagModal.module.sass"

export interface DeleteFlagModalProps {
  flagId: string
}

export const DeleteFlagModal: React.FC<DeleteFlagModalProps> = observer(
  ({ flagId }) => {
    const alert = useAlert()
    const modal = useModal(ModalsTypes.DELETE_FEATURE_FLAG_MODAL)

    const { appConfigController } = useController()
    const { featuresStore } = useStore()

    const flag = featuresStore.getFlagById(flagId)

    const handleSubmit = async () => {
      modal.hideModal()
      const error = await appConfigController.deleteFeatureFlag(flagId)

      appConfigController.loadFeatureFlags()

      if (error) {
        alert.error(error)
      } else {
        alert.success(
          <>
            Feature flag &quot;<b>{flag?.key}</b>
            &quot; was successfully deleted
          </>
        )
      }
    }

    React.useEffect(() => {
      if (flag == null) modal.hideModal()
    }, [flag])

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={
              <>
                Are you sure you want to delete flag &quot;
                <b>{flag?.key}</b>
                &quot;?
              </>
            }
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <ModalFooterContainer>
          <Button variant="outlined" onClick={modal.hideModal}>
            Cancel
          </Button>
          <Button color="red" onClick={handleSubmit}>
            Delete
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  }
)

export default DeleteFlagModal
