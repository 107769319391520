import React from "react"
import clsx from "clsx"

import { CheckboxSize } from "./types"

import styles from "./Radio.module.sass"

export interface RadioProps extends React.ComponentProps<"input"> {
  variant?: CheckboxSize
}

export const Radio: React.FC<RadioProps> = ({
  id,
  className,
  variant = CheckboxSize.medium,
  value,
  checked,
  onClick,
  ...restProps
}) => {
  const classes = clsx(styles.root, styles[`size-${variant}`], className)

  return (
    <label className={classes} htmlFor={id}>
      <input
        id={id}
        type="radio"
        checked={checked != null ? checked : value === id}
        onClick={onClick}
        readOnly={!!onClick}
        {...restProps}
      />
      <span className={styles.checkmark} />
    </label>
  )
}

export default Radio
