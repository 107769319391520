import React, { forwardRef } from "react"
import clsx from "clsx"

import styles from "./Collapsable.module.sass"

export interface CollapsableProps {
  open?: boolean
  className?: string
  rootClassName?: string
}

export const Collapsable = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CollapsableProps>
>(({ open = true, className, rootClassName, children }, ref) => (
  <div
    ref={ref}
    className={clsx(styles.root, { [styles.opened]: open }, rootClassName)}
  >
    <div className={clsx(styles.container, className)}>{children}</div>
  </div>
))

export default Collapsable
