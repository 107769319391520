import { useEffect } from "react"
import { useAlert } from "react-alert"
import { useNavigate } from "react-router-dom"

import { useAuthCodeParams } from "@pages/upload/AddDataConnector/useAuthCodeParams"
import { useStore } from "@store/index"

export const useLoginSSO = () => {
  const {
    authStore: { loginWithSSOCode },
  } = useStore()
  const navigate = useNavigate()
  const alert = useAlert()
  const redirectParams = useAuthCodeParams()

  const handleCompleteLogin = async (code: string) => {
    const error = await loginWithSSOCode(code)
    if (!error) return
    alert.error(error)
    navigate("/login", { replace: true })
  }

  useEffect(() => {
    if (redirectParams?.code) {
      handleCompleteLogin(redirectParams?.code)
    }
  }, [redirectParams])

  return null
}

export default useLoginSSO
