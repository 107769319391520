import { makeAutoObservable } from "mobx"

import { DataCategoryEntity } from "@framework/types/knowledge"

export class DataTypesStore {
  constructor() {
    makeAutoObservable(this)
  }

  data: DataCategoryEntity[] | null = null

  error: string | null = null

  isLoading: boolean = false

  getAvatarById = (id: string) => {
    if (this.data == null) return null
    return this.data.find((it) => it.id === id) ?? null
  }

  storeData = (data: DataCategoryEntity[]) => {
    this.data = data
  }
}

export default DataTypesStore
