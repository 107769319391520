import { Observable } from "rxjs"
import { AxiosResponse } from "axios"

import {
  QueryHistoryData,
  QueryHistoryFilters,
  SearchingTrendPointData,
  AppUsageSummaryData,
  APIUsageSummaryData,
  APICallsAmountPointData,
  APICallsHistoryData,
  APICallStatus,
  UserTrendPointData,
} from "@framework/types/analytics"
import {
  PaginationListMeta,
  PaginationParams,
  VirtualListChunk,
  VirtualListChunkMeta,
} from "@framework/types/utils"
import { DateRange } from "@framework/types/common"
import { ActiveUsers, SimpleBaseUserData } from "@framework/types/user"

import HttpService from "./http.service"

export interface GetQuestionsStatisticResponse extends AppUsageSummaryData {}

export interface GetAPIUsageSummaryResponse extends APIUsageSummaryData {}

export interface GetAPIUsageTrendsResponse {
  data: APICallsAmountPointData[]
}

export type APICallsLogsFilter = {
  url?: string
  apiKeyName?: string
  startDate?: string
  endDate?: string
  status?: APICallStatus
}

export interface GetAPIUsageHistoryResponse {
  data: APICallsHistoryData[]
  meta: {
    total: number
    pageNum: string
    pageSize: string
  }
}

export interface GetActiveUserVirtualListResponse {
  data: ActiveUsers[]
  meta: VirtualListChunkMeta
}

export type GetLoadSearchingTrendReportResponse = SearchingTrendPointData[]

export type GetLoadUserTrendReportResponse = UserTrendPointData[]

export interface GetLoadQueryHistoryReportResponse {
  data?: QueryHistoryData[]
  meta: PaginationListMeta
}

class AnalyticsAPI extends HttpService {
  loadQuestionsStatistic = (
    params: {
      avatarIds?: string[]
      startDate: string // DD-MM-YYYY
      endDate: string // DD-MM-YYYY
    },
    signal?: AbortSignal
  ): Promise<AxiosResponse<GetQuestionsStatisticResponse>> =>
    this.get("ts/analytics/overview", true, params, null, signal)

  loadSearchingTrendReport = (
    params: {
      avatarIds?: string[]
      startDate?: string // DD-MM-YYYY
      endDate?: string // DD-MM-YYYY
    },
    signal?: AbortSignal
  ): Promise<AxiosResponse<GetLoadSearchingTrendReportResponse>> =>
    this.get("ts/analytics/query-trend", true, params, null, signal)

  loadUserTrendReport = (
    startDate?: string, // DD-MM-YYYY
    endDate?: string, // DD-MM-YYYY
    signal?: AbortSignal
  ): Promise<AxiosResponse<GetLoadUserTrendReportResponse>> =>
    this.get(
      "ts/analytics/users",
      true,
      {
        startDate,
        endDate,
      },
      null,
      signal
    )

  loadQueryHistoryReport = (
    meta?: PaginationParams,
    filters?: QueryHistoryFilters,
    signal?: AbortSignal
  ): Promise<AxiosResponse<GetLoadQueryHistoryReportResponse>> =>
    this.get(
      "ts/v2/admin/questions/data",
      true,
      {
        ...meta,
        startDate: filters?.date?.start,
        endDate: filters?.date?.end,
        userIds: filters?.userIds,
        assignStatus: filters?.assignStatus,
        productSolutionIds: filters?.solutions,
        ratings: filters?.ratings,
      },
      null,
      signal
    )

  loadAPIUsageSummary$ = (): Observable<
    AxiosResponse<GetAPIUsageSummaryResponse>
  > => this.getStream$("ts/api-call-logs/overview")

  loadAPIUsageTrends$ = (
    period: DateRange
  ): Observable<AxiosResponse<GetAPIUsageTrendsResponse>> =>
    this.getStream$("ts/api-call-logs/usage-trends", {
      params: {
        startDate: period.start,
        endDate: period.end,
      },
    })

  downloadAPIUsageTrendReport = (
    startDate?: string, // ISO
    endDate?: string // ISO
  ): Promise<AxiosResponse<Blob>> =>
    this.download("ts/api-call-logs/usage-trends/download", {
      startDate,
      endDate,
    })

  loadAPIUsageHistory$ = (
    query: string,
    pagination: PaginationParams,
    filter: APICallsLogsFilter
  ): Observable<AxiosResponse<GetAPIUsageHistoryResponse>> =>
    this.getStream$("ts/api-call-logs/api-logs", {
      params: { ...filter, ...pagination, url: query },
    })

  downloadAPIUsageHistory = (
    query: string,
    filter: APICallsLogsFilter
  ): Promise<AxiosResponse<Blob>> =>
    this.download("ts/api-call-logs/api-logs/download", {
      ...filter,
      url: query,
    })

  downloadUsageAnalyticsReport = (
    period: DateRange
  ): Promise<AxiosResponse<Blob>> =>
    this.download("ts/analytics/download", {
      startDate: period.start,
      endDate: period.end,
    })

  loadActiveUsers$ = (
    filter: {
      period: DateRange
    },
    meta: VirtualListChunk
  ): Observable<AxiosResponse<GetActiveUserVirtualListResponse>> =>
    this.getStream$(`ts/analytics/top-active-users`, {
      params: {
        startDate: filter.period.start,
        endDate: filter.period.end,
        ...meta,
      },
    })
}

export default new AnalyticsAPI()
