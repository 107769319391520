import { observer } from "mobx-react-lite"
import React from "react"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import InviteUserForm from "@pages/auth/components/InviteUserForm/InviteUserForm"
import { useController, useStore } from "@store/index"
import { SimpleInviteUserFormType } from "@framework/types/auth"

import styles from "./InviteUserModal.module.sass"

export interface InviteUserModalProps {}

export const InviteUserModal: React.FC<InviteUserModalProps> = observer(() => {
  const alert = useAlert()

  const inviteModal = useModal(ModalsTypes.INVITE_USER_MODAL)
  const { adminUsersController } = useController()
  const { adminUsersStore } = useStore()

  const handleSubmit = async (form: SimpleInviteUserFormType) => {
    const error = await adminUsersController.inviteUser(form)

    if (error) {
      alert.error(error)
    } else {
      alert.success(
        <>
          Invitation was sent to email: <b>&quot;{form.email}&quot;</b>
        </>
      )
    }

    inviteModal.hideModal()
  }

  return (
    <BaseModal
      title={
        <ModalTitle
          titleText="Invite new User"
          subtitleText="Enter an email of user you want to invite and they will receive a message with next instructions"
        />
      }
      className={styles.root}
      containerClassName={styles.container}
      onClose={inviteModal.hideModal}
    >
      <InviteUserForm
        onSubmit={handleSubmit}
        onCancel={inviteModal.hideModal}
        isLoading={adminUsersStore.isLoading}
      />
    </BaseModal>
  )
})

export default InviteUserModal
