import { makeAutoObservable } from "mobx"

import RecommendedProductListStore from "./recommended-product-list.store"
import ProductListStore from "./product-list.store"

export default class ProductsViewStore {
  protected recommendedProductsStore: RecommendedProductListStore

  protected productsStore: ProductListStore

  constructor(injections: {
    recommendedProductsStore: RecommendedProductListStore
    productsStore: ProductListStore
  }) {
    this.productsStore = injections.productsStore

    this.recommendedProductsStore = injections.recommendedProductsStore

    makeAutoObservable(this)
  }

  isOtherVisible = false

  showOther = (show: boolean) => {
    this.isOtherVisible = show
  }

  toggleOthersVisibility = () => {
    this.isOtherVisible = !this.isOtherVisible
  }

  get withRecommended() {
    return this.productsStore.state.excludeProductIds.length > 0
  }
}
