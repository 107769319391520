import { autorun, makeAutoObservable, reaction } from "mobx"

import RootStore from "@store/RootStore"
import { Option } from "@framework/types/utils"
import { USER_EXPLORE_AVATAR_KEY } from "@framework/constants/search-results"

import { avatarsToOptions } from "./dataTransformers"

export class UserSetupStore {
  // injections

  rootStore: RootStore

  get avatarsStore() {
    return this.rootStore.avatarsStore
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore

    this.avatarId = localStorage.getItem(USER_EXPLORE_AVATAR_KEY) ?? ""

    reaction(
      () => this.avatarOptions,
      (options) => {
        if (options == null) return
        if (!this.avatarId) {
          this.avatarId = (options.at(0)?.index as string) ?? ""
        }
      }
    )
    autorun(() => localStorage.setItem(USER_EXPLORE_AVATAR_KEY, this.avatarId))
  }

  // state

  get avatarOptions(): Option[] | null {
    return this.avatarsStore.avatars
      ? avatarsToOptions(this.avatarsStore.avatars)
      : null
  }

  avatarId: string

  // actions

  setAvatarId = (newAvatarId: string = "") => {
    this.avatarId = newAvatarId
  }
}

export default UserSetupStore
