import React from "react"
import clsx from "clsx"

import styles from "./ButtonGroup.module.scss"

export interface ButtonGroupProps {
  className?: string
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  className,
}) => <div className={clsx(styles.root, className)}>{children}</div>

export default ButtonGroup
