import { makeAutoObservable, runInAction } from "mobx"
import {
  filter,
  from,
  ObservableInput,
  race,
  retry,
  Subscription,
  switchMap,
  tap,
  timer,
} from "rxjs"
import { toStream } from "mobx-utils"

import { GetComparisonReportParams } from "@framework/types/product"
import productsService from "@services/products.service"

type State = {
  summary: string | null

  error: string | null

  isLoading: boolean

  request: GetComparisonReportParams | null

  lastTryTimestamp: number
}

const defaultState: State = {
  summary: null,

  error: null,

  isLoading: false,

  request: null,

  lastTryTimestamp: Date.now(),
}

export default class ProductComparisonSummaryStore {
  constructor() {
    this.state = { ...defaultState }

    makeAutoObservable(this)
  }

  state: State

  setSummary = (summary: string) => {
    this.state.summary = summary
  }

  private summaryStream$?: Subscription

  subscribeSummary = () => {
    this.unsubscribeSummary()
    this.summaryStream$ = this.initLoadingStream()
  }

  unsubscribeSummary = () => {
    if (this.summaryStream$) {
      this.summaryStream$.unsubscribe()
      this.summaryStream$ = undefined
    }
  }

  private initLoadingStream = () => {
    return from(
      toStream(
        () => [this.state.request, this.state.lastTryTimestamp],
        false
      ) as ObservableInput<[GetComparisonReportParams, number]>
    )
      .pipe(
        filter(([request]) => request != null),
        tap(() =>
          runInAction(() => {
            this.state.summary = null
            this.state.isLoading = true
            this.state.error = ""
          })
        ),
        switchMap(([request]) => productsService.getComparisonReport$(request)),
        tap((response) => {
          runInAction(() => {
            const { report } = response.data

            this.state.summary = report
            this.state.isLoading = false
          })
        }),
        retry({
          delay: () => {
            runInAction(() => {
              this.state.isLoading = false
              this.state.error = "Loading Failed"
            })
            return race(
              timer(5_000),
              from(
                toStream(
                  () => this.state.lastTryTimestamp,
                  true
                ) as ObservableInput<number>
              )
            )
          },
        })
      )
      .subscribe()
  }

  requestReload = () => {
    this.state.lastTryTimestamp = Date.now()
  }

  resetRequest = () => {
    runInAction(() => {
      this.state.lastTryTimestamp = Date.now()
      this.state.request = null
    })
  }

  requestSummary = async (params: GetComparisonReportParams) => {
    this.state.request = params
  }
}
