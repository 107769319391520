import { AxiosResponse } from "axios"

import {
  AcronymDTO,
  NewAcronymDTO,
  SynonymDTO,
} from "@framework/types/glossary"

import HttpService from "./http.service"

export interface GetAllAcronymsResponse {
  data: AcronymDTO[]
}

export interface GetAllSynonymsResponse {
  data: SynonymDTO[]
}

export interface CreateSynonymResponse {
  data: SynonymDTO
}

export interface CreateAcronymResponse {
  data: AcronymDTO
}

export interface UploadSynonymResponse {
  data: SynonymDTO[]
  message: string
}

export interface UploadAcronymResponse {
  data: AcronymDTO[]
  message: string
}

class GlossaryAPI extends HttpService {
  getAllAcronyms = async (
    query: string
  ): Promise<AxiosResponse<GetAllAcronymsResponse>> =>
    this.get(
      query
        ? `ts/admin/word-acronym/all?query=${query}`
        : `ts/admin/word-acronym/all`,
      true
    )

  getAllSynonyms = (
    query: string
  ): Promise<AxiosResponse<GetAllSynonymsResponse>> =>
    this.get(
      query
        ? `ts/admin/word-synonym/all?query=${query}`
        : `ts/admin/word-synonym/all`,
      true
    )

  createSynonym = (
    synonyms: SynonymDTO["synonyms"]
  ): Promise<AxiosResponse<CreateSynonymResponse>> =>
    this.post(`ts/admin/word-synonym`, { synonyms }, true)

  createAcronym = (
    acronym: NewAcronymDTO
  ): Promise<AxiosResponse<CreateAcronymResponse>> =>
    this.post(`ts/admin/word-acronym`, acronym, true)

  updateSynonym = (
    id: SynonymDTO["id"],
    synonyms: SynonymDTO["synonyms"]
  ): Promise<AxiosResponse<CreateSynonymResponse>> =>
    this.patch(`ts/admin/word-synonym?wordSynonymId=${id}`, { synonyms }, true)

  updateAcronym = (
    id: AcronymDTO["id"],
    acronym: NewAcronymDTO
  ): Promise<AxiosResponse<CreateAcronymResponse>> =>
    this.patch(`ts/admin/word-acronym?wordAcronymId=${id}`, acronym, true)

  uploadAcronymFromFile = (
    form: FormData
  ): Promise<AxiosResponse<UploadAcronymResponse>> =>
    this.post(`ts/admin/word-acronym/upload`, form, true)

  uploadSynonymFromFile = (
    form: FormData
  ): Promise<AxiosResponse<UploadSynonymResponse>> =>
    this.post(`ts/admin/word-synonym/upload`, form, true)

  deleteSynonym = (id: SynonymDTO["id"]): Promise<AxiosResponse<null>> =>
    this.delete(`ts/admin/word-synonym?wordSynonymId=${id}`, true)

  deleteAcronym = (id: AcronymDTO["id"]): Promise<AxiosResponse<null>> =>
    this.delete(`ts/admin/word-acronym?wordAcronymId=${id}`, true)
}

export default new GlossaryAPI()
