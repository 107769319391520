import React from "react"
import { Form, Formik } from "formik"
import { observer } from "mobx-react-lite"

import useToggle from "@components/hooks/useToggle"
import Button from "@components/ui/Button/Button"
import SidebarPopup from "@components/modals/components/SidebarPopup"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"

import FilterSidebarHeader from "./components/Header"

type TargetRenderCallbackOptions = {
  onToggle: () => void
  onReset: () => void
}

interface FilterSidebarProps<T> {
  initialValue?: T
  defaultValue?: any
  children?: React.ReactNode
  onChange?: (newFilters: T) => void
  targetSlot?: (options: TargetRenderCallbackOptions) => React.ReactNode
}

const FilterSidebar = observer(<T,>(props: FilterSidebarProps<T>) => {
  const {
    initialValue,
    defaultValue = {},
    targetSlot,
    onChange,
    children,
  } = props

  const { isOpened, handleToggle, setOpened } = useToggle()

  const handleSubmit = (values: any) => {
    onChange?.(values)
    setOpened(false)
  }

  return (
    <Formik initialValues={initialValue ?? {}} onSubmit={handleSubmit}>
      {({ resetForm, submitForm }) => (
        <>
          {targetSlot?.({
            onToggle: handleToggle,
            onReset: () => {
              resetForm({ values: defaultValue })
              submitForm()
            },
          })}

          <SidebarPopup open={isOpened} onClose={handleToggle}>
            <Templates.RollScript
              gutter="24"
              headerSocket={
                <FilterSidebarHeader
                  onClear={() => resetForm({ values: defaultValue })}
                />
              }
              footerSocket={
                <List>
                  <Button variant="outlined" onClick={submitForm}>
                    Apply
                  </Button>
                </List>
              }
            >
              <Form>{children}</Form>
            </Templates.RollScript>
          </SidebarPopup>
        </>
      )}
    </Formik>
  )
})

export default FilterSidebar
