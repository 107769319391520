import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { Synonym, SynonymsGroupsByLetter } from "@framework/types/glossary"
import Button from "@components/ui/Button/Button"
import { constructComplexUrlWithParams } from "@utils/textUtils"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import useQuery from "@components/hooks/useQuery"
import { useStore } from "@store/index"
import { GlossaryPageMode } from "@pages/upload/Glossary/Glossary"

import SynonymRow from "./SynonymRow"
import Table from "../Table/Table"
import Row from "../Table/Row"
import Column from "../Table/Column"
import AlphabetSwitch from "./AlphabetSwitch"
import EditSynonymRow from "./EditSynonymRow"

import styles from "./index.module.sass"

export interface SynonymTableProps {
  glossaryGroups: SynonymsGroupsByLetter
}

export const SynonymTable: React.FC<SynonymTableProps> = React.memo(
  ({ glossaryGroups }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = useQuery()

    const { restrictionsStore: access } = useStore()

    const [letter, setLetter] = React.useState<string>("A")

    const editId = queryParams.get("managingId")

    const handleAdd = () => {
      navigate(
        constructComplexUrlWithParams(location.pathname, {
          glossaryMode: GlossaryPageMode.SYNONYM,
          managingId: "new",
        })
      )
    }

    const items = glossaryGroups?.[letter]

    const editSynonym = React.useMemo(() => {
      if (editId !== "new") {
        const toEdit = Object.values(glossaryGroups)
          .flatMap((it) => it)
          .find((it) => it.id === editId)

        if (toEdit != null) return toEdit
      }
      return { id: "new", mainWord: "", synonymsByMainWord: [""] }
    }, [editId, glossaryGroups])

    return (
      <Templates.RollScript
        className={styles.root}
        gutter="0"
        headerSocket={
          <AlphabetSwitch
            value={letter}
            onChange={setLetter}
            isActive={(it: string) => !!glossaryGroups?.[it]?.length}
            className={styles.switch}
          />
        }
        footerSocket={
          access.canEditGlossary ? (
            editId === "new" ? (
              <Table className={styles.table}>
                <EditSynonymRow synonym={editSynonym} key={editId} />
              </Table>
            ) : (
              <Button
                className={styles.addButton}
                onClick={handleAdd}
                color="primary"
                size="big"
              >
                Add Synonym
              </Button>
            )
          ) : null
        }
      >
        <Table className={styles.table}>
          {items?.length ? (
            items?.map((glossaryUnit) =>
              editId === glossaryUnit.id ? (
                <EditSynonymRow synonym={editSynonym} key={editId} />
              ) : (
                <SynonymRow
                  synonym={glossaryUnit as Synonym}
                  key={`SynonymRow-${glossaryUnit.id}`}
                />
              )
            )
          ) : (
            <Row>
              <Column justify="center">
                <Text color="text50Color">
                  No synonyms added for the letter &quot;{letter}&quot;
                </Text>
              </Column>
            </Row>
          )}
        </Table>
      </Templates.RollScript>
    )
  }
)

export default SynonymTable
