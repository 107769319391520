import React, { useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import filter from "lodash/filter"
import { useAlert } from "react-alert"

import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ScrollableContainer from "@components/ui/ScrollableContainer/ScrollableContainer"
import BusinessCard from "@components/ui/EntityCard/components/BusinessCard"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { ModalsTypes } from "@components/modals/constants"
import TextInput from "@components/ui/TextInput/TextInput"
import ListItem from "@components/ui/ListItem/ListItem"
import Loader from "@components/ui/Loader/BarLoader"
import useModal from "@components/modals/useModal"
import useSearch from "@components/hooks/useSearch"
import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store/index"

import ModalContainer from "../components/Container"

import styles from "./index.module.sass"

export interface DatatypeDeleteModalProps {
  datatypeId: string
}

const DatatypeDeleteModal: React.FC<DatatypeDeleteModalProps> = observer(
  ({ datatypeId }) => {
    const { hideModal } = useModal(ModalsTypes.DATATYPE_DELETE_MODAL)
    const alert = useAlert()

    const [selectedItem, setSelectedItem] = useState("")

    const {
      knowledgeDatatypesController: { deleteDatatype },
      datatypesController: { loadAllDataTypes },
    } = useController()

    const {
      datatypesStore: { data },
      knowledgeDatatypeDetailsStore: { isLoading },
    } = useStore()

    const handleSubmit = async () => {
      const error = await deleteDatatype(datatypeId, selectedItem)

      if (error) {
        alert.error(error)
        return
      }
      alert.success("Datatype was successfully deleted")

      loadAllDataTypes()
      hideModal()
    }

    const [searchProps] = useSearch()

    const options = useMemo(() => {
      const query = searchProps.value.toLowerCase()
      return filter(data ?? [], (item) =>
        item.name.toLowerCase().includes(query)
      )
    }, [data, searchProps.value])

    return (
      <ModalContainer
        title={<ModalTitle titleText="Delete Data Type" />}
        onClose={hideModal}
        className={styles.root}
        containerClassName={styles.container}
      >
        <Templates.RollScript
          gutter="16"
          headerSocket={
            <>
              <div className={styles.warning}>
                <Icon name="info" color="gold" />
                <Text variant="h4" weight="semibold">
                  Transfer Associated Data
                </Text>
              </div>
              Move the associated data to other data type before deleting
              <Text variant="h6" upperCase className={styles.label}>
                Transfer Associated Data To
              </Text>
            </>
          }
          footerSocket={
            <ModalFooterContainer>
              <Button disabled={isLoading} variant="outlined">
                Cancel
              </Button>
              <Button disabled={isLoading} color="red" onClick={handleSubmit}>
                Delete {isLoading && <Loader />}
              </Button>
            </ModalFooterContainer>
          }
        >
          <List gutter="8">
            <TextInput
              {...searchProps}
              placeholder="Search"
              before={<Icon name="search" />}
              autoFocus
            />
            <ScrollableContainer>
              <List gutter="8">
                {options.map((item) => (
                  <ListItem
                    key={item.id}
                    onClick={() => setSelectedItem(item.id)}
                    active={selectedItem === item.id}
                  >
                    <BusinessCard
                      title={item.name}
                      avatar={<DocumentIcon icon={item.iconUrl ?? "global"} />}
                      inline
                    />
                  </ListItem>
                ))}
              </List>
            </ScrollableContainer>
          </List>
        </Templates.RollScript>
      </ModalContainer>
    )
  }
)

export default DatatypeDeleteModal
