import { makeAutoObservable } from "mobx"

import { Point, Range } from "@framework/types/common"

import { GridStylesCache } from "../types"

class MatrixGrid {
  readonly overscanCount = 3

  readonly defaultCellWidth = 200

  readonly defaultCellHeight = 65

  readonly totalColumns = 27

  readonly totalRows = 52

  visibleGridRect: Range<Point>

  gridElement: Element | null = null

  xAxisSizes: Record<number, number> = {}

  yAxisSizes: Record<number, number> = {}

  private cellsStyleCache: GridStylesCache | null = null

  constructor() {
    this.visibleGridRect = {
      start: { x: 0, y: 0 },
      end: { x: 0, y: 0 },
    }
    makeAutoObservable(this)
  }

  setGridContainer = (element: Element | null) => {
    this.gridElement = element
  }

  setGridStylesCache = (cache: GridStylesCache | null) => {
    this.cellsStyleCache = cache
  }

  setVisibleRect = (rect: Range<Point>) => {
    this.visibleGridRect = rect
  }

  get getCellCoordinates() {
    const sizeCache = this.cellsStyleCache

    return (point: Point) => {
      const cell = `${point.y}-${point.x}`

      return {
        x: sizeCache?.[cell]?.left ?? 0,
        y: sizeCache?.[cell]?.top ?? 0,
      }
    }
  }

  getCellWidth = (index: number) => {
    const width = this.xAxisSizes[index]
    if (width == null) return this.defaultCellWidth
    return width
  }

  getCellHeight = (index: number) => {
    const height = this.yAxisSizes[index]
    if (height == null) return this.defaultCellHeight
    return height
  }

  getRangeWidth = (rect: Range<Point>) => {
    return getReactSideSize(rect.start.x, rect.end.x, this.getCellWidth)
  }

  getRangeHeight = (rect: Range<Point>) => {
    return getReactSideSize(rect.start.y, rect.end.y, this.getCellHeight)
  }

  resizeCellWidth = (index: number, shift: number) => {
    this.xAxisSizes[index] = this.getCellWidth(index) + shift
  }

  resizeCellHeight = (index: number, shift: number) => {
    this.yAxisSizes[index] = this.getCellHeight(index) + shift
  }

  get rect(): Range<Point> {
    return {
      start: { x: 0, y: 0 },
      end: { x: this.totalColumns - 1, y: this.totalRows - 1 },
    }
  }
}

export const getReactSideSize = (
  startIndex: number,
  endIndex: number,
  getCellSize: (index: number) => number
) => {
  let res = 0
  for (let i = startIndex; i <= endIndex; i += 1) res += getCellSize(i)
  return res
}

export default MatrixGrid
