import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store/index"
import AvatarPickerSidebar from "@components/prototypes/AvatarPickerSidebar"
import SolutionsCardContainer from "@pages/search/AvailableSolutions/SolutionsCardContainer"
import useAvatarScope from "@pages/search/AvatarScopeContext/useAvatarScope"
import { SolutionData } from "@framework/types/solution"
import mainRoutes from "@root/main.routes"

import styles from "./ProductSolutions.module.sass"

const DEFAULT_WELCOME_MESSAGE =
  "Enhance your productivity - so you can focus on strategic decisions that matter."

const ProductSolutions: React.FC = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()

  const { avatar, setAvatar } = useAvatarScope()
  const {
    solutionsController: { loadAvatarSolutions },
  } = useController()

  const { solutionsStore } = useStore()

  const { isLoading, solutions } = solutionsStore

  const handleLaunch = (solution: SolutionData) => {
    solutionsStore.setSolution(solution)
    navigate(mainRoutes.solutionView(solution.key))
  }

  const autoPickSingleOption = location.state?.action === "OPEN_SINGLE_SOLUTION"

  React.useEffect(() => {
    if (isLoading || !autoPickSingleOption) return
    if (solutions?.length === 1) handleLaunch(solutions[0])
  }, [isLoading, solutions, autoPickSingleOption])

  useEffect(() => {
    if (avatar?.id) loadAvatarSolutions(avatar.id)
  }, [avatar?.id])

  return (
    <MainLayout>
      <div className={clsx(styles.root)}>
        <header className={styles.header}>
          <div className={styles.greeting}>
            <Text variant="h1" weight="bold">
              {avatar?.name} Solutions
            </Text>
            <Text variant="body1" color="text50Color">
              {DEFAULT_WELCOME_MESSAGE}
            </Text>
          </div>

          <AvatarPickerSidebar value={avatar?.id} onChange={setAvatar} />
        </header>

        <div className={styles.solutionsContainer}>
          <SolutionsCardContainer
            onLaunch={handleLaunch}
            className={styles.solutionsCardContainer}
            showHint
          />
        </div>
      </div>
    </MainLayout>
  )
})

export default ProductSolutions
