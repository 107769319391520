import React, { HTMLProps } from "react"
import clsx from "clsx"

import styles from "./TabsCollection.module.sass"

export interface TabsItemProps extends HTMLProps<HTMLButtonElement> {
  active?: boolean
}

export const TabsItem: React.FC<TabsItemProps> = ({
  children,
  active = false,
  onClick,
  ...rest
}) => (
  <button
    {...rest}
    className={clsx(styles.item, { [styles.active]: active })}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
)

export default TabsItem
