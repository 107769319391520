import React from "react"
import clsx from "clsx"

import { Icon } from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"

import styles from "./UserSettingsMenu.module.sass"

export type MenuItemProps = React.HTMLAttributes<HTMLButtonElement> & {
  icon?: IconName
  color?: "dark" | "light" | "red" | "primary" | "secondary"
}
export const MenuItem: React.FC<MenuItemProps> = ({
  children,
  className,
  icon = "question-mark",
  color = "light",
  ...rest
}) => {
  return (
    <button
      type="button"
      className={clsx(styles.link, styles[`color-${color}`], className)}
      {...rest}
    >
      <Icon name={icon} />
      {children}
    </button>
  )
}

export default MenuItem
