import React, { useState } from "react"

import { PaginationProps } from "@components/ui/Pagination/Pagination"
import useEvent from "@components/hooks/useEvent"

type Options = {
  totalRows: number
  pageSize: number
  initialPage?: number
  totalPages?: number
  onPageChange?: (page: number) => void
}

type Return = {
  forcePage: number
  pageCount: number
  onPageChange: PaginationProps["onPageChange"]
  onClick: PaginationProps["onClick"]
}

const usePagination = (options: Options): Return => {
  const { totalRows = 0, pageSize = 1, totalPages, initialPage = 0 } = options
  const [page, setPage] = useState(initialPage)

  const pageCount = totalPages ?? Math.ceil(totalRows / pageSize)

  const handleChangePage = useEvent((page: number) => {
    setPage(page)
    options.onPageChange?.(page)
  })

  React.useLayoutEffect(() => {
    if (page + 1 > pageCount)
      handleChangePage(pageCount > 0 ? pageCount - 1 : 0)
  }, [pageCount])

  const onPageChange: PaginationProps["onPageChange"] = useEvent((it) => {
    handleChangePage(it.selected)
  })

  const onClick: PaginationProps["onClick"] = useEvent(
    ({ selected, nextSelectedPage, isPrevious, isNext }) => {
      if (nextSelectedPage != null) return nextSelectedPage
      if (isPrevious && selected === 0) return pageCount - 1
      if (isNext && selected === pageCount - 1) return 0
      return selected
    }
  )

  return {
    forcePage: page,
    pageCount,
    onPageChange,
    onClick,
  }
}

export default usePagination
