import React from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import { useStore } from "@store"

export interface SimpleTableProps {
  text: string
}

export const StatusText: React.FC<SimpleTableProps> = observer(({ text }) => {
  let newText = text
  const { subscriptionStore } = useStore()
  const { isEditable } = subscriptionStore
  if (isEditable) {
    newText = "None"
  }
  return (
    <Text
      variant="h5"
      weight="bold"
      color={newText === "None" ? "text40Color" : "text100Color"}
    >
      {text}
    </Text>
  )
})

export default StatusText
