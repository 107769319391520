import { makeAutoObservable } from "mobx"

import { ProductFilter } from "@framework/types/product-filter"
import { ListResponse } from "@framework/types/product"

export default class ProductFilterStore {
  constructor() {
    makeAutoObservable(this)
  }

  filterTree: ProductFilter[] | null = null

  selectedFilters: Record<string, string[]> = {}

  error: string | null = null

  isLoading: boolean = false

  setFilters = (filters: ListResponse<ProductFilter>) => {
    this.filterTree = filters.data ?? []
  }

  applyFilter = (filter: Record<string, string[]>) => {
    this.selectedFilters = { ...filter }
  }

  getFiltersList = (): ProductFilter[] => {
    if (this.filterTree == null) return []
    return this.filterTree.flatMap(flattenProductFilters)
  }
}

const flattenProductFilters = (
  productFilter: ProductFilter
): ProductFilter[] => {
  if (productFilter.filter) return [productFilter]
  if (productFilter.attributes) {
    return productFilter.attributes.flatMap((attribute) =>
      flattenProductFilters(attribute)
    )
  }
  return []
}
