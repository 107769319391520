import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { useController, useStore } from "@store/index"
import { InstanceConfigRecord } from "@framework/types/app"
import Collapsable from "@components/ui/Collapsable/Collapsable"
import useCollapsible from "@components/ui/Collapsable/useCollapsible"
import CollapseHeader from "@components/ui/Collapsable/CollapseHeader"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import ConfigForm from "./ConfigForm"

import styles from "./index.module.sass"

interface Props {
  config: InstanceConfigRecord
}

export const InstanceConfigRecordForm: React.FC<Props> = observer(
  ({ config }) => {
    const alert = useAlert()

    const modal = useModal(ModalsTypes.DELETE_INSTANCE_CONFIG_MODAL)

    const collapsible = useCollapsible()

    const { appConfigController } = useController()

    const {
      appStore: { instanceConfig },
    } = useStore()

    const init = () => {
      appConfigController.loadAllInstanceConfigurations()
    }

    const handleDelete = () => {
      modal.showModal({
        configId: config.id,
        configKey: config.key,
        callback: init,
      })
    }

    const handleUpdate = async (data: object) => {
      const error = await appConfigController.updateInstanceConfiguration(
        config.id,
        config.key,
        data
      )
      if (error) {
        alert.error(error)
        return
      }
      alert.success(`Configuration id:${config.id} was updated successfully`)
      init()
    }

    return (
      <>
        <CollapseHeader
          open={collapsible.isActive(config.id)}
          onToggle={() => collapsible.toggle(config.id)}
        >
          <Templates.Header
            left={<Text>{config.key}</Text>}
            right={<Text>{config.id}</Text>}
          />
        </CollapseHeader>
        <Collapsable open={collapsible.isActive(config.id)}>
          <ConfigForm
            className={styles.body}
            defaultValue={JSON.stringify(config.value, null, "\t")}
            onSubmit={handleUpdate}
            onDelete={handleDelete}
            loading={instanceConfig.isLoading}
          />
        </Collapsable>
      </>
    )
  }
)

export default InstanceConfigRecordForm
