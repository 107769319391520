export const normalizeConfig = <T = any>(config: T) =>
  Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.entries(config).map(([key, value]) => {
      const normalizedValue =
        value === "true" || value === "false" ? value === "true" : value
      return [key, normalizedValue]
    })
  )

export default null
