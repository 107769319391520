import React from "react"

import SecondaryLayout from "@components/layout/SecondaryLayout/SecondaryLayout"
import ScrollableContainer from "@components/modals/CreateAvatarModal/components/ScrollableContainer/ScrollableContainer"
import HTMLText from "@components/ui/Typography/HTMLText"

import SERVICE_TERMS from "./terms"

import styles from "./ServiceTerms.module.sass"

interface ServiceTermsProps {}

const ServiceTermsPage: React.FC<ServiceTermsProps> = () => (
  <SecondaryLayout className={styles.root}>
    <div className={styles.container}>
      <ScrollableContainer className={styles.list}>
        <HTMLText>{SERVICE_TERMS}</HTMLText>
      </ScrollableContainer>
    </div>
  </SecondaryLayout>
)

export default ServiceTermsPage
