import React from "react"
import { Routes, Route } from "react-router-dom"
import { observer } from "mobx-react-lite"

import CollapsibleContextProvider from "@components/ui/Collapsable/CollapsibleContext"
import { useController, useStore } from "@store/index"
import ActiveAvatarContextProvider from "@pages/search/ActiveAvatarContext"
import AvatarScopeContextProvider from "@pages/search/AvatarScopeContext"
import { SolutionTypes } from "@framework/constants/search-results"
import FactFinderExtendedResult from "@pages/home/ExtendedResult/FactFinderExtendedResult"
import DocChatExtendedResult from "@pages/home/ExtendedResult/DocChatExtendedResult"

import ProductSolutions from "./SolutionsList/ProductSolutions"
import LaunchSolutions from "../launch-solutions/LaunchSolutions"
import ProductPage from "../product/Product"

const ProductSolutionsRoot: React.FC = observer(() => {
  const {
    userSetupStore: { avatarId, setAvatarId },
    restrictionsStore: access,
  } = useStore()

  const { documentChatSolutionController, factFinderSolutionController } =
    useController()

  React.useEffect(() => {
    // TODO think over this logic
    documentChatSolutionController.reset()
    factFinderSolutionController.reset()
  }, [avatarId])

  return (
    <ActiveAvatarContextProvider avatarId={avatarId}>
      <AvatarScopeContextProvider avatarId={avatarId} onChange={setAvatarId}>
        <CollapsibleContextProvider>
          <Routes>
            <Route path="/" element={<ProductSolutions />} />

            {access.isExtendedResultAvailable && (
              <Route
                path={`${SolutionTypes.InsightsFinder}/source/:searchId/:blockId/:passageId/:tab`}
                element={<FactFinderExtendedResult />}
              />
            )}

            {access.isExtendedResultAvailable && (
              <Route
                path={`${SolutionTypes.PrivateWorkspace}/source/:searchId/:blockId/:passageId/:tab`}
                element={<DocChatExtendedResult />}
              />
            )}
            <Route path="products/:id" element={<ProductPage />} />
            <Route path=":solutionType/*" element={<LaunchSolutions />} />
          </Routes>
        </CollapsibleContextProvider>
      </AvatarScopeContextProvider>
    </ActiveAvatarContextProvider>
  )
})

export default ProductSolutionsRoot
