import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { toStream } from "mobx-utils"
import {
  from,
  ObservableInput,
  retry,
  Subscription,
  switchMap,
  tap,
  timer,
} from "rxjs"

import analyticsService from "@services/analytics.service"
import { APIUsageSummaryData } from "@framework/types/analytics"

const PULLING_DELAY = 120_000
const RETRY_DELAY = 30_000

type State = {
  isLoading: boolean

  error: string | null

  data: APIUsageSummaryData | null

  reloadTrigger: boolean
}

export default class ApiUsageSummaryStore {
  state: State

  private loadingStream$?: Subscription

  constructor() {
    this.state = {
      isLoading: false,

      error: null,

      data: null,

      reloadTrigger: false,
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "data", () => {
      this.loadingStream$ = this.initStream()
    })

    onBecomeUnobserved(this.state, "data", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  initStream = () => {
    return from(
      toStream(() => [this.state.reloadTrigger], true) as ObservableInput<
        [boolean]
      >
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.error = null
          })
        ),

        switchMap(() => analyticsService.loadAPIUsageSummary$()),

        tap((response) =>
          runInAction(() => {
            const { data } = response
            this.state.data = data
            this.state.isLoading = false
          })
        ),

        retry({
          delay: () => {
            runInAction(() => {
              this.state.error = "Loading Failed"
              this.state.isLoading = false
              this.state.data = null
            })
            return timer(RETRY_DELAY)
          },
        }),

        switchMap(() => timer(PULLING_DELAY)),

        tap(() => this.refresh())
      )
      .subscribe()
  }

  refresh = () => {
    this.state.reloadTrigger = !this.state.reloadTrigger
  }
}
