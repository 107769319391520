import React from "react"

import Button, { ButtonProps } from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import ButtonGroup from "@components/ui/Button/ButtonGroup"

export interface FilterButtonProps {
  counter?: number
  color?: ButtonProps["color"]
  variant?: ButtonProps["variant"]
  size?: ButtonProps["size"]
  onOpen?: () => void
  onClean?: () => void
}

const FilterButton: React.FC<FilterButtonProps> = ({
  counter,
  children = "Filter",
  color,
  variant,
  size,
  onOpen,
  onClean,
}) => {
  return (
    <ButtonGroup>
      <Button
        color={color}
        variant={variant}
        size={size}
        onClick={onOpen}
        before={<Icon name="sliders" />}
        after={<Icon name="arrow-down" />}
        title="Open"
      >
        {children}
      </Button>
      {!!counter && (
        <Button
          color={color}
          variant={variant}
          size={size}
          onClick={onClean}
          title="Reset"
          after={<Icon name="cross" style={{ fontSize: "0.8em" }} />}
        >
          {counter}
        </Button>
      )}
    </ButtonGroup>
  )
}

export default FilterButton
