import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import BackButton from "@components/prototypes/BackButton"
import Button from "@components/ui/Button/Button"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import List from "@components/ui/List/List"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import { useController, useStore } from "@store/index"

import RolesActionsTable from "../components/RolesActionsTable"

import styles from "./Roles.module.sass"

export interface RolesProps {}

export const RolesPage: React.FC<RolesProps> = observer(() => {
  const navigate = useNavigate()

  const editRoleModal = useModal(ModalsTypes.EDIT_USER_ROLE_MODAL)
  const editActionModal = useModal(ModalsTypes.EDIT_USER_ACTION_MODAL)
  const handleCreateRole = () => {
    editRoleModal.showModal({})
  }

  const handleAddAction = () => {
    editActionModal.showModal({})
  }

  const { rolesStore, actionsStore, restrictionsStore: access } = useStore()
  const { rolesController, actionsController } = useController()

  useEffect(() => {
    rolesController.init()
    actionsController.init()
  }, [])

  const isLoading = rolesStore.isLoading || actionsStore.isLoading

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton onClick={() => navigate("../")}>Roles</BackButton>
            }
            right={
              <List direction="row" justify="flex-end" gutter="16">
                {access.canAddAction && (
                  <Button
                    onClick={handleAddAction}
                    disabled={isLoading}
                    className={styles.inviteButton}
                    color="primary"
                    size="big"
                    before={<Icon name="format_list_numbered" />}
                  >
                    Add action
                  </Button>
                )}

                {access.canAddRole && (
                  <Button
                    onClick={handleCreateRole}
                    disabled={isLoading}
                    className={styles.inviteButton}
                    color="primary"
                    size="big"
                    before={<Icon name="key-2" />}
                  >
                    Add Role
                  </Button>
                )}
              </List>
            }
          />

          <LoadingCover isLoading={isLoading} className={styles.body}>
            <RolesActionsTable className={styles.table} />
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})

export default RolesPage
