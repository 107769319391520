import React, { useMemo, useState } from "react"
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List as VirtualList,
  ListProps,
} from "react-virtualized"
import { observer } from "mobx-react-lite"
import { FieldProps } from "formik"
import without from "lodash/without"

import Dropdown from "@components/prototypes/FilterSidebar/components/Dropdown/Dropdown"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"
import { useStore } from "@store/index"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import ListItem from "@components/ui/ListItem/ListItem"
import { DataCategoryEntity } from "@framework/types/knowledge"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { IconName } from "@components/ui/Icon/IconName"
import List from "@components/ui/List/List"
import Skeleton from "@components/ui/Skeleton/Skeleton"

import styles from "./DataTypeSelect.module.sass"

export interface DataTypeSelectProps extends FieldProps<string[]> {
  label?: string
  valueKey?: keyof DataCategoryEntity
}

const DataTypeSelect: React.FC<DataTypeSelectProps> = observer(
  ({ label, field, form, valueKey = "id" }) => {
    const [open, setOpen] = useState(false)

    const { value = [], name } = field
    const { setFieldValue } = form

    const { allDatatypesStore } = useStore()

    const handleClick = (newValue: string) => {
      if (!value.includes(newValue)) {
        setFieldValue(name, [...value, newValue])
      } else {
        setFieldValue(name, without(value, newValue))
      }
    }

    const renderRow: ListProps["rowRenderer"] = ({
      index,
      key,
      parent,
      style,
    }) => {
      const dataType = allDatatypesStore.getByIndex(index)

      if (!dataType)
        return (
          <div style={style} key={key}>
            <ListItem>
              <Skeleton lineHeight={style.height} minWidth={100} />
            </ListItem>
          </div>
        )

      const active = value?.includes(dataType[valueKey] as string)

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
          key={`${key}-cached`}
        >
          <div style={style} key={key}>
            <ListItem
              active={active}
              onClick={() => handleClick(dataType[valueKey] as string)}
            >
              <List direction="row" gutter="16" align="center">
                <DocumentIcon
                  icon={(dataType.iconUrl as IconName) ?? "global"}
                />
                {dataType.name}
              </List>
            </ListItem>
          </div>
        </CellMeasurer>
      )
    }

    const cache = useMemo(() => {
      return new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 58,
      })
    }, [])

    const isChecked = !!value?.length

    const handleUncheck = (e: React.MouseEvent) => {
      if (isChecked) setFieldValue(name, undefined)
      else setOpen(true)
      e.stopPropagation()
    }

    return (
      <Dropdown
        title={label}
        opened={open}
        onClick={() => setOpen((value) => !value)}
        before={
          <CheckboxWithLabel
            checked={!!value?.length}
            onClick={handleUncheck}
          />
        }
      >
        <Templates.RollScript className={styles.root}>
          <div className={styles.container}>
            {allDatatypesStore.state.total === 0 ? (
              allDatatypesStore.state.isLoading ? (
                <Loader size="large" fluid />
              ) : (
                <NotFound>No Data Types found</NotFound>
              )
            ) : (
              <AutoSizer>
                {({ width, height }) => (
                  <VirtualList
                    rowCount={allDatatypesStore.state.total}
                    rowHeight={cache.rowHeight}
                    rowRenderer={renderRow}
                    height={height}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </div>
        </Templates.RollScript>
      </Dropdown>
    )
  }
)

export default DataTypeSelect
