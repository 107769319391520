import { makeAutoObservable } from "mobx"

import knowledgeService from "@services/knowledge.service"
import { BasicTopic } from "@framework/types/knowledge"
import { EditSubtopicFormData } from "@components/modals/ExpertTopicModal/EditSubtopicForm"
import { CollectionContentModeType } from "@services/common/types"

import ExpertInsightsStore from "./expert-insights.store"

export default class ExpertInsightsController {
  expertInsightsStore: ExpertInsightsStore

  constructor(injections: { expertInsightsStore: ExpertInsightsStore }) {
    makeAutoObservable(this)

    this.expertInsightsStore = injections.expertInsightsStore
  }

  loadTopicList = async (
    avatarId: string,
    query?: string,
    deepSearch: boolean = false,
    mode: CollectionContentModeType = "edit"
  ) => {
    const store = this.expertInsightsStore.topicsStore.getCollection(avatarId)

    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.getExpertTopicList(avatarId, {
        pageNum: 0,
        pageSize: 5000,
        query,
        deepSearch,
        mode,
      })

      const collection = response.data.data ?? []

      store.setData(collection)
    } catch (e) {
      store.error = "Unexpected error"
      store.setData([])
    } finally {
      store.isLoading = false
    }
  }

  createTopic = async (avatarId: string, data: BasicTopic) => {
    const store = this.expertInsightsStore.topicsStore.getCollection(avatarId)

    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.createExpertTopic(avatarId, data)

      return { data: response.data.data, status: "SUCCESS" }
    } catch (e) {
      store.error = "Unexpected error"
      return { message: store.error, status: "FAILED" }
    } finally {
      store.isLoading = false
    }
  }

  updateTopic = async (avatarId: string, topicId: string, data: BasicTopic) => {
    const store = this.expertInsightsStore.topicsStore.getCollection(avatarId)

    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.updateExpertTopic(
        avatarId,
        topicId,
        data
      )

      return { data: response.data.data, status: "SUCCESS" }
    } catch (e) {
      store.error = "Unexpected error"
      return { message: store.error, status: "FAILED" }
    } finally {
      store.isLoading = false
    }
  }

  loadSubtopicList = async (
    avatarId: string,
    topicId: string,
    query?: string,
    mode: CollectionContentModeType = "edit"
  ) => {
    const store = this.expertInsightsStore.subtopicsStore.getCollection(topicId)

    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.getExpertSubtopicList(
        avatarId,
        topicId,
        { pageNum: 0, pageSize: 5000, query, mode }
      )

      const collection = response.data.data ?? []

      store.setData(collection)
    } catch (e) {
      store.error = "Unexpected error"
      store.setData([])
    } finally {
      store.isLoading = false
    }
  }

  loadSubtopic = async (avatarId: string, subtopicId: string) => {
    const store = this.expertInsightsStore.subtopicStore.getEntity(subtopicId)

    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.getExpertSubtopicById(
        avatarId,
        subtopicId
      )

      store.setData(response.data.data)
    } catch (e) {
      store.error = "Unexpected error"
      store.setData(null)
    } finally {
      store.isLoading = false
    }
  }

  createSubtopic = async (
    avatarId: string,
    topicId: string,
    data: EditSubtopicFormData
  ) => {
    const store = this.expertInsightsStore.subtopicsStore.getCollection(topicId)

    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.createExpertSubtopic(
        avatarId,
        topicId,
        data
      )

      return { data: response.data.data, status: "SUCCESS" }
    } catch (e) {
      store.error = "Unexpected error"
      return { message: store.error, status: "FAILED" }
    } finally {
      store.isLoading = false
    }
  }

  updateSubtopic = async (
    avatarId: string,
    topicId: string,
    subtopic: string,
    data: EditSubtopicFormData
  ) => {
    const store = this.expertInsightsStore.subtopicsStore.getCollection(topicId)

    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.updateExpertSubtopic(
        avatarId,
        subtopic,
        data
      )

      return { data: response.data.data, status: "SUCCESS" }
    } catch (e) {
      store.error = "Unexpected error"
      return { message: store.error, status: "FAILED" }
    } finally {
      store.isLoading = false
    }
  }

  createArticle = async (
    avatarId: string,
    subtopicId: string,
    article: string
  ) => {
    try {
      const response = await knowledgeService.createExpertArticle(
        avatarId,
        subtopicId,
        {
          title: article,
          description: "",
        }
      )

      return { data: response.data.data, status: "SUCCESS" }
    } catch (e) {
      return { message: "Unexpected error", status: "FAILED" }
    }
  }

  createArticles = async (
    avatarId: string,
    subtopicId: string,
    article: string[]
  ) => {
    const store =
      this.expertInsightsStore.articlesStore.getCollection(subtopicId)

    try {
      store.isLoading = true
      store.error = null

      const response = await Promise.all(
        article.map((it) => this.createArticle(avatarId, subtopicId, it))
      )

      return { data: response, status: "SUCCESS" }
    } catch (e) {
      store.error = "Unexpected error"
      return { message: store.error, status: "FAILED" }
    } finally {
      store.isLoading = false
    }
  }

  loadArticlesList = async (
    avatarId: string,
    subtopicId: string,
    mode: CollectionContentModeType = "edit"
  ) => {
    const store =
      this.expertInsightsStore.articlesStore.getCollection(subtopicId)

    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.getExpertArticleList(
        avatarId,
        subtopicId,
        { pageNum: 0, pageSize: 5000, mode }
      )

      const collection = response.data.data ?? []

      store.setData(collection)
    } catch (e) {
      store.error = "Unexpected error"
      store.setData([])
    } finally {
      store.isLoading = false
    }
  }

  loadArticleInsightList = async (avatarId: string, articleId: string) => {
    const store =
      this.expertInsightsStore.articleInsightsStore.getCollection(articleId)

    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.getExpertArticleInsightList(
        avatarId,
        articleId,
        { pageNum: 0, pageSize: 5000 }
      )

      const collection = response.data.data ?? []

      store.setData(collection)
    } catch (e) {
      store.error = "Unexpected error"
      store.setData([])
    } finally {
      store.isLoading = false
    }
  }

  createArticleInsight = async (
    avatarId: string,
    articleId: string,
    data: { insight: string }
  ) => {
    const store =
      this.expertInsightsStore.articleInsightsStore.getCollection(articleId)

    try {
      store.isLoading = true
      store.error = null

      await knowledgeService.createExpertArticleInsight(
        avatarId,
        articleId,
        data
      )
    } catch (e) {
      store.error = "Unexpected error"
      store.setData([])
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  updateArticleInsight = async (
    avatarId: string,
    articleId: string,
    insightId: string,
    data: { insight: string }
  ) => {
    const store =
      this.expertInsightsStore.articleInsightsStore.getCollection(articleId)

    try {
      store.isLoading = true
      store.error = null

      await knowledgeService.updateExpertArticleInsight(
        avatarId,
        insightId,
        data
      )
    } catch (e) {
      store.error = "Unexpected error"
      store.setData([])
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  deleteArticleInsight = async (
    avatarId: string,
    articleId: string,
    insightId: string
  ) => {
    const store =
      this.expertInsightsStore.articleInsightsStore.getCollection(articleId)

    try {
      store.isLoading = true
      store.error = null

      await knowledgeService.deleteExpertArticleInsight(avatarId, insightId)
    } catch (e) {
      store.error = "Unexpected error"
      store.setData([])
    } finally {
      store.isLoading = false
    }
    return store.error
  }
}
