import QueryAttachmentsStore from "../query-attachments.store"
import SearchFlowStore from "../search-flow.store"

export class DocumentChatSolutionStore {
  searchFlowStore = new SearchFlowStore()

  queryAttachments = new QueryAttachmentsStore()
}

export default DocumentChatSolutionStore
