import { makeAutoObservable } from "mobx"

import { Product } from "@framework/types/product"

export default class ProductStore {
  constructor() {
    makeAutoObservable(this)
  }

  product: Product | null = null

  error: string | null = null

  isLoading = false
}
