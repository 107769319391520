import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Switch from "@components/ui/Switch/Switch"
import { SubscriptionOption } from "@framework/types/subscription"
import { useController, useStore } from "@store"
import Chip from "@components/ui/Chip/Chip"

import styles from "./index.module.sass"

interface StatusSwitchProps {
  option: SubscriptionOption
  optionName: string
}

const StatusSwitch: React.FC<StatusSwitchProps> = observer(
  ({ option, optionName }) => {
    const { subscriptionStore } = useStore()
    const { subscriptionController } = useController()
    const { editedSubscriptionData, isEditable, data } = subscriptionStore

    const selectedOptions: SubscriptionOption[] = (
      editedSubscriptionData as any
    )?.[optionName]

    const activeOption: SubscriptionOption = (data as any)?.[optionName]?.find(
      (item: SubscriptionOption) => item.id === option.id
    )

    const handleSwitchChange = (
      option: SubscriptionOption,
      active: boolean
    ) => {
      const updated = selectedOptions.map((opt) =>
        opt.id === option.id ? { ...opt, active } : opt
      )
      subscriptionController.updateEditData(optionName, updated)
    }

    return (
      <>
        {isEditable ? (
          <Switch
            className={clsx(styles.switch)}
            items={[
              { name: "inactive", value: "INACTIVE" },
              { name: "active", value: "ACTIVE" },
            ]}
            checked={option.active ? "active" : "inactive"}
            onChange={(name) => handleSwitchChange(option, name === "active")}
            size="small"
          />
        ) : (
          <Chip color={activeOption?.active ? "green" : "red"} uppercase>
            <span className={styles.text}>
              {activeOption?.active ? "ACTIVE" : "INACTIVE"}
            </span>
          </Chip>
        )}
      </>
    )
  }
)

export default StatusSwitch
