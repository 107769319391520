import React from "react"
import { Field } from "formik"

import UserSelect from "@components/prototypes/FilterSidebar/components/UserSelect/UserSelect"

const FilterForm: React.FC = () => {
  return (
    <>
      <Field
        name="updatedByUserIds"
        label="Updated By User"
        valueKey="id"
        component={UserSelect}
      />
      <Field
        name="createdByUserIds"
        label="Created By User"
        valueKey="id"
        component={UserSelect}
      />
    </>
  )
}

export default FilterForm
