import { autorun, makeAutoObservable } from "mobx"

import uploadService from "@services/upload.service"
import { BasicCloudFolder, DataSourceNodeName } from "@framework/types/upload"
import MicrosoftAPI, { GetAccessRequest } from "@services/microsoft.service"
import AppStore from "@store/app/app.store"
import { FailResponse, SuccessResponse } from "@framework/types/common"

import DataConnectorPreviewsStore from "./data-connector-preview.store"
import CloudFolderPreviewsStore from "./cloud-folder-previews.store"
import UploadStore from "./upload.store"

export class DataConnectorController {
  appStore: AppStore

  microsoftService: MicrosoftAPI

  connectorPreviews: DataConnectorPreviewsStore

  folderPreviews: CloudFolderPreviewsStore

  dataManagerStore: UploadStore

  error: string | null = null

  constructor(injections: {
    dataConnectorPreviewsStore: DataConnectorPreviewsStore
    cloudFolderPreviews: CloudFolderPreviewsStore
    uploadStore: UploadStore
    appStore: AppStore
  }) {
    makeAutoObservable(this)
    this.connectorPreviews = injections.dataConnectorPreviewsStore
    this.folderPreviews = injections.cloudFolderPreviews
    this.dataManagerStore = injections.uploadStore
    this.appStore = injections.appStore
    this.microsoftService = new MicrosoftAPI()

    autorun(() => {
      this.microsoftService = new MicrosoftAPI({
        apiURL: this.appStore.uiConfigStore.config?.services?.microsoft?.apiURL,
      })
    })
  }

  loadDataConnectorPreview = async (dataConnectorId: string) => {
    try {
      const response = await uploadService.getDataConnectionById(
        dataConnectorId
      )
      const { data } = response.data
      if (data) {
        this.connectorPreviews.collection.set(data.id, data)
      }
    } catch (error: any) {
      console.log(error)
      this.error = "Unexpected error"
    }
  }

  loadFolderPreview = async (folderId: string) => {
    const response = await uploadService.getDataConnectorFolder(folderId)
    const { data } = response.data
    if (data) {
      this.folderPreviews.collection.set(data.id, data)
    }
  }

  shareFolders = async (
    dataConnectorId: string,
    folders: BasicCloudFolder[],
    dataTypeId?: string
  ) => {
    try {
      await uploadService.updateCloudFoldersList(
        dataConnectorId,
        folders,
        dataTypeId
      )
    } catch (error: any) {
      if (error.response.data.message) {
        const { message } = error.response.data

        if (message === "FOLDER_ALREADY_EXISTS")
          return "One or more selected items was already added"
      }

      return "Unexpected error"
    }
    return null
  }

  connectCloudFolders = async (
    selectedEntityIds: string[],
    dataTypeId: string,
    dataConnectorType: DataSourceNodeName
  ) => {
    try {
      await uploadService.connectCloudEntityList(
        selectedEntityIds,
        dataTypeId,
        dataConnectorType
      )
    } catch (error: any) {
      if (error.response.data.message) {
        const { message } = error.response.data

        if (message === "FOLDER_ALREADY_EXISTS")
          return "One or more selected items was already added"
      }

      return "Unexpected error"
    }
    return null
  }

  deleteDataConnectorFolder = async (folderId: string) => {
    try {
      await uploadService.removeDataConnectorFolder(folderId)
    } catch (error: any) {
      return "Unknown error while deleting"
    }
    return null
  }

  deleteCloudEntity = async (entityId: string) => {
    try {
      await uploadService.removeDataConnectorEntity(entityId)
    } catch (error: any) {
      return "Unknown error while deleting"
    }
    return null
  }

  getSignedDataURL = async (
    url: string
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await uploadService.signedDataUrl(url)

      return { status: "SUCCESS", data: response.data.data.signedUrl }
    } catch (error) {
      return { status: "FAILED", message: "Unexpected error" }
    }
  }

  getMicrosoftOAuthCodeURL = (params: GetAccessRequest) =>
    this.microsoftService.getAuthCodeLink(params)
}

export default DataConnectorController
