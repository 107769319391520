/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"
import { useNavigate } from "react-router-dom"

import BackButton from "@components/prototypes/BackButton"

import styles from "./SecondaryLayout.module.sass"

export interface SecondaryLayoutProps {
  withBackButton?: boolean
  className?: string
  onBack?: React.MouseEventHandler
  onClick?: React.MouseEventHandler
}

export const SecondaryLayout: React.FC<SecondaryLayoutProps> = ({
  withBackButton = true,
  className,
  children,
  onBack,
  onClick,
}) => {
  const navigate = useNavigate()

  const handleBack = onBack || (() => navigate(-1))

  return (
    <div className={clsx(styles.root, className)} onClick={onClick}>
      {children}
      {withBackButton && (
        <BackButton className={styles.backButton} onClick={handleBack}>
          Back
        </BackButton>
      )}
    </div>
  )
}

export default SecondaryLayout
