import React, { useEffect, useMemo, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"
import sortBy from "lodash/sortBy"

import { TabsCollection, TabsItem } from "@components/ui/TabsCollection"
import Loader from "@components/ui/Loader/BarLoader"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store/index"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"

import styles from "./SolutionTabsContainer.module.sass"

const SolutionTabsContainer: React.FC = observer(() => {
  const navigate = useNavigate()
  const { solutionsStore } = useStore()
  const { avatar } = useActiveAvatar()

  const { solutionsController } = useController()
  const { isLoading, solutions, setSolution } = solutionsStore
  const { solutionType } = useParams()

  const firstUpdate = useRef(true)

  const tabs = useMemo(() => {
    const list = (solutions ?? []).map((solution) => ({
      name: solution?.key,
      value: solution?.name,
    }))
    return sortBy(list, (it) => it.name)
  }, [solutions])

  useEffect(() => {
    if (isLoading) return

    const tab = solutions?.find((item) => item.key === solutionType)

    if (tab) {
      setSolution(tab)
      return
    }

    navigate("../")
  }, [solutionType, tabs, isLoading])

  useEffect(() => {
    if (avatar?.id) {
      solutionsController.loadAvatarSolutions(avatar.id)
    }
    if (firstUpdate.current) {
      firstUpdate.current = false
    }
  }, [avatar?.id])

  if (isLoading && !solutions) {
    return <Loader fluid size="large" />
  }

  if (!tabs?.length) {
    return (
      <Text variant="h2" className={styles.notAvailable}>
        No Solutions Available
      </Text>
    )
  }

  const handleTabSelect = (type: string) => {
    const tab = solutions?.find((item) => item.key === type)
    if (tab) {
      setSolution(tab)
      navigate(`/solutions/${tab.key}`)
    }
  }

  return (
    <TabsCollection
      className={styles.tabsContainer}
      defaultValue={solutionType}
      onSelect={handleTabSelect}
      items={tabs}
      renderItem={({ item, handleSelect, isActive }) => (
        <TabsItem
          onClick={(e) => handleSelect(item, e)}
          active={isActive}
          key={item?.name}
        >
          {item?.value}
        </TabsItem>
      )}
    />
  )
})

export default SolutionTabsContainer
