import * as yup from "yup"

import { DateRangePresetOption } from "@components/ui/DatePicker/DatePicker"
import {
  getCurrentPeriodRange,
  getLastNDaysRange,
  getLastPeriodRange,
} from "@utils/date"

import { DAY_14_NUMBER } from "./global"

export const randomWordsArray =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse faucibus elementum eros, sit amet ullamcorper velit vehicula at. Quisque libero velit, dignissim ac ornare in, viverra ut est. Aliquam ut nisl lectus. Donec quam erat, porta mollis tristique quis, sollicitudin id nulla. Morbi magna ligula, tempus et mattis ac, tristique porttitor purus. Mauris a ex quam. Etiam posuere ullamcorper eros ac lobortis. Nulla blandit congue erat. Phasellus quis augue eget diam faucibus tincidunt sed ac ligula. Proin semper eros in risus aliquet mattis. Mauris malesuada urna et laoreet pretium. Vivamus erat orci, posuere ut sollicitudin lobortis, dictum et neque. In dictum malesuada egestas. Vivamus ultricies sem at sem mattis interdum.".split(
    " "
  )

/**
 * @example "//192.168.1.112/sgw-2024-05-09-a4fpo", "//192.168.1.112:2344/a/as23~23+235s-_as/d/+234"  etc.
 */
export const baseURLValidator = yup
  .string()
  .matches(
    /^((\/\/)((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/)([a-z/\d\-%_.~+]*)*$/,
    "Format should be the following: \n//{IPv4}[:PORT]/{PATH}"
  )

export const defaultDateRangePresets: DateRangePresetOption<[Date, Date]>[] = [
  {
    name: "This Year",
    value: () => getCurrentPeriodRange("year"),
  },
  {
    name: "This Month",
    value: () => getCurrentPeriodRange("month"),
  },
  {
    name: "Last Year",
    value: () => getLastPeriodRange(1, "year"),
  },
  {
    name: "Last Month",
    value: () => getLastPeriodRange(1, "month"),
  },
  {
    name: "Last Week",
    value: () => getLastPeriodRange(1, "week"),
  },
  {
    name: "Last 14 Days",
    value: () => getLastNDaysRange(DAY_14_NUMBER),
  },
  {
    name: "Last 7 Days",
    value: () => getLastNDaysRange(7),
  },
]
