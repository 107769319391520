import React from "react"
import { observer } from "mobx-react-lite"

import Skeleton from "@components/ui/Skeleton/Skeleton"
import { useStore } from "@store/index"
import List from "@components/ui/List/List"
import NotFound from "@components/ui/NotFound/NotFound"
import ExpertStrip from "@components/ui/UserCard/ExpertStrip"

export const TopExpertList: React.FC = observer(() => {
  const { expertStore } = useStore()
  const { isLoading, experts } = expertStore

  const topExperts = experts?.slice(0, 5) ?? []

  return (
    <List gutter="16">
      {isLoading ? (
        <Skeleton
          count={5}
          lineHeight={60}
          minWidth={100}
          spacing={16}
          rounded
        />
      ) : topExperts.length ? (
        topExperts.map((item) => (
          <ExpertStrip data={item} rating={item.points} key={item.id} />
        ))
      ) : (
        <NotFound>No Experts in this Avatar</NotFound>
      )}
    </List>
  )
})

export default TopExpertList
