import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import Icon from "@components/ui/Icon/Icon"
import DataTypeListItem from "@components/ui/DataTypeCard/DataTypeListItem"
import List from "@components/ui/List/List"
import { useSelectDataTypeContext } from "@pages/upload/PreselectDataTypeContext"
import { useStore } from "@store/index"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import NotFound from "@components/ui/NotFound/NotFound"

import styles from "./DataTypeLink.module.sass"

const DataTypeLink: React.FC = observer(() => {
  const alert = useAlert()
  const { allDatatypesStore } = useStore()

  const { dataTypeId, selectDataType } = useSelectDataTypeContext()

  const dataType = allDatatypesStore.getById(dataTypeId)

  React.useEffect(() => {
    if (!allDatatypesStore.state.isLoading && dataType == null && dataTypeId) {
      selectDataType({ invalidateDefault: true })
      alert.error(
        "Previously chosen Data Type no longer exist or not available for you. Please select another one"
      )
    }
  }, [allDatatypesStore.state.isLoading, dataType, dataTypeId])

  return (
    <List gutter="0">
      {dataType == null ? (
        allDatatypesStore.state.isLoading ? (
          <Skeleton lineHeight={100} minWidth={100} />
        ) : (
          <NotFound>No Data type found</NotFound>
        )
      ) : (
        <DataTypeListItem
          active
          data={dataType}
          onClick={() => selectDataType()}
        >
          {dataType?.name}
        </DataTypeListItem>
      )}

      <div className={styles.linkDivider}>
        <Icon
          name="link"
          title={`Uploaded files will be associated with "${
            dataType?.name ?? dataTypeId
          }" Data Type`}
        />
      </div>
    </List>
  )
})

export default DataTypeLink
