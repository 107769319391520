import { makeAutoObservable } from "mobx"

import { Point, Range } from "@framework/types/common"

import { Parser, ParseResult, tokenize } from "../parser"
import { normalizeFormula } from "../parser/utils"
import { refToRange } from "../utils"

const formulaParser = new Parser()

class CellEditorState {
  // injections

  // state

  input: any

  touched: boolean

  formula: ParseResult | null

  error: string | null

  autoFocusCell: boolean = true

  constructor(options: { input: any; autoFocusCell?: boolean }) {
    this.input = options.input ?? ""
    this.formula = null
    this.error = null
    this.autoFocusCell = options.autoFocusCell ?? true
    this.touched = false

    this.process()

    makeAutoObservable(this)
  }

  get refEntries(): [string, Range<Point>][] {
    const refs = this.formula?.refs ?? []
    return refs.map((it) => [it, refToRange(it)])
  }

  setInput = (value?: string) => {
    this.input = value
    this.touched = true
    this.process()
  }

  process = () => {
    const text = this.input

    this.formula = null
    this.error = null

    if (typeof text !== "string") return

    if (!text?.startsWith("=")) return

    const tokens = tokenize(text.substring(1))

    this.formula = formulaParser.parse(tokens)
  }

  normalize = () => {
    try {
      if (this.formula != null) {
        const normalFormulaStr = getNormalFormulaText(this.formula)

        this.formula = formulaParser.parse(tokenize(normalFormulaStr))
        this.input = `=${normalFormulaStr}`
      }
    } catch (error: any) {
      this.error =
        typeof error.message === "string" ? error.message : "Unexpected error"
    }
    return this.input
  }
}

export default CellEditorState

const getNormalFormulaText = (formula: ParseResult) => {
  if (!formula?.isValid) throw new Error("Formula parsing error")
  return normalizeFormula(formula.matched)
}
