import { makeAutoObservable } from "mobx"

import { QueryHistoryData } from "@framework/types/analytics"
import { PaginationListMeta } from "@framework/types/utils"

const PAGE_SIZE = 10

export default class QueryHistoryStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading = false

  error: string | null = null

  data: QueryHistoryData[] = []

  meta: PaginationListMeta = {
    pageNum: 0,
    pageSize: PAGE_SIZE,
    total: PAGE_SIZE,
    totalPages: 1,
  }

  setData = (data: QueryHistoryData[], meta: PaginationListMeta) => {
    this.data = data
    this.meta = meta
  }

  resetDate = () => {
    this.data = []
  }
}
