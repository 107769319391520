import React from "react"
import { observer } from "mobx-react-lite"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { UserRequest, UserRequestStatuses } from "@framework/types/security"

interface ContextMenuProps {
  userRequest: UserRequest
  onAction: (action: "approve" | "decline") => void
}

const ContextMenu: React.FC<ContextMenuProps> = observer(
  ({ userRequest, onAction }) => {
    const { isOpened, handleToggle, setOpened } = useToggle()

    const disabled = userRequest.status !== UserRequestStatuses.PENDING

    return (
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        disabled={disabled}
        mode="onFocus"
        content={
          <TooltipContainer color="primary" placement="bottom">
            <MenuItem
              icon="user-follow"
              onClick={() => onAction("approve")}
              key="edit"
            >
              Approve
            </MenuItem>
            <MenuItem
              color="red"
              icon="user-unfollow"
              onClick={() => onAction("decline")}
              key="edit"
            >
              Decline
            </MenuItem>
          </TooltipContainer>
        }
      >
        <IconButton
          disabled={disabled}
          size="medium"
          active={isOpened}
          onClick={handleToggle}
        >
          <Icon name="dots" />
        </IconButton>
      </Tooltip>
    )
  }
)

export default ContextMenu
