import { makeAutoObservable } from "mobx"

import { Option } from "@framework/types/utils"

export default class MultiStepStore {
  currentStepIndex: number

  completedStepIndex: number

  steps: Option[]

  constructor(options: { steps: Option[] }) {
    this.currentStepIndex = 0
    this.completedStepIndex = -1
    this.steps = options.steps

    makeAutoObservable(this)
  }

  setStepIndex = (stepIndex: number) => {
    this.currentStepIndex = stepIndex
  }

  setCompletedStepIndex = (stepIndex: number) => {
    this.completedStepIndex = stepIndex
  }

  reset = () => {
    this.currentStepIndex = 0
    this.completedStepIndex = -1
  }
}
