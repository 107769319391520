import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import Templates from "@components/ui/Templates"
import Rating from "@components/ui/Rating/Rating"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"

import styles from "./AnswerFeedbackModal.module.sass"

export interface AnswerFeedbackModalProps {
  onSubmit?: (rating: number, message: string) => Promise<void>
  rating?: number
  onHide?: () => void
}

export const AnswerFeedbackModal: React.FC<AnswerFeedbackModalProps> = observer(
  ({ onSubmit, rating = 3, onHide }) => {
    const { hideModal } = useModal(ModalsTypes.ANSWER_FEEDBACK_MODAL)

    const [feedbackRating, setRating] = React.useState(rating)
    const [feedbackMessage, setMessage] = React.useState("")
    const [isLoading, setLoading] = React.useState(false)

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      setLoading(true)
      await onSubmit?.(feedbackRating, feedbackMessage)
      setLoading(false)
    }

    const handleClose = () => {
      hideModal()
      onHide?.()
    }

    const getRatingText = (rating: number) => {
      switch (rating) {
        case 1:
          return "Not helpful"
        case 2:
          return "Slightly Helpful"
        case 3:
          return "Moderately Helpful"
        case 4:
          return "Very Helpful"
        case 5:
          return "Extremely Helpful"
        default:
          return "Moderately Helpful"
      }
    }

    return (
      <BaseModal
        title={
          <>
            <ModalTitle titleText="Send feedback" />

            <div className={styles.ratings}>
              <span>How helpful was this?</span>
              <Rating
                value={feedbackRating}
                onChange={(newRating) => {
                  setRating(newRating)
                }}
              />
              <span>{getRatingText(feedbackRating)}</span>
            </div>
          </>
        }
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Templates.RollScript
            gutter="24"
            footerSocket={
              <ModalFooterContainer>
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                  type="submit"
                  after={isLoading && <Loader />}
                >
                  Submit
                </Button>
              </ModalFooterContainer>
            }
          >
            <TextAreaInput
              name="feedback_note"
              value={feedbackMessage}
              onInput={(e) => setMessage(e.currentTarget.value)}
              placeholder="Enter your note here (optional)"
              className={styles.input}
              type="textarea"
              autoFocus
            />
          </Templates.RollScript>
        </form>
      </BaseModal>
    )
  }
)

export default AnswerFeedbackModal
