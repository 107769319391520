const SERVICE_TERMS = `<h2>Terms of Service</h2>
  <h3>1. Introduction</h3>
  <p>These Terms of Service (“Terms”) govern your use of our SaaS platform (“Service”). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use the Service.</p>
  
  <h3>2. Use of the Service</h3>
  <p>You are granted a non-exclusive, non-transferable, limited license to access and use the Service in accordance with these Terms. You agree not to misuse the Service or use it in any manner that could damage, disable, overburden, or impair the Service.</p>
  
  <h3>3. User Accounts</h3>
  <p>To access certain features of the Service, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and are fully responsible for all activities that occur under your account.</p>
  
  <h3>4. Data Privacy and Security</h3>
  <p>Your use of the Service is subject to our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using the Service, you consent to the collection and use of your information as described in our Privacy Policy.</p>
  
  <h3>5. Service Availability</h3>
  <p>We strive to keep the Service available at all times. The service terms are subject to SLA agreement that we have with your organization.</p>
  
  <h3>6. Acceptable Use Policy</h3>
  <p>You agree not to use the Service for any unlawful or prohibited activities. This includes, but is not limited to, distributing malware, engaging in phishing, or any activities that violate the intellectual property rights of others.</p>
  
  <h3>7. Intellectual Property</h3>
  <p>All intellectual property rights in and to the Service, including but not limited to software, content, and trademarks, are owned by or licensed to Nesh. The Intellectual property terms are further defined in the Software Agreement that we have with your organization.</p>
  
  <h3>8. Limitation of Liability</h3>
  <p>To the maximum extent permitted by law, Hello Nesh shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of the Service. The Limitation of Liability terms are further defined in the Software Agreement that we have with your organization.</p>
  
  <h3>9. Marketing and Communication</h3>
  <p>By using the Service, you agree to receive communications from Hello Nesh, including marketing and service-related emails. You may opt-out of marketing emails at any time by following the unsubscribe instructions included in these emails. However, you will continue to receive service-related communications necessary for the operation of your account and the Service.</p>
  
  <h3>10. Contact Information</h3>
  <p>If you have any questions about these Terms, please contact us at service@hellonesh.io.</p>
  <br />`

export default SERVICE_TERMS
