import { observer } from "mobx-react-lite"
import React from "react"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { UserAction } from "@framework/types/security"
import { useController, useStore } from "@store/index"

import styles from "./DeleteModal.module.sass"

export interface DeleteUserActionModalProps {
  action: UserAction
}

export const DeleteUserActionModal: React.FC<DeleteUserActionModalProps> =
  observer(({ action }) => {
    const modal = useModal(ModalsTypes.DELETE_USER_ACTION_MODAL)
    const alert = useAlert()

    const { actionsController } = useController()
    const {
      rolesStore: { isLoading },
    } = useStore()

    const handleSubmit = async () => {
      modal.hideModal()
      const error = await actionsController.removeAction(action.id)
      if (error) {
        alert.error(error)
      } else {
        alert.success(
          <>
            User action &quot;<b>{action.name}</b>
            &quot; was successfully deleted
          </>
        )
      }
    }

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={
              <>
                Are you sure you want to delete action &quot;
                <b>{action.name.toUpperCase()}</b>
                &quot;?
              </>
            }
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <ModalFooterContainer>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={modal.hideModal}
          >
            Cancel
          </Button>
          <Button color="red" disabled={isLoading} onClick={handleSubmit}>
            Delete {isLoading && <Loader />}
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  })

export default DeleteUserActionModal
