import { makeAutoObservable } from "mobx"

import QueryAttachment from "../search/query-attachment"

export class RequirementsStore {
  attachments: QueryAttachment[] = []

  requirementText: string = ""

  constructor() {
    makeAutoObservable(this)
  }

  reset = (
    options?: Partial<{
      attachments: QueryAttachment[]
      requirementText: string
    }>
  ) => {
    this.requirementText = options?.requirementText ?? ""
    this.attachments = [...(options?.attachments ?? [])]
  }
}

export default RequirementsStore
