import { observer } from "mobx-react-lite"
import React from "react"

import {
  BinaryExpression,
  Block,
  Constant,
  Formula,
  Function,
  Marker,
  Reference,
  UnaryExpression,
  Unknown,
} from "./types"

import { ParseResult } from "."

const renderBinaryExpression = (range: BinaryExpression): React.ReactNode[] => {
  return [
    ...renderFormula(range.left),
    range.token,
    ...renderFormula(range.right),
  ]
}

const renderUnaryExpression = (range: UnaryExpression): React.ReactNode[] => {
  return [range.token, ...renderFormula(range.value)]
}

export const renderFormula = (formula: Formula | Marker) => {
  if (!("type" in formula)) return [formula.token]

  switch (formula.type) {
    case "un-exp": {
      return renderUnaryExpression(formula)
    }
    case "bin-exp": {
      return renderBinaryExpression(formula)
    }
    case "fun": {
      return renderFunction(formula)
    }
    case "ref": {
      return renderRef(formula)
    }
    case "const": {
      return renderConst(formula)
    }
    case "block": {
      return renderBlock(formula)
    }
    case "unknown": {
      return renderUnknown(formula)
    }

    default:
      return [""]
  }
}

const renderBlock = (formula: Block) => {
  const initial = []

  if (formula.open) initial.push(<span style={{ color: "#cdffcd" }}>(</span>)

  const res = formula.arguments.reduce<React.ReactNode[]>((acc, it, index) => {
    if (index !== 0) acc.push(",")
    acc.push(renderFormula(it))
    return acc
  }, initial)

  if (formula.closed) res.push(<span style={{ color: "#cdffcd" }}>)</span>)

  return res
}

const renderRef = (formula: Reference) => {
  return [
    <span
      style={{
        color: "rgb(255 190 78)",
      }}
    >
      {formula.token}
    </span>,
  ]
}

const renderFunction = (formula: Function) => {
  return [
    <span style={{ color: "#cdffcd" }}>{formula.token}</span>,
    renderBlock(formula.block),
  ]
}

const renderConst = (formula: Constant) => {
  return [<span style={{ color: "lightskyblue" }}>{formula.token}</span>]
}

const renderUnknown = (formula: Unknown) => {
  return [<span style={{ color: "lightgray" }}>{formula.token}</span>]
}

const HighlightedText: React.FC<{ value: string; tree?: ParseResult | null }> =
  observer(({ value, tree }) => {
    if (tree == null) return <>{value}</>
    return (
      <>
        ={renderFormula(tree.matched)}
        {renderFormula(tree.rest)}
      </>
    )
  })

export default HighlightedText
