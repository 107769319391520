import React from "react"
import clsx from "clsx"

import styles from "./Container.module.sass"

interface ContainerProps {
  className?: string
  children?: React.ReactNode
}

export const Container: React.FC<ContainerProps> = React.memo(
  ({ className, children }) => {
    return <div className={clsx(styles.root, className)}>{children}</div>
  }
)

export default Container
