import { normalizeNumber } from "./numberUtils"
import { textToHash } from "./textUtils"

type HSL = { H: number; S: number; L: number }

export const textToHSL = (text: string): HSL => {
  const hash = textToHash(text)
  const H = normalizeNumber(hash, 0, 360)
  const S = normalizeNumber(hash, 25, 50)
  const L = normalizeNumber(hash, 25, 60)
  return { H, S, L }
}

export const HSLtoString = ({ H, S, L }: HSL) => `hsl(${H}, ${S}%, ${L}%)`
