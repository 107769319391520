import { makeAutoObservable } from "mobx"

import { SignUpUserRequestFormType } from "@framework/types/auth"
import {
  UserRequest,
  UserRequestStatuses,
  UserRequestsHash,
} from "@framework/types/security"
import userService from "@services/user.service"
import { arrayToHash } from "@utils/collections"

export class UserRequestStore {
  isUserRequestsCollectionLoading = false

  userRequestsCollection: UserRequestsHash | null = null

  errorMessage: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  loadUsersRequests = async () => {
    try {
      this.errorMessage = null
      this.setUserRequestsCollectionLoading(true)

      const {
        data: { data },
      } = await userService.getAllUserRequests()

      this.userRequestsCollection = arrayToHash<UserRequest, UserRequestsHash>(
        data,
        (userRequest) => ({
          [userRequest.id]: userRequest,
        })
      )
    } catch (error: any) {
      if (error) {
        this.errorMessage = "Loading failed"
      }
    } finally {
      this.setUserRequestsCollectionLoading(false)
    }
  }

  createUsersRequest = async (email: string) => {
    try {
      this.setUserRequestsCollectionLoading(true)
      this.errorMessage = null

      const {
        data: { data },
      } = await userService.createUserRequest(email)

      this.userRequestsCollection = {
        ...(this.userRequestsCollection ?? {}),
        [data.id]: data,
      }
    } catch (error: any) {
      this.errorMessage = "Unexpected error"

      if (error?.response?.data?.message === "USER_REQUEST_ALREADY_EXIST")
        this.errorMessage = "User already requested access"

      if (error?.response?.data?.message === "USER_ALREADY_EXISTS")
        this.errorMessage = "User already exists"
    } finally {
      this.setUserRequestsCollectionLoading(false)
    }
    return this.errorMessage
  }

  signUp = ({ email }: SignUpUserRequestFormType) => {
    return this.createUsersRequest(email)
  }

  private updateUserRequestStatus = async (
    requestId: string,
    status: UserRequestStatuses
  ) => {
    try {
      const {
        data: { data },
      } = await userService.updateUserRequest(requestId, status)

      if (data.status === UserRequestStatuses.PENDING)
        throw new Error("Unexpected error")

      this.userRequestsCollection = Object.entries(
        this.userRequestsCollection ?? {}
      ).reduce<UserRequestsHash>((acc, [reqId, userRequest]) => {
        if (userRequest.id !== data.id) acc[reqId] = userRequest
        return acc
      }, {})
    } catch (error: any) {
      return "Updating user request status failed"
    }
    return null
  }

  updateUserRequest = async (
    requestId: string,
    status: UserRequestStatuses
  ) => {
    try {
      this.setUserRequestsCollectionLoading(true)
      this.errorMessage = null

      this.errorMessage = await this.updateUserRequestStatus(requestId, status)
    } catch (error: any) {
      this.errorMessage = "Unexpected error"
    } finally {
      this.setUserRequestsCollectionLoading(false)
    }
    return this.errorMessage
  }

  updateUserRequests = async (
    requestIds: string[],
    status: UserRequestStatuses
  ) => {
    try {
      this.setUserRequestsCollectionLoading(true)
      this.errorMessage = null

      const result = await Promise.all(
        requestIds.map((id) => this.updateUserRequestStatus(id, status))
      )

      return {
        status: "SUCCESS",
        data: requestIds.map((it, idx) => ({ id: it, error: result[idx] })),
      }
    } catch (error: any) {
      this.errorMessage = "Unexpected error"
    } finally {
      this.setUserRequestsCollectionLoading(false)
    }
    return { status: "FAILED", message: this.errorMessage }
  }

  setUserRequestsCollectionLoading = (isLoading: boolean) => {
    this.isUserRequestsCollectionLoading = isLoading
  }
}

export default UserRequestStore
