import { IconName } from "@components/ui/Icon/IconName"

export type Option<
  T extends string | number = string,
  V = string,
  I extends string = IconName
> = {
  // name means id
  name: T
  // value means label
  value: V
  icon?: I
  className?: string
  coverImage?: string
  isDisabled?: boolean
  description?: string
  index?: string | number
}

export type Primitive =
  | string
  | number
  | boolean
  | null
  | undefined
  | symbol
  | bigint

export type PaginationParams = {
  pageNum: number
  pageSize: number
  query?: string
}

export type VirtualListChunk = {
  offset: number
  limit: number
}

export enum ManagementMode {
  normal = "normal",
  editing = "editing",
  adding = "adding",
  deleting = "deleting",
}

export type PaginationListMeta = {
  pageNum: number
  pageSize: number
  query?: string
  total: number
  totalPages: number
}

export type VirtualListChunkMeta = VirtualListChunk & {
  query?: string
  total: number
}

export type FileCollectionFractions = {
  totalFiles: number
  uploadedFiles: number
  runningFiles: number
  failedFiles: number
}

export type WebsiteCollectionFractions = {
  totalWebsites: number
  uploadedWebsites: number
  runningWebsites: number
  failedWebsites: number
}

export type CloudFoldersCollectionFractions = {
  totalFolders: number
  uploadedFolders: number
  runningFolders: number
  failedFolders: number
}

const DOCUMENT_ICONS_PATH = "/images/document-icons"

export const documentIcon = [
  "img:file",
  "img:txt",
  "img:json",
  "img:docx",
  "img:pdf",
  "img:pptx",
  "img:pub",
  "img:microsoft-sharepoint",
  "img:website",
  "img:web_site_source",
  "img:faq",
  "img:faq_source",
  "img:xlsx",
  "img:azure-storage",
  "img:folder_source",
  "img:folder_empty",
  "img:folder_full",
  "img:box_inc",
  "img:aws_s3",
  "img:email_source",
  "img:cloud_source",
  "img:calendar_source",
  "img:sand_time_source",
  "img:time_source",
  "img:confluence",
  "img:microsoft-teams",
  "img:zendesk",
  "img:one-note",
  "img:slack",
  "img:google-drive",
  "img:salesforce",
  "img:jira-software",
  "img:one-drive",
  "img:thunderbolt_source",
  "img:competitor_intelligence",
  "img:engineering_design",
  "img:maintenance_manual",
  "img:product_handbook",
  "img:regulatory_data",
  "img:safety_data_sheet",
  "img:standards",
  "img:store",
  "img:technical_data_sheets",
  "img:voice_of_customer",
  "img:glossary",
  "img:aws-ec2",
] as const

export type DocumentIconType = (typeof documentIcon)[number]

export const isDocumentIconName = (icon: string): icon is DocumentIconType =>
  documentIcon.includes(icon as DocumentIconType)

export const getDocumentIconSrc = (
  iconName: DocumentIconType | string | undefined,
  defaultIcon: DocumentIconType = "img:website"
) => {
  const name = iconName && isDocumentIconName(iconName) ? iconName : defaultIcon
  return `${DOCUMENT_ICONS_PATH}/${name.replace("img:", "")}.svg`
}

export const colorOptions = [
  "primary",
  "secondary",
  "default",
  "green",
  "gold",
  "red",
] as const

export type ColorType = (typeof colorOptions)[number]
