import React from "react"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { useMultiStep } from "@components/ui/MultiStep/MultiStepContext"

export type InviteUserModalFooterProps = {
  isLoading?: boolean
}

export const InviteUserModalFooter: React.FC<InviteUserModalFooterProps> =
  observer(({ isLoading }) => {
    const { back, isLastStep } = useMultiStep()
    const formik = useFormikContext()

    return (
      <ModalFooterContainer>
        <Button disabled={isLoading} variant="outlined" onClick={() => back()}>
          Back
        </Button>
        <Button
          disabled={isLoading || !formik.isValid}
          color="primary"
          type="submit"
          after={isLoading && <Loader />}
        >
          {isLastStep ? "Send Invite" : "Next"}
        </Button>
      </ModalFooterContainer>
    )
  })

export default InviteUserModalFooter
