import React from "react"

import Text from "@components/ui/Typography/Text"
import { useAppConfig } from "@components/theme/AppConfigProvider"
import Button from "@components/ui/Button/Button"

import styles from "./ComingSoonCard.module.sass"

const ComingSoonCard: React.FC = () => {
  const theme = useAppConfig()
  return (
    <div className={styles.root}>
      <Text variant="h2" color="text100Color" weight="bold">
        Enhancing Experiences
      </Text>

      <div className={styles.description}>
        <Text variant="body2" color="text70Color">
          Our team constantly introduces new solutions
        </Text>
      </div>

      <div className={styles.footer}>
        <Text variant="body2" color="text70Color">
          Have feedback or suggestions?
        </Text>

        <Button
          variant="slim"
          size="small"
          onClick={() => window.open(theme.support.supportPageURL, "_blank")}
        >
          Share through help center
        </Button>
      </div>
    </div>
  )
}

export default ComingSoonCard
