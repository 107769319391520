import React from "react"

import useEvent from "./useEvent"

interface Options {
  value?: string
  onChange?: (newValue: string) => void
}

export type SearchReturnValue = [
  { value: string; onChange: React.ChangeEventHandler<HTMLInputElement> },
  { setQuery: (newValue: string) => void }
]

export const useSearch = ({
  value,
  onChange,
}: Options = {}): SearchReturnValue => {
  const [searchQuery, setQuery] = React.useState(value ?? "")

  const handleChangeValue = useEvent((newValue: string) => {
    setQuery(newValue)
    onChange?.(newValue)
  })

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useEvent(
    (e) => {
      handleChangeValue(e.target?.value ?? "")
    }
  )

  React.useEffect(() => {
    if (value != null && value !== searchQuery) setQuery(value)
  }, [value])

  return [
    { value: searchQuery, onChange: handleChange },
    { setQuery: handleChangeValue },
  ]
}

export default useSearch
