import React from "react"
import { useNavigate } from "react-router-dom"

import Button from "@components/ui/Button/Button"
import Switch from "@components/ui/Switch/Switch"
import Templates from "@components/ui/Templates"
import { Option } from "@framework/types/utils"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"
import EmailsPreview from "../AvatarKnowledgeSection/EmailsPreview"
import ChatsPreview from "../AvatarKnowledgeSection/ChatsPreview"
import MeetingsPreview from "../AvatarKnowledgeSection/MeetingsPreview"

import styles from "./AvailableKnowledge.module.sass"

export const InformalKnowledgeSection: React.FC = () => {
  const navigate = useNavigate()

  const [tab, setTab] = React.useState<string>(tabOptions[0].name)

  const { avatar } = useActiveAvatar()

  const handleShowAll = () =>
    navigate(tab, {
      state: {
        backTo: "/home",
        avatar: avatar?.id,
      },
    })

  return (
    <>
      <Templates.Header
        offsetBottom="middle"
        offsetTop="medium"
        left={
          <Switch
            size="small"
            items={tabOptions}
            checked={tab}
            onChange={setTab}
          />
        }
      />

      {tab === "emails" && <EmailsPreview />}
      {tab === "chats" && <ChatsPreview />}
      {tab === "meetings" && <MeetingsPreview />}

      <div className={styles.footer}>
        <Button variant="outlined" size="small" onClick={handleShowAll}>
          Show other {tab}
        </Button>
      </div>
    </>
  )
}

export default InformalKnowledgeSection

const tabOptions: Option[] = [
  {
    name: "emails",
    value: "Emails",
  },
  {
    name: "chats",
    value: "Chats",
  },
  {
    name: "meetings",
    value: "Meeting Minutes",
  },
]
