import React, { useEffect } from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Loader from "@components/ui/Loader/BarLoader"
import { EmailFormType } from "@framework/types/auth"

import styles from "./EmailForm.module.sass"

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Filed is required")
    .email("Must be a valid email"),
})

interface EmailFormProps {
  isLoading?: boolean
  className?: string // eslint-disable-line
  errors?: EmailFormType
  onSubmit: (form: EmailFormType) => void
}

const EmailForm: React.FC<EmailFormProps> = ({
  errors,
  isLoading,
  onSubmit,
}) => {
  const formik = useFormik<EmailFormType>({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    if (errors) formik.setErrors(errors)
  }, [errors])

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <div className={styles.form}>
        <TextInput
          autoFocus
          name="email"
          placeholder="Email"
          value={formik.values.email}
          withError={!!(formik.touched.email && formik.errors.email)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          after={
            formik.touched.email &&
            formik.errors.email && (
              <div className={styles.after}>
                <ErrorChip
                  message={formik.errors.email}
                  messagePlacement="left"
                />
              </div>
            )
          }
        />
        <Button
          type="submit"
          color="primary"
          onClick={formik.submitForm}
          disabled={isLoading}
          after={isLoading && <Loader />}
        >
          Send
        </Button>
      </div>
    </form>
  )
}

export default EmailForm
