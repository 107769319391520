import { makeAutoObservable, runInAction } from "mobx"

import productsService from "@services/products.service"
import QueryAttachment from "@store/search/query-attachment"
import { Company, ProductThemeType } from "@framework/types/company"
import { GetComparisonReportParams } from "@framework/types/product"
import RestrictionsStore from "@store/restrictions/restrictions.store"

import type RootStore from "../RootStore"
import ProductComparisonStore from "./product-comparison.store"

export default class ProductComparisonController {
  protected productComparisonStore: ProductComparisonStore

  protected restrictionsStore: RestrictionsStore

  constructor(injections: RootStore) {
    this.productComparisonStore = injections.productComparisonStore
    this.restrictionsStore = injections.restrictionsStore

    makeAutoObservable(this)
  }

  generateSummary = () => {
    const { selectedProductsStore, targetCompaniesStore, requirementsStore } =
      this.productComparisonStore

    const store = this.productComparisonStore.summaryStore

    try {
      if (!targetCompaniesStore.selectedCompanyId)
        throw new Error("Target Company is required fo summary")

      const params: GetComparisonReportParams = {
        targetCompanyId: targetCompaniesStore.selectedCompanyId,
        businessUnit: targetCompaniesStore.businessUnit ?? undefined,
        productIds: selectedProductsStore.selectedProducts.map((it) => it.id),
        requirementText: requirementsStore.requirementText,
        requirementFile: requirementsStore.attachments[0]?.file,
        baseCompanyId: targetCompaniesStore.baseCompany.id,
      }

      store.requestSummary(params)
    } catch (error) {
      store.state.error = "Unexpected error"
      store.resetRequest()
    }
  }

  requestBaseProducts = async () => {
    const { targetCompaniesStore, requirementsStore } =
      this.productComparisonStore

    const viewStore = this.productComparisonStore.baseProductsViewStore
    const otherProductsStore = this.productComparisonStore.baseProductsStore
    const recommendedProductsStore =
      this.productComparisonStore.baseRecommendedProductsStore

    try {
      const baseCompanyId = targetCompaniesStore.baseCompany.id

      viewStore.showOther(false)

      await this.loadBaseRecommendedProducts(
        [baseCompanyId],
        [],
        requirementsStore.requirementText,
        requirementsStore.attachments[0]?.file
      )

      otherProductsStore.reset({
        companyIds: [baseCompanyId],
        excludeProductIds: recommendedProductsStore.state.data.map(
          (it) => it.id
        ),
      })
    } catch (error) {
      // TODO
    }
  }

  requestTargetProducts = async () => {
    const { targetCompaniesStore, requirementsStore } =
      this.productComparisonStore

    const viewStore = this.productComparisonStore.targetProductsViewStore
    const otherProductsStore = this.productComparisonStore.targetProductsStore
    const recommendedProductsStore =
      this.productComparisonStore.targetRecommendedProductsStore

    try {
      const baseCompanyId = targetCompaniesStore.baseCompany.id

      viewStore.showOther(false)

      await this.loadTargetRecommendedProducts(
        [],
        [baseCompanyId],
        requirementsStore.requirementText,
        requirementsStore.attachments[0]?.file
      )

      otherProductsStore.reset({
        excludeCompanyIds: [baseCompanyId],
        excludeProductIds: recommendedProductsStore.state.data.map(
          (it) => it.id
        ),
      })
    } catch (error) {
      // TODO
    }
  }

  loadBaseRecommendedProducts = async (
    companyIds: string[],
    excludeCompanyIds: string[],
    requirementText: string,
    requirementFile: File
  ) => {
    const store = this.productComparisonStore.baseRecommendedProductsStore

    try {
      runInAction(() => {
        store.state.isLoading = true
        store.state.data = []
        store.state.error = null
      })

      if (requirementText) {
        const response = await productsService.getRecommendedProducts({
          companyIds,
          excludeCompanyIds,
          requirementText,
          requirementFile,
        })

        runInAction(() => {
          store.state.data = response.data.data
          store.state.total =
            response.data.meta?.total ?? response.data.data.length
        })
      }
    } catch (error) {
      store.state.error = "Unexpected error"
    } finally {
      store.state.isLoading = false
    }
  }

  loadTargetRecommendedProducts = async (
    companyIds: string[],
    excludeCompanyIds: string[],
    requirementText: string,
    requirementFile: File
  ) => {
    const store = this.productComparisonStore.targetRecommendedProductsStore

    try {
      runInAction(() => {
        store.state.isLoading = true
        store.state.data = []
        store.state.error = null
      })

      if (requirementText) {
        const response = await productsService.getRecommendedProducts({
          companyIds,
          excludeCompanyIds,
          requirementText,
          requirementFile,
        })

        runInAction(() => {
          store.state.data = response.data.data
          store.state.total =
            response.data.meta?.total ?? response.data.data.length
        })
      }
    } catch (error) {
      store.state.error = "Unexpected error"
    } finally {
      store.state.isLoading = false
    }
  }

  retrySummaryGeneration = () => {
    this.productComparisonStore.summaryStore.requestReload()
  }

  fetchTargetCompanies = async () => {
    const store = this.productComparisonStore.targetCompaniesStore

    try {
      store.isLoading = true

      const response = await productsService.getTargetCompanies()

      store.setCompanies(response.data)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
  }

  fetchBusinessUnits = async (companyId: string) => {
    const store = this.productComparisonStore.targetCompaniesStore

    try {
      store.isLoading = true
      store.businessUnitList = []

      if (!companyId) return

      const response = await productsService.getBusinessUnitList(companyId)

      store.businessUnitList = response.data.data.map((it) => it.name)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
  }

  fetchCompanyThemes = async (themeType: ProductThemeType = "public") => {
    const store = this.productComparisonStore.companyThemesStore
    const { targetCompaniesStore } = this.productComparisonStore
    try {
      store.isLoading = true

      const { selectedCompanyId, businessUnit } = targetCompaniesStore

      const requestOptions = {
        businessUnit: businessUnit ?? undefined,
        type: themeType,
      }

      if (!selectedCompanyId) {
        store.setThemes([], requestOptions)
        return
      }

      if (store.companyThemes != null && requestOptions.type !== themeType)
        store.setThemes([])

      const response = await productsService.getCompanyThemes(
        selectedCompanyId,
        requestOptions
      )

      store.setThemes(response.data.data, requestOptions)
    } catch (error) {
      store.errorMessage = "Unexpected error"
    } finally {
      store.isLoading = false
    }
  }

  setRequirements = (
    options?: Partial<{
      attachments: QueryAttachment[]
      requirementText: string
    }>
  ) => {
    this.productComparisonStore.requirementsStore.reset(options)
  }

  fetchProduct = async (id: string) => {
    const store = this.productComparisonStore.productStore

    try {
      runInAction(() => {
        store.product = null
        store.isLoading = true
      })

      const response = await productsService.getProductById(id)

      runInAction(() => {
        store.product = response.data.data
        store.isLoading = false
      })
    } catch (error: any) {
      store.error = "Unexpected error"

      if (error.response?.status != null) {
        const { status } = error.response
        if (status === 404) store.error = "Product was removed or never existed"
      }
    } finally {
      store.isLoading = false
    }
  }

  fetchProductFilters = async () => {
    const store = this.productComparisonStore.productFilterStore
    try {
      store.isLoading = true

      const response = await productsService.getProductFilters()

      store.setFilters(response.data)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
  }

  resetSelectedCompany = (options?: { baseCompany: Company }) => {
    this.productComparisonStore.targetCompaniesStore.reset(options?.baseCompany)
  }

  resetSelectedProducts = () => {
    this.productComparisonStore.selectedProductsStore.reset()
  }

  resetRequirements = () => {
    this.productComparisonStore.requirementsStore.reset()
  }

  resetStepper = () => {
    this.productComparisonStore.multiformStore.reset()
  }

  reset = (options?: { baseCompany: Company }) => {
    this.resetStepper()
    this.resetSelectedCompany(options)
    this.resetSelectedProducts()
    this.resetRequirements()
  }
}
