import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import CheckboxWithLabel, {
  CheckboxWithLabelProps,
} from "@components/ui/Checkbox/CheckboxWithLabel"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { useStore } from "@store/index"

import styles from "./FeatureFlagCard.module.sass"

interface FeatureFlagCardProps extends CheckboxWithLabelProps {
  id: string
  onDelete?: React.MouseEventHandler
}

export const FeatureFlagCard: React.FC<FeatureFlagCardProps> = observer(
  ({ id, className, checked, onDelete, ...rest }) => {
    const { featuresStore } = useStore()

    const inUse = featuresStore.isFlagUsed(id)

    return (
      <CheckboxWithLabel
        {...rest}
        className={clsx(styles.root, { [styles.active]: checked }, className)}
        checked={checked}
        after={
          <>
            {inUse && <UsageBadge />}

            {onDelete != null && (
              <Tooltip
                mode="onHover"
                justify="center"
                className={styles.tooltip}
                content={
                  <TooltipContainer
                    color="primary"
                    placement="top"
                    className={styles.tooltipPopup}
                  >
                    Delete Flag
                  </TooltipContainer>
                }
              >
                <IconButton onClick={onDelete}>
                  <Icon name="trash-can" />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
      />
    )
  }
)
export default FeatureFlagCard

const UsageBadge: React.FC = () => {
  return (
    <Tooltip
      mode="onHover"
      justify="center"
      className={styles.tooltip}
      content={
        <TooltipContainer
          color="primary"
          placement="top"
          className={styles.tooltipPopup}
        >
          Flag is used in current version of App
        </TooltipContainer>
      }
    >
      <Icon name="done" color="green" />
    </Tooltip>
  )
}
