import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"

import mainRoutes from "@root/main.routes"
import SecondaryLayout from "@components/layout/SecondaryLayout/SecondaryLayout"
import { useStore } from "@store/index"

import {
  ConfirmCodeForm,
  ConfirmCodeFormType,
} from "../components/ConfirmCodeForm/ConfirmCodeForm"

import styles from "./ConfirmEmail.module.sass"

export type QueryParamsType = {
  prevStep: "login" | "sign-up"
}

interface ConfirmEmailProps {}

const ConfirmEmailPage: React.FC<ConfirmEmailProps> = observer(() => {
  const navigate = useNavigate()
  const { prevStep } = useParams<QueryParamsType>()
  const {
    authStore: {
      confirmation2FAEmail,
      isAuthorized,
      verifyOtpErrors,
      isSignInLoading,
      verifyOTPCode,
      cancelConfirmationData,
    },
  } = useStore()

  // const [isSupportMessageShown, showSupportMessage] = useState(false)

  const email = confirmation2FAEmail ?? ""
  const backLink = prevStep ? `/${prevStep}` : mainRoutes.login()

  const responseErrors: ConfirmCodeFormType | undefined = React.useMemo(
    () =>
      !verifyOtpErrors.otp
        ? undefined
        : {
            verificationCode: verifyOtpErrors.otp,
          },
    [verifyOtpErrors.otp]
  )

  const handleSubmit = React.useCallback(
    (form: ConfirmCodeFormType) => {
      if (!confirmation2FAEmail) return
      verifyOTPCode(confirmation2FAEmail, form.verificationCode)
    },
    [confirmation2FAEmail]
  )

  const handleCancel = () => navigate(backLink, { replace: true })

  React.useEffect(() => {
    if (!confirmation2FAEmail || !prevStep) {
      handleCancel()
      return undefined
    }

    return () => {
      cancelConfirmationData()
    }
  }, [confirmation2FAEmail, prevStep])

  React.useEffect(() => {
    if (isAuthorized)
      navigate(mainRoutes.home(), { replace: true, state: { reason: "LOGIN" } })
  }, [isAuthorized])

  /**
   * hidden till change-email or resend otp code API implemented
   */
  // useEffect(() => {
  //   if (isSupportMessageShown) return undefined
  //   if (responseErrors) {
  //     showSupportMessage(true)
  //     return undefined
  //   }

  //   const timer = setTimeout(() => {
  //     showSupportMessage(true)
  //   }, 20_000)
  //   return () => clearTimeout(timer)
  // }, [responseErrors])

  return (
    <SecondaryLayout className={styles.root} onBack={handleCancel}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Confirm your email</h1>
          <h4>
            We sent verification code to <span>{email}</span>.
            <br />
            Enter the code below
          </h4>
        </div>

        <ConfirmCodeForm
          codeLength={6}
          onSubmit={handleSubmit}
          isLoading={isSignInLoading}
          errors={responseErrors}
        />

        {/* {isSupportMessageShown && (
          <div className={styles.footer}>
            <h4>Have an issues with confirming your email?</h4>
            <Button
              variant="slim"
              color="primary"
              onClick={() => navigate(mainRoutes.changeEmail())}
            >
              Change your email
            </Button>
          </div>
        )} */}
      </div>
    </SecondaryLayout>
  )
})

export default ConfirmEmailPage
