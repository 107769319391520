import { makeAutoObservable, reaction } from "mobx"

import {
  CategorizedTheme,
  CompanyTheme,
  ProductThemeType,
} from "@framework/types/company"

export class CompanyThemesStore {
  constructor() {
    makeAutoObservable(this)
    reaction(
      () => this.companyThemes,
      (themes) => {
        if (!themes) {
          this.categorizedThemes = []
          return
        }
        this.categorizedThemes = this.groupThemesByCategory(themes)
      }
    )
  }

  isLoading: boolean = false

  errorMessage: string = ""

  activeThemeType: ProductThemeType | null = null

  companyThemes: CompanyTheme[] | null = null

  categorizedThemes: CategorizedTheme[] = []

  setThemes = (
    data: CompanyTheme[] | null,
    request?: { type: ProductThemeType }
  ) => {
    this.activeThemeType = request?.type ?? null
    this.companyThemes = data
  }

  groupThemesByCategory(themes: CompanyTheme[]): CategorizedTheme[] {
    return themes.reduce((acc: CategorizedTheme[], theme) => {
      const existingCategory = acc.find((c) => c.category === theme.category)
      if (existingCategory) {
        existingCategory.themes.push(theme)
      } else {
        acc.push({ category: theme.category, themes: [theme] })
      }
      return acc
    }, [])
  }
}

export default CompanyThemesStore
