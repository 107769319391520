import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { useStore } from "@store/index"
import { SolutionData } from "@framework/types/solution"

import SolutionCard from "./SolutionCard"
import ComingSoonCard from "./ComingSoonCard"

import styles from "./AvailableSolutions.module.sass"

type SolutionsCardContainerProps = {
  showHint?: boolean
  className?: string
  onLaunch?: (option: SolutionData) => void
}

const SolutionsCardContainer: React.FC<SolutionsCardContainerProps> = observer(
  ({ className, showHint = false, onLaunch }) => {
    const { solutionsStore } = useStore()

    const { solutions } = solutionsStore

    return (
      <div className={clsx(styles.solutionCardContainer, className)}>
        {solutions &&
          solutions.map((item) => (
            <SolutionCard onClick={onLaunch} solution={item} key={item.id} />
          ))}

        {showHint && <ComingSoonCard />}
      </div>
    )
  }
)

export default SolutionsCardContainer
