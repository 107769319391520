import {
  makeAutoObservable,
  observable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
  when,
} from "mobx"
import { toStream } from "mobx-utils"
import {
  from,
  ObservableInput,
  retry,
  Subscription,
  switchMap,
  tap,
  timer,
} from "rxjs"

import { BasicCloudFolderEntity } from "@framework/types/upload"
import uploadService from "@services/upload.service"
import { VirtualListChunk } from "@framework/types/utils"
import { asyncSearchBy } from "@utils/optionsUtils"

const CHUNK_SIZE = 500
const RETRY_DELAY = 10 * 1000

export class AllExternalCloudFoldersStore {
  private loadingStream$?: Subscription

  // state
  isLoading: boolean

  data: BasicCloudFolderEntity[]

  reloadTrigger: boolean

  errorMessage: string | null

  dataConnectorId: string

  constructor(options: { dataConnectorId: string }) {
    this.isLoading = false

    this.data = []

    this.errorMessage = null

    this.reloadTrigger = false

    this.dataConnectorId = options.dataConnectorId

    makeAutoObservable(this, {
      data: observable.shallow,
      filteredListCache: observable.shallow,
    })

    onBecomeObserved(this, "data", () => {
      this.loadingStream$ = this.initLoadingStream()
    })

    onBecomeUnobserved(this, "data", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    return from(
      toStream(
        () => [this.dataConnectorId, this.reloadTrigger],
        true
      ) as ObservableInput<[string, boolean]>
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            this.isLoading = true
            this.errorMessage = null
          })
        ),
        switchMap(([dataConnectorId]) => from(this.loadAll(dataConnectorId))),
        tap((response) => {
          runInAction(() => {
            this.data = response
            this.isLoading = false
          })
        }),
        retry({
          delay: () => {
            runInAction(() => {
              this.isLoading = false
              this.errorMessage = "Loading Failed"
            })
            return timer(RETRY_DELAY)
          },
        })
      )
      .subscribe()
  }

  filteredListCache?: {
    query: string
    data: BasicCloudFolderEntity[]
  }

  getChunk = async ({
    offset,
    limit,
    query = "",
  }: { query?: string } & VirtualListChunk) => {
    await when(() => !this.isLoading)

    if (this.errorMessage) throw new Error(this.errorMessage)

    if (
      this.filteredListCache == null ||
      this.filteredListCache.query !== query
    ) {
      this.filteredListCache = {
        data: await asyncSearchBy(this.data, query, (it) => it.name),
        query,
      }
    }

    const chunk = this.filteredListCache.data.slice(offset, offset + limit)

    return {
      data: chunk,
      meta: {
        total: this.data.length,
        totalFound: this.filteredListCache.data.length,
        offset,
        limit,
      },
    }
  }

  private loadAll = async (
    dataConnectorId: string
  ): Promise<BasicCloudFolderEntity[]> => {
    const result: BasicCloudFolderEntity[][] = []
    let pageNum = 1
    let lastChunkSize = 0

    let nextPageToken: string | undefined

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await uploadService.getCloudFoldersList(
        dataConnectorId,
        {
          pageNum,
          pageSize: CHUNK_SIZE,
        },
        nextPageToken
      )

      const latestResultItem = result.at(-1)?.at(-1)
      const latestLoadedItem = response.data.data.at(-1)

      if (
        latestResultItem?.cloudFolderId != null &&
        latestLoadedItem?.cloudFolderId != null &&
        latestResultItem.cloudFolderId === latestLoadedItem.cloudFolderId
      )
        throw new Error("Incorrect API usage")

      nextPageToken = response.data.meta.nextPageToken || undefined

      result.push(response.data.data)

      pageNum += 1

      lastChunkSize = response.data.data.length
    } while (lastChunkSize === CHUNK_SIZE)

    return result.flatMap((it) => it)
  }

  refresh = () => {
    this.reloadTrigger = !this.reloadTrigger
  }
}

export default AllExternalCloudFoldersStore
