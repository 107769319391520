import { makeAutoObservable, reaction } from "mobx"

type Options = {
  singleActive?: boolean
}

class CollapsibleList {
  constructor(options?: Options) {
    this.singleActive = options?.singleActive ?? false
    makeAutoObservable(this)

    reaction(
      () => this.singleActive,
      () => {
        if (this.singleActive) this.toggleAll(false)
      }
    )
  }

  /**
   * if true - only one section can be opened at the moment
   */
  singleActive: boolean

  /**
   * flag include means only items from list considered as active
   * flag exclude means that any item considered as active except ones from the list
   */
  mode: "include" | "exclude" = "include"

  list: Set<string> = new Set()

  get isActive() {
    const { list, mode } = this
    return (name: string) =>
      list.has(name) ? mode === "include" : mode === "exclude"
  }

  get isAnyActive() {
    return this.mode === "exclude" || this.list.size > 0
  }

  toggleAll = (open = !this.isAnyActive) => {
    this.mode = open ? "exclude" : "include"
    this.list = new Set()
  }

  toggle = (name: string) => {
    if (!name) throw new Error("Empty name not allowed")
    if (this.list.has(name)) this.list.delete(name)
    else {
      if (this.singleActive) this.list.clear()
      this.list.add(name)
    }
  }

  setSingleActive = (value: boolean) => {
    this.singleActive = value
  }
}

export default CollapsibleList
