import clsx from "clsx"
import React from "react"

import styles from "./ResultContainer.module.sass"

export interface ResultContainerProps {
  title?: string
  titleSticky?: boolean
  control?: React.ReactNode
  footer?: React.ReactNode
  spacing?: "small" | "medium" | "big"
}

const ResultContainer: React.FC<ResultContainerProps> = ({
  title,
  titleSticky,
  children,
  footer,
  control,
  spacing = "medium",
}) => (
  <div className={styles.root}>
    {title != null && (
      <section
        className={clsx(styles.header, { [styles.sticky]: titleSticky })}
      >
        <h4 className={styles.title}>{title}</h4>
        {control}
      </section>
    )}

    <section className={clsx(styles.body, styles[`space-${spacing}`])}>
      {children}
    </section>

    {footer != null && <section className={styles.footer}>{footer}</section>}
  </div>
)

export default ResultContainer
