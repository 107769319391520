import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store/index"
import MultiSelect from "@components/ui/MultiSelect/MultiSelect"
import {
  getSelectOptions,
  getSelectedOptionIds,
} from "@utils/subscriptionUtils"
import {
  SubscriptionOption,
  SubscriptionOptionList,
} from "@framework/types/subscription"

export interface SimpleTableProps {
  label?: string
  optionName: string
}

export const EditSelect: React.FC<SimpleTableProps> = observer(
  ({ optionName, label = "Select an option" }) => {
    const { subscriptionStore } = useStore()
    const { subscriptionController } = useController()
    const { editedSubscriptionData } = subscriptionStore
    const [isLoading, setIsLoading] = useState(false)

    const { availableOption } = subscriptionStore

    const options = getSelectOptions(
      (availableOption as SubscriptionOptionList) || {},
      optionName,
      editedSubscriptionData[
        optionName as keyof typeof editedSubscriptionData
      ] as SubscriptionOption[]
    )

    const handleAddNew = async (newOptionName: string) => {
      setIsLoading(true)
      await subscriptionController.addAvailableOption(newOptionName, optionName)
      setIsLoading(false)
    }

    const selectedOptionIds = getSelectedOptionIds(
      (editedSubscriptionData as any)[optionName] || []
    )

    const onSelectionChange = (selectedItems: string[]) => {
      const updatedData = options.filter((item) =>
        selectedItems.includes(item.id)
      )
      subscriptionController.updateEditData(optionName, updatedData)
    }

    return (
      <MultiSelect
        options={options}
        onAddNew={handleAddNew}
        placeholder={label}
        defaultSelectedItems={selectedOptionIds}
        onSelectionChange={onSelectionChange}
        isLoading={isLoading}
      />
    )
  }
)

export default EditSelect
