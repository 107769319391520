/* eslint-disable no-underscore-dangle */
import { makeAutoObservable } from "mobx"

import { QuestionFeedbackType } from "@framework/constants/search-results"

export class AnswerFeedbackStore {
  isQuestionsLoading: boolean = false

  isFavoritesLoading: boolean = false

  favoritesLoadingError: string | null = null

  isFavoritesInit: boolean = false

  constructor(options: { feedback: QuestionFeedbackType; reason: string }) {
    makeAutoObservable(this)

    this.type = options.feedback
    this.comments = options.reason
  }

  isLoading: boolean = false

  type: QuestionFeedbackType

  comments: string | null = null

  data: string | null = null

  error: string | null = null
}

export default AnswerFeedbackStore
