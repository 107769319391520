/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import classnames from "clsx"
import React, { useEffect, useRef, useState } from "react"

import styles from "./Tooltip.module.scss"

export type TooltipProps = {
  disabled?: boolean
  mode?: "onHover" | "onFocus"
  justify?: "start" | "center" | "end"
  show?: boolean
  content: React.ReactNode
  className?: string
  contentClassName?: string
  onChange?: (isOpened: boolean) => void
  onClose?: () => void
}

export const Tooltip: React.FC<TooltipProps> = ({
  disabled,
  show,
  mode = "onHover",
  justify = "center",
  children,
  content,
  className,
  onChange,
  onClose,
}) => {
  const element = useRef<HTMLDivElement | null>(null)

  const [opened, setOpen] = useState(false)

  const handleChange = useRef((isOpened: boolean) => {
    onChange?.(isOpened)
    setOpen(isOpened)
    if (!isOpened) onClose?.()
  }).current

  useEffect(() => {
    if (disabled) setOpen(false)

    if (mode !== "onFocus") return

    const handleClick = (e: MouseEvent) => {
      if (disabled) {
        handleChange(false)
        return
      }

      if (element.current && element.current.contains(e.target as Node)) {
        handleChange(true)
        return
      }

      handleChange(false)
    }

    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [mode, disabled, handleChange])

  const renderAllowed = opened && !disabled

  const props =
    mode === "onHover"
      ? {
          onMouseEnter: () => handleChange(true),
          onMouseLeave: () => {
            handleChange(false)
          },
        }
      : {
          onClick: (e: any) => e.stopPropagation(),
        }

  useEffect(() => {
    if (show !== undefined) handleChange(show)
  }, [show])

  return (
    <div
      className={classnames(
        styles.root,
        styles[`justify-${justify}`],
        className
      )}
      {...props}
      ref={element}
    >
      {children}
      {renderAllowed ? content : null}
    </div>
  )
}

export default Tooltip
