import React from "react"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ExpertTopicDTO } from "@framework/types/knowledge"

import EditTopicForm from "./EditTopicForm"

export interface EditTopicModalProps {
  initialValue?: ExpertTopicDTO
  onSubmit?: (data: any) => Promise<void>
}

export const EditTopicModal: React.FC<EditTopicModalProps> = observer(
  ({ initialValue, onSubmit }) => {
    const { hideModal } = useModal(ModalsTypes.EDIT_EXPERT_TOPIC_MODAL)

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={initialValue == null ? "Add Topic" : "Edit Topic"}
          />
        }
        onClose={hideModal}
      >
        <EditTopicForm
          initialValue={initialValue}
          onSubmit={onSubmit}
          onCancel={hideModal}
        />
      </BaseModal>
    )
  }
)

export default EditTopicModal
