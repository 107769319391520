import React from "react"

import UsersBubbles from "@components/ui/UsersBubbles/UsersBubbles"

import { ExpertUserData } from "./ExpertSearch"

import styles from "./AssignExpertToQuestionModal.module.sass"

interface ExpertsPreviewProps {
  value: string[]
  experts: ExpertUserData[]
  onClick: () => void
}

const ExpertsPreview: React.FC<ExpertsPreviewProps> = ({
  onClick,
  value,
  experts,
}) => {
  const selectedExperts = React.useMemo(() => {
    const expertMap = new Map(experts.map((it) => [it.id, it]))
    return value.reduce<ExpertUserData[]>((acc, it) => {
      const expert = expertMap.get(it)
      if (expert != null) acc.push(expert)
      return acc
    }, [])
  }, [value, experts])

  return (
    <UsersBubbles
      onClick={onClick}
      className={styles.expertsSummary}
      prefix={
        selectedExperts.length === 1
          ? selectedExperts[0].email
          : `${selectedExperts.length} selected`
      }
      users={selectedExperts}
    />
  )
}

export default ExpertsPreview
