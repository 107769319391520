import React from "react"
import clsx from "clsx"

import { ColorType } from "@framework/types/utils"

import styles from "./Tooltip.module.scss"

export type PlacementType = "top" | "right" | "bottom" | "left"

export type TooltipContainerProps = {
  tip?: "center" | "start" | "end"
  placement?: PlacementType
  color?: ColorType
  className?: string
  contentClassName?: string
  margin?: string
  width?: string
}

export const TooltipContainer: React.FC<TooltipContainerProps> = ({
  placement = "bottom",
  tip = "center",
  color = "default",
  margin,
  children,
  className,
  contentClassName,
  width,
}) => (
  <div
    className={clsx(
      styles.wrapper,
      styles[`position-${placement}`],
      contentClassName
    )}
    style={{ "--tooltip-padding": margin, width } as any}
  >
    <div
      className={clsx(
        styles.body,
        styles[`alignment-${tip}`],
        styles[`color-${color}`],
        className
      )}
    >
      <span className={styles.tip} />
      {children}
    </div>
  </div>
)

export default TooltipContainer
