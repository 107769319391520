import { makeAutoObservable } from "mobx"

import userService from "@services/user.service"
import { PaginationParams } from "@framework/types/utils"
import { SimpleInviteUserFormType } from "@framework/types/auth"
import defaultErrorHandler from "@store/utils/error-handlers"

import AdminUsersStore from "./admin-users.store"

const EXISTING_EMAIL_ERROR =
  "There is an existing user with this email address. Please try a different email address."

export class AdminUsersController {
  usersStore: AdminUsersStore

  constructor(injections: { adminUsersStore: AdminUsersStore }) {
    this.usersStore = injections.adminUsersStore
    makeAutoObservable(this)
  }

  loadActiveUsersPage = async (options: PaginationParams) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      const response = await userService.getAdminUsers(options)

      if (response.data.data) {
        this.usersStore.updateUsers(response.data.data, response.data.meta)
        return
      }
    } catch (error: any) {
      this.usersStore.setError(defaultErrorHandler(error, "loading users"))
    } finally {
      this.usersStore.setLoading(false)
    }
  }

  inviteUser = async (form: SimpleInviteUserFormType) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      await userService.inviteUser(form)
    } catch (error: any) {
      const { message } = error.response.data
      if (message === "USER_ALREADY_EXIST_WITH_EMAIL") {
        this.usersStore.setError(EXISTING_EMAIL_ERROR)
      } else {
        this.usersStore.setError(defaultErrorHandler(error, "inviting user"))
      }
    } finally {
      this.usersStore.setLoading(false)
    }
    return this.usersStore.error
  }

  removeUser = async (userID: string) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      await userService.deleteUser(userID)
      this.usersStore.resetPages()
    } catch (error) {
      this.usersStore.setError(defaultErrorHandler(error, "deleting user"))
    } finally {
      this.usersStore.setLoading(false)
    }
    return this.usersStore.error
  }

  changeUserRoles = async (userID: string, userRoleIds: string[]) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      const response = await userService.updateUserRoles(userID, {
        userRoleIds,
      })

      this.usersStore.updateUser(response.data.data)
    } catch (error) {
      this.usersStore.setError(
        defaultErrorHandler(error, "updating user roles")
      )
    } finally {
      this.usersStore.setLoading(false)
    }
    return this.usersStore.error
  }

  changeUserPassword = async (userID: string, password: string) => {
    try {
      this.usersStore.setLoading()
      this.usersStore.setError()

      await userService.changeUserPassword(userID, {
        password,
      })
    } catch (error) {
      this.usersStore.setError(defaultErrorHandler(error, "updating user"))
    } finally {
      this.usersStore.setLoading(false)
    }
    return this.usersStore.error
  }
}

export default AdminUsersController
