import { Option } from "../framework/types/utils"
import { initArray } from "./numberUtils"

export const optionByName = (options: Option[], name: string) => {
  const idx = options.findIndex(({ name: itemName }) => itemName === name)
  return { index: idx, option: options[idx] }
}

export const filterOptions = (options: Option[], query: string) => {
  if (!query) return [...options]
  return options.filter((it) =>
    it.value.toLowerCase().includes(query.toLocaleLowerCase())
  )
}

export const findOption = <T extends string>(
  options: Option<T, any>[],
  name?: string | null
): Option<T, any> | undefined => {
  if (name == null) return undefined
  return options.find((it) => it.name.toLowerCase() === name.toLowerCase())
}

export const searchBy = <T>(
  list: T[],
  query: string | null | undefined,
  getter: (item: T) => string
): T[] => {
  if (!query) return [...list]
  const validQuery = query.trim().toLowerCase()
  return list.filter((it) => getter(it).toLowerCase().includes(validQuery))
}

const asyncSearchChunk = <T>(
  list: T[],
  query: string,
  getter: (item: T) => string,
  chunkIndex: number,
  chunkSize: number
) => {
  return new Promise<T[]>((resolve) => {
    setTimeout(() => {
      const res: T[] = []

      const offset = chunkIndex * chunkSize

      for (let j = 0; j < chunkSize; j += 1) {
        if (offset + j >= list.length) break

        const item = list[offset + j]

        if (getter(item).toLowerCase().includes(query)) res.push(item)
      }

      resolve(res)
    }, 0)
  })
}

export const asyncSearchBy = async <T>(
  list: T[],
  query: string | null | undefined,
  getter: (item: T) => string,
  chunkSize: number = 10_000
): Promise<T[]> => {
  if (!query) return [...list]
  const validQuery = query.trim().toLowerCase()

  const chunks = Math.ceil(list.length / chunkSize)

  const res: T[][] = await Promise.all(
    initArray(chunks, (i) =>
      asyncSearchChunk(list, validQuery, getter, i, chunkSize)
    )
  )

  return res.flatMap((it) => it)
}
