import { makeAutoObservable } from "mobx"

import ExpertArticleInsightsStore from "./article-insights.store"
import ExpertSubtopicStore from "./expert-active-subtopic.store"
import ExpertArticlesStore from "./expert-articles.store"
import ExpertSubtopicsStore from "./expert-subtopics.store"
import ExpertTopicsStore from "./expert-topics.store"

export default class ExpertInsightsStore {
  constructor() {
    makeAutoObservable(this)

    this.topicsStore = new ExpertTopicsStore()

    this.subtopicStore = new ExpertSubtopicStore()

    this.subtopicsStore = new ExpertSubtopicsStore()

    this.articlesStore = new ExpertArticlesStore()

    this.articleInsightsStore = new ExpertArticleInsightsStore()
  }

  topicsStore: ExpertTopicsStore

  subtopicStore: ExpertSubtopicStore

  subtopicsStore: ExpertSubtopicsStore

  articlesStore: ExpertArticlesStore

  articleInsightsStore: ExpertArticleInsightsStore
}
