import { makeAutoObservable, runInAction, when } from "mobx"

import analyticsService from "@services/analytics.service"
import AvatarsStore from "@store/avatars/avatars.store"
import { PaginationParams } from "@framework/types/utils"
import { QueryHistoryFilters } from "@framework/types/analytics"
import {
  DateRange,
  FailResponse,
  SuccessResponse,
} from "@framework/types/common"

import UsageAnalyticsStore from "./usage-analytics.store"

export default class AnalyticsController {
  usageAnalyticsStores: UsageAnalyticsStore

  avatarsStore: AvatarsStore

  private abortControllers: AbortController[] = []

  private queryHistoryAbortController: AbortController = new AbortController()

  constructor(injections: {
    usageAnalyticsStores: UsageAnalyticsStore
    avatarsStore: AvatarsStore
  }) {
    makeAutoObservable(this)

    this.usageAnalyticsStores = injections.usageAnalyticsStores

    this.avatarsStore = injections.avatarsStore
  }

  cancelPreviousRequest() {
    this.abortControllers.forEach((controller) => controller.abort())
    this.abortControllers = []
  }

  loadUsageSummary = async (startDate: string, endDate: string) => {
    const store = this.usageAnalyticsStores.summaryStore
    const abortController = new AbortController()
    this.abortControllers.push(abortController)
    try {
      await when(() => !store.isLoading)

      runInAction(() => {
        store.isLoading = true
        store.error = null
        store.data = null
      })

      const response = await analyticsService.loadQuestionsStatistic(
        {
          startDate,
          endDate,
        },
        abortController?.signal
      )

      store.setData(response.data)
    } catch (e) {
      if (!abortController.signal?.aborted) {
        store.error = "Unexpected error"
        store.resetData()
      }
    } finally {
      store.isLoading = false
    }
  }

  loadUserTrendData = async (startDate?: string, endDate?: string) => {
    const store = this.usageAnalyticsStores.userTrendsStore
    const abortController = new AbortController()
    this.abortControllers.push(abortController)
    try {
      await when(() => !store.isLoading)

      runInAction(() => {
        store.isLoading = true
        store.error = null
        store.data = []
      })

      const response = await analyticsService.loadUserTrendReport(
        startDate,
        endDate,
        abortController.signal
      )

      store.setData(response.data ?? [])
    } catch (e) {
      if (!abortController.signal?.aborted) {
        store.error = "Unexpected error"
        store.resetData()
      }
    } finally {
      store.isLoading = false
    }
  }

  loadSearchingTrendData = async (startDate?: string, endDate?: string) => {
    const store = this.usageAnalyticsStores.searchingTrendsStore
    const abortController = new AbortController()
    this.abortControllers.push(abortController)
    try {
      await when(() => !store.isLoading)

      runInAction(() => {
        store.isLoading = true
        store.error = null
        store.data = []
      })

      const response = await analyticsService.loadSearchingTrendReport(
        {
          startDate,
          endDate,
        },
        abortController.signal
      )

      store.setData(response.data ?? [])
    } catch (e) {
      if (!abortController.signal?.aborted) {
        store.error = "Unexpected error"
        store.resetData()
      }
    } finally {
      store.isLoading = false
    }
  }

  loadQueryHistoryData = async (
    filters?: QueryHistoryFilters,
    meta?: PaginationParams
  ) => {
    this.queryHistoryAbortController.abort()
    this.queryHistoryAbortController = new AbortController()
    const store = this.usageAnalyticsStores.queryHistoryStore
    try {
      store.isLoading = true
      store.error = null

      const response = await analyticsService.loadQueryHistoryReport(
        meta,
        {
          ...filters,
        },
        this.queryHistoryAbortController.signal
      )
      store.setData(response.data.data ?? [], response.data.meta)
    } catch (e) {
      if (!this.queryHistoryAbortController.signal?.aborted) {
        store.error = "Unexpected error"
        store.resetDate()
      }
    } finally {
      store.isLoading = false
    }
  }

  avatarIdsToNames = (avatarIds: string[]) => {
    return avatarIds.map((avatarId) => {
      const avatar = this.avatarsStore.getAvatarById(avatarId)
      if (avatar == null)
        throw new Error(`Can't find avatar by id: ${avatarIds}`)
      return avatar.name
    })
  }

  getAnalyticsReportDataAsBlob = async (
    period: DateRange
  ): Promise<SuccessResponse<Blob> | FailResponse> => {
    const store = this.usageAnalyticsStores.reportStore

    try {
      store.isLoading = true
      store.error = null

      const response = await analyticsService.downloadUsageAnalyticsReport(
        period
      )

      store.setData(response.data)

      return {
        data: response.data,
        status: "SUCCESS",
      }
    } catch (e) {
      store.error = "Unexpected error"
      store.resetData()
    } finally {
      store.isLoading = false
    }
    return {
      message: store.error,
      status: "FAILED",
    }
  }
}
