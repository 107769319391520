import { makeAutoObservable } from "mobx"

import { AppUsageSummaryData } from "@framework/types/analytics"

export default class UsageAnalyticsSummaryStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading = false

  error: string | null = null

  data: AppUsageSummaryData | null = null

  setData = (data: AppUsageSummaryData | null) => {
    this.data = data
  }

  resetData = () => {
    this.data = null
  }
}
