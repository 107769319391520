import { makeAutoObservable } from "mobx"
import isUndefined from "lodash/isUndefined"
import omitBy from "lodash/omitBy"

import userService from "@services/user.service"

import ApiKeysStore from "./api-keys.store"
import RootStore from "../RootStore"
import defaultErrorHandler from "../utils/error-handlers"

export default class ApiKeysController {
  apiKeysStore: ApiKeysStore

  constructor(injections: RootStore) {
    this.apiKeysStore = injections.apiKeysStore
    makeAutoObservable(this)
  }

  protected loadAllApiKeys = async () => {
    const response = await userService.getAllApiKeys()

    this.apiKeysStore.setApiKeys(response.data.data)
  }

  init = async () => {
    try {
      this.apiKeysStore.setLoading()

      await this.loadAllApiKeys()
    } catch (error) {
      this.apiKeysStore.setError(defaultErrorHandler(error))
    } finally {
      this.apiKeysStore.setLoading(false)
    }
    return this.apiKeysStore.error
  }

  createApiKey = async (params: { name: string; permissions: string[] }) => {
    try {
      this.apiKeysStore.setLoading()

      const response = await userService.createApiKey(params)

      this.apiKeysStore.setApiKeys([
        ...this.apiKeysStore.apiKeys,
        response.data.data,
      ])

      this.apiKeysStore.setCreatedApiKey(response.data.data)
    } catch (error) {
      this.apiKeysStore.setError(defaultErrorHandler(error))
    } finally {
      this.apiKeysStore.setLoading(false)
    }
  }

  deleteApiKey = async (id: string) => {
    try {
      this.apiKeysStore.setLoading()

      await userService.deleteApiKey(id)

      this.apiKeysStore.setApiKeys(
        this.apiKeysStore.apiKeys.filter((key) => key.id !== id)
      )
    } catch (error) {
      this.apiKeysStore.setError(defaultErrorHandler(error))
    } finally {
      this.apiKeysStore.setLoading(false)
    }
  }

  updateApiKey = async (
    id: string,
    data: {
      name?: string
      addPermissions?: string[]
      removePermissions?: string[]
    }
  ) => {
    try {
      this.apiKeysStore.setLoading()

      const response = await userService.updateApiKey(
        id,
        omitBy(data, isUndefined)
      )

      this.apiKeysStore.setApiKeys(
        this.apiKeysStore.apiKeys.map((key) =>
          key.id === id
            ? {
                ...key,
                ...response.data.data,
              }
            : key
        )
      )
    } catch (error) {
      this.apiKeysStore.setError(defaultErrorHandler(error))
    } finally {
      this.apiKeysStore.setLoading(false)
    }
  }
}
