import { makeAutoObservable } from "mobx"

import ExpertAnswersListStore from "./expert-answers-list.store"

export default class AvatarExpertKnowledgeStore {
  constructor() {
    makeAutoObservable(this)
  }

  answerCollectionsMap: Map<string, ExpertAnswersListStore> = new Map()

  getAnswersCollection = (avatarId: string) => {
    if (!this.answerCollectionsMap.has(avatarId))
      this.resetAnswersCollections(avatarId)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.answerCollectionsMap.get(avatarId)!
  }

  resetAnswersCollections = (collectionKey: string) => {
    this.answerCollectionsMap.set(collectionKey, new ExpertAnswersListStore())
  }
}
