import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import Text from "@components/ui/Typography/Text"
import { CompanyTheme } from "@framework/types/company"
import Templates from "@components/ui/Templates"
import { getCategoryIcon } from "@framework/constants/solutions"
import Icon from "@components/ui/Icon/Icon"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"

import styles from "./ProductThemesModal.module.sass"

export interface ProductThemesModalProps {
  category?: string
  themes: CompanyTheme[]
}

export const ProductThemesModal: React.FC<ProductThemesModalProps> = observer(
  ({ category = "Themes", themes }) => {
    const { hideModal } = useModal(ModalsTypes.PRODUCT_THEMES_MODAL)

    return (
      <BaseModal
        className={styles.root}
        title={
          <>
            <Icon
              circle
              color="primary"
              className={styles.icon}
              name={getCategoryIcon(category)}
            />
            <ModalTitle titleText={category} />
          </>
        }
        onClose={hideModal}
      >
        <Templates.RollScript
          gutter="24"
          footerSocket={
            <ModalFooterContainer>
              <Button variant="outlined" onClick={hideModal}>
                Ok
              </Button>
            </ModalFooterContainer>
          }
        >
          <Text className={styles.body} variant="caption1" color="text70Color">
            <ul className={styles.list}>
              {themes.map((theme) => (
                <li className={styles.item} key={theme.id}>
                  {theme.content}
                </li>
              ))}
            </ul>
          </Text>
        </Templates.RollScript>
      </BaseModal>
    )
  }
)

export default ProductThemesModal
