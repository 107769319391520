/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import Collapsable from "@components/ui/Collapsable/Collapsable"
import Icon from "@components/ui/Icon/Icon"

import styles from "./Dropdown.module.sass"

export interface DropdownProps {
  before?: React.ReactNode
  after?: React.ReactNode
  icon?: React.ReactNode
  title?: string
  className?: string
  containerClassName?: string
  headerClassName?: string
  opened?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const Dropdown: React.FC<DropdownProps> = ({
  before,
  title,
  after,
  icon,
  children,
  className,
  headerClassName,
  containerClassName,
  opened = false,
  onClick,
}) => (
  <div className={clsx(styles.root, className)}>
    <button
      type="button"
      className={clsx(styles.header, headerClassName)}
      onClick={onClick}
    >
      <span>{before}</span>
      {icon}
      <span>{title}</span>
      {after ?? (
        <Icon
          name="arrow-down"
          className={styles.arrow}
          rotateAngle={opened ? -180 : 0}
        />
      )}
    </button>
    <Collapsable
      open={opened}
      className={clsx(styles.container, containerClassName)}
    >
      {children}
    </Collapsable>
  </div>
)

export default Dropdown
