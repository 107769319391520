import { AxiosResponse } from "axios"

import HttpService, { StreamEvents } from "@services/http.service"
import QueryAttachment from "@store/search/query-attachment"

export type CreateChatAttachmentResponse = {
  id: string
  sessionId: string
}

class ChatAPI extends HttpService {
  search = (
    params: {
      query: string
      avatar: string
      sessionId: string
      productSolutionId: string
    },
    events: StreamEvents
  ): Promise<void> => {
    return this.postJSONStream("ts/chat/query", params, events, true)
  }

  createAttachment = (params: {
    attachment: QueryAttachment
    sessionId?: string
  }): Promise<AxiosResponse<CreateChatAttachmentResponse>> => {
    const data = new FormData()
    data.set("attachment", params.attachment.file)

    if (params.sessionId) {
      data.set("sessionId", params.sessionId)
    }

    return this.post("ts/chat/attachment", data, true)
  }

  deleteAttachment = (params: {
    id: string
    sessionId: string
  }): Promise<AxiosResponse> => {
    return this.delete("ts/chat/attachment", params, true)
  }
}

export default new ChatAPI()
