import { makeAutoObservable } from "mobx"

import SearchingTrendsStore from "./searching-trends.store"
import QueryHistoryStore from "./query-history.store"
import UsageAnalyticsSummaryStore from "./usage-analytics-summary.store"
import UserTrendsStore from "./user-trends-report.store"
import ActiveUsersVirtualCollectionStore from "./active-users-virtual-list"
import UsageAnalyticsReportStore from "./analytics-report.store"

export default class UsageAnalyticsStore {
  summaryStore: UsageAnalyticsSummaryStore

  userTrendsStore: UserTrendsStore

  searchingTrendsStore: SearchingTrendsStore

  queryHistoryStore: QueryHistoryStore

  activeUsersStore: ActiveUsersVirtualCollectionStore

  reportStore: UsageAnalyticsReportStore

  constructor() {
    makeAutoObservable(this)

    this.summaryStore = new UsageAnalyticsSummaryStore()
    this.queryHistoryStore = new QueryHistoryStore()
    this.userTrendsStore = new UserTrendsStore()
    this.searchingTrendsStore = new SearchingTrendsStore()
    this.activeUsersStore = new ActiveUsersVirtualCollectionStore()
    this.reportStore = new UsageAnalyticsReportStore()
  }
}
