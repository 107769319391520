import { makeAutoObservable } from "mobx"

import appService from "@services/app.service"

import FeaturesStore from "./features.store"
import AppStore from "./app.store"

export class AppConfigController {
  featuresStore: FeaturesStore

  appStore: AppStore

  constructor(injections: {
    featuresStore: FeaturesStore
    appStore: AppStore
  }) {
    makeAutoObservable(this)

    this.featuresStore = injections.featuresStore
    this.appStore = injections.appStore
  }

  isLoading = false

  error: string | null = null

  loadFeatureFlags = async () => {
    try {
      this.featuresStore.isLoading = true
      this.featuresStore.error = null

      const resp = await appService.getAllFeatureFlags()

      this.featuresStore.storeFeatures(resp.data.data ?? [])
    } catch (error) {
      this.featuresStore.error = null
    } finally {
      this.featuresStore.isLoading = false
    }
    return this.featuresStore.error
  }

  addFeatureFlag = async (name: string, defaultValue = false) => {
    try {
      this.featuresStore.isLoading = true
      this.featuresStore.error = null

      await appService.createFeatureFlag({
        key: name,
        value: defaultValue,
      })
    } catch (error) {
      this.featuresStore.error = null
    } finally {
      this.featuresStore.isLoading = false
    }
    return this.featuresStore.error
  }

  updateFeatureFlag = async (flagId: string, value: boolean) => {
    try {
      this.featuresStore.error = null

      this.featuresStore.toggleFlag(flagId, value)
      const resp = await appService.editFeatureFlag(flagId, value)
      const flag = resp.data.data
      this.featuresStore.toggleFlag(flag.id, flag.value)
    } catch (error) {
      this.featuresStore.error = null
    }
    return this.featuresStore.error
  }

  deleteFeatureFlag = async (flagId: string) => {
    try {
      this.featuresStore.error = null

      await appService.removeFeatureFlag(flagId)
    } catch (error) {
      this.featuresStore.error = null
    }
    return this.featuresStore.error
  }

  loadInstanceConfig = async () => {
    const store = this.appStore.uiConfigStore
    try {
      store.isLoading = true
      store.error = null

      const response = await appService.getUIThemeConfiguration()

      store.updateConfig(response.data.data)
    } catch (error) {
      store.error = "Unexpected Error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  loadAllInstanceConfigurations = async () => {
    const store = this.appStore.instanceConfig
    try {
      store.isLoading = true
      store.error = null

      const response = await appService.getAllConfigurations()

      store.updateConfig(response.data.data ?? [])
    } catch (error) {
      store.error = "Unexpected Error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  updateInstanceConfiguration = async (
    configId: string,
    key: string,
    value: object
  ) => {
    const store = this.appStore.instanceConfig
    try {
      store.isLoading = true
      store.error = null

      await appService.updateConfiguration(configId, key, value)
    } catch (error) {
      store.error = "Unexpected Error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  createInstanceConfiguration = async (key: string, value: object) => {
    const store = this.appStore.instanceConfig
    try {
      store.isLoading = true
      store.error = null

      await appService.createConfiguration(key, value)
    } catch (error) {
      store.error = "Unexpected Error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  removeInstanceConfigurationById = async (configId: string) => {
    const store = this.appStore.instanceConfig
    try {
      store.isLoading = true
      store.error = null

      await appService.deleteConfigurationById(configId)
    } catch (error) {
      store.error = "Unexpected Error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }
}

export default AppConfigController
