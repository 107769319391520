import clamp from "lodash/clamp"
import { makeAutoObservable } from "mobx"

import { AxisName } from "../types"

class Resizer {
  active: boolean = false

  axis: AxisName = "x"

  initialPosition: number = 0

  shift: number = 0

  cellIndex: number = 0

  maxNegativeShift: number = 0

  maxPositiveShift: number = 0

  constructor() {
    makeAutoObservable(this)
  }

  start = (
    axis: AxisName,
    cellIndex: number,
    initialPosition: number,
    maxNegativeShift: number,
    maxPositiveShift: number
  ) => {
    this.axis = axis
    this.cellIndex = cellIndex
    this.initialPosition = initialPosition
    this.maxNegativeShift = -maxNegativeShift
    this.maxPositiveShift = maxPositiveShift

    this.shift = 0
    this.active = true
  }

  update = (newPosition: number) => {
    this.shift = clamp(
      newPosition - this.initialPosition,
      this.maxNegativeShift,
      this.maxPositiveShift
    )
  }

  end = (callback?: EndCallback) => {
    if (this.active) {
      this.active = false
      callback?.(this.axis, this.cellIndex, this.shift)
    }
  }
}

export default Resizer

type EndCallback = (axis: AxisName, cellIndex: number, shift: number) => void
