import React from "react"
import { useFormik } from "formik"
import * as yup from "yup"

import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Loader from "@components/ui/Loader/BarLoader"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"

import styles from "./EditFlagForm.module.sass"

export const validationSchema = yup.object({
  name: yup
    .string()
    .required("Filed is required")
    .matches(
      /^[a-zA-Z]+$/,
      "Must be a single word without digits or special characters"
    )
    .min(3, "Must be at least 3 chars length")
    .max(32, "Must be 32 chars maximum")
    .default("")
    .trim(),

  defaultValue: yup.bool().default(false),
})

export type FormData = yup.InferType<typeof validationSchema>

interface EditFlagFormProps {
  isLoading?: boolean
  className?: string
  initialValues?: FormData
  onSubmit: (form: FormData) => void
}

const EditFlagForm: React.FC<EditFlagFormProps> = ({
  isLoading,
  initialValues = validationSchema.getDefault(),
  onSubmit,
}) => {
  const formik = useFormik<FormData>({
    initialValues,
    validationSchema,
    onSubmit: (form: FormData) => {
      onSubmit(validationSchema.cast(form))
    },
  })

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <div className={styles.form}>
        <TextInput
          autoFocus
          name="name"
          placeholder="Enter flag key..."
          value={formik.values.name}
          withError={!!(formik.touched.name && formik.errors.name)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          after={
            formik.touched.name &&
            formik.errors.name && (
              <div className={styles.after}>
                <ErrorChip
                  message={formik.errors.name}
                  messagePlacement="left"
                />
              </div>
            )
          }
        />

        <CheckboxWithLabel
          checked={formik.values.defaultValue}
          onCheck={() =>
            formik.setFieldValue("defaultValue", !formik.values.defaultValue)
          }
          label={formik.values.defaultValue ? "Enabled" : "Disabled"}
        />

        <Button type="submit" color="primary" disabled={isLoading}>
          Save {isLoading && <Loader />}
        </Button>
      </div>
    </form>
  )
}

export default EditFlagForm
