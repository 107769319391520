import { autorun, toJS, makeAutoObservable } from "mobx"
import defaults from "lodash/defaults"

import { AppConfig, ServiceStatus } from "@framework/types/app"
import { defaultAppRestrictions } from "@framework/constants/app"
import appService from "@services/app.service"
import config from "@root/config"

import { normalizeConfig } from "./utils"
import InstanceConfigStore from "./instance-config.store"
import AppUIConfigStore from "./app-ui-config.store"

const isFullAccess = config.FULL_ACCESS_MODE

export class AppStore {
  config: AppConfig = defaultAppRestrictions

  instanceConfig: InstanceConfigStore

  uiConfigStore: AppUIConfigStore

  isConfigLoading: boolean = false

  isConfigInited: boolean = false

  introShown: boolean = JSON.parse(
    localStorage.getItem("INTRO_SHOWN") ?? "true"
  )

  errorMessage: string = ""

  constructor() {
    makeAutoObservable(this)

    this.instanceConfig = new InstanceConfigStore()
    this.uiConfigStore = new AppUIConfigStore()

    if (isFullAccess)
      autorun(() => {
        console.log("APP STORE: CONFIG", toJS(this.config))
      })
  }

  loadAppConfig = async () => {
    this.isConfigLoading = true
    this.errorMessage = ""
    this.isConfigInited = false
    try {
      const response = await appService.getAppConfig()

      if (response.data.status === "SUCCESS") {
        this.config = defaults(
          normalizeConfig(response.data.data),
          defaultAppRestrictions
        )
        return
      }
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.errorMessage = "Loading config failed"
        return
      }
    } finally {
      this.isConfigInited = true
      this.isConfigLoading = false
    }
  }

  hideIntro = () => {
    this.introShown = false
  }

  serviceStatus: ServiceStatus[] = []

  isServiceStatusLoading: boolean = false

  serviceStatusLoadingError: string = ""

  loadServiceStatus = async () => {
    this.isServiceStatusLoading = true
    this.serviceStatusLoadingError = ""
    try {
      const response = await appService.getServiceStatus()
      if (Array.isArray(response.data)) {
        this.serviceStatus = response.data
        return true
      }
    } catch (error: any) {
      this.serviceStatusLoadingError = "Status loading failed"
    } finally {
      this.isServiceStatusLoading = false
    }
    return false
  }
}

export default AppStore
