import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo } from "react"
import sortBy from "lodash/sortBy"

import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import { useStore } from "@store/index"

import UserRequestsTable from "../components/UserRequestsTable"

import styles from "./Users.module.sass"

export interface UserRequestsProps {
  query?: string
}

export const UserRequests: React.FC<UserRequestsProps> = observer(
  ({ query }) => {
    const {
      userRequestStore: {
        loadUsersRequests,
        userRequestsCollection,
        isUserRequestsCollectionLoading,
      },
    } = useStore()

    useEffect(() => {
      loadUsersRequests()
    }, [])

    const sortedCollection = useMemo(
      () =>
        sortBy(
          Object.values(userRequestsCollection ?? {}),
          (it) => it.createdAt
        ).reverse(),
      [userRequestsCollection]
    )

    const nothingIsFound = sortedCollection.length === 0

    return (
      <LoadingCover
        isLoading={isUserRequestsCollectionLoading}
        className={styles.body}
      >
        {nothingIsFound && !isUserRequestsCollectionLoading ? (
          <NotFound>
            No user requests found
            {query ? (
              <>
                &nbsp;by query <b>&ldquo;{query}&rdquo;</b>
              </>
            ) : null}
          </NotFound>
        ) : (
          <UserRequestsTable rows={sortedCollection} />
        )}
      </LoadingCover>
    )
  }
)

export default UserRequests
