import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

import { Entity } from "@framework/types/common"
import { DataSourcesListMeta } from "@framework/types/upload"

export default class EntityCollection<T extends Entity = Entity> {
  constructor() {
    makeAutoObservable(this)
  }

  id = nanoid()

  isLoading = false

  error: string | null = null

  data: T[] = []

  total = 0

  get totalLoaded(): number {
    return this.data.length
  }

  get dataMap(): Map<string, T> {
    return new Map(this.data.map((it) => [it.id, it]))
  }

  setData = (data: T[], meta: Partial<DataSourcesListMeta> = {}) => {
    const { pageNum = 0, pageSize = data.length, total = data.length } = meta

    const startIdx = pageNum * pageSize
    const stopIdx = startIdx + data.length

    const newArray = this.data.slice(0, total)
    newArray.splice(startIdx, stopIdx, ...data)

    this.data = newArray
    this.total = total
  }

  resetData = () => {
    this.data = []
  }

  get getById() {
    const { dataMap } = this
    return (answerId: string) => dataMap.get(answerId)
  }

  get getByIndex() {
    const { data } = this
    return (index: number) => data[index] ?? null
  }
}
