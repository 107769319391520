import React from "react"

import { AnswerItem } from "@framework/types/search"
import { useStore } from "@store/index"

export const usePassageChips = (passage: AnswerItem): string[] => {
  const { restrictionsStore: access } = useStore()

  return React.useMemo(() => {
    const {
      company = "",
      source_date = "",
      metadata,
      source_type = "",
      display_source = "",
    } = passage.value
    const { index } = passage

    const res = []

    if (index != null) res.push(`Passage ${Number(index) + 1}`)

    if (company) res.push(company)

    const date = access.isAnswersSortingByModifiedDate
      ? metadata?.created_date ?? ""
      : source_date

    if (date) res.push(date)

    const source = source_type === "None" ? "Other" : source_type

    if (source) res.push(source)

    if (display_source) res.push(display_source)

    return res
  }, [passage])
}

export default usePassageChips
