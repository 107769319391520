import { useEffect, useState, useRef } from "react"

export const useResizeObserver = <E extends Element>(
  targetRef: React.RefObject<E | null>,
  disabled?: boolean
) => {
  const [contentRect, setContentRect] = useState<DOMRectReadOnly | null>(null)

  const resizeObserver = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    if (disabled) {
      setContentRect(null)
      return undefined
    }

    resizeObserver.current = new ResizeObserver((entries) => {
      if (entries) setContentRect(structuredClone(entries[0].contentRect))
    })
    if (targetRef.current) {
      resizeObserver.current.observe(targetRef.current)
    }

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect()
      }
    }
  }, [targetRef.current, disabled])

  return contentRect
}

export default useResizeObserver
