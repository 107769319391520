import React from "react"
import { observer } from "mobx-react-lite"

import ModalOverlay from "./components/ModalOverlay/ModalOverlay"
import { useModalContext } from "./ModalContext"

export interface ModalProviderProps {}

export const ModalProvider: React.FC<ModalProviderProps> = observer(
  ({ children }) => {
    const modalsStore = useModalContext()
    const { modal, activeModalId, closeModal } = modalsStore

    const handleClose = () => activeModalId && closeModal(activeModalId)

    if (!modal) return null
    return (
      <ModalOverlay
        modalId={activeModalId}
        modal={modal.props}
        onClose={handleClose}
      >
        {children}
      </ModalOverlay>
    )
  }
)

export default ModalProvider
