import React from "react"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"

export const UserTableHeader: React.FC = observer(() => {
  return (
    <Row>
      <Column as="th" key="name" width="350px" />

      <Column as="th" key="actions" width="70px" />

      <Column as="th" key="roles" width="auto">
        Roles
      </Column>

      <Column as="th" key="created_date" width="150px">
        Created Date
      </Column>

      <Column as="th" key="created_date" width="150px">
        Last Active Date
      </Column>

      <Column as="th" key="avatars" width="100px">
        Avatars
      </Column>
    </Row>
  )
})

export default UserTableHeader
