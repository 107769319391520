import React from "react"

import ModalStore from "@store/modals/modal.store"

export const ModalContext = React.createContext<ModalStore | null>(null)

interface Props {
  instance: ModalStore
}

export const ModalContextProvider: React.FC<Props> = React.memo(
  ({ children, instance }) => {
    return (
      <ModalContext.Provider value={instance}>{children}</ModalContext.Provider>
    )
  }
)

export const useModalContext = () => {
  const context = React.useContext(ModalContext)

  if (context == null) throw new Error("Can't find ModalContext")

  return context
}
