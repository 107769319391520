import React, { useEffect } from "react"
import { useFormik } from "formik"
import * as yup from "yup"
import clsx from "clsx"

import {
  CommentType,
  PasswordValidationComment,
  strongPasswordValidationSchema,
} from "@framework/constants/auth"
import Button from "@components/ui/Button/Button"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Loader from "@components/ui/Loader/BarLoader"
import PasswordField from "@components/ui/PasswordField/PasswordField"

import RequiredPasswordField from "../RequiredPasswordField/RequiredPasswordField"

import styles from "./ChangePasswordForm.module.sass"

export const validationSchema = yup.object({
  password: strongPasswordValidationSchema.clone().default(""),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .default(""),
})

export type FormData = yup.InferType<typeof validationSchema>

interface ChangePasswordFormProps {
  isLoading?: boolean
  className?: string
  errors?: FormData
  onSubmit: (form: FormData) => void
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  errors,
  isLoading,
  className,
  onSubmit,
}) => {
  const formik = useFormik<FormData>({
    initialValues: validationSchema.getDefault(),
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    if (errors) formik.setErrors(errors)
  }, [errors])

  const getPasswordErrorMessage = () => {
    const error = formik.errors.password
    if (error) return PasswordValidationComment[error as CommentType]
    return ""
  }

  return (
    <form
      className={clsx(styles.root, className)}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.form}>
        <RequiredPasswordField
          name="password"
          placeholder="New password"
          type="password"
          value={formik.values.password}
          withError={!!(formik.touched.password && formik.errors.password)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          after={
            formik.touched.password &&
            formik.errors.password && (
              <div className={styles.after}>
                <ErrorChip
                  message={getPasswordErrorMessage()}
                  messagePlacement="left"
                />
              </div>
            )
          }
        />
        <PasswordField
          name="confirmPassword"
          placeholder="Confirm new password"
          value={formik.values.confirmPassword}
          withError={
            !!(formik.touched.confirmPassword && formik.errors.confirmPassword)
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          after={
            formik.touched.confirmPassword &&
            formik.errors.confirmPassword && (
              <ErrorChip
                message={formik.errors.confirmPassword}
                messagePlacement="left"
              />
            )
          }
        />
        <Button
          type="submit"
          color="primary"
          onClick={formik.submitForm}
          disabled={isLoading}
          after={isLoading && <Loader />}
        >
          Reset
        </Button>
      </div>
    </form>
  )
}

export default ChangePasswordForm
