import React from "react"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"

import EditAvatarForm from "./EditAvatarForm"
import BaseModal from "../components/BaseModal/BaseModal"
import ModalTitle from "../components/ModalTitle/ModalTitle"
import useModal from "../useModal"
import { ModalsTypes } from "../constants"

import styles from "./EditAvatarModal.module.sass"

export interface EditAvatarModalProps {
  avatarId?: string
}

export const EditAvatarModal: React.FC<EditAvatarModalProps> = observer(
  ({ avatarId }) => {
    const alert = useAlert()

    const {
      avatarDetailsStore: { isLoading, data },
    } = useStore()

    const {
      avatarController: { loadAvatarDetails },
      datatypesController,
    } = useController()

    const modal = useModal(ModalsTypes.CREATE_AVATAR_MODAL)

    const handleClose = () => modal.hideModal()

    const init = async (avatarId: string) => {
      const error = await loadAvatarDetails(avatarId)
      if (error) alert.error(error)
    }

    React.useEffect(() => {
      if (avatarId) init(avatarId)
    }, [avatarId])

    React.useEffect(() => {
      datatypesController.loadAllDataTypes()
    }, [])

    const avatar = avatarId ? data : null

    const isEdit = avatarId != null
    const title = isEdit ? "Edit Avatar" : "Avatar Setup"

    return (
      <BaseModal
        className={styles.root}
        title={<ModalTitle titleText={title} />}
        containerClassName={styles.container}
        onClose={handleClose}
      >
        {avatar?.id !== avatarId || isLoading ? (
          isLoading ? (
            <Loader size="large" fluid />
          ) : (
            <NotFound>Not found</NotFound>
          )
        ) : (
          <EditAvatarForm avatar={avatar ?? {}} onClose={handleClose} />
        )}
      </BaseModal>
    )
  }
)

export default EditAvatarModal
