import { observer } from "mobx-react-lite"
import React from "react"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { useController, useStore } from "@store/index"

import styles from "./DeleteAvatarModal.module.sass"

export interface DeleteAvatarModalProps {
  avatarId: string
}

export const DeleteAvatarModal: React.FC<DeleteAvatarModalProps> = observer(
  ({ avatarId }) => {
    const modal = useModal(ModalsTypes.DELETE_AVATAR_MODAL)
    const alert = useAlert()

    const {
      avatarController: { deleteAvatar },
      avatarAdminController: { loadAvatars },
    } = useController()
    const { avatarDetailsStore } = useStore()

    const handleSubmit = async () => {
      const error = await deleteAvatar(avatarId)
      if (error) {
        alert.error(error)
      } else {
        alert.success(<>Avatar was successfully deleted</>)
      }

      loadAvatars()

      modal.hideModal()
    }

    const { isLoading } = avatarDetailsStore

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={<>Are you sure you want to remove avatar?</>}
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <ModalFooterContainer>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={modal.hideModal}
          >
            Cancel
          </Button>
          <Button color="red" disabled={isLoading} onClick={handleSubmit}>
            Delete {isLoading && <Loader />}
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  }
)

export default DeleteAvatarModal
