import React from "react"
import { observer } from "mobx-react-lite"

import {
  AnswerData,
  ImageAnswerType,
  TableAnswerType,
} from "@framework/types/search"
import Switch from "@components/ui/Switch/Switch"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store/index"
import AnswerOriginalView from "@components/ui/AnswerOriginalView/AnswerOriginalView"
import MainBoxLayout from "@components/layout/MainBoxLayout/MainBoxLayout"
import Text from "@components/ui/Typography/Text"
import HTMLText from "@components/ui/Typography/HTMLText"

import TextResult from "./components/TextResult/TextResult"
import ImageResult from "./components/ImageResult/ImageResult"
import TableResult from "./components/TableResult/TableResult"
import { useTabs } from "./useTabs"

import styles from "./MixedResult.module.sass"

export interface MixedResultProps {
  answer: AnswerData
  options: any[]
}

export const MixedResult: React.FC<MixedResultProps> = observer(
  ({ answer, options }) => {
    const { restrictionsStore: access } = useStore()

    const { dataConnectorController } = useController()

    const { resultType, setResultType } = useTabs(options)

    const handleSourceClick = async () => {
      const sourceUrl = answer.source

      if (!sourceUrl) return

      const res = await dataConnectorController.getSignedDataURL(sourceUrl)

      if (res.status === "SUCCESS") window.open(res.data, "_blank")
    }

    const isExpertAnswer = answer.source === "expert answer"

    return (
      <MainBoxLayout className={styles.root} backOnBgClick>
        <Text variant="h1" className={styles.title}>
          {answer.source_name}
        </Text>

        {!!answer.source && (
          <Button
            onClick={handleSourceClick}
            className={styles.source}
            variant="outlined"
          >
            Open data source <Icon name="external-link" />
          </Button>
        )}

        <Switch items={options} checked={resultType} onChange={setResultType} />

        {resultType === "Text" &&
          (isExpertAnswer ? (
            <HTMLText>{(answer as any).text ?? (answer as any).value}</HTMLText>
          ) : (
            <TextResult
              content={(answer as any).text ?? (answer as any).value}
            />
          ))}

        {resultType === "Table" &&
          (access.tableViewResultIsImage ? (
            <ImageResult data={answer as ImageAnswerType} />
          ) : (
            <TableResult data={answer as TableAnswerType} />
          ))}

        {resultType === "Image" && (
          <ImageResult data={answer as ImageAnswerType} />
        )}

        {resultType === "Original" && !!answer.screenshots && (
          <div className={styles.content}>
            <AnswerOriginalView images={answer.screenshots} />
          </div>
        )}
      </MainBoxLayout>
    )
  }
)

export default MixedResult
