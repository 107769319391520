import React from "react"
import { useNavigate } from "react-router-dom"

import { ChatMessage } from "@components/prototypes/Chat/ChatMessages"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"
import ChatPreview from "../InformalKnowledgeDetails/ChatPreview"

import styles from "./KnowledgeSection.module.sass"

export const ChatsPreview: React.FC = () => {
  const navigate = useNavigate()
  const { avatar } = useActiveAvatar()

  const handleShowAll = () =>
    navigate("chats", {
      state: {
        backTo: "/home",
        avatar: avatar?.id,
      },
    })

  return (
    <div className={styles.preview}>
      <ChatPreview messages={chatMock1} onShowMore={handleShowAll} />
      <ChatPreview messages={chatMock2} onShowMore={handleShowAll} />
    </div>
  )
}

export default ChatsPreview

export const chatMock1: ChatMessage[] = [
  {
    message:
      "Hey, have you heard back from the client regarding the proposal we sent last week? Im eager to know their response and discuss next steps. Let me know if you have any updates. Thanks!",
    fromMe: false,
    moment: Date.now(),
  },
  {
    message:
      "I just checked my inbox, and unfortunately, we haven't received a response from the client yet.",
    fromMe: true,
    moment: Date.now(),
  },
  {
    message:
      "Oh, that's disappointing. I was hoping to hear back by now. Do you think we should follow up with them again?",
    fromMe: false,
    moment: Date.now(),
  },
]

export const chatMock2: ChatMessage[] = [
  {
    message:
      "I think it would be a good idea to follow up with the client. I'll draft a polite email and send it to them today. I'll keep you updated on any progress or response we receive.",
    fromMe: true,
    moment: Date.now(),
  },
  {
    message: "ok",
    fromMe: false,
    moment: Date.now(),
  },

  {
    message:
      "Hey, have you heard back from the client regarding the proposal we sent last week? Im eager to know their response and discuss next steps. Let me know if you have any updates. Thanks!",
    fromMe: false,
    moment: Date.now() + 86400000,
  },
]
