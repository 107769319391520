import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import List from "@components/ui/List/List"
import { Acronym } from "@framework/types/glossary"
import { ManagementMode } from "@framework/types/utils"
import { constructComplexUrlWithParams } from "@utils/textUtils"
import Button from "@components/ui/Button/Button"
import { useStore } from "@store/index"
import { GlossaryPageMode } from "@pages/upload/Glossary/Glossary"

import Row from "../Table/Row"
import Column from "../Table/Column"

import styles from "./index.module.sass"

interface AcronymRowProps {
  acronym: Acronym
}

export const AcronymRow: React.FC<AcronymRowProps> = observer(({ acronym }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { restrictionsStore: access } = useStore()

  const deleteModal = useModal(ModalsTypes.DELETE_ACRONYM_MODAL)

  const handleEdit = () => {
    navigate(
      constructComplexUrlWithParams(location.pathname, {
        glossaryMode: GlossaryPageMode.ACRONYM,
        managementMode: ManagementMode.editing,
        managingId: acronym.id,
      })
    )
  }

  const handleDelete = () => {
    deleteModal.showModal({
      acronym,
      onClose: () =>
        navigate(
          constructComplexUrlWithParams(location.pathname, {
            glossaryMode: GlossaryPageMode.ACRONYM,
          })
        ),
    })
  }

  return (
    <Row className={clsx(styles.row)} highlightable>
      <Column
        key={`${acronym.id} mainWord column`}
        justify="center"
        align="center"
      >
        <span className={styles.text}>{acronym.mainWord}</span>
      </Column>

      <Column align="center" key={`${acronym.id} definition column`}>
        <List
          direction="row"
          gutter="8"
          wrap="wrap"
          align="center"
          overflow="initial"
        >
          {acronym.definition ? (
            <Chip color="blue" uppercase key={acronym.id}>
              <span>{acronym.definition}</span>
            </Chip>
          ) : (
            "-"
          )}
        </List>
      </Column>

      <Column key={`context-menu-${acronym.id}`} align="center" justify="end">
        {access.canEditGlossary && (
          <List
            direction="row"
            gutter="8"
            justify="flex-end"
            className={styles.showOnHover}
          >
            <>
              <Button
                variant="outlined"
                size="medium"
                noPadding
                onClick={handleEdit}
                title="Edit"
              >
                <Icon name="edit" />
              </Button>
              <Button
                variant="outlined"
                size="medium"
                color="red"
                onClick={handleDelete}
                noPadding
                title="Delete"
              >
                <Icon name="trash-can" />
              </Button>
            </>
          </List>
        )}
      </Column>
    </Row>
  )
})

export default AcronymRow
