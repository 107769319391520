import React from "react"
import isEmpty from "lodash/isEmpty"
import moment from "moment"

import Row from "@components/ui/BaseTable/Row"
import Col from "@components/ui/BaseTable/Column"
import { DataCategoryEntity } from "@framework/types/knowledge"
import UserCard from "@components/ui/UserCard/UserCard"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import BusinessCard from "@components/ui/EntityCard/components/BusinessCard"
import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import VerticalItemList from "@pages/admin/components/VerticalItemList"
import { AvatarDetailsData } from "@framework/types/avatar"

import ContextMenu from "../Context"

import styles from "./index.module.sass"

interface DatatypesTableRowProps {
  datatype: DataCategoryEntity
  onAssignClick: React.MouseEventHandler
}

const DatatypesTableRow: React.FC<DatatypesTableRowProps> = ({
  datatype,
  onAssignClick,
}) => {
  const { name, description, id, channels, user, iconUrl } = datatype

  return (
    <Row>
      <Col key="name" align="center">
        <BusinessCard
          avatar={<DocumentIcon icon={iconUrl ?? "global"} />}
          title={name}
        >
          <div className={styles.description}>
            {isEmpty(description) ? "N/A" : description}
          </div>
        </BusinessCard>
      </Col>
      <Col key="actions" align="center">
        <ContextMenu datatypeId={id} />
      </Col>
      <Col key="avatar" align="center">
        {channels?.length ? (
          <VerticalItemList<AvatarDetailsData> items={channels} withAvatar />
        ) : (
          <Button
            before={<Icon name="plus" />}
            onClick={onAssignClick}
            variant="outlined"
            size="small"
          >
            Assign Avatars
          </Button>
        )}
      </Col>
      <Col key="createdBy" align="center">
        {user && (
          <UserCard
            fullName={user.name}
            avatarSrc={user.avatarUrl ?? ""}
            align="center"
          />
        )}
      </Col>
      <Col key="createdAt" align="center">
        {moment(datatype.createdAt).format("MMM DD, YYYY")}
      </Col>
    </Row>
  )
}

export default DatatypesTableRow
