import { makeAutoObservable } from "mobx"

import FileUploadingTask from "./FileUploadingTask"

export class FileUploadingStore {
  tasks: FileUploadingTask[]

  inProgressTasks: Set<string> = new Set()

  constructor() {
    makeAutoObservable(this)
    this.tasks = []
  }

  addTask = (task: FileUploadingTask) => {
    this.tasks.push(task)
  }

  get isAnyInProgress() {
    return this.inProgressTasks.size > 0
  }

  onUploadCompleteCallback?: () => void

  setOnCompleteCallback = (callback: () => void | null) => {
    this.onUploadCompleteCallback = callback
  }

  flush = () => {
    this.tasks = []
    this.inProgressTasks = new Set()
  }

  addTaskInProgress = (file: FileUploadingTask) => {
    this.inProgressTasks.add(file.id)
  }

  deleteTaskFromInProgress = (file: FileUploadingTask) => {
    this.inProgressTasks.delete(file.id)
  }
}

export default FileUploadingStore
