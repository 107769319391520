import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"

import FileUploadingStore from "./file-uploading.store"

export class FileUploadingTask {
  manager: FileUploadingStore

  id: string

  data: File

  error: string | null = null

  status: "FAILED" | "IN_PROGRESS" | "SUCCESS"

  constructor(config: { file: File; manager: FileUploadingStore }) {
    makeAutoObservable(this)

    this.id = nanoid()
    this.data = config.file
    this.status = "IN_PROGRESS"
    this.error = null

    this.manager = config.manager
  }

  startUpload = () => {
    this.error = null
    this.status = "IN_PROGRESS"
    this.manager.addTaskInProgress(this)
  }

  uploadSucceeded = () => {
    this.status = "SUCCESS"
    this.manager.deleteTaskFromInProgress(this)
  }

  uploadFailed = (error: string) => {
    this.status = "FAILED"
    this.error = error
    this.manager.deleteTaskFromInProgress(this)
  }
}

export default FileUploadingTask
