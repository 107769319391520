import React, { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store"
import useSearch from "@components/hooks/useSearch"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import Button from "@components/ui/Button/Button"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import List from "@components/ui/List/List"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import TextInput from "@components/ui/TextInput/TextInput"
import { CreateApiKeyFormData } from "@components/modals/ApiKeysModal/CreateApiKeyModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import { searchBy } from "@utils/optionsUtils"

import ApiKeysTable from "./components"

import styles from "./ApiKeys.module.sass"

const ApiKeys: React.FC = observer(() => {
  const navigate = useNavigate()
  const [search] = useSearch()
  const {
    apiKeysStore: { isLoading, apiKeys, createdApiKey },
  } = useStore()
  const {
    apiKeysController: { createApiKey, init },
  } = useController()
  const createApiKeyModal = useModal(ModalsTypes.CREATE_API_KEY_MODAL)
  const showApiKeyModal = useModal(ModalsTypes.SHOW_API_KEY_MODAL)

  const handleSubmit = async (form: CreateApiKeyFormData) => {
    await createApiKey({ name: form.name, permissions: form.selectedSolutions })
    createApiKeyModal.hideModal()
  }

  const handleCreateApiKey = () => {
    createApiKeyModal.showModal({
      onSubmit: handleSubmit,
    })
  }

  const filteredApiKeys = useMemo(() => {
    return searchBy(apiKeys, search.value, (it) => it.name)
  }, [apiKeys.length, search.value])

  useEffect(() => {
    if (createdApiKey) {
      showApiKeyModal.showModal({
        apiKey: createdApiKey,
      })
    }
  }, [createdApiKey])

  useEffect(() => {
    init()
  }, [])

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton onClick={() => navigate("../")}>
                <span>API Key Management</span>
              </BackButton>
            }
            right={
              <List direction="row" justify="flex-end" gutter="16">
                <Button
                  onClick={handleCreateApiKey}
                  disabled={isLoading}
                  className={styles.createButton}
                  color="primary"
                  size="big"
                  before={<Icon name="plus" />}
                >
                  New API Key
                </Button>
              </List>
            }
          >
            <TextInput
              {...search}
              placeholder="Search..."
              before={<Icon name="search" />}
            />
          </EntityPageHeaderLayout>

          <LoadingCover isLoading={isLoading}>
            {filteredApiKeys.length === 0 ? (
              <NotFound>No API Keys found</NotFound>
            ) : (
              <ApiKeysTable items={filteredApiKeys} />
            )}
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})

export default ApiKeys
