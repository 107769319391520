export const transformCodeInput = (value: string, codeLength: number) =>
  value.trim().replace(/[" "]/g, "").slice(0, codeLength)

export const setCaretPosition = (input: HTMLInputElement, pos: number) => {
  if (input.setSelectionRange) {
    input.focus()
    input.setSelectionRange(pos, pos + 1)
    return
  }
  // IE8 and below
  const el = input as any
  if (el.createTextRange) {
    const range = el.createTextRange()
    range.collapse(true)
    range.moveEnd("character", pos)
    range.moveStart("character", pos)
    range.select()
  }
}
