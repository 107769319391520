import { DataSourceName, fileSourcesNames } from "@framework/types/upload"

export const dataSourceNameToAppendFiledName = (sourceName: DataSourceName) => {
  if (sourceName === "website") return "websiteId"
  if (sourceName === "folders" || sourceName === "spaces") return "folderId"
  if (sourceName === "smb") return "cloudDirectoryId"
  if (sourceName === "amazon-aws-s3") return "cloudDirectoryId"
  if (fileSourcesNames.includes(sourceName as any)) return "fileId"
  return null
}

const defaultRequestBody = {
  fileIds: [],
  folderIds: [],
  websiteIds: [],
  dataConnectorIds: [],
  fileIdsToDelete: [],
  websiteIdsToDelete: [],
  folderIdsToDelete: [],
}

export const transformAppendItemsForm = (
  path: DataSourceName,
  items: string[]
) => {
  const filedName = dataSourceNameToAppendFiledName(path)
  if (filedName == null) throw new Error(`Source Name - ${path} not supported`)
  return {
    ...defaultRequestBody,
    [`${filedName}s`]: items,
  }
}

export const transformDeleteItemsForm = (
  path: DataSourceName,
  items: string[]
) => {
  const filedName = dataSourceNameToAppendFiledName(path)
  if (filedName == null) throw new Error(`Source Name - ${path} not supported`)
  return {
    ...defaultRequestBody,
    [`${filedName}sToDelete`]: items,
  }
}
