import React from "react"
import clsx from "clsx"

import styles from "./Table.module.sass"

export interface TableProps {
  hideBorder?: boolean
  className?: string
  tableClassName?: string
  layout?: "fixed" | "auto" | "default"
}

export const Table: React.FC<TableProps> = ({
  layout = "default",
  children,
  hideBorder,
  tableClassName,
  className,
}) => (
  <div
    className={clsx(
      styles.root,
      { [styles.withBorder]: !hideBorder },
      className
    )}
  >
    <div className={clsx(styles.container, styles[`layout-${layout}`])}>
      <table className={tableClassName}>
        <tbody>{children}</tbody>
      </table>
    </div>
  </div>
)

export default Table
