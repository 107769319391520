import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { Option } from "@framework/types/utils"
import { NotificationBadge } from "@components/ui/Loader/BadgeWrapper/BadgeWrapper"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import Profile from "./Profile/Profile"
import NavItem from "./NavItem/NavItem"

import styles from "./MobileSideMenu.module.sass"

export interface MobileSideMenuProps {
  menuOptions: Option[]
  profileOptions: Option[]
  isOpened?: boolean
}

export const MobileSideMenu: React.FC<MobileSideMenuProps> = ({
  menuOptions,
  profileOptions,
  isOpened = true,
}) => {
  const { showModal } = useModal(ModalsTypes.LOGOUT_MODAL)

  const handleLogoutClick = () => showModal({})

  return (
    <div className={clsx(styles.root, { [styles.opened]: isOpened })}>
      <div className={styles.menu}>
        <div className={styles.header}>
          <Profile menuOptions={profileOptions} />
        </div>

        <div className={styles.body}>
          <NavItem to="/home" label="Home" icon="home" key="home" />

          {menuOptions.map(({ name, value, icon }) => (
            <NavItem
              to={name}
              label={value}
              icon={icon}
              mark={<NavItemBadge name={name} />}
              key={name}
            />
          ))}
        </div>

        <div className={styles.footer}>
          <NavItem
            to="/login"
            label="logout"
            icon="log-out"
            className={styles.logout}
            onClick={handleLogoutClick}
            key="logout"
          />
        </div>
      </div>
    </div>
  )
}

type NavItemBadgeProps = {
  name: string
}

export const NavItemBadge: React.FC<NavItemBadgeProps> = observer(
  ({ name }) => {
    const {
      updateStore: { hasChanges },
    } = useStore()
    if (name === "/updates" && hasChanges) return <NotificationBadge />
    return null
  }
)

export default MobileSideMenu
