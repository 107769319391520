/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"
import { observer } from "mobx-react-lite"

import Range2D from "./state/Range2D"
import { useMatrixContext } from "./MatrixContext"
import { includes } from "./utils"

import styles from "./Spreadsheet.module.sass"

export const SelectionSquare: React.FC<{
  cell: Range2D
}> = observer(({ cell }) => {
  const { grid, editor } = useMatrixContext()

  if (!editor.isEditing && !cell.active) return null

  const isVisible = includes(grid.visibleGridRect, cell.origin)
  const position = grid.getCellCoordinates(cell.origin)

  if (!isVisible) return null
  return (
    <div
      className={styles.selected}
      role="button"
      tabIndex={0}
      style={{
        left: position.x,
        top: position.y,
        width: grid.getCellWidth(cell.origin.x),
        height: grid.getCellHeight(cell.origin.y),
      }}
    />
  )
})

export default SelectionSquare
