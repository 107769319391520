import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"

import BlurContainer from "@components/modals/components/BlurContainer/BlurContainer"
import Button from "@components/ui/Button/Button"
import Drawer from "@components/ui/Drawer/Drawer"
import Icon from "@components/ui/Icon/Icon"

import styles from "./index.module.sass"

const UNMOUNT_DELAY = 400

export interface SidebarPopupProps {
  open: boolean
  keepMounted?: boolean
  className?: string
  onClose?: () => void
}

export const SidebarPopup: React.FC<SidebarPopupProps> = observer(
  ({ open = false, keepMounted = true, className, children, onClose }) => {
    const [isMounted, setIsMounted] = React.useState(open)

    React.useEffect(() => {
      if (open || keepMounted) {
        setIsMounted(true)
        return undefined
      }

      const timeout = setTimeout(() => {
        setIsMounted(false)
      }, UNMOUNT_DELAY)

      return () => clearTimeout(timeout)
    }, [keepMounted, open])

    return (
      <BlurContainer blur={open}>
        <Drawer
          position="right"
          open={open}
          className={clsx(styles.root, className)}
        >
          {isMounted && children}

          <Button
            className={styles.closeButton}
            color="secondary"
            size="big"
            noPadding
            onClick={onClose}
          >
            <Icon name="cross" />
          </Button>
        </Drawer>
      </BlurContainer>
    )
  }
)

export default SidebarPopup
