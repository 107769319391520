import { makeAutoObservable } from "mobx"

export default class UsageAnalyticsReportStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading = false

  error: string | null = null

  data: Blob | null = null

  setData = (data: Blob | null) => {
    this.data = data
  }

  resetData = () => {
    this.data = null
  }
}
