import { AxiosResponse } from "axios"
import { makeAutoObservable } from "mobx"

import {
  Acronym,
  AcronymDTO,
  AcronymsGroupsByLetter,
} from "@framework/types/glossary"
import glossaryService from "@services/glossary.service"
import { UploadFilesErrorResponse } from "@services/upload.service"
import { isDeepEqual } from "@utils/collections"

const transformAndGroupAcronyms = (
  acronymsDTO: AcronymDTO[]
): AcronymsGroupsByLetter => {
  const transformedAcronyms: Acronym[] = acronymsDTO.map((dto) => ({
    id: dto.id,
    mainWord: dto.word,
    definition: dto.acronym,
  }))

  return transformedAcronyms.reduce<AcronymsGroupsByLetter>((acc, acronym) => {
    const firstLetter = acronym.mainWord.charAt(0).toUpperCase()
    if (!acc[firstLetter]) {
      acc[firstLetter] = []
    }
    acc[firstLetter].push(acronym)
    return acc
  }, {})
}

export class AcronymsStore {
  isAcronymsLoading = false

  acronymsCollection: AcronymsGroupsByLetter | null = null

  errorMessage: string | null = null

  errorMessageUploadFile: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  loadAcronyms = async (query: string = "") => {
    this.setAcronymsLoading(true)
    this.errorMessage = null
    try {
      const {
        data: { data },
        status,
      } = await glossaryService.getAllAcronyms(query)

      if (status === 200) {
        this.acronymsCollection = transformAndGroupAcronyms(data)
      }
    } catch (error: any) {
      this.errorMessage = "Loading failed"
    } finally {
      this.setAcronymsLoading(false)
      this.isAcronymsLoading = false
    }
    return this.errorMessage
  }

  removeAcronym = async (managedAcronym: Acronym) => {
    const acronymsCollectionBackup = this.acronymsCollection

    try {
      this.errorMessage = null

      // optimistic changes
      this.acronymsCollection = Object.keys(
        this.acronymsCollection ?? {}
      ).reduce(
        (acc, letter) => ({
          ...acc,
          [letter]: (this.acronymsCollection?.[letter] ?? []).filter(
            (acronym) => acronym.id !== managedAcronym.id
          ),
        }),
        {} as AcronymsGroupsByLetter
      )

      // request
      await glossaryService.deleteAcronym(managedAcronym.id)
    } catch (error: any) {
      this.errorMessage = "Removing of acronym failed"
      this.acronymsCollection = acronymsCollectionBackup
    }
    return this.errorMessage
  }

  updateAcronym = async (managedAcronym: Acronym) => {
    this.errorMessage = null

    const acronymsCollectionBackup = this.acronymsCollection

    let exist = false
    // optimistic changes
    this.acronymsCollection = Object.keys(
      this.acronymsCollection ?? {}
    ).reduce<AcronymsGroupsByLetter>(
      (acc, letter) => ({
        ...acc,
        [letter]: (this.acronymsCollection?.[letter] ?? []).map((acronym) => {
          exist = !!exist || acronym.id === managedAcronym.id
          return acronym.id === managedAcronym.id ? managedAcronym : acronym
        }),
      }),
      {}
    )

    if (!exist) {
      try {
        // request
        this.setAcronymsLoading(true)

        const newAcronym = transformAndGroupAcronyms([
          (
            await glossaryService.createAcronym({
              word: managedAcronym.mainWord,
              acronym: managedAcronym.definition,
            })
          ).data.data,
        ])

        const firstLetter = Object.keys(newAcronym)[0]

        this.acronymsCollection = {
          ...this.acronymsCollection,
          [firstLetter]: [
            ...(this.acronymsCollection?.[firstLetter] ?? []),
            ...Object.values(newAcronym?.[firstLetter] ?? []),
          ],
        }
      } catch (error: any) {
        if (error) {
          this.errorMessage = "Creating synonym was failed"
          // rollback
          this.acronymsCollection = acronymsCollectionBackup
        }
      } finally {
        this.setAcronymsLoading(false)
      }
    } else {
      try {
        // request
        this.setAcronymsLoading(true)

        const updatedAcronym: Acronym | null = Object.values(
          transformAndGroupAcronyms([
            (
              await glossaryService.updateAcronym(managedAcronym.id, {
                word: managedAcronym.mainWord,
                acronym: managedAcronym.definition,
              })
            ).data.data,
          ])
        )[0][0]

        if (!isDeepEqual(managedAcronym, updatedAcronym)) {
          this.acronymsCollection = Object.keys(
            this.acronymsCollection ?? {}
          ).reduce<AcronymsGroupsByLetter>((acc, letter) => {
            acc[letter] = (this.acronymsCollection?.[letter] ?? []).map(
              (acronym) => {
                return acronym.id === updatedAcronym?.id
                  ? updatedAcronym
                  : acronym
              }
            )
            return acc
          }, {})
        }
      } catch (error: any) {
        if (error) {
          this.errorMessage = "Updating synonym was failed"
          // rollback
          this.acronymsCollection = acronymsCollectionBackup
        }
      } finally {
        this.setAcronymsLoading(false)
      }
    }
    return this.errorMessage
  }

  uploadAcronymsFromFile = async (files: File[]) => {
    try {
      this.setAcronymsLoading(true)
      this.errorMessageUploadFile = null

      const form = new FormData()
      for (let x = 0; x < files.length; x += 1) {
        form.append("files[]", files[x])
      }

      // request

      const {
        data: { data },
        status,
      } = await glossaryService.uploadAcronymFromFile(form)

      if (status === 201) {
        const uploadedAcronyms = transformAndGroupAcronyms(data)

        const firstLetters = Object.keys(uploadedAcronyms)

        this.acronymsCollection = firstLetters.reduce(
          (acc, firstLetter) => {
            acc[firstLetter] = [
              ...(acc[firstLetter] ?? []),
              ...Object.values(uploadedAcronyms[firstLetter] ?? []),
            ]
            return acc
          },
          { ...this.acronymsCollection }
        )
      }
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.errorMessageUploadFile = "Unknown error while uploading files"
        return false
      }

      const typedResponse: AxiosResponse<UploadFilesErrorResponse> = response

      if (typedResponse.status === 413) {
        this.errorMessageUploadFile = "File is too large"
        return false
      }

      if (typedResponse.data.status === "BODY_INCOMPLETE") {
        this.errorMessageUploadFile = "No files added"
        return false
      }
      if (typedResponse.data.status === "INCORRECT_URL") {
        this.errorMessageUploadFile = "Current source type not maintenance"
        return false
      }
      return false
    } finally {
      this.setAcronymsLoading(false)
    }
    return true
  }

  setAcronymsLoading = (isLoading: boolean) => {
    this.isAcronymsLoading = isLoading
  }

  clearErrorMessage = () => {
    this.errorMessage = null
  }
}

export default AcronymsStore
