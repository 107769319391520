import React, { useState } from "react"

import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"
import Templates from "@components/ui/Templates"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import Button from "@components/ui/Button/Button"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"

import styles from "./index.module.sass"

interface IconSelectorProps {
  list: IconName[]
  value?: IconName
  onChange?: (value: IconName) => void
  onCancel: () => void
}

const IconSelector: React.FC<IconSelectorProps> = ({
  list,
  value = "global",
  onChange,
  onCancel,
}) => {
  const [selectedIcon, setSelectedIcon] = useState<IconName>(value)

  const handleChange = (icon: IconName) => setSelectedIcon(icon)

  const handleSelect = () => onChange?.(selectedIcon)

  return (
    <Templates.RollScript
      headerSocket={<ModalTitle titleText="Select Icon" />}
      footerSocket={
        <ModalFooterContainer>
          <Button variant="outlined" onClick={onCancel}>
            Back
          </Button>
          <Button color="primary" onClick={handleSelect}>
            Done
          </Button>
        </ModalFooterContainer>
      }
      gutter="32"
    >
      <div className={styles.root}>
        {list.map((item) => (
          <DocumentIcon
            className={styles.option}
            color={selectedIcon === item ? "primary" : "secondary"}
            key={item}
            icon={item}
            onClick={() => handleChange(item)}
          />
        ))}
      </div>
      <div className={styles.preview}>
        <div>
          <Icon name={selectedIcon} />
        </div>
      </div>
    </Templates.RollScript>
  )
}

export default IconSelector
