import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"

import LoadingPlaceholder from "./LoadingPlaceholder"

const AppConfigProvider: React.FC = observer(({ children }) => {
  const { appStore } = useStore()

  React.useEffect(() => {
    appStore.loadAppConfig()
  }, [])

  if (!appStore.isConfigInited) return <LoadingPlaceholder />
  return <>{children}</>
})

export default AppConfigProvider
