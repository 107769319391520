import React, { useState } from "react"

import { TabsCollection, TabsItem } from "@components/ui/TabsCollection"
import Text from "@components/ui/Typography/Text"
import { Option } from "@framework/types/utils"
import { useStore } from "@store/index"

import ActiveAvatarContextProvider from "../ActiveAvatarContext"
import useAvatarScope from "../AvatarScopeContext/useAvatarScope"
import TopExperts from "../TopExperts/TopExperts"
import ExpertKnowledgeSection from "./ExpertKnowledgeSection"
import FormalKnowledgeSection from "./FormalKnowledgeSection"
import InformalKnowledgeSection from "./InformalKnowledgeSection"

import styles from "./AvailableKnowledge.module.sass"

type DataType = "formal-knowledge" | "informal-knowledge" | "expert-knowledge"

const tabs: Option<DataType>[] = [
  { name: "formal-knowledge", value: "Formal Knowledge" },
  { name: "informal-knowledge", value: "Informal Knowledge" },
  { name: "expert-knowledge", value: "Expert Knowledge" },
]

const AvailableKnowledge: React.FC = () => {
  const [tab, setTab] = useState<string>(tabs[0].name)
  const { avatar } = useAvatarScope()
  const { restrictionsStore: access } = useStore()

  const filteredTabs = tabs.filter((tab) => {
    if (tab.name === "informal-knowledge") {
      return access.isInformalKnowledgeSectionAvailable
    }
    if (tab.name === "expert-knowledge") {
      return access.isExpertKnowledgeSectionAvailable
    }
    return true
  })

  return (
    <div className={styles.root}>
      <div className={styles.knowledge}>
        <div className={styles.heading}>
          <Text variant="h4" weight="bold">
            Available Knowledge
          </Text>
        </div>
        <div className={styles.knowledgeTabsContainer}>
          <TabsCollection
            defaultValue={tab}
            onSelect={setTab}
            items={filteredTabs}
            renderItem={({ item, isActive, handleSelect }) => (
              <TabsItem
                onClick={(e) => handleSelect(item, e)}
                active={isActive}
                key={item.name}
              >
                <div className={styles.switchItem}>{item.value}</div>
              </TabsItem>
            )}
          />
          {avatar?.id !== null && (
            <ActiveAvatarContextProvider avatarId={avatar?.id}>
              {
                {
                  "formal-knowledge": <FormalKnowledgeSection />,
                  "informal-knowledge":
                    access.isInformalKnowledgeSectionAvailable && (
                      <InformalKnowledgeSection />
                    ),
                  "expert-knowledge":
                    access.isExpertKnowledgeSectionAvailable && (
                      <ExpertKnowledgeSection />
                    ),
                }[tab]
              }
            </ActiveAvatarContextProvider>
          )}
        </div>
      </div>
      {avatar?.id !== null && (
        <ActiveAvatarContextProvider avatarId={avatar?.id}>
          <TopExperts />
        </ActiveAvatarContextProvider>
      )}

      {/* temporary hidden */}
      {/* <SolutionSnippetContainer /> */}
    </div>
  )
}

export default AvailableKnowledge
