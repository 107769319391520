import clsx from "clsx"
import React from "react"
import { Link, LinkProps } from "react-router-dom"

import ErrorChip from "../ErrorChip/ErrorChip"
import Text from "../Typography/Text"

import styles from "./LinkCard.module.sass"

export interface LinkCardProps {
  to: LinkProps["to"]
  state?: LinkProps["state"]

  label: string
  icon: React.ReactNode
  leftControl?: React.ReactNode
  rightControl?: React.ReactNode
  description?: React.ReactNode
  error?: string
  disabled?: boolean
  className?: string
  onClick?: LinkProps["onClick"]
}

export const LinkCard: React.FC<LinkCardProps> = ({
  to,
  state,
  className,
  disabled,
  leftControl,
  rightControl,
  description,
  error,
  icon,
  label,
  onClick,
}) => {
  return (
    <Link
      onClick={onClick}
      aria-disabled={disabled}
      className={clsx(styles.root, className)}
      to={to}
      state={state}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <span className={styles.before}>{leftControl}</span>

          <span className={styles.icon}>{icon}</span>

          <span className={styles.after}>{rightControl}</span>
        </div>

        <Text variant="h5" align="center">
          {label}
        </Text>
      </div>

      {error ? (
        <ErrorChip message={error} />
      ) : (
        <p className={styles.description}>{description}</p>
      )}
    </Link>
  )
}

export default LinkCard
