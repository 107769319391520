/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MouseEventHandler } from "react"

import Radio from "@components/ui/Checkbox/Radio"
import Text from "@components/ui/Typography/Text"

import styles from "./RadioItem.module.sass"

interface RadioItemProps {
  checked?: boolean
  label?: string
  description?: string
  onClick?: MouseEventHandler
}

const RadioItem: React.FC<RadioItemProps> = ({
  label,
  description,
  checked,
  onClick,
}) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <Radio checked={checked} />
      <div>
        <Text variant="caption1">{label}</Text>
        <Text variant="inherit" color="text70Color">
          {description}
        </Text>
      </div>
    </div>
  )
}

export default RadioItem
