import clsx from "clsx"
import React from "react"
import moment from "moment"
import { observer } from "mobx-react-lite"

import { getDataSourceCategoryDescription } from "@framework/constants/upload"
import { DataCategoryEntity } from "@framework/types/knowledge"

import Avatar from "../Avatar/Avatar"
import Chip from "../Chip/Chip"
import DocumentIcon from "../Icon/DocumentIcon"
import Text from "../Typography/Text"
import List from "../List/List"

import styles from "./DataTypeListItem.module.sass"

export interface DataTypeListItemProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  data: DataCategoryEntity
  controlSlot?: React.ReactNode
  disabled?: boolean
  active?: boolean
}

export const DataTypeListItem: React.FC<DataTypeListItemProps> = observer(
  ({
    controlSlot,
    children,
    data,
    disabled = false,
    active = false,
    className,
    onClick,
    onDoubleClick,
    ...rest
  }) => {
    const desc = getDataSourceCategoryDescription(data.name)

    const title = data.name

    const subtitle = `last update: ${moment(data.lastUpdatedAt).fromNow()}`

    return (
      <button
        type="button"
        disabled={disabled}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
        className={clsx(
          styles.root,
          {
            [styles.clickable]: onClick || onDoubleClick,
            [styles.active]: active,
          },
          className
        )}
        {...rest}
      >
        <div className={styles.header}>
          <span className={styles.icon}>
            <DocumentIcon icon={desc.icon} color="primary-solid" />
          </span>

          <div className={styles.meta}>
            <Text className={styles.title} variant="h5">
              {title}
            </Text>

            <Text
              className={styles.subtitle}
              color="text50Color"
              variant="caption2"
            >
              {subtitle}
            </Text>
          </div>
          <div className={styles.control}>{controlSlot}</div>
        </div>
        <div className={styles.content}>
          <List direction="row" gutter="8">
            <Text
              variant="caption2"
              color="text70Color"
              className={styles.label}
            >
              Avatars:
            </Text>
            <List direction="row" gutter="8" wrap="wrap">
              {data.channels?.length ? (
                data.channels?.map((it) => (
                  <Chip
                    variant="rounded"
                    color="secondary"
                    before={<Avatar src={it.imageUrl} />}
                    key={it.id}
                  >
                    {it.name}
                  </Chip>
                ))
              ) : (
                <Text
                  variant="caption2"
                  color="text100Color"
                  className={styles.label}
                >
                  Not connected
                </Text>
              )}
            </List>
          </List>
        </div>
      </button>
    )
  }
)

export default DataTypeListItem
