import React from "react"
import { Link, useNavigate } from "react-router-dom"

import List from "@components/ui/List/List"
import mainRoutes from "@root/main.routes"
import IntroLayout from "@components/layout/IntroLayout/IntroLayout"
import Button from "@components/ui/Button/Button"

import SignUpForm from "./components/SignUpForm/SignUpForm"

import styles from "./SignIn.module.sass"

export interface SignUpPageProps {}

const SignUpPage: React.FC<SignUpPageProps> = () => {
  const navigate = useNavigate()

  return (
    <IntroLayout>
      <div className={styles.root}>
        <div className={styles.header}>
          Already have an account?
          <Button
            variant="outlined"
            size="medium"
            className={styles.smallButton}
            onClick={() => navigate(mainRoutes.login())}
          >
            Sign In
          </Button>
        </div>

        <List justify="center" gutter="24" className={styles.body}>
          <SignUpForm />
        </List>

        <div className={styles.footer}>
          By signing in or creating an account, you agree with our&nbsp;
          <Link to="/terms">Terms & Conditions</Link> and&nbsp;
          <Link to="/privacy">Privacy Statement</Link>
        </div>
      </div>
    </IntroLayout>
  )
}

export default SignUpPage
