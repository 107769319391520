import React from "react"

import styles from "./ModalTitle.module.sass"

export interface ModalTitleProps {
  titleText: React.ReactNode
  subtitleText?: React.ReactNode
}

export const ModalTitle: React.FC<ModalTitleProps> = ({
  titleText,
  subtitleText,
}) => (
  <div className={styles.root}>
    <h2>{titleText}</h2>
    {!!subtitleText && <h4>{subtitleText}</h4>}
  </div>
)

export default ModalTitle
