import { makeAutoObservable } from "mobx"

import { ConnectedCloudFolder } from "@framework/types/upload"

import EntityCollection from "../utils/EntityCollection"

export default class AvatarDataConnectorFoldersStore {
  constructor() {
    makeAutoObservable(this)
  }

  collectionsMap: Map<string, EntityCollection<ConnectedCloudFolder>> =
    new Map()

  getCollectionKey = (dataCategoryId: string, dataConnectorId: string) =>
    `${dataCategoryId}_${dataConnectorId}`

  getCollection = (dataCategoryId: string, dataConnectorId: string) => {
    const key = this.getCollectionKey(dataCategoryId, dataConnectorId)
    if (!this.collectionsMap.has(key)) this.resetCollection(key)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.collectionsMap.get(key)!
  }

  resetCollection = (collectionKey: string) => {
    this.collectionsMap.set(collectionKey, new EntityCollection())
  }
}
