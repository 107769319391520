import React from "react"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import * as yup from "yup"
import { nanoid } from "nanoid"

import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { TextInput } from "@components/ui/TextInput/TextInput"
import Templates from "@components/ui/Templates"
import Label from "@components/ui/Label/Label"
import List from "@components/ui/List/List"
import Icon from "@components/ui/Icon/Icon"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"

import styles from "./EditTopicModal.module.sass"

export const validationSchema = yup.object({
  articles: yup
    .array(
      yup.object({
        id: yup.string(),
        value: yup.string().required("Filed is required").default("").trim(),
      })
    )
    .default([{ id: nanoid(), value: "" }]),
})

export type EditArticleFormData = yup.InferType<typeof validationSchema>

export interface EditArticleFormProps {
  initialValue?: Partial<EditArticleFormData>
  onSubmit?: (form: EditArticleFormData) => Promise<void>
  onCancel?: () => void
}

export const EditArticleForm: React.FC<EditArticleFormProps> = observer(
  ({ initialValue, onSubmit, onCancel }) => {
    const [isLoading, setLoading] = React.useState(false)

    const handleSubmit = async (form: EditArticleFormData) => {
      setLoading(true)
      await onSubmit?.(validationSchema.cast(form))
      setLoading(false)
    }

    const formik = useFormik<EditArticleFormData>({
      initialValues: validationSchema.cast(initialValue, {
        stripUnknown: true,
      }),
      validationSchema,
      onSubmit: handleSubmit,
    })

    const handleAdd = () => {
      const prevValue = formik.values.articles ?? []
      formik.setValues({
        ...formik.values,
        articles: [...prevValue, { id: nanoid(), value: "" }],
      })
    }

    const handleRemove = (idx: number) => {
      const newList =
        formik.values.articles?.filter((_, index) => idx !== index) ?? []

      formik.setValues({ ...formik.values, articles: newList })

      formik.setTouched({
        ...formik.touched,
        articles: [],
      })
    }

    return (
      <form onSubmit={formik.handleSubmit} className={styles.root}>
        <Templates.RollScript
          className={styles.formContainer}
          gutter="32"
          footerSocket={
            <ModalFooterContainer>
              <Button
                disabled={isLoading}
                variant="outlined"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                type="submit"
                after={isLoading && <Loader />}
              >
                Save
              </Button>
            </ModalFooterContainer>
          }
        >
          <List gutter="16">
            <Label id="articles" label="Articles">
              <List gutter="16">
                {formik.values.articles.map((item, idx, theList) => {
                  const isTouched =
                    formik.touched.articles?.[idx]?.value ?? false

                  const error = (formik.errors.articles?.[idx] as any)?.value

                  return (
                    <TextInput
                      id={item.id}
                      className={styles.input}
                      placeholder="Type article"
                      value={item.value}
                      name={`articles[${idx}].value`}
                      withError={isTouched && error != null}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      key={item.id}
                      autoFocus
                      after={
                        theList.length > 1 ? (
                          <div className={styles.after}>
                            <Icon
                              name="cross"
                              className={styles.closeBtn}
                              onClick={() => handleRemove(idx)}
                            />
                          </div>
                        ) : null
                      }
                    />
                  )
                })}
              </List>
            </Label>
            <List align="flex-end">
              <Button
                size="small"
                variant="outlined"
                before={<Icon name="plus" />}
                onClick={handleAdd}
              >
                Add Article
              </Button>
            </List>
          </List>
        </Templates.RollScript>
      </form>
    )
  }
)

export default EditArticleForm
