import { observer } from "mobx-react-lite"
import React from "react"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import { useStore } from "@store/index"
import { Acronym } from "@framework/types/glossary"

import styles from "./DeleteModal.module.sass"

export interface DeleteAcronymModalProps {
  acronym: Acronym
  onClose: () => void
}

export const DeleteAcronymModal: React.FC<DeleteAcronymModalProps> = observer(
  ({ acronym }) => {
    const { hideModal } = useModal(ModalsTypes.DELETE_ACRONYM_MODAL)
    const alert = useAlert()

    const {
      acronymsStore: { removeAcronym, isAcronymsLoading },
    } = useStore()

    const handleSubmit = async () => {
      hideModal()
      const error = await removeAcronym(acronym)
      if (error) alert.error(error)
      else
        alert.success(
          <>
            Acronym &quot;<b>{acronym.mainWord}</b>
            &quot; was successfully deleted
          </>
        )
    }

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={
              <>
                Are you sure you want to delete acronym &quot;
                <b>{acronym.mainWord.toUpperCase()}</b>
                &quot;?
              </>
            }
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={hideModal}
      >
        <ModalFooterContainer>
          <Button
            variant="outlined"
            disabled={isAcronymsLoading}
            onClick={hideModal}
          >
            Cancel
          </Button>
          <Button
            color="red"
            disabled={isAcronymsLoading}
            onClick={handleSubmit}
          >
            Delete {isAcronymsLoading && <Loader />}
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  }
)

export default DeleteAcronymModal
