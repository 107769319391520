import React from "react"
import { observer } from "mobx-react-lite"
import { Navigate, RouteProps, Outlet, useLocation } from "react-router-dom"

import { useStore } from "@store/index"

import LoadingPlaceholder from "./LoadingPlaceholder"

export type PrivateOutletProps = RouteProps & {}

export const PrivateOutlet: React.FC<PrivateOutletProps> = observer(() => {
  const location = useLocation()
  const { authStore, userStore } = useStore()

  React.useEffect(() => {
    if (!authStore.isAuthorized) authStore.setRestrictedLocation(location)
  }, [authStore.isAuthorized])

  if (!authStore.isAuthorized) return <Navigate to="/login" replace />

  if (
    userStore.userActions == null &&
    userStore.loadActionsErrorMessage == null
  )
    return <LoadingPlaceholder />

  return <Outlet />
})

export default PrivateOutlet
