/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"

import Icon from "@components/ui/Icon/Icon"

import styles from "./CollapseHeader.module.sass"

export interface CollapseHeaderProps {
  open: boolean
  title?: React.ReactNode
  className?: string
  onToggle?: React.MouseEventHandler
}

export const CollapseHeader: React.FC<CollapseHeaderProps> = ({
  open = false,
  title,
  className,
  children,
  onToggle,
}) => {
  return (
    <h4
      // title={title}
      onClick={onToggle}
      className={clsx(
        styles.root,
        { [styles.clickable]: onToggle, [styles.active]: open },
        className
      )}
    >
      <Icon name="arrow-down" rotateAngle={open ? 180 : 0} />{" "}
      {children ?? title}
    </h4>
  )
}

export default CollapseHeader
