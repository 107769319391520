import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry } from "rxjs/operators"
import { toStream } from "mobx-utils"

import uploadService from "@services/upload.service"
import {
  DataConnectorAccountEntity,
  DataConnectorSourceName,
} from "@framework/types/upload"

const PULLING_DELAY = 30_000
const RETRY_DELAY = 10_000

type State = {
  isLoading: boolean
  data: DataConnectorAccountEntity[]
  errorMessage: string
  reloadTrigger: number
  readonly dataConnectorName: DataConnectorSourceName
}

export class DataConnectorAccountsStore {
  state: State

  private loadingStream$?: Subscription

  constructor(dataConnector: DataConnectorSourceName) {
    this.state = {
      isLoading: false,

      data: [],

      errorMessage: "",

      reloadTrigger: Date.now(),

      dataConnectorName: dataConnector,
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "data", () => {
      this.loadingStream$ = this.initLoadingStream()
    })

    onBecomeUnobserved(this.state, "data", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    return from(
      toStream(() => [this.state.reloadTrigger], true) as ObservableInput<
        [boolean]
      >
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.errorMessage = ""
          })
        ),

        switchMap(() =>
          uploadService.getDataConnectorAccounts$(this.state.dataConnectorName)
        ),

        tap((response) =>
          runInAction(() => {
            this.state.data = response.data.data ?? []
            this.state.isLoading = false
          })
        ),

        retry({
          delay: () => {
            runInAction(() => {
              this.state.errorMessage = "Loading Failed"
            })
            return timer(RETRY_DELAY)
          },
        }),

        switchMap(() => timer(PULLING_DELAY)),
        tap(() => this.refresh())
      )
      .subscribe()
  }

  get getById() {
    const { data } = this.state
    const dataIndex = new Map(data.map((it) => [it.id, it]))
    return (id?: string) => {
      return id ? dataIndex.get(id) : undefined
    }
  }

  refresh = () => {
    this.state.reloadTrigger = Date.now()
  }
}

export default DataConnectorAccountsStore
