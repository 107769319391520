import { makeAutoObservable } from "mobx"

import RootStore from "@store/RootStore"
import { capitalizeFirstLetter, stringArrayToOptions } from "@utils/textUtils"
import { Option } from "@framework/types/utils"
import searchService from "@services/search.service"

export class SearchSuggestionStore {
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  trendingQuestions: Option[] = []

  favoriteQuestions: Option[] = []

  isQuestionsLoading = false

  questionsLoadError: string | null = null

  /**
   * Need to be reviewed
   */
  loadQuestions = async (avatar: string, productSolutionId: string) => {
    this.isQuestionsLoading = true
    this.trendingQuestions = []
    this.favoriteQuestions = []
    this.questionsLoadError = null
    try {
      const favoriteResp = await searchService.getUsersQuestions(
        avatar,
        productSolutionId
      )
      if (favoriteResp.data.data) {
        this.favoriteQuestions = stringArrayToOptions(
          favoriteResp.data.data,
          capitalizeFirstLetter
        )
      }
    } catch (error) {
      this.questionsLoadError = "Can't load"
    } finally {
      this.isQuestionsLoading = false
    }
  }
}

export default SearchSuggestionStore
