import React from "react"

import { CollapsibleContext } from "./CollapsibleContext"

const useCollapsible = () => {
  const context = React.useContext(CollapsibleContext)
  if (context == null) throw new Error("Can't find collapsible context")
  return context
}

export default useCollapsible
