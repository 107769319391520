import { makeAutoObservable } from "mobx"

import productsService from "@services/products.service"
import { CompanyTheme } from "@framework/types/company"
import { FailResponse, SuccessResponse } from "@framework/types/common"

import UnifiedMatrixStore from "./unified-matrix.store"

export default class UnifiedMatrixController {
  matrix: UnifiedMatrixStore

  constructor(injections: { unifiedMatrixStore: UnifiedMatrixStore }) {
    this.matrix = injections.unifiedMatrixStore

    makeAutoObservable(this)
  }

  fetchCompanies = async () => {
    const store = this.matrix.companiesStore

    try {
      store.isLoading = true

      const response = await productsService.getTargetCompanies()

      store.setCompanies(response.data)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
  }

  generateThemes = async (
    companyName: string,
    category: string,
    businessUnit?: string
  ): Promise<SuccessResponse<string> | FailResponse> => {
    try {
      const response = await productsService.generateThemes({
        companyName,
        category,
        businessUnit,
      })

      const themes = response.data.data

      return {
        status: "SUCCESS",
        data: themes.length ? makeThemesReport(themes) : "-",
      }
    } catch (error) {
      return {
        status: "FAILED",
        message: "Unexpected error",
      }
    }
  }
}

const makeThemesReport = (themes: CompanyTheme[]) => {
  return themes.map((it) => `- ${it.content}`).join("\n")
}
