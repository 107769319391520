import React from "react"

import SecondaryLayout from "@components/layout/SecondaryLayout/SecondaryLayout"
import Button from "@components/ui/Button/Button"
import TextInput from "@components/ui/TextInput/TextInput"

import styles from "./ChangeEmail.module.sass"

interface ServiceTermsProps {}

const ChangeEmailPage: React.FC<ServiceTermsProps> = () => (
  <SecondaryLayout className={styles.root}>
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Change email</h1>
        <h4>Change your email and we will send your verification PIN code</h4>
      </div>
      <div className={styles.form}>
        <TextInput placeholder="Email" />
        <Button color="primary">Send</Button>
      </div>
    </div>
  </SecondaryLayout>
)

export default ChangeEmailPage
