import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"
import { Outlet } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Text from "@components/ui/Typography/Text"
import { useStore } from "@store/index"
import { DONT_SHOW_INTRO_AGAIN_STORAGE_KEY } from "@framework/constants/auth"
import AvatarPickerSidebar from "@components/prototypes/AvatarPickerSidebar"

import useAvatarScope from "./AvatarScopeContext/useAvatarScope"
import AvailableKnowledge from "./AvailableKnowledge/AvailableKnowledge"
import AvailableSolutions from "./AvailableSolutions/AvailableSolutions"
import Intro from "./Intro/Intro"

import styles from "./HomeBeta.module.sass"

const DEFAULT_WELCOME_MESSAGE =
  "Take advantage of our powerful Avatars and Solutions to enhance your workflow."

const HomePageBeta: React.FC = observer(() => {
  const {
    userStore: { user },
    appStore: { introShown, hideIntro },
    restrictionsStore,
  } = useStore()

  const { avatar, setAvatar } = useAvatarScope()

  const greeting = `Hello${user ? `, ${user.firstName}!` : ""}`

  const dontShowAgain = JSON.parse(
    localStorage.getItem(DONT_SHOW_INTRO_AGAIN_STORAGE_KEY) ?? "false"
  )

  if (restrictionsStore.showIntro && !dontShowAgain && introShown) {
    return <Intro onHide={hideIntro} />
  }

  return (
    <MainLayout>
      <div className={clsx(styles.root)}>
        <header className={styles.header}>
          <div className={styles.greeting}>
            <Text variant="h1" weight="bold">
              {greeting}
            </Text>
            <Text variant="body1" color="text50Color">
              {DEFAULT_WELCOME_MESSAGE}
            </Text>
          </div>

          <AvatarPickerSidebar value={avatar?.id} onChange={setAvatar} />
        </header>

        <AvailableSolutions />

        <AvailableKnowledge />
      </div>

      <Outlet />
    </MainLayout>
  )
})

export default HomePageBeta
