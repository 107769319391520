import { useContext } from "react"

import SearchDetailsContext from "./SearchDetailsContext"

const useSearchDetailsContext = () => {
  const context = useContext(SearchDetailsContext)
  if (context != null) return context
  throw new Error("Search Details Context not found")
}

export default useSearchDetailsContext
