import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import { Acronym } from "@framework/types/glossary"
import { useStore } from "@store/index"
import { constructComplexUrlWithParams } from "@utils/textUtils"
import { GlossaryPageMode } from "@pages/upload/Glossary/Glossary"

import RecordFormRow from "./RecordFormRowForm"

interface EditAcronymRowProps {
  acronym: Acronym
  onSubmit?: (newAcronym: Acronym) => void
}

export const EditAcronymRow: React.FC<EditAcronymRowProps> = observer(
  ({ acronym, onSubmit }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const alert = useAlert()

    const {
      acronymsStore: { updateAcronym },
    } = useStore()

    const handleSubmit = async ({
      title,
      items,
    }: {
      title: string
      items: string[]
    }) => {
      const error = await updateAcronym({
        id: acronym.id,
        mainWord: title,
        definition: items[0],
      })

      if (error) alert.error(error)
      else {
        navigate(
          constructComplexUrlWithParams(location.pathname, {
            glossaryMode: GlossaryPageMode.ACRONYM,
          })
        )
        onSubmit?.({
          id: acronym.id,
          mainWord: title,
          definition: items[0],
        })
      }
    }

    const handleCancel = () => {
      navigate(
        constructComplexUrlWithParams(location.pathname, {
          glossaryMode: GlossaryPageMode.ACRONYM,
        })
      )
    }

    return (
      <RecordFormRow
        defaultTitle={acronym.mainWord}
        defaultItems={[acronym.definition]}
        maxItems={1}
        minItems={1}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    )
  }
)

export default EditAcronymRow
