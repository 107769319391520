import { autorun, makeAutoObservable, toJS } from "mobx"

import { Point, Range } from "@framework/types/common"

import CellManager from "./CellManager"
import {
  cellIdFromPoint,
  cellIdToPoint,
  contains,
  forEachOfRange,
  includes,
  rangeSize,
  refToRange,
} from "../utils"
import CellEditorState from "./CellEditorState"
import MatrixStore from "./MatrixStore"
import FunctionManager from "./FunctionManager"

class EditManager {
  // injections
  private context: MatrixStore

  // state
  activeCellId: string | null = null

  activeCellState: CellEditorState | null = null

  functionManager: FunctionManager

  get isEditing() {
    return this.activeCellId != null
  }

  get isCellEditing() {
    const { activeCellId } = this
    return (point: Point) => activeCellId === cellIdFromPoint(point)
  }

  data: Map<string, CellManager> = new Map()

  constructor(injections: { context: MatrixStore }) {
    this.context = injections.context

    this.functionManager = new FunctionManager({ manager: this })

    // autorun(() => console.log(toJS(this)))

    makeAutoObservable(this)
  }

  editCell = (
    point: Point,
    options: { initialValue?: string; focusCell?: boolean } = {}
  ) => {
    const cell = this.getCellAtPoint(point)

    this.activeCellState = new CellEditorState({
      input: options.initialValue ?? cell.state.input,
      autoFocusCell: options.focusCell,
    })

    this.activeCellId = cellIdFromPoint(point)
  }

  initCell = (point: Point, value: string | number) => {
    const cell = this.getCellAtPoint(point)

    cell.setInput(value)
  }

  submitCell = () => {
    const cell = this.getActiveCell()

    if (cell == null) return

    cell.setInput(this.activeCellState?.normalize())

    this.activeCellId = null
    this.activeCellState = null
  }

  cancelCell = () => {
    this.activeCellId = null
    this.activeCellState = null
  }

  getRefValues = (refs: string[]): Record<string, any | any[]> => {
    return Object.fromEntries(refs.map((ref) => [ref, this.getRefValue(ref)]))
  }

  getRefValue = (ref: string): any | any[] => {
    const refRange = refToRange(ref)

    if (rangeSize(refRange) === 1) {
      return this.getCellAtPoint(refRange.start).value
    }

    const cols = refRange.end.x - refRange.start.x + 1
    const rows = refRange.end.y - refRange.start.y + 1
    const values = []

    for (let xi = 0; xi < cols; xi += 1) {
      for (let yi = 0; yi < rows; yi += 1) {
        values.push(
          this.getCellAtPoint({
            x: refRange.start.x + xi,
            y: refRange.start.y + yi,
          }).value
        )
      }
    }

    return values
  }

  isValidateRefs = (cellId: string, refs: string[]) => {
    const origin = cellIdToPoint(cellId)
    const boundary = this.context.grid.rect
    return refs.every((ref) => {
      const refRange = refToRange(ref)
      return !includes(refRange, origin) && contains(boundary, refRange)
    })
  }

  get findCell() {
    return (point: Point) => {
      const id = cellIdFromPoint(point)

      const cell = this.data.get(id)
      return cell ?? null
    }
  }

  get getCellAtPoint() {
    const { getCell } = this
    return (point: Point) => {
      const id = cellIdFromPoint(point)
      return getCell(id)
    }
  }

  get getActiveCell() {
    const { activeCellId: id, getCell } = this
    return () => {
      if (id == null) return null
      return getCell(id)
    }
  }

  get getCell() {
    return (id: string) => {
      const cell = this.data.get(id)
      if (cell != null) return cell

      this.data.set(id, new CellManager({ id, manager: this }))
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.data.get(id)!
    }
  }

  cleanUpRange = (range: Range<Point>) => {
    forEachOfRange(range, (x, y) => {
      this.getCellAtPoint({ x, y }).cleanUp()
    })
  }

  initMatrix = (value: string[][]) => {
    const rows = value.length
    for (let yi = 0; yi < rows; yi += 1) {
      const cols = value[yi].length
      for (let xi = 0; xi < cols; xi += 1) {
        this.initCell(
          {
            x: xi,
            y: yi,
          },
          value[yi][xi]
        )
      }
    }
  }
}

export default EditManager
