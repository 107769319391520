import { Formula, Marker } from "./types"

export const normalizeFormula = (formula: Formula | Marker): string => {
  if (!("type" in formula)) return formula.token.trim()

  switch (formula.type) {
    case "un-exp": {
      return `${formula.token.trim()}${normalizeFormula(formula.value)}`
    }
    case "bin-exp": {
      return `${normalizeFormula(
        formula.left
      )}${formula.token.trim()}${normalizeFormula(formula.right)}`
    }
    case "fun": {
      return `${formula.token.toUpperCase().trim()}(${formula.block.arguments
        .map(normalizeFormula)
        .join(",")})`
    }
    case "ref": {
      return formula.token.toUpperCase().trim().split(":").sort().join(":")
    }
    case "const":
    case "unknown": {
      return formula.token.trim()
    }

    default:
      return ""
  }
}

export const normalizeTokens = (tokens: string[]) =>
  tokens.map((it) => it.trim().toUpperCase())
