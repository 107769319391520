import React from "react"
import clsx from "clsx"

import styles from "./BurgerButton.module.sass"

interface BurgerButtonProps {
  className?: string
  onClick?: () => unknown
  isOpened?: boolean
}

const BurgerButton: React.FC<BurgerButtonProps> = ({
  className,
  onClick,
  isOpened = false,
}) => {
  const buttonClassName = clsx(className, styles.button, {
    [styles.open]: isOpened,
  })

  return (
    <button type="button" onClick={onClick} className={buttonClassName}>
      <span />
      <span />
      <span />
    </button>
  )
}

export default BurgerButton
