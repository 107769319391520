import React from "react"
import { useField } from "formik"

import Icon from "@components/ui/Icon/Icon"
import { useId } from "@components/hooks/useId"

import styles from "./SearchInputField.module.sass"

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
}

export const SearchInputField: React.FC<Props> = ({ id, name, ...rest }) => {
  const [filed, _, meta] = useField(name)
  const htmlId = useId("input_")

  return (
    <label htmlFor={id ?? htmlId} className={styles.input}>
      <Icon name="search" />
      <input
        {...rest}
        onChange={(e) => meta.setValue(e.target.value)}
        value={filed.value}
        id={id ?? htmlId}
      />
    </label>
  )
}

export default SearchInputField
