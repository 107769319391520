import { DataSourceName } from "@framework/types/upload"

export const extractSourceTypeBlackList = <T extends object = any>(
  config: T,
  mapper: object
) => {
  const mapperEntries = Object.entries(mapper)

  return mapperEntries.reduce((blackList, [key, value]) => {
    if (!config[key as keyof T]) blackList.push(value)

    return blackList
  }, [] as DataSourceName[])
}

export default null
