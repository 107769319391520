import {
  SubscriptionOptionList,
  SubscriptionOption,
} from "@framework/types/subscription"

export const getSelectOptions = (
  subscriptionOptionList: SubscriptionOptionList,
  key: string,
  editOptionList?: SubscriptionOption[]
) => {
  const selectOptions =
    (subscriptionOptionList[key] as unknown as SubscriptionOption[]) || []
  const options = selectOptions?.map((sOption) => {
    const editedOption = editOptionList?.find((e) => e.id === sOption.id)
    return {
      id: sOption.id,
      name: sOption.name,
      value: sOption.name,
      active: editedOption?.active,
      groupName: sOption?.groupName,
    }
  })

  return options
}

export const getSelectedOptionIds = (options: SubscriptionOption[]) =>
  options?.map((sOption) => sOption.id)

export const getSubscriptionOptions = (
  subscriptionOptionList: SubscriptionOptionList,
  key: string
) => {
  return (subscriptionOptionList[key] as unknown as SubscriptionOption[]) || []
}
