import { makeAutoObservable } from "mobx"

import { AvatarDetailsData } from "@framework/types/avatar"

export class AvatarAdminStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading = false

  data: AvatarDetailsData[] | null = null

  error: string | null = null
}

export default AvatarAdminStore
