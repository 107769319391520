import { makeAutoObservable } from "mobx"

class SelectableGrid {
  constructor(selected: [string, string[]][] = []) {
    this.selected = new Map(
      selected.map(([colName, rowValues]) => [colName, new Set(rowValues)])
    )

    makeAutoObservable(this)
  }

  selected: Map<string, Set<string>>

  get rowTotalSelected() {
    return this.selected.size
  }

  get isSelected() {
    return (colName: string, rowName: string) => {
      const row = this.selected.get(colName)
      return row != null && row.has(rowName)
    }
  }

  entries = (): [string, string[]][] => {
    return Array.from(this.selected.entries()).map(([key, list]) => [
      key,
      Array.from(list.values()),
    ])
  }

  select = (xName: string, yName: string, value?: boolean) => {
    const row = this.selected.get(xName)
    if (!row) return
    if ((value != null && !value) || row.has(yName)) row.delete(yName)
    else row.add(yName)
  }
}

export default SelectableGrid
