import flatten from "lodash/flatten"
import keyBy from "lodash/keyBy"

import { randomNumber } from "@utils/numberUtils"
import { delay } from "@utils/promise"

import EditManager from "./EditManager"
import { validateOperand } from "../utils"
import { FunctionDescription } from "../types"

class FunctionManager {
  // injections

  manager: EditManager

  // state

  functions: Record<string, FunctionDescription>

  constructor(options: { manager: EditManager }) {
    this.manager = options.manager

    this.functions = keyBy(defaultFunctions, (it) => it.name.toUpperCase())
  }

  getFunction = (name: string) => {
    const func = this.functions[name.toUpperCase()]

    if (func == null) {
      throw new Error(`Unknown function name: '${name}'`)
    }

    return func
  }

  hasFunction = (name: string) => {
    return this.functions[name.toUpperCase()] != null
  }

  registerFunction = (newFunc: FunctionDescription) => {
    const name = newFunc.name.toUpperCase()

    if (this.hasFunction(name)) return false

    this.functions[name] = { ...newFunc, name }

    return true
  }
}

export default FunctionManager

const defaultFunctions: FunctionDescription[] = [
  {
    name: "SUM",
    handler: (...args: any[]) => args.reduce((acc, it) => acc + it, 0),
    transformArgs: (args: any[]) => {
      if (args.length < 1)
        throw new Error(`Function SUM expects at least one argument`)

      return flatten(args).map((it) => validateOperand(it))
    },
  },
  {
    name: "RANDOM",
    handler: (...args: [number, number]) => randomNumber(...args),
    transformArgs: (args: any[]) => {
      if (args.length > 2)
        throw new Error("Function RANDOM expects two argument max")

      const min = Number(args[0] || 0)
      if (Number.isNaN(min))
        throw new Error("Function RANDOM expects number as a first argument")

      const max = Number(args[1] || 100)
      if (Number.isNaN(max))
        throw new Error("Function RANDOM expects number as a second argument")

      return [min, max]
    },
  },
  {
    name: "DELAY",
    handler: async (...args: [number, any]) => {
      return delay(...args)
    },
    transformArgs: (args: any[]) => {
      if (args.length !== 2)
        throw new Error("Function DELAY expects two argument")

      const timeout = Number(args[0] || 1_000)
      if (Number.isNaN(timeout))
        throw new Error("Function DELAY expects number as a first argument")

      return [timeout, args[1]]
    },
  },
]
