import React from "react"
import { matchPath, useLocation, useNavigate } from "react-router-dom"

import useToggle from "@components/hooks/useToggle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Avatar from "@components/ui/Avatar/Avatar"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { Option } from "@framework/types/utils"
import { useStore } from "@store/index"

import MenuItem from "./MenuItem"
import { NavItemTooltip } from "./NavItemTooltip"

export interface UserSettingsMenuProps {
  menuOptions?: Option[]
}

const UserSettingsMenu: React.FC<UserSettingsMenuProps> = ({
  menuOptions = [],
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const {
    userStore: { user, fullName },
  } = useStore()

  const { showModal } = useModal(ModalsTypes.LOGOUT_MODAL)
  const { isOpened, handleToggle, setOpened } = useToggle()

  const handleLogout = () => {
    showModal({})
  }

  const handleMenu = (name: string) => () => {
    navigate(`/${name}`)
  }

  const isAvatarActive = Boolean(
    menuOptions.find(
      ({ name }) => matchPath(`/${name}`, location.pathname) != null
    )
  )

  return (
    <NavItemTooltip text="Profile" disabled={isOpened}>
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer placement="right">
            {menuOptions?.map((it) => (
              <MenuItem
                color="dark"
                icon={it.icon}
                onClick={handleMenu(it.name)}
                key={it.name}
              >
                {it.value}
              </MenuItem>
            ))}
            <MenuItem
              color="red"
              icon="log-out"
              onClick={handleLogout}
              key="logout"
            >
              Log out
            </MenuItem>
          </TooltipContainer>
        }
      >
        <Avatar
          name={fullName}
          active={isAvatarActive}
          src={user?.avatarURL ?? ""}
          onClick={handleToggle}
        />
      </Tooltip>
    </NavItemTooltip>
  )
}

export default UserSettingsMenu
