import React from "react"
import { Helmet } from "react-helmet"
import { observer } from "mobx-react-lite"

import { useAppConfig } from "@components/theme/AppConfigProvider"

export const AppMetaHelmet: React.FC = observer(() => {
  const theme = useAppConfig()

  return (
    <Helmet>
      <title>{theme.copy.instanceName}</title>
      <meta
        name="description"
        content={`${theme.copy.instanceName} - The Enterprise Answer Engine`}
      />
    </Helmet>
  )
})

export default AppMetaHelmet
