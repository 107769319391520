import { makeAutoObservable } from "mobx"

import { AvatarData } from "@framework/types/avatar"

export class AvatarsStore {
  constructor() {
    makeAutoObservable(this)
  }

  avatars: AvatarData[] | null = null

  loadAvatarError: string | null = null

  isAvatarsLoading = false

  getAvatarById = (avatarId?: string) => {
    if (this.avatars == null) return null
    return this.avatars.find((it) => it.id === avatarId) ?? null
  }

  getAvatarByName = (avatarName?: string) => {
    if (this.avatars == null) return null
    return this.avatars.find((it) => it.name === avatarName) ?? null
  }
}

export default AvatarsStore
