import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { BaseUserRole } from "@framework/types/security"
import { useController } from "@store/index"

import EditRoleForm, { userRoleFormSchema, FormData } from "./EditRoleForm"

import styles from "./EditUserRoleModal.module.sass"

export interface EditUserRoleModalProps {
  role?: BaseUserRole
}

export const EditUserRoleModal: React.FC<EditUserRoleModalProps> = observer(
  ({ role }) => {
    const [roleId] = React.useState(role?.id)
    const isEdit = roleId != null

    const alert = useAlert()

    const { rolesController } = useController()

    const modal = useModal(ModalsTypes.EDIT_USER_ROLE_MODAL)

    const handleSubmit = async (formData: FormData) => {
      modal.hideModal()
      const error = await (isEdit
        ? rolesController.updateRole(roleId, formData)
        : rolesController.createRole(formData))
      if (error) alert.error(error)
    }

    const initial = userRoleFormSchema.cast(role, { stripUnknown: true })

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={isEdit ? "Edit user role" : "Create new role"}
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <EditRoleForm initialValues={initial} onSubmit={handleSubmit} />
      </BaseModal>
    )
  }
)

export default EditUserRoleModal
