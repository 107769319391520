import { makeAutoObservable } from "mobx"

import { DataConnectorSourceName } from "@framework/types/upload"

import ExternalFoldersStores from "./data-folders.store"
import CloudFolderPreviewsStore from "./cloud-folder-previews.store"
import DataSourcesDetailsStore from "./data-sources-details.store"
import DataConnectorAccountsStore from "./data-connector-accounts.store"
import UploadedFilesCollectionStore from "./uploaded-files-virtual-collection.store"
import WebsitesCollectionStore from "./connected-websites-virtual-collection.store"
import CloudFoldersCollectionStore from "./cloud-folders-virtual-collection.store"
import CloudFilesCollectionStore from "./cloud-files-virtual-collection.store"
import ExternalEntitiesStores from "./external-entities-stores.store"
import CloudEntitiesCollectionStore from "./cloud-entities-virtual-collection.store"

export class UploadStore {
  constructor(injections: {
    foldersStore: ExternalFoldersStores
    dataConnectorFoldersStore: CloudFolderPreviewsStore
  }) {
    makeAutoObservable(this)

    this.externalFoldersStores = injections.foldersStore
    this.dataConnectorFoldersStore = injections.dataConnectorFoldersStore

    this.uploadedFilesCollections = new UploadedFilesCollectionStore()
    this.connectedWebsitesCollections = new WebsitesCollectionStore()
  }

  dataSourceDetails = new DataSourcesDetailsStore()

  externalFoldersStores = new ExternalFoldersStores()

  externalEntitiesStores = new ExternalEntitiesStores()

  dataConnectorAccountStores: Record<
    DataConnectorSourceName | string,
    DataConnectorAccountsStore
  > = {}

  dataConnectorFoldersStores: Record<string, CloudFoldersCollectionStore> = {}

  dataConnectorFilesStores: Record<string, CloudFilesCollectionStore> = {}

  dataConnectorEntitiesStores: Record<string, CloudEntitiesCollectionStore> = {}

  dataConnectorFoldersStore = new CloudFolderPreviewsStore()

  uploadedFilesCollections: UploadedFilesCollectionStore

  connectedWebsitesCollections: WebsitesCollectionStore

  getDataConnectorAccountsStoreByName = (
    dataConnectorName: DataConnectorSourceName
  ): DataConnectorAccountsStore => {
    this.dataConnectorAccountStores[dataConnectorName] =
      this.dataConnectorAccountStores[dataConnectorName] ??
      new DataConnectorAccountsStore(dataConnectorName)

    return this.dataConnectorAccountStores[dataConnectorName]
  }

  getCloudFoldersStore = (
    dataConnectorName: DataConnectorSourceName,
    dataConnectorId: string
  ): CloudFoldersCollectionStore => {
    const key = `${dataConnectorName}_${dataConnectorId}`
    // eslint-disable-next-line no-return-assign
    return (this.dataConnectorFoldersStores[key] =
      this.dataConnectorFoldersStores[key] ??
      new CloudFoldersCollectionStore(dataConnectorName, dataConnectorId))
  }

  getCloudFilesStore = (
    dataConnectorName: DataConnectorSourceName,
    dataConnectorId: string,
    folderId: string
  ): CloudFilesCollectionStore => {
    const key = `${dataConnectorName}_${dataConnectorId}_${folderId}`
    // eslint-disable-next-line no-return-assign
    return (this.dataConnectorFilesStores[key] =
      this.dataConnectorFilesStores[key] ??
      new CloudFilesCollectionStore(
        dataConnectorName,
        dataConnectorId,
        folderId
      ))
  }

  getCloudEntitiesStore = (
    dataConnectorName: DataConnectorSourceName,
    dataConnectorId: string
  ): CloudEntitiesCollectionStore => {
    const key = `${dataConnectorName}_${dataConnectorId}`
    // eslint-disable-next-line no-return-assign
    return (this.dataConnectorEntitiesStores[key] =
      this.dataConnectorEntitiesStores[key] ??
      new CloudEntitiesCollectionStore(dataConnectorName, dataConnectorId))
  }
}

export default UploadStore
