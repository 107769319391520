import { Formula } from "../parser/types"
import { ParseResult } from "../parser"
import EditManager from "./EditManager"
import { validateOperand } from "../utils"

class FormulaExecutor {
  // injections

  manager: EditManager

  // state

  formula: ParseResult

  status: "initial" | "inProgress" | "failed" | "completed"

  error: string = ""

  refValues: Record<string, any> | null

  result: any | null = null

  constructor(options: { manager: EditManager; formula: ParseResult }) {
    this.manager = options.manager
    this.formula = options.formula
    this.status = "initial"
    this.refValues = null
  }

  run = async () => {
    try {
      this.status = "inProgress"

      if (!this.formula.isValid) throw new Error("Formula parsing error")

      this.refValues = this.manager.getRefValues(this.formula.refs)

      this.result = await this.evaluate(this.formula.matched, this.refValues)

      this.status = "completed"
    } catch (error: any) {
      this.status = "failed"
      this.error =
        typeof error.message === "string" ? error.message : "Unexpected error"
    }
  }

  evaluate = async (
    formula: Formula,
    values: Record<string, any>
  ): Promise<any> => {
    if (formula.type === "unknown") throw new Error("Formula parsing error")

    switch (formula.type) {
      case "const": {
        return formula.value
      }
      case "ref": {
        return values[formula.name]
      }
      case "un-exp": {
        return this.evaluateUnaryExpression(
          formula.operation,
          await this.evaluate(formula.value, values)
        )
      }
      case "bin-exp": {
        return this.evaluateBinaryExpression(
          formula.operation,
          await this.evaluate(formula.left, values),
          await this.evaluate(formula.right, values)
        )
      }
      case "fun": {
        return this.evaluateFunction(
          formula.name,
          await this.evaluate(formula.block, values)
        )
      }
      case "block": {
        return Promise.all(
          formula.arguments.map((it) => this.evaluate(it, values))
        )
      }

      default:
        return 0
    }
  }

  protected evaluateUnaryExpression = (operation: "+" | "-", arg: any) => {
    const value = validateOperand(arg)

    if (operation === "-") return -value
    return value
  }

  protected evaluateBinaryExpression = (
    operation: "+" | "-" | "*" | "/",
    arg1: any,
    arg2: any
  ) => {
    const leftValue = validateOperand(arg1)
    const rightValue = validateOperand(arg2)

    if (operation === "-") return leftValue - rightValue
    if (operation === "+") return leftValue + rightValue
    if (operation === "/") return leftValue / rightValue
    return leftValue * rightValue
  }

  protected evaluateFunction = async (
    funcName: string,
    args: any[]
  ): Promise<any> => {
    const funcDescription = this.manager.functionManager.getFunction(funcName)

    const res = await funcDescription.handler(
      ...funcDescription.transformArgs(args)
    )

    return res
  }
}

export default FormulaExecutor
