import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import { APICallsHistoryData } from "@framework/types/analytics"
import { US_DATE_FORMAT } from "@framework/constants/global"
import Column from "@components/ui/BaseTable/Column"
import Text from "@components/ui/Typography/Text"
import Row from "@components/ui/BaseTable/Row"
import Chip from "@components/ui/Chip/Chip"
import { renderDuration } from "@utils/date"

interface QueryHistoryTableRowProps {
  data: APICallsHistoryData
}

const APICallRow: React.FC<QueryHistoryTableRowProps> = observer(({ data }) => {
  const { id, createdAt, apiKeyName, code, url, responseTime } = data

  const renderStatus = () => {
    return (
      <Chip
        color={code >= 300 ? (code >= 400 ? "red" : "gold") : "green"}
        variant="rounded"
      >
        {code}
      </Chip>
    )
  }

  return (
    <Row>
      <Column key={`${id}-status`}>{renderStatus()}</Column>

      <Column key={`${id}-path`}>{url}</Column>

      <Column key={`${id}-date`}>
        <Text variant="inherit">
          {moment(createdAt).format(US_DATE_FORMAT)}
          <br />
          {moment(createdAt).format("HH:mm")}
        </Text>
      </Column>

      <Column key={`${id}-duration`}>
        {renderDuration(responseTime, "ms")}
      </Column>

      <Column key={`${id}-key-name`}>{apiKeyName}</Column>
    </Row>
  )
})

export default APICallRow
