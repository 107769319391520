import React from "react"

import ProductCard from "./components/ProductCard"

import styles from "./index.module.sass"

const DATA = [1, 2, 3, 4]

const ProductRecommender: React.FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.productsContainer}>
        {DATA.map((item) => (
          <ProductCard key={item} />
        ))}
      </div>
    </div>
  )
}

export default ProductRecommender
