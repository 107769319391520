import { AxiosResponse } from "axios"

import { DataCategoryEntity } from "@framework/types/knowledge"

import HttpService from "./http.service"

export interface GetKnowledgeDatatypeResponse {
  data: DataCategoryEntity
}

class KnowledgeDatatypesAPI extends HttpService {
  createDatatype = (
    payload: Partial<DataCategoryEntity>
  ): Promise<AxiosResponse<DataCategoryEntity>> =>
    this.post("ts/knowledge-data-type", payload)

  getKnowledgeDatatype = (
    id: string
  ): Promise<AxiosResponse<GetKnowledgeDatatypeResponse>> =>
    this.get(`ts/knowledge-data-type?knowledgeDataTypeId=${id}`)

  removeDatatype = (
    knowledgeDataTypeId: string,
    newKnowledgeDataTypeId?: string
  ): Promise<AxiosResponse<any>> =>
    this.delete("ts/knowledge-data-type", {}, true, {
      knowledgeDataTypeId,
      newKnowledgeDataTypeId,
    })

  editDatatype = (
    knowledgeDataTypeId: string,
    data: Partial<DataCategoryEntity>
  ): Promise<AxiosResponse<DataCategoryEntity>> =>
    this.patch("ts/knowledge-data-type", data, true, { knowledgeDataTypeId })
}

export default new KnowledgeDatatypesAPI()
