/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from "react"
import clsx from "clsx"
import { matchPath, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Avatar from "@components/ui/Avatar/Avatar"
import Chip from "@components/ui/Chip/Chip"
import Icon from "@components/ui/Icon/Icon"
import { Option } from "@framework/types/utils"
import useToggle from "@components/hooks/useToggle"
import Text from "@components/ui/Typography/Text"

import NavItem from "../NavItem/NavItem"

import styles from "./Profile.module.sass"

export interface ProfileProps {
  menuOptions: Option[]
}

export const Profile: React.FC<ProfileProps> = observer(({ menuOptions }) => {
  const location = useLocation()

  const { isOpened, setOpened, handleToggle } = useToggle()

  const { userStore } = useStore()

  const { user, fullName } = userStore

  useEffect(() => {
    const showProfileMenu = !!menuOptions.find(
      ({ name }) => matchPath(`/${name}`, location.pathname) != null
    )
    setOpened(showProfileMenu)
  }, [menuOptions, location.pathname])

  return (
    <div className={clsx(styles.root)}>
      <div className={clsx(styles.profile)} onClick={handleToggle}>
        <Avatar
          className={styles.avatar}
          name={fullName}
          src={user?.avatarURL ?? ""}
        />

        <div className={styles.title}>
          <Text variant="h1" weight="bold">
            {fullName}
          </Text>
        </div>

        <Icon
          className={styles.trigger}
          name="arrow-down"
          rotateAngle={isOpened ? 180 : 0}
        />
      </div>

      <div
        className={clsx(styles.menuContainer, styles.bordered, {
          [styles.visible]: isOpened,
        })}
      >
        <div className={styles.menu}>
          {menuOptions.map(({ value, name, icon }) => (
            <NavItem to={`/${name}`} icon={icon} label={value} key={name} />
          ))}
        </div>
      </div>
    </div>
  )
})

export default Profile
