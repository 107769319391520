import { makeAutoObservable } from "mobx"

import { ExpertSubtopicDTO } from "@framework/types/knowledge"
import LoadableEntity from "@store/utils/LoadableEntity"

export default class ExpertSubtopicStore {
  constructor() {
    makeAutoObservable(this)

    this.dataMap = new Map()
  }

  dataMap: Map<string, LoadableEntity<ExpertSubtopicDTO>>

  getEntity = (subtopicId: string) => {
    if (!this.dataMap.has(subtopicId)) this.resetCollection(subtopicId)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.dataMap.get(subtopicId)!
  }

  resetCollection = (subtopicId: string) => {
    this.dataMap.set(subtopicId, new LoadableEntity({ id: subtopicId }))
  }
}
