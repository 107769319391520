import React from "react"
import { observer } from "mobx-react-lite"
import findIndex from "lodash/findIndex"
import findLastIndex from "lodash/findLastIndex"

import Switch from "@components/ui/Switch/Switch"
import MultiStepContextProvider, {
  MultiStepContextData,
} from "@components/ui/MultiStep/MultiStepContext"

export type Step = {
  name: string
  value: string
  disabled?: boolean
}

export type MultiStepProps = {
  steps: Step[]
  onExit?: () => void
  onComplete?: () => void
  children: (step: Step, context: MultiStepContextData) => React.ReactNode
  canJump?: boolean
  switchClassName?: string
}

export const MultiStep: React.FC<MultiStepProps> = observer(
  ({
    steps,
    onExit: handleExit,
    onComplete: handleComplete,
    children,
    canJump = true,
    switchClassName,
  }) => {
    const [stepIdx, setStepIdx] = React.useState<number>(
      // by default - first non-disabled step would be the active one
      findIndex(steps, (step) => {
        return !step.disabled
      })
    )

    const handleNext = (fromIdx = stepIdx) => {
      const targetIdx = fromIdx + 1

      if (steps[targetIdx]?.disabled) {
        handleNext(targetIdx)
        return
      }

      if (targetIdx < steps.length) {
        setStepIdx(targetIdx)
        return
      }

      if (handleComplete) handleComplete()
    }

    const handleBack = (fromIdx = stepIdx) => {
      const targetIdx = fromIdx - 1

      if (steps[targetIdx]?.disabled) {
        handleBack(targetIdx)
        return
      }

      if (targetIdx >= 0) {
        setStepIdx(targetIdx)
        return
      }

      if (handleExit) handleExit()
    }

    const isLastStep =
      findLastIndex(steps, (step) => {
        return !step.disabled
      }) === stepIdx

    const step = steps[stepIdx]

    const context = { isLastStep, back: handleBack, next: handleNext }

    return (
      <MultiStepContextProvider {...context}>
        <Switch
          items={steps}
          checked={step.name}
          onChange={canJump ? (_, idx) => setStepIdx(idx) : undefined}
          className={switchClassName}
          key="tabs"
        />

        {step != null && children(step, context)}
      </MultiStepContextProvider>
    )
  }
)

export default MultiStep
