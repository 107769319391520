/* eslint-disable react/no-unstable-nested-components */
import React from "react"
import clsx from "clsx"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import { Option } from "@framework/types/utils"
import DropDownContainer from "@components/ui/Dropdown/DropDownContainer"
import Box from "@components/ui/Dropdown/Box"

import styles from "./ContextMenu.module.sass"

interface ContextMenuProps {
  id: string
  options: Option[]
  onContext?: (id: string, name: string) => void
}

const ContextMenu = React.memo(
  ({ id, options, onContext }: ContextMenuProps) => {
    const handleContext = (name: string) => () => {
      onContext?.(id, name)
    }

    return (
      <DropDownContainer
        placement="bottom-end"
        target={(isActive) => (
          <IconButton size="medium" active={isActive}>
            <Icon name="dots" />
          </IconButton>
        )}
      >
        <Box color="primary">
          {options.map(({ icon, name, value, className }) => (
            <Button
              className={clsx(styles.menuItem, className)}
              variant="slim"
              onClick={handleContext(name)}
              key={name}
            >
              <Icon name={icon} />
              {value}
            </Button>
          ))}
        </Box>
      </DropDownContainer>
    )
  }
)

export default ContextMenu
