import React, { useEffect } from "react"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import { useController, useStore } from "@store/index"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import { SolutionTypes } from "@framework/constants/search-results"
import NotFound from "@components/ui/NotFound/NotFound"
import AvatarPickerSidebar from "@components/prototypes/AvatarPickerSidebar"

import ActiveAvatarContextProvider from "../search/ActiveAvatarContext"
import SolutionTabsContainer from "./SolutionTabsContainer/SolutionTabsContainer"
import FactFinderPage from "./SolutionTabsContainer/components/Tabs/FactFinder"
import DocChat from "./SolutionTabsContainer/components/Tabs/DocChat"
import ProductRecommender from "./SolutionTabsContainer/components/Tabs/ProductRecommender"
import ProductComparison from "./SolutionTabsContainer/components/Tabs/ProductComparison"
import useAvatarScope from "../search/AvatarScopeContext/useAvatarScope"
import UnifiedMatrix from "./SolutionTabsContainer/components/Tabs/UnifiedMatrix"

import styles from "./LaunchSolutions.module.sass"

const LaunchSolutions: React.FC = observer(() => {
  const { solutionType } = useParams()

  if (solutionType == null) throw new Error("'solutionType' is required param")

  const {
    factFinderSolutionStore: { searchFlowStore },
    solutionsStore,
  } = useStore()
  const {
    appConfigController: { loadAllInstanceConfigurations },
  } = useController()

  const { avatar, setAvatar } = useAvatarScope()

  useEffect(() => {
    document.documentElement.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    })
  }, [])

  useEffect(() => {
    document.documentElement.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    })
  }, [searchFlowStore.flowSequence.length])

  useEffect(() => {
    loadAllInstanceConfigurations()
  }, [])

  const activeSolution = solutionsStore.solutions?.find(
    (it) => it.key === solutionType
  )

  const renderSolution = () => {
    switch (activeSolution?.key) {
      case SolutionTypes.InsightsFinder:
        return <FactFinderPage data={activeSolution} />

      case SolutionTypes.PrivateWorkspace:
        return <DocChat data={activeSolution} />

      case SolutionTypes.ApplicationImplementation:
        return <UnifiedMatrix />

      case SolutionTypes.ProductComparison:
        return (
          <Routes>
            <Route
              path="/"
              element={<ProductComparison data={activeSolution} />}
            >
              <Route
                path=":step"
                element={<ProductComparison data={activeSolution} />}
              />
            </Route>
          </Routes>
        )

      case SolutionTypes.ProductRecommender:
        return <ProductRecommender />

      default:
        return <NotFound>Solution is not available</NotFound>
    }
  }

  return (
    <MainLayout>
      <div className={clsx(styles.root)}>
        <Templates.Header
          className={styles.header}
          left={
            <Text variant="h1" weight="bold">
              Solutions
            </Text>
          }
          right={
            <AvatarPickerSidebar value={avatar?.id} onChange={setAvatar} />
          }
        />

        <div className={styles.topContainer}>
          {!!avatar && (
            <ActiveAvatarContextProvider avatarId={avatar.id}>
              <SolutionTabsContainer />
            </ActiveAvatarContextProvider>
          )}
        </div>

        {!!solutionsStore.solutions?.length && (
          <>
            <div className={styles.body}>{renderSolution()}</div>

            <Outlet />
          </>
        )}
      </div>
    </MainLayout>
  )
})

export default LaunchSolutions
