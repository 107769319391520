import { makeAutoObservable } from "mobx"

import AvatarKnowledgePreviewStore from "./avatar-knowledge-preview.store"

export default class KnowledgePreviewStore {
  constructor() {
    makeAutoObservable(this)
  }

  avatarPreviewsMap: Map<string, AvatarKnowledgePreviewStore> = new Map()

  getAnswersCollection = (avatarId: string) => {
    if (!this.avatarPreviewsMap.has(avatarId))
      this.resetAnswersCollections(avatarId)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.avatarPreviewsMap.get(avatarId)!
  }

  resetAnswersCollections = (avatarId: string) => {
    this.avatarPreviewsMap.set(avatarId, new AvatarKnowledgePreviewStore())
  }
}
