/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"

import { DataConnectorAccountEntity } from "@framework/types/upload"
import Chip from "@components/ui/Chip/Chip"
import Text from "@components/ui/Typography/Text"
import List from "@components/ui/List/List"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import Icon from "@components/ui/Icon/Icon"
import { renderShortNumber } from "@utils/numberUtils"
import UserChip from "@components/prototypes/UserChip"

import styles from "./DataConnectorAccountItem.module.sass"

export interface DataConnectorAccountItemProps {
  data: DataConnectorAccountEntity
  control?: React.ReactNode
  onClick?: React.MouseEventHandler
}

export const DataConnectorAccountItem: React.FC<DataConnectorAccountItemProps> =
  observer(({ data, control, onClick }) => {
    const connectorContext = getDataSourceNodeContextProps(data.connectorType)

    const totalFolders = data.totalFolders ?? 0
    const totalFiles = data.totalFiles ?? 0

    return (
      <button type="button" className={styles.root} onClick={onClick}>
        <DocumentIcon icon={connectorContext.icon} width={27} height={27} />

        <div>
          <div className={styles.header}>
            <Text variant="h3">{data.name}</Text>
            {control}
          </div>

          <div className={styles.meta}>
            <Text variant="caption2" color="text50Color">
              Last update: {moment(data.lastUpdatedAt).fromNow()}
            </Text>
          </div>

          <div className={styles.footer}>
            <List direction="row" gutter="4" align="center">
              <Text variant="caption1" color="text70Color">
                Connected by:{" "}
              </Text>
              <UserChip data={data.lastUpdatedBy} />
            </List>

            <List direction="row" gutter="8">
              <Chip
                title={`${totalFolders} Folders`}
                color="secondary"
                variant="rounded"
                before={<Icon name="folder" />}
              >
                {renderShortNumber(totalFolders)}
              </Chip>
              <Chip
                title={`${totalFiles} Files`}
                color="secondary"
                variant="rounded"
                before={<Icon name="file" />}
              >
                {renderShortNumber(totalFiles)}
              </Chip>
            </List>
          </div>
        </div>
      </button>
    )
  })

export default DataConnectorAccountItem
