import React from "react"
import moment from "moment"

import { IEEEFooterConfig } from "@framework/types/theme"

import styles from "./IEEEFooter.module.sass"

export const IEEEFooter: React.FC<{ data: IEEEFooterConfig }> = React.memo(
  ({ data }) => {
    const currentYear = moment().year()

    const links = data?.links ?? []
    const copyright = data?.copyright ?? ""

    return (
      <footer className={styles.root}>
        {links.length > 0 && (
          <ul className={styles.links}>
            {links.map((it) => {
              return (
                <li className={styles.link} key={it.label}>
                  <a href={it.url}>{it.label}</a>
                </li>
              )
            })}
          </ul>
        )}

        {!!copyright && (
          <section className={styles.copyright}>
            <p>
              {copyright.replaceAll("$current-year", currentYear.toString())}
            </p>
          </section>
        )}
      </footer>
    )
  }
)

export default IEEEFooter
