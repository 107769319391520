import { makeAutoObservable } from "mobx"

import RootStore from "@store/RootStore"
import avatarsService from "@services/avatars.service"

import AvatarAdminStore from "./avatar-admin.store"

export class AvatarAdminController {
  avatarAdminStore: AvatarAdminStore

  constructor(injections: RootStore) {
    makeAutoObservable(this)
    this.avatarAdminStore = injections.avatarAdminStore
  }

  loadAvatars = async () => {
    this.avatarAdminStore.isLoading = true
    this.avatarAdminStore.error = null

    try {
      const response = await avatarsService.getAdminAvatars()
      if (response.status < 400) {
        this.avatarAdminStore.data = response.data.data
        return
      }
    } catch (error) {
      this.avatarAdminStore.data = null
      this.avatarAdminStore.error = "Can't load avatars list"
    } finally {
      this.avatarAdminStore.isLoading = false
    }
  }
}

export default AvatarAdminController
