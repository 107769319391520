import { makeAutoObservable } from "mobx"

import { SummaryQueryType } from "./types"

export class SearchMessageBlockStore {
  // injections

  // constructor

  constructor(config: { id: string }) {
    this.id = config.id
    this.timestamp = Date.now()

    makeAutoObservable(this)
  }

  // state

  id: string

  timestamp: number

  messages: string[] = []

  queryType: SummaryQueryType = "DEFAULT"

  isLoading = false

  // actions

  addMessage = (message: string) => {
    this.messages = [...this.messages, message]
  }

  replaceMessage = (messageIdx: number, message: string) => {
    this.messages[messageIdx] = message
  }

  setLoading = (loading: boolean) => {
    this.isLoading = loading
  }
}

export default SearchMessageBlockStore
