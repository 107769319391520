/* eslint-disable camelcase */
import { useMemo } from "react"

import useQuery from "@components/hooks/useQuery"

export const OAuthState = {
  stringify: <T extends object>(form: T): string => btoa(JSON.stringify(form)),
  parse: <T extends object>(state: string | null): T | any => {
    if (!state) return {}
    return JSON.parse(atob(state))
  },
}

export const useAuthCodeParams = <T extends object = object>(
  validator?: (form: T) => boolean
) => {
  const queryParams = useQuery()

  const state = queryParams.get("state")
  const code = queryParams.get("code")

  const form = useMemo(() => OAuthState.parse<T>(state), [state])

  return useMemo(() => {
    if (!code || (!!validator && !validator?.(form))) return null
    return { form, code }
  }, [code, form])
}
