import { makeAutoObservable } from "mobx"

import { SearchingTrendPointData } from "@framework/types/analytics"

export default class SearchingTrendsStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading = false

  error: string | null = null

  data: SearchingTrendPointData[] = []

  setData = (data: SearchingTrendPointData[]) => {
    this.data = data
  }

  resetData = () => {
    this.data = []
  }
}
