import { observer } from "mobx-react-lite"
import React from "react"

import Icon from "@components/ui/Icon/Icon"
import { useAppThemeManager } from "@components/theme/AppThemeProvider"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import useToggle from "@components/hooks/useToggle"
import ListItem from "@components/ui/ListItem/ListItem"
import Button from "@components/ui/Button/Button"

import NavItemTooltip from "./NavItemTooltip"

export const UIThemeMenu: React.FC = observer(() => {
  const { activeColorSchemeName, colorSchemeOptions, setActiveColorScheme } =
    useAppThemeManager()

  const { isOpened, handleToggle, setOpened } = useToggle()

  if (colorSchemeOptions.length < 2) return null

  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      content={
        <TooltipContainer placement="right">
          {colorSchemeOptions.map((it) => (
            <ListItem
              active={activeColorSchemeName === it.name}
              onClick={() => {
                setActiveColorScheme(it.name)
                setOpened(false)
              }}
              key={it.name}
            >
              {it.name}
            </ListItem>
          ))}
        </TooltipContainer>
      }
    >
      <NavItemTooltip text="Theme" disabled={isOpened}>
        <Button
          noPadding
          variant="outlined"
          color="default"
          size="big"
          onClick={handleToggle}
        >
          <Icon name="palette" />
        </Button>
      </NavItemTooltip>
    </Tooltip>
  )
})

export default UIThemeMenu
