import { useContext } from "react"

import { AvatarMembershipContext } from "./AvatarMembershipContext"

const useAvatarMembershipContext = () => {
  const context = useContext(AvatarMembershipContext)
  if (context != null) return context
  throw new Error("Avatar Edit Context not found")
}

export default useAvatarMembershipContext
