import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { BehaviorSubject, Subscription, timer } from "rxjs"
import { switchMap, tap, retry } from "rxjs/operators"

import { BaseUserData } from "@framework/types/user"
import userService from "@services/user.service"

const MAX_BATCH_SIZE = 5_000
const PULLING_DELAY = 60 * 1000
const RETRY_DELAY = 10 * 1000

type State = {
  isLoading: boolean
  data: BaseUserData[]
  total: number
  errorMessage: string
}

type Page = {
  pageNum: number
  pageSize: number
}

export class AllUsersStore {
  state: State

  private loadingStream$?: Subscription

  private currentPage$?: BehaviorSubject<Page>

  constructor() {
    this.state = {
      isLoading: false,

      data: [],

      total: 0,

      errorMessage: "",
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "data", () => {
      this.loadingStream$ = this.initLoadingStream()
    })

    onBecomeUnobserved(this.state, "data", () => {
      this.currentPage$?.unsubscribe()
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    this.currentPage$ = new BehaviorSubject({
      pageNum: 0,
      pageSize: MAX_BATCH_SIZE,
    })

    return this.currentPage$
      .pipe(
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.errorMessage = ""
          })
        ),
        switchMap((pagination) => userService.getUsers$(pagination)),
        tap((response) => {
          const { pageNum, pageSize, total } = response.data.meta

          runInAction(() => {
            const newData = response.data.data ?? []

            this.state.data = this.state.data.slice(0, total)

            this.state.data.splice(pageNum * pageSize, pageSize, ...newData)

            this.state.total = total ?? this.state.data.length ?? 0
          })

          const loaded = (pageNum + 1) * pageSize

          if (total > loaded) {
            this.loadMore(pageNum + 1, pageSize)
            return
          }

          runInAction(() => {
            this.state.isLoading = false
          })
        }),
        retry({
          delay: () => {
            runInAction(() => {
              this.state.errorMessage = "Loading Failed"
            })
            return timer(RETRY_DELAY)
          },
        }),

        switchMap(() => timer(PULLING_DELAY)),

        tap(() => this.refresh())
      )
      .subscribe()
  }

  get getById() {
    const usersMap = new Map(this.state.data.map((it) => [it.id, it]))
    return (userId: string | null) =>
      userId == null ? null : usersMap.get(userId) ?? null
  }

  loadMore = (pageNum: number, pageSize: number) => {
    this.currentPage$?.next({ pageNum, pageSize })
  }

  refresh = () => {
    this.loadMore(0, MAX_BATCH_SIZE)
  }
}

export default AllUsersStore
