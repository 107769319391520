import React from "react"

import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { UserActionEntity } from "@framework/types/security"
import Icon from "@components/ui/Icon/Icon"

import styles from "./index.module.sass"

interface ActionDescriptionProps {
  action: UserActionEntity
}

const ActionDescription: React.FC<ActionDescriptionProps> = React.memo(
  ({ action }) => {
    return (
      <div className={styles.label}>
        {action.name.toUpperCase()}
        <Tooltip
          mode="onHover"
          justify="start"
          className={styles.tooltip}
          content={
            <TooltipContainer
              color="primary"
              placement="right"
              className={styles.tooltipPopup}
            >
              {action.description || "This action has no description"}
            </TooltipContainer>
          }
        >
          <Icon name="info" />
        </Tooltip>
      </div>
    )
  }
)

export default ActionDescription
