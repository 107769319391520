import { makeAutoObservable } from "mobx"

import { Entity } from "@framework/types/common"

export default class LoadableEntity<T extends Entity = Entity> {
  constructor(config: { id: string }) {
    makeAutoObservable(this)

    this.id = config.id
  }

  id: string

  isLoading = false

  error: string | null = null

  data: T | null = null

  setData = (data: T | null) => {
    this.data = data
  }

  resetData = () => {
    this.data = null
  }
}
