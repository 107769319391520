import React, { MouseEventHandler } from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"

import { CheckboxSize } from "./types"

import styles from "./Checkbox.module.sass"

export interface CheckboxProps {
  id?: string
  className?: string
  size?: keyof typeof CheckboxSize
  name?: string
  disabled?: boolean
  checked?: boolean
  partial?: boolean
  onClick?: MouseEventHandler
}

export const Checkbox: React.FC<CheckboxProps> = ({
  size = CheckboxSize.medium,
  name,
  id,
  disabled,
  checked = false,
  className,
  partial = false,
  onClick,
}) => (
  <div className={clsx(styles.root, styles[size], className)}>
    <input
      id={id}
      name={name}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      readOnly={!onClick}
      onClick={onClick}
    />
    <div className={styles.fakeCheckbox}>
      <Icon className={styles.iconChecked} name={partial ? "minus" : "done"} />
    </div>
  </div>
)

export default Checkbox
