import React, { useState } from "react"
import { useAlert } from "react-alert"

import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { QuestionFeedbackType } from "@framework/constants/search-results"
import { FeedbackDTO } from "@framework/types/feedback"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { useController, useStore } from "@store/index"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Rating from "@components/ui/Rating/Rating"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"

import styles from "./StarRatings.module.sass"

const SUCCESS_MESSAGE_TITLE = "You've successfully voted"
const SUCCESS_MESSAGE_SUBTITLE = "Thank you for your feedback!"
const APP_FEEDBACK_TITLE = "How was your overall experience"

type StarRatingsProps = {
  className?: string
  disabled?: boolean
  message?: string
}

const StarRatings: React.FC<StarRatingsProps> = ({
  disabled = false,
  message = "",
}) => {
  const alert = useAlert()
  const modal = useModal(ModalsTypes.ANSWER_FEEDBACK_MODAL)
  const feedbackModal = useModal(ModalsTypes.APP_FEEDBACK_MODAL)

  const { searchEntityBlock, searchEntity } = useSearchBlock()
  const {
    factFinderSolutionController,
    feedbackController: { getPrompt, sendFeedback, ignore },
  } = useController()
  const { solutionsStore } = useStore()
  const { solution } = solutionsStore

  if (!(searchEntityBlock instanceof SearchSummaryBlockStore))
    throw new Error(
      "This answers data store does not support voting feature yet"
    )

  const [rating, setRating] = useState(3)

  const handleStarClick = (starIndex: number) => {
    setRating(starIndex)
    handleFeedback(starIndex)
  }

  const handleAlert = (error: string | null, title?: string) => {
    if (error) {
      alert.error(error)
      return
    }
    alert.success(
      <>
        {title ?? SUCCESS_MESSAGE_TITLE}
        <Text variant="h5" color="text50Color">
          {SUCCESS_MESSAGE_SUBTITLE}
        </Text>
      </>
    )
  }

  const handleFeedback = async (ratingVal: number) => {
    const rawAnswer = searchEntityBlock.serialize()

    if (rawAnswer == null) return

    const feedback =
      ratingVal > 3
        ? QuestionFeedbackType.POSITIVE
        : ratingVal === 3
        ? QuestionFeedbackType.NEUTRAL
        : QuestionFeedbackType.NEGATIVE

    modal.showModal({
      onSubmit: async (rating: number, message: string) => {
        const error = await factFinderSolutionController.voteForQuestion(
          searchEntityBlock,
          searchEntity.filter.searchAvatar,
          searchEntity.filter.searchAvatarId,
          searchEntity.filter.searchQuery,
          feedback,
          rawAnswer,
          message,
          searchEntityBlock.queryType,
          solution?.id,
          rating
        )

        handleAlert(error)

        setRating(rating)

        if (rating === 5) {
          await handlePositiveFeedback()
        }

        modal.hideModal()
      },
      rating: ratingVal,
      onHide: () => {
        setRating(3)
      },
    })
  }

  const handleSubmitFeedback = async (payload: FeedbackDTO) => {
    const error = await sendFeedback(payload)
    handleAlert(error, APP_FEEDBACK_TITLE)
  }

  const handleSendLater = async () => {
    const error = await ignore()
    handleAlert(error, APP_FEEDBACK_TITLE)
  }

  const handlePositiveFeedback = async () => {
    const prompt = await getPrompt()

    if (prompt && prompt.showPrompt) {
      feedbackModal.showModal({
        onSubmit: handleSubmitFeedback,
        onClose: handleSendLater,
        withComment: true,
        title: APP_FEEDBACK_TITLE,
      })
    }
  }

  return (
    <Tooltip
      content={
        <TooltipContainer
          placement="bottom"
          className={styles.tooltip}
          color="primary"
        >
          <div className={styles.defaultRating}>
            <Text variant="h4">
              {disabled ? `You rated ${rating}` : "Default rating 3"}
            </Text>
            <Icon name="star-filled" color="gold" />
          </div>

          {disabled ? (
            message && <Text variant="h6">{message}</Text>
          ) : (
            <Text variant="h6">
              You can also write a feedback by clicking on the star ratings
            </Text>
          )}
        </TooltipContainer>
      }
      mode="onHover"
    >
      <Rating
        value={rating}
        {...(!disabled && { onChange: handleStarClick })}
      />
    </Tooltip>
  )
}

export default StarRatings
