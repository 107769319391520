import { AppRestrictions } from "../types/app"

const DEFAULT_RESTRICTION_VALUE = false

export const defaultAppRestrictions: AppRestrictions = {
  showChannels: DEFAULT_RESTRICTION_VALUE,
  showSharing: DEFAULT_RESTRICTION_VALUE,
  showAnswerFeedback: DEFAULT_RESTRICTION_VALUE,
  showProductSolutions: DEFAULT_RESTRICTION_VALUE,
  groupResults: DEFAULT_RESTRICTION_VALUE,
  showDataExplorer: DEFAULT_RESTRICTION_VALUE,
  showButtonLink: DEFAULT_RESTRICTION_VALUE,
  twoFactorAuth: DEFAULT_RESTRICTION_VALUE,
  showFileDeleter: DEFAULT_RESTRICTION_VALUE,
  showHighlightBox: DEFAULT_RESTRICTION_VALUE,
  showTableScreenShot: DEFAULT_RESTRICTION_VALUE,
  showLatestUpdates: DEFAULT_RESTRICTION_VALUE,
  showMetadata: DEFAULT_RESTRICTION_VALUE,

  isSSOEnabled: DEFAULT_RESTRICTION_VALUE,
  enableSAMLSSO: false,

  showSortModifiedDateOption: false,

  disableSearchExtendedView: false,

  disableInformalKnowledgeSection: false,
  disableExpertKnowledgeSection: false,

  showSummaryAIAlert: false,

  showGlossary: false,
  enableGlossaryUpload: false,

  enableVoiceRecognizer: true,
}

export const AppMediaQueries = {
  minMobile: "(max-width: 465px)",
  maxMobile: "(max-width: 768px)",
  minTablet: "(max-width: 900px)",
  maxTablet: "(max-width: 1100px)",
  minDesktop: "(max-width: 1200px)",
  maxDesktop: "(max-width: 1450px)",
  wideDesktop: "(max-width: 1700px)",
}
