import { AxiosResponse } from "axios"

import { AppUIConfig } from "@framework/types/theme"
import { TypedFilter } from "@store/search/search-filters.store"

import {
  AppConfig,
  AppPatchNote,
  FeatureFlag,
  InstanceConfigRecord,
  ServiceStatus,
} from "../framework/types/app"
import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export interface GetAppChangeLogResponse extends DefaultSuccessResponse {
  data: AppPatchNote[]
}

export interface GetAppConfigResponse extends DefaultSuccessResponse {
  data: AppConfig
}

export type GetAppServicesStatus = ServiceStatus[]

export interface GetAllFeatureFlagsResponse {
  data?: FeatureFlag[]
}

export interface UpdateFeatureFlagResponse {
  data: FeatureFlag
}

export interface GetAllInstanceConfigsResponse {
  data: InstanceConfigRecord[] | null
}

export interface GetInstanceConfigResponse {
  data: Partial<AppUIConfig>
}

class AppAPI extends HttpService {
  getChangeLog = (): Promise<AxiosResponse<GetAppChangeLogResponse>> =>
    this.get("ts/changelog")

  getAppConfig = (): Promise<AxiosResponse<GetAppConfigResponse>> =>
    this.get("ts/user/config")

  getServiceStatus = (): Promise<AxiosResponse<GetAppServicesStatus>> =>
    this.get("ts/healthcheck/status")

  getAllFeatureFlags = (): Promise<AxiosResponse<GetAllFeatureFlagsResponse>> =>
    this.get("ts/admin/config-flag/all")

  createFeatureFlag = (flag: {
    key: string
    value: boolean
  }): Promise<AxiosResponse<unknown>> => this.post("ts/admin/config-flag", flag)

  editFeatureFlag = (
    flagId: string,
    value: boolean
  ): Promise<AxiosResponse<UpdateFeatureFlagResponse>> =>
    this.patch("ts/admin/config-flag", { value }, true, {
      configurationId: flagId,
    })

  removeFeatureFlag = (flagId: string): Promise<AxiosResponse<unknown>> =>
    this.delete("ts/admin/config-flag", {}, true, {
      configurationId: flagId,
    })

  getAllConfigurations = (): Promise<
    AxiosResponse<GetAllInstanceConfigsResponse>
  > => this.get("ts/company-configurations")

  updateConfiguration = (
    companyConfigurationId: string,
    key: string,
    value: any
  ): Promise<AxiosResponse<unknown>> =>
    this.patch("ts/company-configurations", { key, value }, true, {
      companyConfigurationId,
    })

  createConfiguration = (
    key: string,
    value: any
  ): Promise<AxiosResponse<unknown>> =>
    this.post("ts/company-configurations", { key, value })

  deleteConfigurationById = (
    companyConfigurationId: string
  ): Promise<AxiosResponse<unknown>> =>
    this.delete("ts/company-configurations", {}, true, {
      companyConfigurationId,
    })

  getUIThemeConfiguration = (): Promise<
    AxiosResponse<GetInstanceConfigResponse>
  > => this.get("ts/company-configurations/theme")
}

export default new AppAPI()
