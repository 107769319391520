import { makeAutoObservable } from "mobx"

import { ConnectedCloudFolder } from "@framework/types/upload"

export class CloudFolderPreviewsStore {
  error: string | null = null

  isLoading = false

  collection: Map<string, ConnectedCloudFolder>

  constructor() {
    makeAutoObservable(this)

    this.collection = new Map()
  }

  get getById() {
    return (id: string) => this.collection.get(id)
  }
}

export default CloudFolderPreviewsStore
