import { makeAutoObservable } from "mobx"

import AvatarsStore from "@store/avatars/avatars.store"
import knowledgeService from "@services/knowledge.service"

import KnowledgePreviewStore from "./knowledge-preview.store"

export default class AvatarKnowledgePreviewController {
  knowledgePreviewStore: KnowledgePreviewStore

  avatarsStore: AvatarsStore

  constructor(injections: {
    knowledgePreviewStore: KnowledgePreviewStore
    avatarsStore: AvatarsStore
  }) {
    this.knowledgePreviewStore = injections.knowledgePreviewStore
    this.avatarsStore = injections.avatarsStore
    makeAutoObservable(this)
  }

  loadExpertAnswers = async (avatarId: string) => {
    const { expertKnowledgeStore } =
      this.knowledgePreviewStore.getAnswersCollection(avatarId)
    try {
      expertKnowledgeStore.isLoading = true
      expertKnowledgeStore.loadingError = null

      const avatar = this.avatarsStore.getAvatarById(avatarId)

      if (avatar == null) throw new Error("No avatar found")

      const response = await knowledgeService.getExpertAnswersByAvatarId(
        avatar.id,
        { pageNum: 0, pageSize: 10 }
      )

      expertKnowledgeStore.appendFromResponse(
        response.data.data,
        response.data.meta
      )
    } catch (e) {
      expertKnowledgeStore.loadingError = "Unexpected error"
    } finally {
      expertKnowledgeStore.isLoading = false
    }
  }

  loadDataCategories = async (avatarId: string) => {
    const { dataCategoriesStore } =
      this.knowledgePreviewStore.getAnswersCollection(avatarId)
    try {
      dataCategoriesStore.isLoading = true
      dataCategoriesStore.error = null

      const avatar = this.avatarsStore.getAvatarById(avatarId)
      if (avatar == null) throw new Error("AVATAR_NOT_FOUND")

      const response = await knowledgeService.getDataTypesByAvatarId(avatar.id)
      dataCategoriesStore.setData(response.data.data)
    } catch (e) {
      dataCategoriesStore.error = "Unexpected error"
    } finally {
      dataCategoriesStore.isLoading = false
    }
  }
}
