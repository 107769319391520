import React from "react"
import clsx from "clsx"

import Button from "@components/ui/Button/Button"
import List from "@components/ui/List/List"

import styles from "./index.module.sass"

export const AlphabetSwitch: React.FC<{
  value: string
  className?: string
  onChange: (value: string) => void
  isActive?: (value: string) => boolean
}> = ({ value, className, onChange, isActive }) => {
  return (
    <List direction="row" gutter="4" wrap="wrap" className={className}>
      {alphabet.map((it) => (
        <Button
          variant="outlined"
          color={it === value ? "primary" : "default"}
          size="small"
          onClick={() => onChange(it)}
          noPadding
          style={{
            borderRadius: "8px",
          }}
          className={clsx({
            [styles.fadeDown]: !isActive?.(it),
          })}
          key={it}
        >
          {it}
        </Button>
      ))}
    </List>
  )
}

export const alphabet = Array.from(Array(26)).map((e, i) =>
  String.fromCharCode(i + 65)
)

export default AlphabetSwitch
