import clsx from "clsx"
import React from "react"

import styles from "./index.module.sass"

export interface TableProps {
  className?: string
}

export const Table: React.FC<TableProps> = ({ children, className }) => (
  <div className={clsx(styles.root, className)}>{children}</div>
)

export default Table
