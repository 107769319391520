import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { useController, useStore } from "@store/index"
import ChangePasswordForm, {
  FormData,
} from "@pages/auth/components/ChangePasswordForm/ChangePasswordForm"
import UserCard from "@components/ui/UserCard/UserCard"

import styles from "./ResetUserPasswordModal.module.sass"

export interface ResetUserPasswordModalProps {
  userId: string
}

export const ResetUserPasswordModal: React.FC<ResetUserPasswordModalProps> =
  observer(({ userId }) => {
    const alert = useAlert()

    const { adminUsersStore } = useStore()
    const { adminUsersController } = useController()

    const user = adminUsersStore.getUserById(userId)

    const modal = useModal(ModalsTypes.CHANGE_USER_PASSWORD_MODAL)

    const handleSubmit = async (formData: FormData) => {
      const error = await adminUsersController.changeUserPassword(
        userId,
        formData.password
      )
      if (error) alert.error(error)
      else alert.success("Password was successfully changed!")
      modal.hideModal()
    }

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText="Reset user password"
            subtitleText="Enter a new password in the form below"
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        {user != null && (
          <UserCard
            orientation="vertical"
            fullName={`${user.firstName} ${user.lastName}`}
            metaInfo={user.email}
          />
        )}
        <ChangePasswordForm
          onSubmit={handleSubmit}
          isLoading={adminUsersStore.isLoading}
        />
      </BaseModal>
    )
  })

export default ResetUserPasswordModal
