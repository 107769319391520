import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import { Editor } from "@components/ui/Editor/Editor"
import Label from "@components/ui/Label/Label"
import List from "@components/ui/List/List"
import Loader from "@components/ui/Loader/BarLoader"
import { ExpertQuestion, KnowledgeQuestion } from "@framework/types/question"
import Templates from "@components/ui/Templates"
import { countSuffix } from "@utils/numberUtils"
import Text from "@components/ui/Typography/Text"

import BaseModal from "../components/BaseModal/BaseModal"
import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"
import ModalTitle from "../components/ModalTitle/ModalTitle"
import { ModalsTypes } from "../constants"
import useModal from "../useModal"
import { ExpertSearch } from "./ExpertSearch"
import useExpertsList from "./useExpertsList"
import QuestionPreview from "./QuestionPreview"
import ExpertsPreview from "./ExpertsPreview"

import styles from "./AssignExpertToQuestionModal.module.sass"

export interface AssignExpertToQuestionModalProps {
  title?: string
  questions: KnowledgeQuestion[] | ExpertQuestion[] | string[]
  avatarId: string
  onSubmit?: (expertId: string[], questionNote: string) => Promise<void> | void
}

export const AssignExpertToQuestionModal: React.FC<AssignExpertToQuestionModalProps> =
  observer(({ title = "Answer questions", questions, avatarId, onSubmit }) => {
    const { hideModal } = useModal(
      ModalsTypes.ASSIGN_EXPERTS_TO_QUESTIONS_MODAL
    )

    const [loading, setLoading] = useState(false)
    const [expertIds, setExpertIds] = useState<string[]>([])
    const [questionNote, setQuestionNote] = useState<string>("")

    const [isUserSelectorShown, showUserSelector] = useState(true)

    const experts = useExpertsList({ avatarId })

    const handleSubmit = async () => {
      setLoading(true)
      await onSubmit?.(expertIds, questionNote)
      setLoading(false)
    }

    const handleNext = () =>
      isUserSelectorShown ? showUserSelector(false) : handleSubmit()

    const handleBack = () => showUserSelector(true)

    const handleClose = hideModal

    return (
      <BaseModal
        title={<ModalTitle titleText={title} />}
        className={styles.root}
        containerClassName={styles.container}
        onClose={hideModal}
      >
        <Templates.RollScript
          gutter="24"
          footerSocket={
            <ModalFooterContainer>
              <Button
                disabled={loading}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                disabled={!expertIds.length || loading}
                variant="contained"
                color="primary"
                onClick={handleNext}
                after={loading && <Loader />}
              >
                {isUserSelectorShown ? "Next" : "Assign"}
              </Button>
            </ModalFooterContainer>
          }
        >
          <List gutter="24">
            <Label
              id="selected_questions"
              label={`Question${countSuffix(questions.length)} to answer`}
            >
              <QuestionPreview questions={questions} />
            </Label>

            {isUserSelectorShown ? (
              <>
                <List gutter="4" className={styles.listContainer}>
                  <Text variant="h5" upperCase>
                    {expertIds.length ? "Experts to assign" : "Select expert"}
                  </Text>
                  <ExpertSearch
                    items={experts.list}
                    isLoading={experts.isLoading}
                    value={expertIds}
                    onChange={setExpertIds}
                  />
                </List>
              </>
            ) : (
              <>
                <Label id="selected_experts" label="Experts to assign">
                  <ExpertsPreview
                    value={expertIds}
                    onClick={handleBack}
                    experts={experts.list}
                  />
                </Label>

                <Label id="question_note" label="Add Notes (Optional)">
                  <Editor
                    placeholder="Add additional context to expert"
                    onChange={setQuestionNote}
                    defaultValue={questionNote}
                  />
                </Label>
              </>
            )}
          </List>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default AssignExpertToQuestionModal
