import get from "lodash/get"
import sortBy from "lodash/sortBy"

import { AnswerDataType, AnswerItem } from "@framework/types/search"
import { AnswerResponseData } from "@services/search.service"

import { AnswerSource } from "./dataTransformers"

export const testAnswerType = <K extends AnswerDataType = AnswerDataType>(
  item: AnswerItem,
  datatype: K
): item is AnswerItem<K> => item.value.data_type === datatype

type SearchSortBuilderOptions = { dateFieldName: string }

export const getAnswersSortingHandler =
  (options: SearchSortBuilderOptions) =>
  (collection: AnswerItem[], order: string) => {
    if (order === "OLDEST")
      return sortBy(collection, (item) => {
        const dateStr = get(item, options.dateFieldName)
        if (dateStr == null) return Infinity
        return new Date(dateStr).getTime()
      })
    if (order === "NEWEST") {
      return sortBy(collection, (item) => {
        const dateStr = get(item, options.dateFieldName)
        if (dateStr == null) return Infinity
        return -new Date(dateStr).getTime()
      })
    }
    if (order === "RELEVANCY") {
      return sortBy(collection, (item) => -(item.value.rank ?? 0))
    }
    return collection
  }

export const getSourcesSortingHandler =
  (options: SearchSortBuilderOptions) =>
  (collection: AnswerSource[], order: string) => {
    if (order === "OLDEST")
      return sortBy(collection, (item) => {
        const dateStr = get(item, options.dateFieldName)
        if (dateStr == null) return Infinity
        return new Date(dateStr).getTime()
      })
    if (order === "NEWEST")
      return sortBy(collection, (item) => {
        const dateStr = get(item, options.dateFieldName)
        if (dateStr == null) return Infinity
        return -new Date(dateStr).getTime()
      })
    if (order === "RELEVANCY") {
      return sortBy(collection, (item) => -(item.hits ?? 0))
    }
    return collection
  }

export const trimAnswerDataForFeedback = (
  answerData: AnswerResponseData
): AnswerResponseData => {
  const answersPart = sortBy(
    answerData.answer.answers,
    (item) => item.rank ?? 0
  )
    .reverse()
    .slice(0, 5)
  return {
    ...answerData,
    answer: {
      ...answerData.answer,
      answers: answersPart,
    },
  }
}
