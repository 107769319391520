import { observer } from "mobx-react-lite"
import React from "react"

import { useStore } from "@store"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import { ApiKeyWithSecret } from "@framework/types/api-keys"
import Icon from "@components/ui/Icon/Icon"
import { copyToClipboard } from "@utils/clipboard"

import styles from "./ShowApiKeyModal.module.sass"

export interface ShowApiKeyModalProps {
  apiKey: ApiKeyWithSecret
  onSubmit?: () => Promise<void>
  onHide?: () => void
}

const ShowApiKeyModal: React.FC<ShowApiKeyModalProps> = observer(
  ({ apiKey, onSubmit, onHide }) => {
    const { hideModal } = useModal(ModalsTypes.SHOW_API_KEY_MODAL)
    const {
      apiKeysStore: { setCreatedApiKey },
    } = useStore()

    const handleSubmit = async () => {
      copyToClipboard(apiKey.apiKey)
      handleClose()
      onSubmit?.()
    }

    const handleClose = () => {
      setCreatedApiKey(null)
      hideModal()
      onHide?.()
    }

    return (
      <BaseModal
        title={<ModalTitle titleText={apiKey.name} />}
        onClose={handleClose}
        className={styles.root}
      >
        <Templates.RollScript
          footerSocket={
            <ModalFooterContainer>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                before={<Icon name="checkbox-multiple-blank" />}
                onClick={handleSubmit}
              >
                Copy
              </Button>
            </ModalFooterContainer>
          }
          bodyClassName={styles.container}
        >
          <div className={styles.detailsContainer}>
            <div className={styles.success}>
              <Icon
                name="checkbox-circle-fill"
                color="green"
                className={styles.icon}
              />
              <Text>API key successfully created</Text>
            </div>
            <Text
              variant="caption2"
              color="text70Color"
              className={styles.warningText}
            >
              To ensure security measures, API keys are displayed only once
              during creation and cannot be retrieved afterwards
            </Text>
            <div className={styles.apiKeyContainer}>
              <Text variant="caption2" color="text70Color">
                API key
              </Text>
              <Text className={styles.apiKey}>{apiKey.apiKey}</Text>
            </div>
          </div>
        </Templates.RollScript>
      </BaseModal>
    )
  }
)
export default ShowApiKeyModal
