import React from "react"

import Table from "@components/ui/BaseTable/Table"

import UserRow from "./UserRow"
import UserTableHeader from "./UserTableHeader"

import styles from "./index.module.sass"

export interface UsersTableProps {
  rows?: string[]
}

export const UsersTable: React.FC<UsersTableProps> = React.memo(
  ({ rows = [] }) => {
    return (
      <div className={styles.root}>
        <Table header={<UserTableHeader />}>
          {rows.map((id) => (
            <UserRow id={id} key={id} />
          ))}
        </Table>
      </div>
    )
  }
)

export default UsersTable
