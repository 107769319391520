export const sizeUnits = [
  "Bytes",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB",
]

export const convertToBytes = (value: number, unit: string): number => {
  const k = 1024

  return value * k ** sizeUnits.indexOf(unit)
}

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) return "0 Bytes"

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizeUnits[i]}`
}

export const autoDownloadBlob = (file: Blob, filename: string) => {
  const href = URL.createObjectURL(file)

  const link = document.createElement("a")
  link.href = href
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  URL.revokeObjectURL(href)
}
