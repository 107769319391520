import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { useController } from "@store/index"

import EditFlagForm, { FormData } from "./EditFlagForm"

import styles from "./EditFeatureFlagModal.module.sass"

export interface EditFeatureFlagModalProps {}

export const EditFeatureFlagModal: React.FC<EditFeatureFlagModalProps> =
  observer(() => {
    const alert = useAlert()

    const { appConfigController } = useController()

    const modal = useModal(ModalsTypes.EDIT_FEATURE_FLAG_MODAL)

    const handleSubmit = async (formData: FormData) => {
      modal.hideModal()
      const error = await appConfigController.addFeatureFlag(
        formData.name,
        formData.defaultValue
      )

      if (error) alert.error(error)

      appConfigController.loadFeatureFlags()
    }

    return (
      <BaseModal
        title={<ModalTitle titleText="Add Feature Flag" />}
        className={styles.root}
        containerClassName={styles.container}
        onClose={modal.hideModal}
      >
        <EditFlagForm onSubmit={handleSubmit} />
      </BaseModal>
    )
  })

export default EditFeatureFlagModal
