import React from "react"
import { observer } from "mobx-react-lite"

import { ColorSchemeOption } from "@framework/types/theme"

import { ThemeGlobalStyles } from "./ThemeGlobalStyles"
import ThemeOverrideStyles from "./ThemeOverridesStyles"

export const AppColorSchemeManagerContext =
  React.createContext<ColorSchemeOption | null>(null)

const AppColorSchemeProvider: React.FC<{
  colorScheme: ColorSchemeOption
}> = observer(({ children, colorScheme }) => {
  return (
    <AppColorSchemeManagerContext.Provider value={colorScheme}>
      {children}

      <ThemeGlobalStyles
        config={colorScheme.palette}
        key={`${colorScheme.name}_palette`}
      />

      <ThemeOverrideStyles
        config={colorScheme.overrides}
        key={`${colorScheme.name}_overrides`}
      />
    </AppColorSchemeManagerContext.Provider>
  )
})

export default AppColorSchemeProvider

export const useAppColors = () => {
  const context = React.useContext(AppColorSchemeManagerContext)
  if (context == null)
    throw new Error("AppColorSchemeManagerContext was not found")
  return context
}
