import React from "react"
import { observer } from "mobx-react-lite"

export type MultiStepContextData = {
  back: () => void
  next: () => void
  isLastStep: boolean
}

export const MultiStepContext =
  React.createContext<MultiStepContextData | null>(null)

export const MultiStepContextProvider: React.FC<MultiStepContextData> =
  observer(({ children, ...data }) => {
    return (
      <MultiStepContext.Provider value={data}>
        {children}
      </MultiStepContext.Provider>
    )
  })

export const useMultiStep = () => {
  const context = React.useContext(MultiStepContext)
  if (context == null) throw new Error("Can't find any MultiStep")
  return context
}

export default MultiStepContextProvider
