import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"

import { SearchEntityContextProvider } from "@pages/search/SearchContext/SearchResultContext"
import AppControlContainer from "@pages/launch-solutions/SolutionTabsContainer/AppControlContainer"
import AnswerSection from "@pages/launch-solutions/SolutionTabsContainer/AnswerSection"
import { useStore } from "@store/index"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import mainRoutes from "@root/main.routes"
import { SolutionData } from "@framework/types/solution"

import SearchWidget from "./components/SearchWidget/SearchWidget"
import FactFinderSearchPassagesSidebar from "./components/SourcePassagesSidebar"

import styles from "./index.module.sass"

interface Props {
  data: SolutionData
}

const FactFinderPage: React.FC<Props> = observer(({ data }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { avatar } = useActiveAvatar()

  const {
    factFinderSolutionStore: { searchFlowStore },
  } = useStore()

  const [isSearched, setIsSearched] = useState(false)

  const closeModal = () =>
    navigate(location.state?.backTo ?? mainRoutes.solutions())

  const isSearching = searchFlowStore.isAnyLoading()

  if (!avatar) return null
  return (
    <div className={styles.root}>
      {searchFlowStore.flowSequence.map((searchId) => {
        return (
          <SearchEntityContextProvider
            searchEntity={searchFlowStore.getById(searchId)}
            key={searchId}
          >
            <AnswerSection />
          </SearchEntityContextProvider>
        )
      })}

      <AppControlContainer
        title={data.name}
        description={data.description}
        moveDown={isSearched || !!searchFlowStore.flowSequence.length}
      >
        <SearchWidget
          avatar={avatar}
          handleSearch={() => setIsSearched(true)}
          disabled={isSearching}
        />
      </AppControlContainer>

      <Routes>
        <Route
          path=":searchId/:blockId/:answerIndex?"
          element={<FactFinderSearchPassagesSidebar onBack={closeModal} />}
        />
      </Routes>
    </div>
  )
})

export default FactFinderPage
