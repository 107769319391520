import { AxiosResponse } from "axios"

import { BaseUserData } from "@framework/types/user"
import {
  RecoverPasswordFormType,
  EmailFormType,
  SignUpFormType,
} from "@framework/types/auth"

import HttpService from "./http.service"

export type VerifyOtpErrorResponseStatus =
  | "VALIDATION_FAILED"
  | "INCORRECT_OTP"
  | "USER_DOES_NOT_EXIST"

export type LoginErrorResponseStatus =
  | "VALIDATION_FAILED"
  | "INVALID_EMAIL"
  | "PASSWORD_MISSING"
  | "USER_DOES_NOT_EXIST"
  | "INCORRECT_PASSWORD"

export type UserAccessData = {
  accessToken?: string
  refreshToken?: string
  requires2FA: boolean
}

export type AuthResponse = {
  data: {
    user: BaseUserData
    access: UserAccessData
  }
}

export interface VerifyOtpErrorResponse {
  data?: unknown
  message: VerifyOtpErrorResponseStatus
}

export interface LoginErrorResponse {
  data: unknown
  message: LoginErrorResponseStatus
}

export type SignUpFormErrorStatus =
  | "INVALID_FIRSTNAME"
  | "INVALID_LASTNAME"
  | "INVALID_EMAIL"
  | "USER_ALREADY_EXIST_WITH_EMAIL"
  | "INVALID_INVITE_CODE"
  | "PASSWORD_MISSING"

export type SignUpErrorResponseStatus =
  | "INVALID_INVITE_EMAIL"
  | "INVALID_INVITE_CODE"
  | "VALIDATION_FAILED"
  | SignUpFormErrorStatus

export interface SignUpErrorResponse {
  failures: [{ field: keyof SignUpFormType; message: SignUpFormErrorStatus }]
  message: SignUpErrorResponseStatus
}

export interface RecoverPasswordResponse {
  data: unknown
  status: "PASSWORD_RESET_EMAILED"
  message: string
}

export interface RecoverPasswordErrorResponse {
  data: unknown
  status: "USER_NOT_FOUND"
  message: string
}

export interface ChangePasswordResponse {
  data: string
  message: string
}

export interface ChangePasswordErrorResponse {
  data: unknown
  status: "BAD_PASSWORD" | "AUTHENTICATION_FAILED"
  message: string
}

class AuthAPI extends HttpService {
  verifyOtp = (
    email: string,
    otp: string
  ): Promise<AxiosResponse<AuthResponse>> =>
    this.post(
      "ts/verify-otp",
      {
        email,
        otp,
      },
      false
    )

  refreshToken = (
    refreshToken: string
  ): Promise<AxiosResponse<{ accessToken: string }>> =>
    this.post(
      "ts/refresh",
      {
        refreshToken,
      },
      true
    )

  login = (
    email: string,
    password: string
  ): Promise<AxiosResponse<AuthResponse>> =>
    this.post(
      "ts/login",
      {
        email,
        password,
      },
      false
    )

  loginSSO = (
    code: string,
    redirectUrl: string
  ): Promise<AxiosResponse<AuthResponse>> =>
    this.post(
      "ts/sso-login",
      {
        code,
        redirectUrl,
      },
      false
    )

  authorizeSSO = (code: string): Promise<AxiosResponse<AuthResponse>> =>
    this.post(
      "ts/sso/authorize",
      {
        code,
      },
      false
    )

  loginSAML = (): string => this.getURL("ts/saml-login")

  loginOIDC = (): string => this.getURL("ts/oidc/login")

  signUp = (
    registerForm: SignUpFormType
  ): Promise<AxiosResponse<AuthResponse>> =>
    this.post("ts/register", registerForm, false)

  recoverPassword = (
    form: EmailFormType
  ): Promise<AxiosResponse<RecoverPasswordResponse>> =>
    this.post("ts/recover-password", form, false)

  changePassword = (
    form: RecoverPasswordFormType
  ): Promise<AxiosResponse<ChangePasswordResponse>> =>
    this.post("ts/change-password", form)

  logout = () => {
    return this.post("ts/logout", {}, true)
  }

  guestLogin = (referrer: string): Promise<AxiosResponse<AuthResponse>> =>
    this.get(`ts/guest-login`, false, null, null, undefined, referrer)
}

export default new AuthAPI()
