import React from "react"
import { observer } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import SidebarPopup from "@components/modals/components/SidebarPopup"

import AnswerSourceHeader from "./AnswerSourceHeader"
import SourcePassages from "./SourcePassages"

import styles from "./SourcePassagesSidebar.module.sass"

export interface MeetingsSidebarProps {
  open: boolean
  onClose?: () => void
}

export const SearchPassagesSidebar: React.FC<MeetingsSidebarProps> = observer(
  ({ open = false, onClose }) => {
    return (
      <SidebarPopup open={open} onClose={onClose}>
        <Templates.RollScript
          className={styles.root}
          headerSocket={<AnswerSourceHeader />}
        >
          <div className={styles.body}>
            <SourcePassages />
          </div>
        </Templates.RollScript>
      </SidebarPopup>
    )
  }
)

export default SearchPassagesSidebar
