import { observer } from "mobx-react-lite"
import React from "react"

import { AnswerData } from "@framework/types/search"
import {
  AnswerViewType,
  ExtendedResultType,
} from "@framework/constants/search-results"
import Icon from "@components/ui/Icon/Icon"

export interface AnswerControlProps {
  answer: AnswerData
  viewType: AnswerViewType
  handleExtendResult?: (tab: ExtendedResultType) => void
}

export const AnswerControl: React.FC<AnswerControlProps> = observer(
  ({ answer, viewType, handleExtendResult }) => {
    const { images } = answer
    const hasImages = !!images?.length

    return (
      <>
        {hasImages && handleExtendResult != null && (
          <Icon name="landscape" onClick={() => handleExtendResult("Image")} />
        )}
        {/* <Icon name="eye" onClick={() => handleExtendResult(viewType)} /> */}
      </>
    )
  }
)

export default AnswerControl
