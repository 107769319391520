import { makeAutoObservable, observable, runInAction, toJS } from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry, map } from "rxjs/operators"
import { toStream } from "mobx-utils"
import cloneDeep from "lodash/cloneDeep"

import { SearchFilter } from "@framework/types/product-filter"
import { Product } from "@framework/types/product"
import { PaginationParams } from "@framework/types/utils"
import productsService from "@services/products.service"

const RETRY_DELAY = 10 * 1000

type Filter = Record<string, string | string[]>

type State = {
  query: string

  filters: Filter

  data: Product[]

  total: number

  error: string | null

  isLoading: boolean

  pagination: PaginationParams

  reloadTrigger: boolean

  companyIds: string[]

  excludeCompanyIds: string[]

  excludeProductIds: string[]

  nextPageToken: string | null
}

const defaultState: State = {
  query: "",
  filters: {},
  data: [],
  total: 0,
  error: null,
  isLoading: false,
  pagination: { pageNum: 0, pageSize: 10 },
  reloadTrigger: false,
  companyIds: [],
  excludeCompanyIds: [],
  excludeProductIds: [],
  nextPageToken: null,
}

export default class ProductListStore {
  state: State

  private loadingStream$?: Subscription

  constructor() {
    this.state = cloneDeep(defaultState)

    makeAutoObservable(this)
  }

  subscribe = () => {
    this.unsubscribe()
    this.loadingStream$ = this.initLoadingStream()
  }

  unsubscribe = () => {
    if (this.loadingStream$) {
      this.loadingStream$.unsubscribe()
      this.loadingStream$ = undefined
    }
  }

  private initLoadingStream = () => {
    return from(
      toStream(
        () => [
          this.state.query,
          this.state.pagination,
          this.state.filters,
          this.state.companyIds,
          this.state.excludeCompanyIds,
          this.state.excludeProductIds,
          this.state.reloadTrigger,
        ],
        false
      ) as ObservableInput<
        [
          string,
          PaginationParams,
          Filter,
          string[],
          string[],
          string[],
          boolean
        ]
      >
    )
      .pipe(
        tap((array) => {
          return runInAction(() => {
            this.state.data = []
            this.state.isLoading = true
            this.state.error = ""
          })
        }),
        switchMap(
          ([
            query,
            pagination,
            productFilters,
            companyIds,
            excludeCompanyIds,
            excludeIds,
            // nextPageToken,
          ]) => {
            const filters: SearchFilter[] = Object.entries(productFilters).map(
              ([key, value]) => ({
                id: key,
                value,
              })
            )

            return productsService
              .getProducts$({
                name: query,
                pageSize: pagination.pageSize,
                pageNum: pagination.pageNum,
                filters,
                companyIds,
                excludeCompanyIds,
                excludeIds,
                // pageToken: nextPageToken || undefined,
              })
              .pipe(map((response) => response.data))
          }
        ),
        tap((response) => {
          runInAction(() => {
            const { data = [], meta } = response

            this.state.data = data

            this.state.total = meta?.total ?? data.length

            this.state.nextPageToken = meta?.nextPageToken ?? null

            this.state.isLoading = false
          })
        }),
        retry({
          delay: () => {
            runInAction(() => {
              this.state.isLoading = false
              this.state.error = "Loading Failed"
            })
            return timer(RETRY_DELAY)
          },
        })
      )
      .subscribe()
  }

  get withRecommended() {
    return this.state.excludeProductIds.length > 0
  }

  search = (query: string) => {
    this.state.query = query
  }

  loadMore = (pagination: PaginationParams) => {
    this.state.pagination = pagination
  }

  refresh = () => {
    this.state.reloadTrigger = !this.state.reloadTrigger
  }

  reset = (newState: Partial<State> = {}) => {
    this.state = observable({
      ...cloneDeep(defaultState),
      ...newState,
    })
  }

  update = (newState: Partial<State> = {}) => {
    this.state = observable({
      ...cloneDeep(this.state),
      ...newState,
    })
  }
}
