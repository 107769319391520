import React, { useEffect } from "react"
import clsx from "clsx"
import AceEditor from "react-ace"

import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-monokai"
import List from "@components/ui/List/List"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import Icon from "@components/ui/Icon/Icon"

import styles from "./ConfigForm.module.sass"

interface Props {
  defaultValue?: string
  loading?: boolean
  className?: string
  onDelete?: () => void
  onSubmit?: (newValue: object) => void
}

export const ConfigForm: React.FC<Props> = ({
  defaultValue = "",
  loading = false,
  className,
  onSubmit,
  onDelete,
}) => {
  const [value, setValue] = React.useState(defaultValue)
  const [valid, setValid] = React.useState(false)

  const validateForm = () => {
    setValid(false)
    const validationResult = parseConfig(value)
    if (validationResult.status === "SUCCESS") setValid(true)
  }

  const handleSubmit = () => {
    if (!valid) return
    const res = parseConfig(value)
    if (res.status === "SUCCESS") onSubmit?.(res.data)
  }

  useEffect(() => {
    validateForm()
  }, [value])

  return (
    <List direction="column" className={clsx(styles.root, className)}>
      <Templates.RollScript
        gutter="24"
        footerSocket={
          <Templates.Header
            left={
              <Button
                color="red"
                size="medium"
                onClick={onDelete}
                disabled={!valid || loading}
                before={<Icon name="trash-can" />}
                after={loading ? <Loader /> : null}
              >
                Delete config
              </Button>
            }
            right={
              <Button
                color="primary"
                size="medium"
                onClick={handleSubmit}
                disabled={!valid || loading}
                after={loading ? <Loader /> : null}
              >
                Update config
              </Button>
            }
          />
        }
      >
        <AceEditor
          value={value}
          mode="json"
          theme="monokai"
          onChange={(newValue) => {
            setValue(newValue)
          }}
          name="UNIQUE_ID_OF_DIV"
          highlightActiveLine
          editorProps={{
            $blockScrolling: true,
          }}
          height="60vh"
          width="100%"
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 4,
          }}
        />
      </Templates.RollScript>
    </List>
  )
}

const parseConfig = (jsonStr: string) => {
  try {
    const res = JSON.parse(normalizeJsonString(jsonStr))
    return { status: "SUCCESS", data: res }
  } catch (e) {
    console.dir(e)
    return { status: "FAILED", error: e }
  }
}

const normalizeJsonString = (jsonString: string) => {
  return jsonString.replace(/\/\*[\s\S]*?\*\/|(?<=[^:])\/\/.*|^\/\/.*/g, "")
}

export default ConfigForm
