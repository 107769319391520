/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import styles from "./RollScript.module.sass"

type Gutter = "0" | "8" | "16" | "24" | "32"

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  headerSocket?: React.ReactNode
  footerSocket?: React.ReactNode
  bodyClassName?: string
  headerClassName?: string
  gutter?: Gutter
}

export const RollScript: React.FC<HeaderProps> = ({
  headerSocket,
  footerSocket,
  children,
  className,
  bodyClassName,
  headerClassName,
  gutter = "0",
  ...rest
}) => {
  return (
    <section
      className={clsx(styles.root, styles[`gutter-${gutter}`], className)}
      {...rest}
    >
      {headerSocket != null && (
        <div className={clsx(styles.header, headerClassName)}>
          {headerSocket}
        </div>
      )}
      {children != null && (
        <div className={clsx(styles.body, bodyClassName)}>{children}</div>
      )}
      {footerSocket != null && (
        <div className={styles.footer}>{footerSocket}</div>
      )}
    </section>
  )
}

export default RollScript
