/* eslint-disable no-underscore-dangle */
import { makeAutoObservable } from "mobx"

import { DataSourceFile } from "@framework/types/upload"

export class DataConnectorPreviewsStore {
  collection: Map<string, DataSourceFile>

  constructor() {
    makeAutoObservable(this)
    this.collection = new Map<string, DataSourceFile>()
  }

  get getById() {
    return (id: string) => this.collection.get(id)
  }
}

export default DataConnectorPreviewsStore
