import React from "react"
import { observer } from "mobx-react-lite"

import { AppUIConfig } from "@framework/types/theme"

import { ThemeGlobalStyles } from "./ThemeGlobalStyles"

export const AppUIConfigManagerContext =
  React.createContext<AppUIConfig | null>(null)

const AppConfigProvider: React.FC<{
  config: AppUIConfig
}> = observer(({ children, config }) => {
  return (
    <AppUIConfigManagerContext.Provider value={config}>
      {children}

      <ThemeGlobalStyles
        config={config.typography.variables}
        key={`${config.id}_typography`}
      />
    </AppUIConfigManagerContext.Provider>
  )
})

export default AppConfigProvider

export const useAppConfig = () => {
  const context = React.useContext(AppUIConfigManagerContext)
  if (context == null)
    throw new Error("AppUIConfigManagerContext was not found")
  return context
}
