import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import mainRoutes from "@root/main.routes"
import useQuery from "@components/hooks/useQuery"
import SecondaryLayout from "@components/layout/SecondaryLayout/SecondaryLayout"
import { useStore } from "@store/index"
import { ChangePasswordFormType } from "@framework/types/auth"
import Button from "@components/ui/Button/Button"

import EmailForm from "../components/EmailForm/EmailForm"
import ChangePasswordForm from "../components/ChangePasswordForm/ChangePasswordForm"

import styles from "./PasswordRestore.module.sass"

interface PasswordRestoreProps {}

const PasswordRestorePage: React.FC<PasswordRestoreProps> = observer(() => {
  const navigate = useNavigate()
  const params = useQuery()
  const { error } = useAlert()
  const recoveryCode = params.get("recovery-code")

  const { authStore } = useStore()
  const {
    isPasswordRestoreLoading,
    successMessage,
    recoveryErrors,
    passwordChangeErrors,
    passwordRecoveryError,
    resetPasswordRecovery,
    requestPasswordRecovery,
    changePassword,
  } = authStore

  const handleChangePassword = (form: ChangePasswordFormType) => {
    if (!recoveryCode) return
    changePassword(recoveryCode, form.password)
  }

  const goToLoginPage = () => {
    navigate(mainRoutes.login())
  }

  useEffect(
    () => () => {
      resetPasswordRecovery()
    },
    []
  )

  useEffect(() => {
    if (passwordRecoveryError) {
      error(passwordRecoveryError)
      resetPasswordRecovery()
    }
  }, [passwordRecoveryError])

  const isRecoveryStage = !!recoveryCode

  const title = isRecoveryStage ? "Restore password" : "Forgot password"
  const subtitle =
    successMessage ||
    (isRecoveryStage ? "" : "Enter your email and we will send you reset link")

  return (
    <SecondaryLayout className={styles.root} onBack={goToLoginPage}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>{title}</h1>
          {subtitle && <h4>{subtitle}</h4>}
        </div>
        {successMessage ? null : isRecoveryStage ? (
          <ChangePasswordForm
            errors={{
              password: passwordChangeErrors.password,
              confirmPassword: "",
            }}
            onSubmit={handleChangePassword}
            isLoading={isPasswordRestoreLoading}
          />
        ) : (
          <EmailForm
            errors={recoveryErrors}
            onSubmit={requestPasswordRecovery}
            isLoading={isPasswordRestoreLoading}
          />
        )}
        {isRecoveryStage && successMessage && (
          <Button variant="slim" color="primary" onClick={goToLoginPage}>
            Go to login page
          </Button>
        )}
      </div>
    </SecondaryLayout>
  )
})

export default PasswordRestorePage
