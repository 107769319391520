import React from "react"
import { observer } from "mobx-react-lite"
import { FormikContext, useFormik } from "formik"
import isEmpty from "lodash/isEmpty"
import without from "lodash/without"
import map from "lodash/map"
import * as yup from "yup"

import { useController, useStore } from "@store"
import List from "@components/ui/List/List"
import ListItem from "@components/ui/ListItem/ListItem"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Templates from "@components/ui/Templates"

import styles from "./InviteUserForm.module.sass"

const validationSchema = yup.object().shape({
  roleIds: yup.array().of(yup.string()).min(1).default([]),
})

type FormData = yup.InferType<typeof validationSchema>

interface InviteUserRolesProps {
  initialValues?: any
  onSubmit: (data: FormData) => void
}

export const InviteUserRoles: React.FC<InviteUserRolesProps> = observer(
  ({ initialValues, children, onSubmit }) => {
    const formik = useFormik<FormData>({
      initialValues: validationSchema.cast(initialValues ?? {}, {
        stripUnknown: true,
      }),
      validateOnMount: true,
      validationSchema,
      onSubmit,
    })

    const { rolesStore } = useStore()
    const { rolesController } = useController()

    const selectedRoles = formik.values.roleIds

    const handleRoleSelect = (roleId: string) => {
      const newValue = selectedRoles.includes(roleId)
        ? without(selectedRoles, roleId)
        : [...selectedRoles, roleId]

      formik.setFieldValue("roleIds", newValue)
    }

    const isSelected = (avatarId: string) => selectedRoles.includes(avatarId)

    React.useEffect(() => {
      rolesController.init()
    }, [])

    return (
      <FormikContext.Provider value={formik}>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <Templates.RollScript footerSocket={children}>
            {rolesStore.isLoading ? (
              <Loader size="medium" fluid />
            ) : isEmpty(rolesStore.roles) ? (
              <NotFound />
            ) : (
              <List gutter="4">
                {map(rolesStore.roles, (role) => {
                  return (
                    <ListItem
                      active={isSelected(role.id)}
                      onClick={() => handleRoleSelect(role.id)}
                      key={role.id}
                      className={styles.roleListItem}
                    >
                      <span className={styles.roleText} title={role.name}>
                        {role.name}
                      </span>
                    </ListItem>
                  )
                })}
              </List>
            )}
          </Templates.RollScript>
        </form>
      </FormikContext.Provider>
    )
  }
)

export default InviteUserRoles
