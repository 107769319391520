/* eslint-disable camelcase */
import React from "react"
import { observer } from "mobx-react-lite"

import { ImageAnswerType } from "@framework/types/search"
import AnswerOriginalView from "@components/ui/AnswerOriginalView/AnswerOriginalView"

import styles from "./ImageResult.module.sass"

export interface ImageResultProps {
  data: ImageAnswerType
}

export const ImageResult: React.FC<ImageResultProps> = observer(({ data }) => {
  const imageUrls = data.images?.length
    ? data.images.map(({ image_url }) => image_url)
    : [data.image_url]

  return (
    <div className={styles.root}>
      <AnswerOriginalView images={imageUrls} />
    </div>
  )
})

export default ImageResult
