import { makeAutoObservable } from "mobx"

import { DataCategoryEntity } from "@framework/types/knowledge"

import EntityCollection from "../utils/EntityCollection"
import ExpertAnswersListStore from "./expert-answers-list.store"

export default class AvatarKnowledgePreviewStore {
  constructor() {
    makeAutoObservable(this)
    this.expertKnowledgeStore = new ExpertAnswersListStore()
    this.dataCategoriesStore = new EntityCollection()
  }

  expertKnowledgeStore: ExpertAnswersListStore

  dataCategoriesStore: EntityCollection<DataCategoryEntity>
}
