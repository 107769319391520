import { DataCategoryEntity } from "./knowledge"
import { SolutionData } from "./solution"
import { BaseUserData } from "./user"

export enum AccessType {
  Public = "public",
  Private = "private",
}

export interface BaseAvatarData {
  id: string
  name: string
  imageUrl: string
}

export interface AvatarData extends BaseAvatarData {
  createdAt: string
  description: string
  userCount: number
  users: BaseUserData[]
  expertCount: number
  experts: BaseUserData[]
  userIds: string[]
  accessType: AccessType
}

export interface AvatarDetailsData extends AvatarData {
  knowledgeDataTypes: DataCategoryEntity[]
  productSolutions?: SolutionData[]
  adminCount: number
}

export interface AvatarEditDTO {
  userIds: string[]
  dataTypeIdsToAdd: string[]
  dataTypeIdsToDelete: string[]
  productSolutionIdsToAdd: string[]
  productSolutionIdsToDelete: string[]
}
