import { makeAutoObservable } from "mobx"
import omit from "lodash/omit"

import RootStore from "@store/RootStore"
import avatarsService from "@services/avatars.service"

import AvatarsStore from "./avatars.store"
import AvatarDetailsStore from "./avatar.store"

export class AvatarController {
  // injections

  avatarsStore: AvatarsStore

  avatarDetailsStore: AvatarDetailsStore

  // state

  // constructor

  constructor(injections: RootStore) {
    this.avatarsStore = injections.avatarsStore
    this.avatarDetailsStore = injections.avatarDetailsStore
    makeAutoObservable(this)
  }

  // actions

  addNewAvatar = async (data: any) => {
    const store = this.avatarDetailsStore

    try {
      store.isLoading = true
      store.error = null

      const response = await avatarsService.createAvatar(
        omit(data, "image") as any
      )

      const { data: createdAvatar } = response.data

      if (data.image) {
        await this.uploadAvatarCoverImage(createdAvatar.id, data.image)
      } else {
        this.avatarDetailsStore.storeData(createdAvatar)
      }

      return this.avatarDetailsStore.data
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  editAvatar = async (avatarId: string, data: any) => {
    const store = this.avatarDetailsStore

    try {
      store.isLoading = true
      store.error = null

      await avatarsService.editAvatar(
        avatarId,
        omit(data, "image", "accessType")
      )

      await this.uploadAvatarCoverImage(avatarId, data.image)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  uploadAvatarCoverImage = async (avatarId: string, image: File) => {
    if (image && image instanceof File) {
      const form = new FormData()
      form.append("image", image)

      await avatarsService.updateAvatarCoverImage(avatarId, form)
    }
  }

  deleteAvatar = async (avatarId: string) => {
    const store = this.avatarDetailsStore

    try {
      store.isLoading = true
      store.error = null

      await avatarsService.removeAvatar(avatarId)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  loadAvatars = async () => {
    this.avatarsStore.isAvatarsLoading = true
    this.avatarsStore.loadAvatarError = null
    try {
      const response = await avatarsService.getAvatars()

      if (response.status < 400) {
        this.avatarsStore.avatars = response.data.data
        return
      }
    } catch (error) {
      this.avatarsStore.avatars = null
      this.avatarsStore.loadAvatarError = "Can't load avatars list"
    } finally {
      this.avatarsStore.isAvatarsLoading = false
    }
  }

  loadAvatarDetails = async (avatarId: string) => {
    const store = this.avatarDetailsStore
    try {
      store.data = null
      store.isLoading = true
      store.error = null

      const response = await avatarsService.getAvatar(avatarId)

      store.storeData(response.data.data)
    } catch (error) {
      store.data = null
      store.error = "Failed to load avatar details"
    } finally {
      store.isLoading = false
    }
    return store.error
  }
}

export default AvatarController
