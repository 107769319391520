/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React, { useMemo, useRef, useState, useEffect } from "react"

import { initArrayByLength } from "@utils/numberUtils"
import useForceUpdate from "@components/hooks/useForceUpdate"

import { setCaretPosition, transformCodeInput } from "./utils"

import styles from "./CodeInput.module.sass"

export interface CodeInputProps {
  name?: string
  value?: string
  codeLength: number
  withError?: boolean
  className?: string
  onChange?: (code: string) => void
  onBlur?: React.FocusEventHandler
}

export const CodeInput: React.FC<CodeInputProps> = ({
  name = "codeInput",
  value,
  className,
  withError,
  codeLength,
  onChange,
  onBlur,
}) => {
  if (codeLength < 1 || codeLength > 10)
    throw new Error("codeLength must be between 1 and 10")

  const forceUpdate = useForceUpdate()

  const [valueArray, setValueArray] = useState(value ? value.split("") : [])

  const inputRef = useRef<HTMLInputElement>(null)
  const rootRef = useRef<HTMLLabelElement>(null)

  const cells = useMemo(() => initArrayByLength(codeLength), [codeLength])

  const handleFocus = (pos: number) => {
    if (inputRef.current) {
      setCaretPosition(inputRef.current, pos)
      forceUpdate()
    }
  }

  const handleBlur = () => {
    inputRef.current?.blur()
    forceUpdate()
  }

  const isFocused = document.activeElement?.isSameNode(inputRef.current)
  const focusedIdx = inputRef.current?.selectionStart ?? null

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target
    const newValue = transformCodeInput(value, codeLength)
    onChange?.(newValue)
    setValueArray(newValue ? newValue.split("") : [])
  }

  useEffect(() => {
    if (focusedIdx !== null) {
      handleFocus(focusedIdx)
    }
  }, [valueArray])

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (rootRef.current && !rootRef.current.contains(e.target as Node)) {
        handleBlur()
      }
    }

    document.addEventListener("mouseup", handleClick)
    return () => {
      document.removeEventListener("mouseup", handleClick)
    }
  }, [rootRef])

  return (
    <label
      className={clsx(
        styles.root,
        { [styles.withError]: withError },
        className
      )}
      htmlFor={name}
      ref={rootRef}
    >
      {cells.map((cellIdx) => (
        <div
          className={clsx(styles.cell, {
            [styles.active]: isFocused && focusedIdx === cellIdx,
          })}
          onClick={() => handleFocus(cellIdx)}
          key={cellIdx}
        >
          {valueArray[cellIdx] ?? ""}
        </div>
      ))}
      <input
        id={name}
        className={styles.input}
        value={valueArray.join("")}
        onInput={handleChange}
        onBlur={onBlur}
        ref={inputRef}
        autoComplete="off"
      />
    </label>
  )
}

export default CodeInput
