import { makeAutoObservable } from "mobx"

import { DataCategoryEntity, DatatypeEditDTO } from "@framework/types/knowledge"
import RootStore from "@store/RootStore"
import knowledgeDatatypesService from "@services/knowledge-datatypes.service"

import KnowledgeDatatypeDetailsStore from "./knowledge-datatype.store"

export class KnowledgeDatatypeController {
  knowledgeDatatypeDetailsStore: KnowledgeDatatypeDetailsStore

  constructor(injections: RootStore) {
    this.knowledgeDatatypeDetailsStore =
      injections.knowledgeDatatypeDetailsStore

    makeAutoObservable(this)
  }

  addNewDatatype = async (data: Partial<DataCategoryEntity>) => {
    const store = this.knowledgeDatatypeDetailsStore

    try {
      store.isLoading = true
      store.error = null

      await knowledgeDatatypesService.createDatatype(data)
    } catch (error) {
      store.error = "Unable to create datatype"
    } finally {
      store.isLoading = false
    }

    return store.error
  }

  editDatatype = async (datatypeId: string, data: Partial<DatatypeEditDTO>) => {
    const store = this.knowledgeDatatypeDetailsStore

    try {
      store.isLoading = true
      store.error = null

      await knowledgeDatatypesService.editDatatype(datatypeId, data)
    } catch (error) {
      store.error = "Unable to save datatype"
    } finally {
      store.isLoading = false
    }

    return store.error
  }

  loadDatatypeDetails = async (id: string) => {
    const store = this.knowledgeDatatypeDetailsStore

    try {
      store.data = null
      store.isLoading = true
      store.error = null

      const res = await knowledgeDatatypesService.getKnowledgeDatatype(id)

      store.storeDatatype(res.data.data)
    } catch (error) {
      store.data = null
      store.error = "Failed to load datatype details"
    } finally {
      store.isLoading = false
    }

    return store.error
  }

  deleteDatatype = async (id: string, transitId?: string) => {
    const store = this.knowledgeDatatypeDetailsStore
    try {
      store.isLoading = true
      store.error = null

      await knowledgeDatatypesService.removeDatatype(id, transitId)
    } catch (error) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }
}

export default KnowledgeDatatypeController
