import React from "react"
import { observer } from "mobx-react-lite"
import { FormikContext, useFormik } from "formik"
import isEmpty from "lodash/isEmpty"
import without from "lodash/without"
import * as yup from "yup"

import { useController, useStore } from "@store"
import List from "@components/ui/List/List"
import ListItem from "@components/ui/ListItem/ListItem"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Templates from "@components/ui/Templates"
import Avatar from "@components/ui/Avatar/Avatar"
import { AccessType } from "@framework/types/avatar"

import styles from "./InviteUserForm.module.sass"

const validationSchema = yup.object().shape({
  avatarIds: yup.array().of(yup.string()).min(0).default([]),
})

type FormData = yup.InferType<typeof validationSchema>

interface InviteUserAvatarsProps {
  initialValues?: any
  onSubmit: (data: FormData) => void
}

export const InviteUserAvatars: React.FC<InviteUserAvatarsProps> = observer(
  ({ initialValues, children, onSubmit }) => {
    const formik = useFormik<FormData>({
      initialValues: validationSchema.cast(initialValues ?? {}, {
        stripUnknown: true,
      }),
      validateOnMount: true,
      validationSchema,
      onSubmit,
    })

    const { avatarController } = useController()
    const { avatarsStore } = useStore()

    const selectedAvatarIds = formik.values.avatarIds

    const handleAvatarSelect = (avatarId: string) => {
      const newValue = selectedAvatarIds.includes(avatarId)
        ? without(selectedAvatarIds, avatarId)
        : [...selectedAvatarIds, avatarId]

      formik.setFieldValue("avatarIds", newValue)
    }

    const isSelected = (avatarId: string) =>
      selectedAvatarIds.includes(avatarId)

    React.useEffect(() => {
      avatarController.loadAvatars()
    }, [])

    return (
      <FormikContext.Provider value={formik}>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <Templates.RollScript gutter="16" footerSocket={children}>
            {avatarsStore.isAvatarsLoading ? (
              <Loader size="medium" fluid />
            ) : isEmpty(avatarsStore.avatars) ? (
              <NotFound />
            ) : (
              <List gutter="4">
                {avatarsStore.avatars?.map((avatar) => {
                  return (
                    <ListItem
                      active={
                        isSelected(avatar.id) ||
                        avatar.accessType === AccessType.Public
                      }
                      onClick={() => handleAvatarSelect(avatar.id)}
                      key={avatar.id}
                      disabled={avatar.accessType === AccessType.Public}
                    >
                      <List gutter="8" direction="row" align="center">
                        <Avatar
                          src={avatar.imageUrl}
                          className={styles.avatarIcon}
                        />
                        <span className={styles.avatarText} title={avatar.name}>
                          {avatar.name}
                        </span>
                      </List>
                    </ListItem>
                  )
                })}
              </List>
            )}
          </Templates.RollScript>
        </form>
      </FormikContext.Provider>
    )
  }
)

export default InviteUserAvatars
