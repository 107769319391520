import React from "react"

import { Block, Formula, Unknown } from "./types"

const validFormula1 = `A12 +  A1 - -sum(b12 , sum( $b$1:b$12, 123 ), -12)`
const validFormula2 = `SUM(A2,B2,B2:C2,SUM(A2:B2,$B$2:$D$2))`
const invalidFormula1 = `(*^asd987asgf a0s-9d0a-sf" 0=-9 0-a9sf =0-)`

export const tokenize = (formula: string) => {
  return (
    formula.match(
      /[(),]|\s*([-+*/]|\$?[A-Za-z]\$?[A-Za-z0-9]*(:\$?[A-Za-z]\$?[A-Za-z0-9]*)?|[A-Za-z]+|[0-9]+|[0-9]+\.[0-9]+|.+)\s*/g
    ) || []
  )
}

export type ParseResult = {
  matched: Formula
  rest: Unknown
  refs: string[]
  isValid: boolean
}

export class Parser {
  private index: number = 0

  tokens: string[] = []

  refs: string[] = []

  errors: number = 0

  parse = (tokens: string[]): ParseResult => {
    this.index = 0
    this.tokens = tokens
    this.refs = []
    this.errors = 0

    return {
      matched: this.parseBinaryExpression(),
      rest: this.parseUnknowns(),
      refs: this.refs,
      isValid: this.errors === 0,
    }
  }

  parseBinaryExpression = (): Formula => {
    const left = this.parseExpression()

    if (this.index >= this.tokens.length) {
      return left
    }

    const token = this.tokens[this.index]
    const sign = token.trim()

    if (["*", "+", "-", "/"].includes(sign)) {
      this.index += 1
      return {
        type: "bin-exp",
        operation: sign as any,
        token,
        left,
        right: this.parseBinaryExpression(),
      }
    }

    return left
  }

  parseExpression = (): Formula => {
    const token = this.tokens[this.index]

    if (token == null) {
      return {
        type: "const",
        value: null,
        token: "",
      }
    }

    const value = token.trim()

    // Unary expression
    if (value === "+" || value === "-") {
      this.index += 1
      return {
        type: "un-exp",
        operation: value,
        token,
        value: this.parseExpression(),
      }
    }

    // Range / Reference
    if (value.match(/^\$?[a-zA-Z]+\$?[0-9]+(:\$?[a-zA-Z]+\$?[0-9]+)?$/)) {
      this.index += 1

      const ref = value.replaceAll("$", "").toUpperCase()

      if (!this.refs.includes(ref)) this.refs.push(ref)

      return {
        type: "ref",
        name: ref,
        token,
      }
    }

    // Number
    if (value.match(/^[0-9]+|[0-9]+\.[0-9]+$/)) {
      this.index += 1
      return {
        type: "const",
        value,
        token,
      }
    }

    // Function
    if (value.match(/^[A-Za-z]+$/) && this.tokens[1 + this.index] === "(") {
      this.index += 2
      return {
        type: "fun",
        name: value,
        token,
        block: this.parseBlock(),
      }
    }

    return this.parseUnknowns()
  }

  parseUnknowns = (): Unknown => {
    let other = ""

    while (this.tokens[this.index]) {
      other += this.tokens[this.index]
      this.index += 1
    }

    if (other) this.errors += 1

    return {
      type: "unknown",
      token: other,
    }
  }

  parseBlock = (): Block => {
    const args: Formula[] = []

    while (this.tokens[this.index] !== ")") {
      args.push(this.parseBinaryExpression())

      if (this.tokens[this.index] !== ",") break

      this.index += 1

      if (this.tokens[this.index] === ")") {
        args.push({ type: "unknown", token: "" })
        this.errors += 1
      }
    }

    const closed = this.tokens[this.index] === ")"

    if (closed) this.index += 1

    return {
      type: "block",
      open: true,
      closed,
      arguments: args,
    }
  }
}
