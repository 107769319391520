import React from "react"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { BaseUserRole } from "@framework/types/security"

interface RoleContextMenuProps {
  role: BaseUserRole
}

const RoleContextMenu = React.memo<RoleContextMenuProps>(({ role }) => {
  const { isOpened, handleToggle, setOpened } = useToggle()

  const editModal = useModal(ModalsTypes.EDIT_USER_ROLE_MODAL)
  const deleteModal = useModal(ModalsTypes.DELETE_USER_ROLE_MODAL)

  const handleEdit = () => {
    editModal.showModal({ role })
  }

  const handleDelete = () => {
    deleteModal.showModal({ role })
  }

  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      justify="end"
      content={
        <TooltipContainer color="primary" placement="bottom" tip="end">
          <MenuItem icon="edit" onClick={handleEdit} key="reset">
            Edit role
          </MenuItem>

          <MenuItem
            color="red"
            icon="trash-can"
            onClick={handleDelete}
            key="logout"
          >
            Delete role
          </MenuItem>
        </TooltipContainer>
      }
    >
      <IconButton size="small" active={isOpened} onClick={handleToggle}>
        <Icon name="dots" />
      </IconButton>
    </Tooltip>
  )
})

export default RoleContextMenu
