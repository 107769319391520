import { observer } from "mobx-react-lite"
import React from "react"
import { useNavigate } from "react-router-dom"

import LoadingPlaceholder from "@components/security/LoadingPlaceholder"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"

const SignOutPlaceholder: React.FC = observer(() => {
  const navigate = useNavigate()
  const feedbackModal = useModal(ModalsTypes.APP_FEEDBACK_MODAL)

  React.useEffect(() => {
    feedbackModal.showModal({})
    navigate("/login", { replace: true })
  }, [])

  return <LoadingPlaceholder />
})

export default SignOutPlaceholder
