import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry } from "rxjs/operators"
import { toStream } from "mobx-utils"

import { BasicCloudFolder } from "@framework/types/upload"
import { VirtualListChunk } from "@framework/types/utils"

import AllExternalCloudFoldersStore from "./all-external-cloud-folders.store"

const CHUNK_SIZE = 100
const RETRY_DELAY = 10 * 1000

type State = {
  query: string
  isLoading: boolean
  data: BasicCloudFolder[]
  requestedFrame: VirtualListChunk
  actualFrame: VirtualListChunk
  reloadTrigger: boolean
  total: number
  totalFound: number
  errorMessage: string
}

export class ExternalCloudFoldersStore {
  state: State

  private loadingStream$?: Subscription

  cloudFoldersService: AllExternalCloudFoldersStore

  constructor(options: { dataConnectorId: string }) {
    this.state = {
      query: "",

      isLoading: false,

      data: [],

      requestedFrame: { offset: 0, limit: CHUNK_SIZE },

      actualFrame: { offset: 0, limit: CHUNK_SIZE },

      reloadTrigger: false,

      total: 0,

      totalFound: 0,

      errorMessage: "",
    }

    this.cloudFoldersService = new AllExternalCloudFoldersStore({
      dataConnectorId: options.dataConnectorId,
    })

    makeAutoObservable(this)

    onBecomeObserved(this.state, "total", () => {
      this.loadingStream$ = this.initLoadingStream()
    })

    onBecomeUnobserved(this.state, "total", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    return from(
      toStream(
        () => [
          this.state.requestedFrame.offset,
          this.state.requestedFrame.limit,
          this.state.query,
          this.state.reloadTrigger,
          this.cloudFoldersService.data,
        ],
        true
      ) as ObservableInput<[number, number, string, boolean, any]>
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.errorMessage = ""
          })
        ),
        switchMap(([offset, limit, query]) =>
          this.cloudFoldersService.getChunk({ query, offset, limit })
        ),
        tap((response) => {
          runInAction(() => {
            const { data = [], meta } = response

            this.state.data = data.map((it) => ({
              ...it,
              id: it.cloudFolderId,
            }))

            this.state.actualFrame = { ...meta }

            this.state.total = meta.total

            this.state.totalFound = meta.totalFound

            this.state.isLoading = false
          })
        }),
        retry({
          delay: () => {
            runInAction(() => {
              this.state.isLoading = false
              this.state.errorMessage = "Loading Failed"
            })
            return timer(RETRY_DELAY)
          },
        })
      )
      .subscribe()
  }

  get getById() {
    const usersMap = new Map(this.state.data.map((it) => [it.id, it]))
    return (id: string | null) => (id == null ? null : usersMap.get(id) ?? null)
  }

  get getByIndex() {
    const { data, actualFrame } = this.state
    return (index: number) => {
      return index == null ? null : data[index - actualFrame.offset] ?? null
    }
  }

  load = async ({ startIndex }: { startIndex: number; stopIndex: number }) => {
    const chunkIndex = Math.floor(startIndex / CHUNK_SIZE) - 1

    const newOffset = Math.max(0, chunkIndex * CHUNK_SIZE)

    this.state.requestedFrame = {
      offset: newOffset,
      limit: CHUNK_SIZE * 3,
    }
  }

  search = (query: string) => {
    this.state.query = query
  }

  refresh = () => {
    this.state.reloadTrigger = !this.state.reloadTrigger
  }
}

export default ExternalCloudFoldersStore
