import React from "react"
import clsx from "clsx"

import Icon from "../Icon/Icon"
import { IconName } from "../Icon/IconName"

import styles from "./AlertBanner.module.sass"

export type AlertType = "info" | "error" | "success"

export type AlertConfig = { className: string; iconName?: IconName }

const alertConfig: Record<AlertType, AlertConfig> = {
  info: {
    className: styles.colorInfo,
  },
  success: {
    className: styles.colorSuccess,
  },
  error: {
    className: styles.colorError,
  },
}

export interface AlertPopupTemplateProps {
  type?: AlertType
  className?: string
}

const AlertBanner: React.FC<AlertPopupTemplateProps> = ({
  type = "info",
  children,
  className,
}) => {
  const config = type ? alertConfig[type] : alertConfig.info

  return (
    <div className={clsx(styles.root, config.className, className)}>
      <Icon className={styles.icon} name={config.iconName || "info"} />
      <p className={styles.message}>{children}</p>
    </div>
  )
}

export default AlertBanner
