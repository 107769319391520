import React from "react"
import { observer, useLocalObservable } from "mobx-react-lite"
import { useAlert } from "react-alert"
import {
  AutoSizer,
  InfiniteLoader,
  ListRowProps,
  List as VirtualList,
} from "react-virtualized"

import { useController, useStore } from "@store/index"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import DataTypeLink from "@components/prototypes/DataType/DataTypeLink"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import {
  CloudEntity,
  DataConnectorSourceName,
  DataSourceNodeName,
} from "@framework/types/upload"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Button from "@components/ui/Button/Button"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import {
  InfiniteSelected,
  InfiniteSelectedContext,
  useInfiniteSelectedContext,
} from "@utils/InfiniteSelected"
import { getExtension } from "@utils/textUtils"
import { DocumentIconType, isDocumentIconName } from "@framework/types/utils"
import ListItem from "@components/ui/ListItem/ListItem"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import Chip from "@components/ui/Chip/Chip"

import { useSelectDataTypeContext } from "../PreselectDataTypeContext"
import CloudEntitiesListControlHeader from "./CloudEntitiesListControlHeader"

import styles from "./ConnectCloudFoldersSidebar.module.sass"

const BATCH_SIZE = 24

const SCANNING_PROCESS_NOTATION_MESSAGE = (
  <>
    <strong>Note:</strong> Data Connector scanning process might take some time.
    Don&apos;t worry if you&apos;re no able to see your files right after
    connection
  </>
)

export interface ConnectCloudEntitiesFormProps {
  title: string
  dataConnectorId: string
  description?: string
  sourceName: DataSourceNodeName
  connectorSourceName: DataConnectorSourceName
  onClose?: () => void
  onSubmit?: () => void
}

export const ConnectCloudEntitiesForm: React.FC<ConnectCloudEntitiesFormProps> =
  observer(
    ({
      title,
      description,
      sourceName,
      connectorSourceName,
      dataConnectorId,
      onClose,
      onSubmit = onClose,
    }) => {
      const alert = useAlert()
      const { uploadStore } = useStore()
      const { dataConnectorController } = useController()

      const { dataTypeId } = useSelectDataTypeContext()

      const store = uploadStore.externalEntitiesStores.getStore(dataConnectorId)

      const collection = store.state

      const selectStore = useLocalObservable(
        () => new InfiniteSelected({ total: collection.total })
      )

      const [isLoading, setLoading] = React.useState(false)

      const handleSubmit = async () => {
        try {
          setLoading(true)

          if (dataTypeId == null) throw Error("Data Type is required")

          const error = await dataConnectorController.connectCloudFolders(
            [...selectStore.data],
            dataTypeId,
            connectorSourceName
          )

          if (error != null) {
            alert.error(
              <>
                <Text variant="h3">Failed to connect</Text>
                <b />
                <Text variant="caption1" color="text70Color">
                  {error}
                </Text>
              </>
            )
            return
          }
          onSubmit?.()
        } finally {
          setLoading(false)
        }
      }

      const renderRow = ({ index, key, style }: ListRowProps) => {
        return (
          <div style={{ ...style, padding: 2 }} key={key}>
            <CheckboxCardItem index={index} dataConnectorId={dataConnectorId} />
          </div>
        )
      }

      const totalSelected = selectStore.selectedCount
      const isValid = totalSelected > 0

      React.useEffect(() => {
        selectStore.selectAll(false)
      }, [store.state.path])

      React.useEffect(() => {
        store.resetPath()
      }, [])

      return (
        <InfiniteSelectedContext.Provider value={selectStore}>
          <Templates.RollScript
            gutter="32"
            headerSocket={
              <List gutter="24">
                <ModalTitle titleText={title} subtitleText={description} />
              </List>
            }
            footerSocket={
              <List align="stretch">
                <Button
                  onClick={handleSubmit}
                  disabled={!isValid || collection.isLoading || isLoading}
                  color="primary"
                  after={isLoading && <Loader />}
                >
                  Connect{" "}
                  {totalSelected
                    ? `${totalSelected} ${
                        totalSelected === 1 ? "Entity" : "Entities"
                      }`
                    : null}
                </Button>
              </List>
            }
          >
            <Templates.RollScript headerSocket={<DataTypeLink />}>
              <Templates.RollScript
                gutter="16"
                headerSocket={
                  <CloudEntitiesListControlHeader
                    dataConnectorId={dataConnectorId}
                  />
                }
              >
                {collection.total === 0 ? (
                  collection.isLoading ? (
                    <Loader size="large" fluid />
                  ) : (
                    <List justify="center">
                      <NotFound>
                        No entities found
                        <br />
                        <br />
                        {!store.state.query && (
                          <List direction="row" gutter="8" align="center">
                            <Icon
                              name="alert"
                              color="gold"
                              className={styles.alertIcon}
                            />
                            <Text variant="caption1" color="text70Color">
                              {SCANNING_PROCESS_NOTATION_MESSAGE}
                            </Text>
                          </List>
                        )}
                      </NotFound>
                    </List>
                  )
                ) : (
                  <div className={styles.container}>
                    <InfiniteLoader
                      isRowLoaded={(it) => store.getByIndex(it.index) != null}
                      loadMoreRows={store.load}
                      rowCount={collection.total}
                      minimumBatchSize={BATCH_SIZE}
                      threshold={BATCH_SIZE}
                      key={store.state.path}
                    >
                      {({ onRowsRendered, registerChild }) => (
                        <AutoSizer>
                          {({ width, height }) => (
                            <VirtualList
                              rowCount={collection.total}
                              ref={registerChild}
                              onRowsRendered={onRowsRendered}
                              rowHeight={74}
                              rowRenderer={renderRow}
                              height={height}
                              width={width}
                            />
                          )}
                        </AutoSizer>
                      )}
                    </InfiniteLoader>
                  </div>
                )}
              </Templates.RollScript>
            </Templates.RollScript>
          </Templates.RollScript>
        </InfiniteSelectedContext.Provider>
      )
    }
  )

export default ConnectCloudEntitiesForm

const CheckboxCardItem: React.FC<{
  index: number
  dataConnectorId: string
}> = observer(({ index, dataConnectorId }) => {
  const { uploadStore, allDatatypesStore } = useStore()

  const selectedContext = useInfiniteSelectedContext()

  const store = uploadStore.externalEntitiesStores.getStore(dataConnectorId)

  const item = store.getByIndex(index)

  if (item == null) return <Skeleton lineHeight={70} minWidth={100} rounded />

  const isFolder = item.type === "dir"

  const dataType = allDatatypesStore.getById(item?.knowledgeDataTypeId)

  const isConnected = item.status === "selected"

  return (
    <ListItem
      active={isConnected || selectedContext.isSelected(item.id)}
      className={styles.card}
      onClick={!isConnected ? () => selectedContext.toggle(item.id) : undefined}
      onDoubleClick={
        isFolder ? () => store.openDirectory(item.name) : undefined
      }
    >
      <List direction="row" gutter="16" align="center">
        <DocumentIcon height={32} icon={getEntityIcon(item)} />

        <Text
          variant="inherit"
          color="inherit"
          className={styles.fileName}
          title={item.name}
        >
          {item.name}
        </Text>

        {isConnected && !isFolder && (
          <Chip
            className={styles.dataTypeBadge}
            color="primary"
            variant="rounded"
            before={
              dataType != null && <Icon name={dataType.iconUrl ?? "global"} />
            }
            title={dataType != null ? dataType.name : "Unknown Data Type"}
          >
            {dataType != null ? dataType.name : "Unknown"}
          </Chip>
        )}

        {isConnected && (
          <Icon
            title="Connected"
            className={styles.checkmark}
            name="checkbox-circle-fill"
            color="primary"
          />
        )}
      </List>
    </ListItem>
  )
})

const getEntityIcon = (entity: CloudEntity): DocumentIconType => {
  if (entity.type === "dir") return "img:folder_full"

  const icon = `img:${getExtension(entity.name)}`

  return isDocumentIconName(icon) ? icon : "img:file"
}
