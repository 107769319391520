import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"
import moment from "moment"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Chip from "@components/ui/Chip/Chip"
import List from "@components/ui/List/List"
import UserCard from "@components/ui/UserCard/UserCard"
import { useStore } from "@store/index"

import ContextMenu from "./UserContextMenu"

import styles from "./index.module.sass"

interface UserRowProps {
  id: string | null
}

export const UserRow: React.FC<UserRowProps> = observer(({ id }) => {
  const { adminUsersStore } = useStore()

  const user = adminUsersStore.getUserById(id)

  if (!user) return null
  return (
    <Row className={clsx(styles.row)}>
      <Column key="name" align="center">
        <UserCard
          fullName={`${user.firstName} ${user.lastName}`}
          metaInfo={user.email}
        />
      </Column>

      <Column key="actions" align="center">
        <ContextMenu user={user} />
      </Column>

      <Column key="roles" align="center">
        <List direction="row" gutter="8" wrap="wrap" overflow="initial">
          {user.userRoles?.length
            ? user.userRoles?.map((role) => (
                <Chip color="primary" uppercase key={role.id}>
                  {role.name}
                </Chip>
              ))
            : "-"}
        </List>
      </Column>

      <Column key="created_date" align="center">
        {user.createdAt ? moment(user.createdAt).format("MMM DD, YYYY") : "-"}
      </Column>

      <Column key="created_date" align="center">
        {user.lastActiveDateTime
          ? moment(user.lastActiveDateTime).format("MMM DD, YYYY")
          : "-"}
      </Column>

      <Column key="avatars" align="center">
        {user.avatars?.length} Avatars
      </Column>
    </Row>
  )
})

export default UserRow
