import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"
import Highlighter from "react-highlight-words"

import { transformAnswerText } from "@utils/textUtils"
import { TextAnswerType } from "@framework/types/search"

import styles from "./TextResult.module.sass"

export interface TextResultProps {
  content?: string
  highlights?: string[]
  data?: TextAnswerType
}

export const TextResult: React.FC<TextResultProps> = observer(
  ({ content, highlights = [] }) => {
    const paragraphs = useMemo(
      () => (content ? transformAnswerText(content) : []),
      [content]
    )

    return (
      <div className={styles.root}>
        {paragraphs.map(({ name, value }) => (
          <p key={name} className={styles.text}>
            <Highlighter
              highlightClassName={styles.highlight}
              autoEscape
              textToHighlight={value}
              searchWords={highlights}
            />
          </p>
        ))}
      </div>
    )
  }
)

export default TextResult
