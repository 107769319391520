import { AxiosResponse } from "axios"

import {
  SubscriptionOption,
  SubscriptionOptionList,
  SubscriptionUsage,
  SubscriptionsData,
} from "@framework/types/subscription"

import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export interface SubscriptionsResponse extends DefaultSuccessResponse {
  data: SubscriptionsData
}

export interface SubscriptionOptionsResponse extends DefaultSuccessResponse {
  data: SubscriptionOptionList
}

export interface MonthlySubscriptionUsageResponse
  extends DefaultSuccessResponse {
  data: SubscriptionUsage
}

export interface SubscriptionUpgradeResponse extends DefaultSuccessResponse {
  data: {
    message: string
  }
}

export interface AddSubscriptionOptionResponse extends DefaultSuccessResponse {
  data: SubscriptionOption
}

class SubscriptionAPI extends HttpService {
  base = "ts/subscriptions"

  getSubscription = (): Promise<AxiosResponse<SubscriptionsResponse>> =>
    this.get(this.base, true)

  getAvailableOptions = (): Promise<
    AxiosResponse<SubscriptionOptionsResponse>
  > => this.get(`${this.base}/options`, true)

  addAvailableOption = (data: {
    name: string
    groupName: string
  }): Promise<AxiosResponse<AddSubscriptionOptionResponse>> =>
    this.post(`${this.base}/options`, data, true)

  updateSubscription = (
    subScriptionsData: SubscriptionsData
  ): Promise<AxiosResponse<SubscriptionsResponse>> =>
    this.patch(this.base, subScriptionsData, true)

  getMonthlySubscriptionUsage = (): Promise<
    AxiosResponse<MonthlySubscriptionUsageResponse>
  > => this.get(`${this.base}/usage`, true)

  upgradeSubscription = (
    description: string
  ): Promise<AxiosResponse<SubscriptionUpgradeResponse>> =>
    this.post(`${this.base}/upgrade`, { description }, true)
}

export default new SubscriptionAPI()
