import { makeAutoObservable } from "mobx"

import { DataCategoryEntity } from "@framework/types/knowledge"

export class KnowledgeDatatypeDetailsStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading = false

  data: DataCategoryEntity | null = null

  error: string | null = null

  storeDatatype = (data: DataCategoryEntity) => {
    this.data = data
  }
}

export default KnowledgeDatatypeDetailsStore
