import { makeAutoObservable } from "mobx"

import { ExpertArticleInsightDTO } from "@framework/types/knowledge"

import EntityCollection from "../utils/EntityCollection"

export default class ExpertArticleInsightsStore {
  constructor() {
    makeAutoObservable(this)

    this.collectionsMap = new Map()
  }

  collectionsMap: Map<string, EntityCollection<ExpertArticleInsightDTO>>

  getCollection = (articleId: string) => {
    if (!this.collectionsMap.has(articleId)) this.resetCollection(articleId)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.collectionsMap.get(articleId)!
  }

  resetCollection = (collectionKey: string) => {
    this.collectionsMap.set(collectionKey, new EntityCollection())
  }
}
