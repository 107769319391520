import { AxiosResponse } from "axios"
import { Observable } from "rxjs"

import {
  BasicTopic,
  DataCategoryEntity,
  ExpertAnswerData,
  ExpertArticleDTO,
  ExpertArticleInsightDTO,
  ExpertSubtopicDTO,
  ExpertTopicDTO,
  KnowledgeDatatypeFilters,
} from "@framework/types/knowledge"
import {
  AnswerStatus,
  ExpertQuestionsDataDTO,
  ExpertQuestionsThreadData,
} from "@framework/types/question"
import { BaseUserData, RankedExpertData } from "@framework/types/user"
import { PaginationListMeta, PaginationParams } from "@framework/types/utils"

import {
  CollectionContentModeType,
  DefaultSuccessResponse,
} from "./common/types"
import HttpService from "./http.service"

export interface GetExpertsResponse extends DefaultSuccessResponse {
  data: {
    experts: BaseUserData[]
  }
}

export interface GetRankedExpertsResponse extends DefaultSuccessResponse {
  data: RankedExpertData[]
}

export interface GetExpertQuestionsResponse extends DefaultSuccessResponse {
  data: ExpertQuestionsDataDTO
}

export interface GetExpertQuestionsThreadResponse
  extends DefaultSuccessResponse {
  data: ExpertQuestionsThreadData
}

export interface GetExpertAssignedToQuestionsResponse
  extends DefaultSuccessResponse {
  data: string[]
}

export interface GetDataCategoriesByAvatarResponse {
  data: DataCategoryEntity[]
}

export interface GetAvatarAnswersResponse extends DefaultSuccessResponse {
  data: ExpertAnswerData[]
  meta: PaginationListMeta
}

export interface GetExpertTopicResponse {
  data?: ExpertTopicDTO
}

export interface GetExpertTopicListResponse {
  data?: ExpertTopicDTO[]
}

export interface GetExpertSubtopicResponse {
  data: ExpertSubtopicDTO
}

export interface GetExpertSubtopicListResponse {
  data?: ExpertSubtopicDTO[]
}

export interface GetExpertArticleListResponse {
  data?: ExpertArticleDTO[]
}

export interface GetExpertArticleResponse {
  data: ExpertArticleDTO
}

export interface GetExpertArticleInsightResponse {
  data?: ExpertArticleInsightDTO
}

export interface GetExpertArticleInsightListResponse {
  data?: ExpertArticleInsightDTO[]
}

class KnowledgeAPI extends HttpService {
  getKnowledgeExperts = (
    avatarId: string
  ): Promise<AxiosResponse<GetExpertsResponse>> =>
    this.get(`ts/v2/knowledge/avatar/${avatarId}/experts?knowledgeType=gap`)

  getRankedExperts = (
    avatarId: string
  ): Promise<AxiosResponse<GetRankedExpertsResponse>> =>
    this.get(`ts/v2/knowledge/avatar/${avatarId}/expert-ranking`)

  getExpertQuestions = (): Promise<AxiosResponse<GetExpertQuestionsResponse>> =>
    this.get(`ts/knowledge/expert/assigned-questions`)

  getExpertQuestionThread = (
    questionText: string,
    questionId: string
  ): Promise<AxiosResponse<GetExpertQuestionsThreadResponse>> =>
    this.post(`ts/knowledge/expert/assigned-questions/${questionId}/thread`, {
      question: questionText,
    })

  getExpertAssignedToQuestion = (
    questionText: string,
    questionId: string
  ): Promise<AxiosResponse<GetExpertAssignedToQuestionsResponse>> =>
    this.post(
      `ts/knowledge/expert/assigned-questions/${questionId}/assigned-experts`,
      {
        question: questionText,
      }
    )

  updateExpertQuestion = (
    questionId: string,
    status: AnswerStatus,
    answer?: string
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.patch(`ts/v2/knowledge/expert/assigned-questions/${questionId}`, {
      status,
      answer,
    })

  reassignQuestionToExpert = (
    questionId: string,
    delegatedToUserIds: string[]
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.post(
      `ts/knowledge/expert/assigned-questions/${questionId}/re-assign`,
      { delegatedToUserIds }
    )

  cancelLastQuestionAssignation = (
    questionId: string
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.patch(
      `ts/knowledge/expert/assigned-questions/${questionId}/cancel`,
      {}
    )

  assignationQuestionDirectlyMultipleExperts = (request: {
    delegatedToUserIds: string[]
    question: string
    avatar: string
    avatarId: string
    userNote?: string
    answer?: any
    summary?: string
  }): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.post(`ts/v2/knowledge/expert/multi-assign`, request)

  getExpertAnswersByAvatarId = (
    avatarId: string,
    params?: PaginationParams
  ): Promise<AxiosResponse<GetAvatarAnswersResponse>> =>
    this.get(`ts/v2/avatar/${avatarId}/expert-knowledge`, true, params)

  getDataTypesByAvatarId = (
    avatarId: string
  ): Promise<AxiosResponse<GetDataCategoriesByAvatarResponse>> =>
    this.get(`ts/v2/avatar/${avatarId}/knowledge-data-type`)

  getAllDataTypes = (
    query?: string,
    filters?: KnowledgeDatatypeFilters
  ): Promise<AxiosResponse<GetDataCategoriesByAvatarResponse>> =>
    this.get(`ts/knowledge-data-type/all`, true, { query, ...filters })

  getAllDataTypes$ = (): Observable<
    AxiosResponse<GetDataCategoriesByAvatarResponse>
  > => this.getStream$(`ts/knowledge-data-type/all`)

  updateDataTypes = (
    knowledgeDataTypeId: string,
    data: Partial<{
      description: string
      fileIds: string[]
      folderIds: string[]
      websiteIds: string[]
      fileIdsToDelete: string[]
      websiteIdsToDelete: string[]
      folderIdsToDelete: string[]
    }>
  ): Promise<AxiosResponse<GetDataCategoriesByAvatarResponse>> =>
    this.patch(`ts/knowledge-data-type`, data, true, {
      knowledgeDataTypeId,
    })

  // topics

  createExpertTopic = (
    avatarId: string,
    data: BasicTopic
  ): Promise<AxiosResponse<GetExpertTopicResponse>> =>
    this.post(`ts/expert-knowledge/${avatarId}/expert-topics`, data)

  getExpertTopicList = (
    avatarId: string,
    params?: PaginationParams & {
      deepSearch?: boolean
      mode?: CollectionContentModeType
    }
  ): Promise<AxiosResponse<GetExpertTopicListResponse>> =>
    this.get(`ts/expert-knowledge/${avatarId}/expert-topics`, true, params)

  updateExpertTopic = (
    avatarId: string,
    topicId: string,
    data: BasicTopic
  ): Promise<AxiosResponse<GetExpertTopicResponse>> =>
    this.patch(`ts/expert-knowledge/${avatarId}/expert-topics/${topicId}`, data)

  removeExpertTopic = (
    avatarId: string,
    topicId: string
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.delete(`ts/expert-knowledge/${avatarId}/expert-topics/${topicId}`)

  // subtopics

  createExpertSubtopic = (
    avatarId: string,
    topicId: string,
    data: BasicTopic
  ): Promise<AxiosResponse<GetExpertSubtopicResponse>> =>
    this.post(
      `ts/expert-knowledge/${avatarId}/expert-topics/${topicId}/expert-sub-topics`,
      data
    )

  getExpertSubtopicList = (
    avatarId: string,
    topicId: string,
    params?: PaginationParams & { mode?: CollectionContentModeType }
  ): Promise<AxiosResponse<GetExpertSubtopicListResponse>> =>
    this.get(
      `ts/expert-knowledge/${avatarId}/expert-topics/${topicId}/expert-sub-topics`,
      true,
      params
    )

  getExpertSubtopicById = (
    avatarId: string,
    subtopicId: string
  ): Promise<AxiosResponse<GetExpertSubtopicResponse>> =>
    this.get(`ts/expert-knowledge/${avatarId}/expert-sub-topics/${subtopicId}`)

  updateExpertSubtopic = (
    avatarId: string,
    subtopicId: string,
    data: BasicTopic
  ): Promise<AxiosResponse<GetExpertSubtopicResponse>> =>
    this.patch(
      `ts/expert-knowledge/${avatarId}/expert-sub-topics/${subtopicId}`,
      data
    )

  // articles

  getExpertArticleList = (
    avatarId: string,
    subtopicId: string,
    params?: PaginationParams & { mode?: CollectionContentModeType }
  ): Promise<AxiosResponse<GetExpertArticleListResponse>> =>
    this.get(
      `ts/expert-knowledge/${avatarId}/expert-sub-topics/${subtopicId}/expert-articles`,
      true,
      params
    )

  createExpertArticle = (
    avatarId: string,
    subtopicId: string,
    data: BasicTopic
  ): Promise<AxiosResponse<GetExpertArticleResponse>> =>
    this.post(
      `ts/expert-knowledge/${avatarId}/expert-sub-topics/${subtopicId}/expert-articles`,
      data
    )

  // insights

  createExpertArticleInsight = (
    avatarId: string,
    articleId: string,
    data: { insight: string }
  ): Promise<AxiosResponse<GetExpertArticleInsightResponse>> =>
    this.post(
      `ts/expert-knowledge/${avatarId}/expert-articles/${articleId}/expert-insights`,
      data
    )

  getExpertArticleInsightList = (
    avatarId: string,
    articleId: string,
    params?: PaginationParams
  ): Promise<AxiosResponse<GetExpertArticleInsightListResponse>> =>
    this.get(
      `ts/expert-knowledge/${avatarId}/expert-articles/${articleId}/expert-insights`,
      true,
      params
    )

  updateExpertArticleInsight = (
    avatarId: string,
    insightId: string,
    data: { insight: string }
  ): Promise<AxiosResponse<GetExpertArticleInsightResponse>> =>
    this.patch(
      `ts/expert-knowledge/${avatarId}/expert-insights/${insightId}`,
      data
    )

  deleteExpertArticleInsight = (
    avatarId: string,
    insightId: string
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.delete(`ts/expert-knowledge/${avatarId}/expert-insights/${insightId}`)
}

export default new KnowledgeAPI()
