import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"

import AppControlContainer from "@pages/launch-solutions/SolutionTabsContainer/AppControlContainer"
import { useStore } from "@store/index"
import { SearchEntityContextProvider } from "@pages/search/SearchContext/SearchResultContext"
import AnswerSection from "@pages/launch-solutions/SolutionTabsContainer/AnswerSection"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import mainRoutes from "@root/main.routes"
import { SolutionData } from "@framework/types/solution"

import SearchWidget from "./components/SearchWidget/SearchWidget"
import FileUpload from "./components/FileUpload"
import DocChatSearchPassagesSidebar from "./components/SourcePassagesSidebar"

import styles from "./index.module.sass"

interface Props {
  data: SolutionData
}

const DocChatPage: React.FC<Props> = observer(({ data }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [searched, setIsSearched] = useState(false)

  const answersRef = useRef<HTMLDivElement>(null)
  const {
    documentChatSolutionStore: { searchFlowStore, queryAttachments },
  } = useStore()

  const { avatar } = useActiveAvatar()

  const closeModal = () =>
    navigate(location.state?.backTo ?? mainRoutes.solutions())

  useEffect(() => {
    if (answersRef.current) {
      answersRef.current.scrollTo({
        top: answersRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [searchFlowStore.flowSequence.length])

  useEffect(() => {
    if (!queryAttachments.hasAttachments) setIsSearched(false)
  }, [queryAttachments.hasAttachments])

  const isSearching = searchFlowStore.isAnyLoading()

  if (!avatar) return null
  return (
    <div className={styles.root} ref={answersRef}>
      {searchFlowStore.flowSequence.map((searchId) => (
        <SearchEntityContextProvider
          searchEntity={searchFlowStore.getById(searchId)}
          key={searchId}
        >
          <AnswerSection defaultQuery />
        </SearchEntityContextProvider>
      ))}

      <AppControlContainer
        moveDown={!!searchFlowStore.flowSequence.length || searched}
        title={data.name}
        description={data.description}
        hideRecentQuestions
      >
        {queryAttachments.attachments.length && queryAttachments.isLoaded ? (
          <SearchWidget
            avatar={avatar}
            onSearch={() => setIsSearched(true)}
            disabled={isSearching}
          />
        ) : (
          <FileUpload />
        )}
      </AppControlContainer>

      <Routes>
        <Route
          path=":searchId/:blockId/:answerIndex?"
          element={<DocChatSearchPassagesSidebar onBack={closeModal} />}
        />
      </Routes>
    </div>
  )
})

export default DocChatPage
