import { makeAutoObservable } from "mobx"

import { Product } from "@framework/types/product"

export default class SelectedProductsStore {
  constructor() {
    makeAutoObservable(this)
  }

  selectedProducts: Product[] = []

  addSelectedProduct = (product: Product) => {
    this.selectedProducts.push(product)
  }

  removeSelectedProduct = (product: Product) => {
    this.selectedProducts = this.selectedProducts.filter(
      (selectedProduct) => selectedProduct.id !== product.id
    )
  }

  reset = () => {
    this.selectedProducts = []
  }
}
