/* eslint-disable camelcase */
import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios"

type GoogleDriveAccessRequest = {
  client_id: string
  redirect_uri: string
  state?: string
}

// more info here https://developers.google.com/identity/protocols/oauth2/web-server?hl=en
interface GoogleDriveAccessRequestParams {
  client_id: string
  state?: string
  redirect_uri: string
  scope: string
  response_type: "code" | "token"
  access_type: "offline" | "online"
  include_granted_scopes: boolean
}

interface GoogleRefreshTokenByCodeResponse {
  refresh_token?: string
  access_token: string
  expires_in: number
  scope: string
  token_type: string
}

interface GoogleRefreshTokenByCodeRequestForm {
  code: string
  client_id: string
  client_secret: string
  redirect_uri: string
  grant_type: "authorization_code"
}

interface RequestProps<Data, Params> {
  url: string
  method: Method
  data?: Data
  params?: Params
}

class GoogleAPI {
  config = {
    apiURL: "https://accounts.google.com",
  }

  getURL = (url: string) => `${this.config.apiURL}/${url}`

  getGoogleDriveAuthCodeLink = (params: GoogleDriveAccessRequest) => {
    const requestParams: GoogleDriveAccessRequestParams = {
      scope: "https://www.googleapis.com/auth/drive",
      include_granted_scopes: true,
      response_type: "code",
      access_type: "offline",
      ...params,
    }
    const searchParams = new URLSearchParams(
      Object.entries(requestParams).map(([key, value]) => [
        key,
        value.toString(),
      ])
    )
    return `${this.getURL("o/oauth2/v2/auth")}?${searchParams.toString()}`
  }

  getGoogleRefreshTokenByCode = (
    data: GoogleRefreshTokenByCodeRequestForm
  ): Promise<AxiosResponse<GoogleRefreshTokenByCodeResponse>> => {
    const searchParams = new URLSearchParams(
      Object.entries(data).map(([key, value]) => [key, value.toString()])
    )
    return axios.request({
      url: "https://oauth2.googleapis.com/token",
      method: "post",
      headers: {
        Host: "oauth2.googleapis.com",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: searchParams,
    })
  }

  request = <Data, Params>(props: RequestProps<Data, Params>) => {
    const requestParams: AxiosRequestConfig = {
      url: this.getURL(props.url),
      method: props.method,
      data: props.data,
      params: props.params,
    }
    return axios.request(requestParams)
  }

  get = <Params>(url: string, params?: Params) =>
    this.request({
      url,
      params,
      method: "get",
    })

  post = <Data, Params>(url: string, data: Data, params?: Params) =>
    this.request({
      url,
      data,
      params,
      method: "post",
    })
}

export default new GoogleAPI()
