import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import { BaseUserRole } from "@framework/types/security"
import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"

import styles from "./DeleteModal.module.sass"

export interface DeleteUserRoleModalProps {
  role: BaseUserRole
}

export const DeleteUserRoleModal: React.FC<DeleteUserRoleModalProps> = observer(
  ({ role }) => {
    const { hideModal } = useModal(ModalsTypes.DELETE_USER_ROLE_MODAL)
    const alert = useAlert()

    const { rolesController } = useController()
    const { rolesStore } = useStore()
    const { isLoading } = rolesStore

    const handleSubmit = async () => {
      hideModal()
      const error = await rolesController.removeRole(role.id)
      if (error) {
        alert.error(error)
      } else {
        alert.success(
          <>
            Role &quot;<b>{role.name}</b>
            &quot; was successfully deleted
          </>
        )
      }
    }

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText={
              <>
                Are you sure you want to delete role &quot;
                <b>{role.name.toUpperCase()}</b>
                &quot;?
              </>
            }
          />
        }
        className={styles.root}
        containerClassName={styles.container}
        onClose={hideModal}
      >
        <ModalFooterContainer>
          <Button variant="outlined" disabled={isLoading} onClick={hideModal}>
            Cancel
          </Button>
          <Button color="red" disabled={isLoading} onClick={handleSubmit}>
            Delete {isLoading && <Loader />}
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  }
)

export default DeleteUserRoleModal
