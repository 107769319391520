import { makeAutoObservable } from "mobx"

import { RankedExpertData } from "@framework/types/user"
import knowledgeService from "@services/knowledge.service"
import RootStore from "@store/RootStore"

export class ExpertStore {
  root: RootStore

  constructor(root: RootStore) {
    makeAutoObservable(this)
    this.root = root
  }

  experts: RankedExpertData[] | null = null

  isLoading = false

  error: string | null = null

  loadRankedExperts = async (avatarId: string) => {
    try {
      this.isLoading = true
      this.error = null
      this.experts = null

      const response = await knowledgeService.getRankedExperts(avatarId)

      this.experts = response.data.data
    } catch (error) {
      this.error = "Unexpected error"
    } finally {
      this.isLoading = false
    }
    return this.error
  }
}

export default ExpertStore
