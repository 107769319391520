import { makeAutoObservable } from "mobx"

import { ExpertArticleDTO } from "@framework/types/knowledge"

import EntityCollection from "../utils/EntityCollection"

export default class ExpertArticlesStore {
  constructor() {
    makeAutoObservable(this)

    this.collectionsMap = new Map()
  }

  collectionsMap: Map<string, EntityCollection<ExpertArticleDTO>>

  getCollection = (avatarId: string) => {
    if (!this.collectionsMap.has(avatarId)) this.resetCollection(avatarId)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.collectionsMap.get(avatarId)!
  }

  resetCollection = (collectionKey: string) => {
    this.collectionsMap.set(collectionKey, new EntityCollection())
  }
}
