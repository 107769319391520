import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry } from "rxjs/operators"
import { toStream } from "mobx-utils"

import { DataCategoryEntity } from "@framework/types/knowledge"
import knowledgeService from "@services/knowledge.service"

const RETRY_DELAY = 15_000

type State = {
  isLoading: boolean
  data: DataCategoryEntity[]
  total: number
  errorMessage: string
  reloadTrigger: number
}

export class AllDataTypesStore {
  state: State

  private loadingStream$?: Subscription

  constructor() {
    this.state = {
      isLoading: false,

      data: [],

      total: 0,

      errorMessage: "",

      reloadTrigger: Date.now(),
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "data", () => {
      this.loadingStream$ = this.initLoadingStream()
    })

    onBecomeUnobserved(this.state, "data", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    const { state } = this

    return from(
      toStream(() => [state.reloadTrigger], true) as ObservableInput<[boolean]>
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            state.isLoading = true
            state.errorMessage = ""
          })
        ),
        switchMap(() => knowledgeService.getAllDataTypes$()),
        tap((response) =>
          runInAction(() => {
            state.data = response.data.data ?? []
            state.total = state.data.length
            state.isLoading = false
          })
        ),
        retry({
          delay: () => {
            runInAction(() => {
              state.errorMessage = "Loading Failed"
            })
            return timer(RETRY_DELAY)
          },
        })
      )
      .subscribe()
  }

  refresh = () => {
    this.state.reloadTrigger = Date.now()
  }

  get getById() {
    const dataMap = new Map(this.state.data.map((it) => [it.id, it]))
    return (id?: string | null) => {
      if (id == null) return null
      return dataMap.get(id) ?? null
    }
  }

  get getByIndex() {
    const { data } = this.state
    return (index: number) => {
      return data[index]
    }
  }
}

export default AllDataTypesStore
