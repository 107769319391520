import React from "react"

import Row from "@components/ui/BaseTable/Row"
import Col from "@components/ui/BaseTable/Column"

interface DatatypesTableHeaderProps {}

const DatatypesTableHeader: React.FC<DatatypesTableHeaderProps> = () => {
  const cols = [
    { key: "name", label: "Data Type" },
    { key: "actions", width: 70, justify: "center" },
    { key: "avatar", label: "Assigned Avatars", width: 300 },
    { key: "createdBy", label: "Created By", width: 250 },
    { key: "createdAt", label: "Created Date", width: 200 },
  ]
  return (
    <Row>
      {cols.map(({ key, width, label }) => (
        <Col key={key} width={width ?? "auto"}>
          {label}
        </Col>
      ))}
    </Row>
  )
}

export default DatatypesTableHeader
