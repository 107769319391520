import { makeAutoObservable } from "mobx"

import userService, { CreateActionRequestData } from "@services/user.service"
import defaultErrorHandler from "@store/utils/error-handlers"

import UserActionsStore from "./user-actions.store"

export class UserActionsController {
  actionsStore: UserActionsStore

  constructor(injections: { actionsStore: UserActionsStore }) {
    this.actionsStore = injections.actionsStore
    makeAutoObservable(this)
  }

  init = async () => {
    try {
      this.actionsStore.setLoading()
      this.actionsStore.setError()

      await this.loadActions()
    } catch (error) {
      this.actionsStore.setError(defaultErrorHandler(error))
    } finally {
      this.actionsStore.setLoading(false)
    }
    return this.actionsStore.error
  }

  protected loadActions = async () => {
    const response = await userService.getAllActions()
    this.actionsStore.setActions(response.data.data)
  }

  createAction = async (data: CreateActionRequestData) => {
    try {
      this.actionsStore.setLoading()
      this.actionsStore.setError()

      const response = await userService.createAction(data)
      this.actionsStore.updateAction(response.data.data)
    } catch (error) {
      this.actionsStore.setError(
        defaultErrorHandler(error, "creating new user action")
      )
    } finally {
      this.actionsStore.setLoading(false)
    }
    return this.actionsStore.error
  }

  updateAction = async (actionId: string, data: CreateActionRequestData) => {
    try {
      this.actionsStore.setLoading()
      this.actionsStore.setError()

      const response = await userService.updateAction(actionId, data)
      this.actionsStore.updateAction(response.data.data)
    } catch (error) {
      this.actionsStore.setError(
        defaultErrorHandler(error, "updating user action")
      )
    } finally {
      this.actionsStore.setLoading(false)
    }
    return this.actionsStore.error
  }

  removeAction = async (actionId: string) => {
    try {
      this.actionsStore.setLoading()
      this.actionsStore.setError()

      await userService.deleteAction(actionId)
      await this.loadActions()
    } catch (error) {
      this.actionsStore.setError(
        defaultErrorHandler(error, "deleting user action")
      )
    } finally {
      this.actionsStore.setLoading(false)
    }
    return this.actionsStore.error
  }
}

export default UserActionsController
