import { observer } from "mobx-react-lite"
import React from "react"

import { useStore } from "@store/index"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"

import BaseModal from "../components/BaseModal/BaseModal"
import ModalTitle from "../components/ModalTitle/ModalTitle"
import { ModalsTypes } from "../constants"
import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"

import styles from "./LogoutModal.module.sass"

export interface LogoutModalProps {}

export const LogoutModal: React.FC<LogoutModalProps> = observer(() => {
  const { authStore } = useStore()

  const logoutModal = useModal(ModalsTypes.LOGOUT_MODAL)

  const handleSubmit = (e: React.FormEvent) => {
    authStore.logout()
    e.preventDefault()
    return false
  }

  return (
    <BaseModal
      title={<ModalTitle titleText="Are you sure you want to log out?" />}
      containerClassName={styles.root}
      onClose={logoutModal.hideModal}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <ModalFooterContainer>
          <Button variant="outlined" onClick={logoutModal.hideModal}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Log Out
          </Button>
        </ModalFooterContainer>
      </form>
    </BaseModal>
  )
})

export default LogoutModal
