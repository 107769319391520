import { AxiosResponse } from "axios"
import { makeAutoObservable } from "mobx"

import uploadService, {
  UploadFilesErrorResponse,
} from "@services/upload.service"

import FileUploadingTask from "./FileUploadingTask"
import FileUploadingStore from "./file-uploading.store"

export class UploadFileController {
  fileUploadingStore: FileUploadingStore

  constructor(injections: { fileUploadingStore: FileUploadingStore }) {
    makeAutoObservable(this)
    this.fileUploadingStore = injections.fileUploadingStore
  }

  uploadFile = async (task: FileUploadingTask, dataTypeId?: string) => {
    try {
      task.startUpload()

      const form = new FormData()
      form.append("files[]", task.data)

      await uploadService.uploadFiles(
        form,
        dataTypeId ? { knowledgeDataTypeId: dataTypeId } : undefined
      )

      task.uploadSucceeded()
    } catch (error: any) {
      const { response } = error

      if (response != null) {
        const typedResponse: AxiosResponse<UploadFilesErrorResponse> = response

        if (typedResponse.status === 413) {
          task.uploadFailed("File is too large")
          return
        }

        if (typedResponse.data.status === "BODY_INCOMPLETE") {
          task.uploadFailed("No files added")
          return
        }

        if (typedResponse.data.status === "INCORRECT_URL") {
          task.uploadFailed("Current source type not maintenance")
          return
        }
      }

      task.uploadFailed("Unknown error")
    }
  }

  uploadFiles = async (files: File[], dataTypeId?: string) => {
    try {
      await Promise.all(
        files.map((file) => {
          const uploadTask = new FileUploadingTask({
            file,
            manager: this.fileUploadingStore,
          })

          this.fileUploadingStore.addTask(uploadTask)

          return this.uploadFile(uploadTask, dataTypeId)
        })
      )
    } catch (error: any) {
      return "Unexpected error"
    }
    return null
  }

  flush = () => {
    this.fileUploadingStore.flush()
  }
}

export default UploadFileController
