import axios, { AxiosError, AxiosResponse } from "axios"

import RootStore from "@store/RootStore"

const interceptor = (rootStore: RootStore) => {
  const instance = axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (error?.response?.status === 401) {
        rootStore.authStore.sessionExpiredError()
      }
      if (error?.response?.status === 502) {
        // TODO throw service down error
      }
      return Promise.reject(error)
    }
  )
  return () => axios.interceptors.response.eject(instance)
}

export default interceptor
