import { makeAutoObservable } from "mobx"
import keyBy from "lodash/keyBy"

import { ListResponse } from "@framework/types/product"
import { Company } from "@framework/types/company"

export default class CompaniesStore {
  companies: Company[] | null = null

  error: string | null = null

  isLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  get companyMap(): Record<string, Company> {
    return keyBy(this.companies ?? [], "id")
  }

  setCompanies = (companies: ListResponse<Company>) => {
    this.companies = companies.data
  }
}
