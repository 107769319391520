import clsx from "clsx"
import React from "react"

import Icon from "@components/ui/Icon/Icon"

import styles from "./BadgeWrapper.module.sass"

export interface BadgeWrapperProps {
  mark?: React.ReactNode
}

export const BadgeWrapper: React.FC<BadgeWrapperProps> = ({
  mark,
  children,
}) => (
  <div className={styles.root}>
    {children}
    {mark}
  </div>
)

export interface BadgeProps {
  className?: string
}

export const SpinnerBadge: React.FC<BadgeProps> = ({ className }) => (
  <span className={clsx(styles.loader, className)}>
    <Icon name="loader" />
  </span>
)

export const NotificationBadge: React.FC<BadgeProps> = ({ className }) => (
  <span className={clsx(styles.notification, className)}>New</span>
)

export default BadgeWrapper
