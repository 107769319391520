import { CellValidationRule } from "./state/CellManager"
import MatrixStore from "./state/MatrixStore"

type CellValidationConfig = {
  range: string
  validation: CellValidationRule
}

const createMatrix = (config?: {
  initialValue?: string[][]
  validations?: CellValidationConfig[]
}) => {
  const instance = new MatrixStore()

  instance.init({ initialValue: config?.initialValue })

  config?.validations?.forEach((it) =>
    instance.setValidation(it.range, it.validation)
  )

  return instance
}

export default createMatrix
