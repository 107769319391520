import React, { useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import * as yup from "yup"

import mainRoutes from "@root/main.routes"
import { useStore } from "@store/index"
import Button from "@components/ui/Button/Button"
import TextInput from "@components/ui/TextInput/TextInput"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import Loader from "@components/ui/Loader/BarLoader"
import { SignInFormType } from "@framework/types/auth"
import PasswordField from "@components/ui/PasswordField/PasswordField"

import styles from "./SignInForm.module.sass"

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Field is required")
    .email("Must be a valid email"),
  // password: yup.string().required("Field is required"),
})

export interface SignInFormProps {
  className?: string
  primary?: boolean
}

const SignInForm: React.FC<SignInFormProps> = observer(({ primary }) => {
  const navigate = useNavigate()
  const { authStore } = useStore()

  const { isSignInLoading, isLoading, loginErrors, confirmation2FAEmail } =
    authStore

  const handleLogin = useCallback(
    (data: SignInFormType) => {
      authStore.login(data.email, data.password)
    },
    [authStore]
  )

  const formik = useFormik<SignInFormType>({
    initialValues: {
      email: "",
      password: "",
    },
    initialErrors: loginErrors,
    validationSchema,
    onSubmit: handleLogin,
  })

  useEffect(() => {
    formik.setErrors(loginErrors)
  }, [loginErrors])

  useEffect(() => {
    if (confirmation2FAEmail) {
      navigate(mainRoutes.loginConfirm())
    }
  }, [confirmation2FAEmail])

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <TextInput
        name="email"
        placeholder="Email"
        value={formik.values.email}
        withError={!!(formik.touched.email && formik.errors.email)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        after={
          formik.touched.email &&
          formik.errors.email && (
            <div className={styles.after}>
              <ErrorChip
                message={formik.errors.email}
                messagePlacement="left"
              />
            </div>
          )
        }
      />
      <PasswordField
        name="password"
        placeholder="Password"
        value={formik.values.password}
        withError={!!(formik.touched.password && formik.errors.password)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        after={
          formik.touched.password &&
          formik.errors.password && (
            <ErrorChip
              message={formik.errors.password}
              messagePlacement="left"
            />
          )
        }
      />
      <div className={styles.forgotPasswordContainer}>
        <Link to="/change-password" className={styles.inputLink}>
          Forgot password?
        </Link>
      </div>
      <Button
        variant={primary ? "contained" : "outlined"}
        color={primary ? "primary" : "default"}
        onClick={formik.submitForm}
        disabled={isLoading}
        after={isSignInLoading && <Loader />}
        type="submit"
      >
        Login
      </Button>
    </form>
  )
})

export default SignInForm
