import React from "react"

import List from "@components/ui/List/List"
import ScrollableContainer from "@components/ui/ScrollableContainer/ScrollableContainer"
import { ExpertQuestion, KnowledgeQuestion } from "@framework/types/question"
import Icon from "@components/ui/Icon/Icon"
import IconChip from "@components/ui/Chip/IconChip"
import Text from "@components/ui/Typography/Text"
import IconButton from "@components/ui/IconButton/IconButton"
import useToggle from "@components/hooks/useToggle"

import styles from "./QuestionPreview.module.sass"

interface QuestionsPreviewProps {
  questions: KnowledgeQuestion[] | ExpertQuestion[] | string[]
}

const QuestionPreview: React.FC<QuestionsPreviewProps> = ({ questions }) => {
  const toggler = useToggle()

  const renderQuestion = (
    question: KnowledgeQuestion | ExpertQuestion | string
  ) => `Q: “${typeof question === "object" ? question.question : question}“`

  const total = questions.length

  const toggleButtonNode = total > 1 && (
    <IconButton onClick={toggler.handleToggle}>
      <Icon name="arrow-down" rotateAngle={toggler.isOpened ? 180 : 0} />
    </IconButton>
  )

  return (
    <div className={styles.root}>
      <IconChip color="primary-solid">
        <Icon name="questions-chat" />
      </IconChip>

      <List gutter="8" className={styles.list}>
        {toggler.isOpened ? (
          <>
            <ScrollableContainer>
              <List gutter="8">
                {questions.map((it) => {
                  const question = renderQuestion(it)
                  return (
                    <Text variant="h5" key={question}>
                      {question}
                    </Text>
                  )
                })}
              </List>
            </ScrollableContainer>

            {toggler.isOpened && toggleButtonNode}
          </>
        ) : (
          <Text variant="h5" className={styles.preview}>
            {total > 1 ? `${total} selected` : renderQuestion(questions[0])}
          </Text>
        )}
      </List>

      {!toggler.isOpened && toggleButtonNode}
    </div>
  )
}

export default QuestionPreview
