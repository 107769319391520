import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useLayoutEffect, useRef, useState } from "react"

import usePrevious from "@components/hooks/usePrevious"
import useResizeObserver from "@components/hooks/useResizeObserver"
import { Option } from "@framework/types/utils"

import styles from "./Switch.module.sass"

export const switchSizeOptions = ["big", "medium", "small"] as const

export type SwitchSize = (typeof switchSizeOptions)[number]

export interface SwitchProps<T extends string | number> {
  items: Option<T>[]
  checked?: T
  disabled?: boolean
  highlighted?: boolean
  className?: string
  asTabs?: boolean
  size?: SwitchSize
  onChange?: (name: T, idx: number) => void
  onRenderLabel?: (name: T, label: string) => React.ReactNode
  onCurrentTabRef?: (ref: HTMLButtonElement) => void
}

const Switch = observer(
  <T extends string | number>({
    className = "",
    items,
    checked,
    disabled = false,
    highlighted = false,
    asTabs = false,
    size = "big",
    onChange,
    onRenderLabel,
  }: SwitchProps<T>) => {
    const checkedIdx = items.findIndex((item) => item.name === checked)
    const prevCheckedIdx = usePrevious(usePrevious(checkedIdx))

    const rootRef = useRef<HTMLDivElement | null>(null)
    const rootRect = useResizeObserver(rootRef)

    const [checkMarkProps, setCheckMarkProps] = useState({
      left: 0,
      height: 0,
      width: 0,
    })

    const getClickHandler = (name: T, id: number) =>
      onChange && name !== checked ? () => onChange(name, id) : undefined

    const onRef = (container: HTMLDivElement | null) => {
      rootRef.current = container
    }

    const handleCalcWidth = (container: HTMLDivElement | null) => {
      if (container == null) return

      const activeChild = container.childNodes[checkedIdx]

      if (activeChild == null) return

      const containerRect = container.getBoundingClientRect()
      const childRect = (
        activeChild as HTMLButtonElement
      ).getBoundingClientRect()

      setCheckMarkProps({
        left: childRect.left - containerRect.left + container.scrollLeft,
        height: childRect.height,
        width: childRect.width,
      })
    }

    useLayoutEffect(() => {
      if (!rootRef.current) return
      handleCalcWidth(rootRef.current)
    }, [...items, checked, disabled, rootRect?.width])

    useLayoutEffect(() => {
      if (!rootRef.current) return
      rootRef.current.scrollTo({
        left: checkMarkProps.left,
        behavior: "smooth",
      })
    }, [checkMarkProps.left])

    if (checkedIdx < 0) return null

    const rootClassName = clsx(
      styles.root,
      {
        [styles.highlight]: highlighted,
        [styles.head]: asTabs,
      },
      styles[`size-${size}`],
      className
    )

    const paramsStyle = {
      "--switch-checkmark-left": `${checkMarkProps.left}px`,
      "--switch-checkmark-width": `${checkMarkProps.width}px`,
      "--switch-checkmark-height": `${checkMarkProps.height}px`,
      "--transition-speed": prevCheckedIdx == null ? "0s" : ".3s",
    } as React.CSSProperties

    return (
      <fieldset
        disabled={disabled}
        className={rootClassName}
        style={paramsStyle}
      >
        <div ref={onRef} className={styles.container}>
          {items.map((item, idx) => {
            const handlerClick = getClickHandler(item.name, idx)
            return (
              <button
                type="button"
                className={clsx(styles.item, {
                  [styles.checked]: idx === checkedIdx,
                })}
                disabled={!handlerClick || item.isDisabled}
                onClick={handlerClick}
                key={item.name}
              >
                {onRenderLabel
                  ? onRenderLabel(item.name, item.value)
                  : item.value}
              </button>
            )
          })}
        </div>
      </fieldset>
    )
  }
)

export default Switch
