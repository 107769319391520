import { observer } from "mobx-react-lite"
import React from "react"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Checkbox from "@components/ui/Checkbox/Checkbox"

interface UserRequestTableHeaderProps {
  isAllSelected?: boolean
  onSelectAll?: () => void
}

export const UserRequestTableHeader: React.FC<UserRequestTableHeaderProps> =
  observer(({ isAllSelected, onSelectAll }) => {
    return (
      <Row>
        <Column as="th" key="selection" width="50px">
          <Checkbox checked={isAllSelected} onClick={onSelectAll} />
        </Column>

        <Column as="th" key="email" width="350px">
          Email
        </Column>

        <Column as="th" key="actions" width="80px" justify="start">
          Actions
        </Column>

        <Column as="th" key="approve" width="80px" justify="start">
          Approve
        </Column>

        <Column as="th" key="status" width="300px">
          Status
        </Column>

        <Column as="th" key="created_date" width="110px">
          Created Date
        </Column>
      </Row>
    )
  })

export default UserRequestTableHeader
