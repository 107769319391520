import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import List from "@components/ui/List/List"
import TextInput from "@components/ui/TextInput/TextInput"

import Column from "../Table/Column"
import Row from "../Table/Row"

import styles from "./index.module.sass"

interface RecordFormRowProps {
  defaultTitle: string
  defaultItems: string[]
  minItems?: number
  maxItems?: number
  onSubmit?: (data: { title: string; items: string[] }) => void
  onCancel?: () => void
}

export const RecordFormRow: React.FC<RecordFormRowProps> = observer(
  ({
    defaultTitle,
    minItems = 1,
    maxItems = Infinity,
    defaultItems,
    onSubmit,
    onCancel,
  }) => {
    const [title, setTitle] = useState(defaultTitle)
    const [items, setItems] = useState<string[]>([...defaultItems])

    const handleSubmit = async () => {
      onSubmit?.({ title, items })
    }

    const handleRemoveItem = (index: number) => {
      setItems((oldItems: string[]) => {
        return oldItems.filter((_, idx) => idx !== index)
      })
    }

    const handleAddItem = () => {
      setItems((oldItems: string[]) => {
        return [...oldItems, ""]
      })
    }

    const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(e.target.value)
    }

    const onItemChange =
      (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setItems((oldItems: string[]) => {
          const newItems = [...oldItems]
          newItems[index] = e.target.value
          return newItems
        })
      }

    return (
      <Row className={styles.row} highlightable>
        <Column justify="center" align="start">
          <TextInput value={title} onChange={onTitleChange} autoFocus />
        </Column>

        <Column>
          <List direction="row" gutter="8" wrap="wrap">
            {items.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index}>
                <TextInput
                  value={item}
                  onChange={onItemChange(index)}
                  after={
                    items.length > minItems && (
                      <Icon
                        name="cross"
                        onClick={() => handleRemoveItem(index)}
                      />
                    )
                  }
                />
              </span>
            ))}
            {items.length < maxItems && (
              <Button
                color="primary"
                variant="outlined"
                size="big"
                before={<Icon name="plus" />}
                onClick={handleAddItem}
              >
                Add
              </Button>
            )}
          </List>
        </Column>

        <Column align="start" justify="end">
          <List direction="row" gutter="8" justify="flex-end">
            <Button size="big" color="secondary" onClick={onCancel}>
              Cancel
            </Button>

            <Button size="big" color="primary" onClick={handleSubmit}>
              Save
            </Button>
          </List>
        </Column>
      </Row>
    )
  }
)

export default RecordFormRow
