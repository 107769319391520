import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry, map } from "rxjs/operators"
import { toStream } from "mobx-utils"

import { CloudEntity } from "@framework/types/upload"
import { VirtualListChunk } from "@framework/types/utils"
import uploadService from "@services/upload.service"

const CHUNK_SIZE = 100
const RETRY_DELAY = 10 * 1000

type State = {
  path: string // folderL1/folderL2/folderL3 | ""
  query: string
  isLoading: boolean
  data: CloudEntity[]
  requestedFrame: VirtualListChunk
  actualFrame: VirtualListChunk
  reloadTrigger: boolean
  total: number
  errorMessage: string
  dataConnectorId: string
}

export class ExternalCloudEntitiesStore {
  state: State

  private loadingStream$?: Subscription

  constructor(options: { dataConnectorId: string }) {
    this.state = {
      path: "",

      query: "",

      isLoading: false,

      data: [],

      requestedFrame: { offset: 0, limit: CHUNK_SIZE },

      actualFrame: { offset: 0, limit: CHUNK_SIZE },

      reloadTrigger: false,

      total: 0,

      errorMessage: "",

      dataConnectorId: options.dataConnectorId,
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "total", () => {
      this.loadingStream$ = this.initLoadingStream()
    })

    onBecomeUnobserved(this.state, "total", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    this.reset()

    return from(
      toStream(
        () => [
          this.state.dataConnectorId,
          this.state.requestedFrame.offset,
          this.state.requestedFrame.limit,
          this.state.query,
          this.state.path,
          this.state.reloadTrigger,
        ],
        true
      ) as ObservableInput<[string, number, number, string, string, boolean]>
    )
      .pipe(
        tap(() =>
          runInAction(() => {
            this.state.isLoading = true
            this.state.errorMessage = ""
          })
        ),
        switchMap(([dataConnectorId, offset, limit, query, path]) =>
          uploadService
            .getCloudEntitiesList$(dataConnectorId, {
              query,
              offset,
              limit,
              path,
            })
            .pipe(map((response) => response.data))
        ),
        tap((response) => {
          runInAction(() => {
            const { data = [], meta } = response

            this.state.data = data

            this.state.actualFrame = {
              offset: meta.offset,
              limit: meta.limit,
            }

            this.state.total = meta.total

            this.state.isLoading = false
          })
        }),
        retry({
          delay: () => {
            runInAction(() => {
              this.state.isLoading = false
              this.state.errorMessage = "Loading Failed"
            })
            return timer(RETRY_DELAY)
          },
        })
      )
      .subscribe()
  }

  get getById() {
    const usersMap = new Map(this.state.data.map((it) => [it.id, it]))
    return (id: string | null) => (id == null ? null : usersMap.get(id) ?? null)
  }

  get getByIndex() {
    const { data, actualFrame } = this.state
    return (index: number) => {
      return index == null ? null : data[index - actualFrame.offset] ?? null
    }
  }

  load = async ({ startIndex }: { startIndex: number; stopIndex: number }) => {
    const chunkIndex = Math.floor(startIndex / CHUNK_SIZE) - 1

    const newOffset = Math.max(0, chunkIndex * CHUNK_SIZE)

    this.state.requestedFrame = {
      offset: newOffset,
      limit: CHUNK_SIZE * 3,
    }
  }

  search = (query: string) => {
    this.state.query = query
  }

  openDirectory = (path: string) => {
    this.setPath(this.state.path ? `${this.state.path}/${path}` : path)
  }

  setPath = (path: string) => {
    runInAction(() => {
      this.state.path = path
      this.reset()
    })
  }

  resetPath = () => {
    this.state.path = ""
  }

  refresh = () => {
    this.state.reloadTrigger = !this.state.reloadTrigger
  }

  reset = () => {
    runInAction(() => {
      this.state.query = ""
      this.state.data = []
      this.state.requestedFrame = { offset: 0, limit: CHUNK_SIZE }
      this.state.actualFrame = { offset: 0, limit: CHUNK_SIZE }
      this.state.total = 0
    })
  }
}

export default ExternalCloudEntitiesStore
