import React from "react"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"

import { useController } from "@store/index"
import { AccessType, AvatarDetailsData } from "@framework/types/avatar"

import ScrollableContainer from "./components/ScrollableContainer/ScrollableContainer"
import BasicInfoForm, {
  FormData,
} from "./components/BasicInfoForm/BasicInfoForm"
import useModal from "../useModal"
import { ModalsTypes } from "../constants"

export interface EditAvatarFormProps {
  avatar: Partial<AvatarDetailsData>
  onClose?: () => void
}

const { Private } = AccessType

export const EditAvatarForm: React.FC<EditAvatarFormProps> = observer(
  ({ avatar, onClose }) => {
    const alert = useAlert()

    const membershipModal = useModal(ModalsTypes.EDIT_AVATAR_MEMBERSHIP_MODAL)

    const {
      avatarController: { editAvatar, addNewAvatar },
      avatarAdminController: { loadAvatars },
    } = useController()

    const isEdit = avatar.id != null

    const handleClose = () => onClose?.()

    const handleEditAvatar = async (id: string, data: FormData) => {
      const error = await editAvatar(id, data)
      return error
    }

    const handleSubmit = async (data: FormData) => {
      let error: string | null = null

      if (avatar.id) {
        error = await handleEditAvatar(avatar.id, data)
      } else {
        const res = await addNewAvatar(data)

        if (typeof res === "string") {
          error = res
        } else if (res?.id) {
          membershipModal.showModal({
            avatarId: res.id,
            type: data.accessType === Private ? "users" : "solutions",
          })
        }
      }
      loadAvatars()

      if (error) {
        alert.error(error)
        return
      }

      handleClose()
    }

    return (
      <ScrollableContainer>
        <BasicInfoForm
          initialValues={{ ...avatar, image: avatar.imageUrl }}
          onSubmit={handleSubmit}
          onCancel={handleClose}
          isEdit={isEdit}
        />
      </ScrollableContainer>
    )
  }
)

export default EditAvatarForm
