import React from "react"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import * as yup from "yup"

import { useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import useModal from "@components/modals/useModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import List from "@components/ui/List/List"
import Templates from "@components/ui/Templates"
import Button from "@components/ui/Button/Button"
import DataTypeListItem from "@components/ui/DataTypeCard/DataTypeListItem"
import TextInput from "@components/ui/TextInput/TextInput"
import useSearch from "@components/hooks/useSearch"
import Icon from "@components/ui/Icon/Icon"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import { searchBy } from "@utils/optionsUtils"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"

import styles from "./index.module.sass"

const DEFAULT_TITLE = "Select Data Type"
const DEFAULT_SUBTITLE =
  "Please select Data Type you would like you files to associate with"

export const validationSchema = yup.object({
  dataTypeId: yup.string().required().default(""),

  remember: yup.boolean().required().default(false),
})

export type PreselectDataTypeFormData = yup.InferType<typeof validationSchema>

export interface PreselectDataTypeModalProps {
  title?: string
  initialValue?: Partial<PreselectDataTypeFormData>
  autoSelect?: boolean
  onSubmit?: (data: PreselectDataTypeFormData) => void
}

export const PreselectDataTypeModal: React.FC<PreselectDataTypeModalProps> =
  observer(
    ({
      title = DEFAULT_TITLE,
      initialValue = "",
      autoSelect = false,
      onSubmit,
    }) => {
      const { hideModal } = useModal(ModalsTypes.PRESELECT_DATA_TYPE_MODAL)

      const { allDatatypesStore } = useStore()

      const collection = allDatatypesStore.state

      const handleSubmit = async (data: PreselectDataTypeFormData) => {
        onSubmit?.(data)
        hideModal()
      }

      const formik = useFormik({
        initialValues: validationSchema.cast(initialValue, {
          stripUnknown: true,
        }),
        validationSchema,
        onSubmit: handleSubmit,
      })

      const handleSelect = (id?: string) =>
        formik.setFieldValue("dataTypeId", id)

      const handleClose = () => hideModal()

      const [searchProps, searchHelpers] = useSearch()

      const sortedList = React.useMemo(() => {
        return searchBy(collection.data, searchProps.value, (it) => it.name)
      }, [collection.data, searchProps.value])

      React.useEffect(() => {
        if (autoSelect) formik.submitForm()
      }, [autoSelect])

      React.useEffect(() => {
        allDatatypesStore.refresh()
      }, [])

      return (
        <BaseModal
          className={styles.root}
          containerClassName={styles.container}
          title={
            <ModalTitle titleText={title} subtitleText={DEFAULT_SUBTITLE} />
          }
          onClose={handleClose}
        >
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <Templates.RollScript
              gutter="24"
              headerSocket={
                <div className={styles.control}>
                  <TextInput
                    {...searchProps}
                    before={<Icon name="search" />}
                    after={
                      !!searchProps.value && (
                        <Icon
                          name="cross"
                          onClick={() => searchHelpers.setQuery("")}
                        />
                      )
                    }
                    placeholder="Search..."
                  />
                </div>
              }
              footerSocket={
                <ModalFooterContainer>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={!formik.values.dataTypeId || collection.isLoading}
                  >
                    Select
                  </Button>
                </ModalFooterContainer>
              }
            >
              <Templates.RollScript
                gutter="16"
                footerSocket={
                  <List align="center">
                    <CheckboxWithLabel
                      label="Remember my choice"
                      checked={formik.values.remember}
                      onCheck={() =>
                        formik.setFieldValue(
                          "remember",
                          !formik.values.remember
                        )
                      }
                    />
                  </List>
                }
              >
                {sortedList.length === 0 ? (
                  collection.isLoading ? (
                    <Loader size="large" primary fluid />
                  ) : (
                    <NotFound>No Data Types Found</NotFound>
                  )
                ) : (
                  <List gutter="8" className={styles.list}>
                    {sortedList.map((it) => {
                      const selected = formik.values.dataTypeId
                      return (
                        <DataTypeListItem
                          data={it}
                          active={selected === it.id}
                          onClick={() => handleSelect(it.id)}
                          onDoubleClick={() => formik.submitForm()}
                          key={it.id}
                        />
                      )
                    })}
                  </List>
                )}
              </Templates.RollScript>
            </Templates.RollScript>
          </form>
        </BaseModal>
      )
    }
  )

export default PreselectDataTypeModal
