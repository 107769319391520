import React, { useEffect, useRef } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import { countSuffix } from "@utils/numberUtils"
import AnswerList from "@pages/home/SearchResults/components/Result/AnswerList"
import ResultContainer from "@pages/home/SearchResults/components/Result/ResultContainer"
import SearchPassagesStore from "@store/search/search-passages.store"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"

import { useSearchBlock } from "../SearchContext/SearchResultContext"

import styles from "./MixedResult.module.sass"

const BATCH_SIZE = 12

const NOT_FOUND_MESSAGE = <>No answer was found. Please try another question</>

export interface MixedResultProps {
  className?: string
}

const MixedResult: React.FC<MixedResultProps> = observer(({ className }) => {
  const { searchEntityBlock, searchEntity, answerIndex } = useSearchBlock(
    SearchSummaryBlockStore
  )

  const containerRef = useRef<HTMLDivElement>(null)

  if (searchEntityBlock.searchPassages == null)
    return <NotFound>No answer was found</NotFound>

  if (!(searchEntityBlock.searchPassages instanceof SearchPassagesStore))
    throw new Error("Wrong Passage store instance used")

  const {
    topAnswers,
    otherAnswers,
    totalOther,
    leftToLoad,
    isMoreAnswersShown,
    isLoadingMore,
    showAllAnswers,
    loadMore,
  } = searchEntityBlock.searchPassages

  const onLoadMore = () => loadMore(batchSize)

  const handleShowAll = () => {
    showAllAnswers(true)
    loadMore(batchSize)
  }

  useEffect(() => {
    if (!answerIndex || isLoadingMore) return

    const searchIndex = parseInt(answerIndex, 10) - 1

    if (
      !topAnswers.some((answer) => answer.index === searchIndex) &&
      !isMoreAnswersShown
    ) {
      handleShowAll()
      return
    }

    const answerId = [...topAnswers, ...otherAnswers].find(
      (answer) => answer.index === searchIndex
    )?.name

    if (!answerId) return

    setTimeout(() => {
      const answerContainer = document.getElementById(answerId)
      if (answerContainer && containerRef.current) {
        containerRef.current.scroll({
          // 165 - sidebar header, 45 - sticky title
          top: answerContainer.offsetTop - 165 - 45,
          behavior: "smooth",
        })
      }
    }, 100)
  }, [answerIndex, isLoadingMore])

  const batchSize = leftToLoad > BATCH_SIZE ? BATCH_SIZE : leftToLoad
  const loading = isLoadingMore

  const TopSectionFooterNode = !isMoreAnswersShown && totalOther > 0 && (
    <Button
      className={styles.centeredButton}
      variant="outlined"
      onClick={handleShowAll}
    >
      Show other {totalOther} Answer{countSuffix(totalOther)}
    </Button>
  )

  const AnswersSectionControlNode = !isMoreAnswersShown ? null : (
    <Button variant="slim" onClick={() => showAllAnswers?.(false)}>
      Hide Answers
    </Button>
  )

  const AnswersSectionFooterNode = loading ? (
    <Loader size="medium" primary />
  ) : (
    leftToLoad > 0 && (
      <Button variant="outlined" onClick={onLoadMore}>
        Load another {batchSize} answer{countSuffix(batchSize)}
      </Button>
    )
  )

  const hasAnswers = topAnswers.length > 0

  return (
    <div className={clsx(styles.root, className)} ref={containerRef}>
      {hasAnswers ? (
        <>
          <ResultContainer
            title="Top answers"
            titleSticky
            footer={TopSectionFooterNode}
          >
            <AnswerList
              items={topAnswers}
              answerTextMaxLines={5}
              query={searchEntity.filter.searchQuery}
            />
          </ResultContainer>
          {isMoreAnswersShown && totalOther > 0 && (
            <ResultContainer
              title={`Other ${totalOther} Answer${countSuffix(totalOther)}`}
              titleSticky
              footer={AnswersSectionFooterNode}
              control={AnswersSectionControlNode}
            >
              <AnswerList
                items={otherAnswers}
                answerTextMaxLines={5}
                query={searchEntity.filter.searchQuery}
              />
            </ResultContainer>
          )}
        </>
      ) : (
        <NotFound>{NOT_FOUND_MESSAGE}</NotFound>
      )}
    </div>
  )
})

export default MixedResult
