import clsx from "clsx"
import React from "react"

import styles from "./Table.module.sass"

export interface ColumnProps {
  minWidth?: number | string | ((value: React.ReactNode) => string)
  width?: number | string | ((value: React.ReactNode) => string)
  children: React.ReactNode
  className?: string
}

export const Column: React.FC<ColumnProps> = React.memo(
  ({ minWidth, width, className, children }) => {
    const style: React.CSSProperties = {
      minWidth: typeof minWidth === "function" ? minWidth(children) : minWidth,
      width: typeof width === "function" ? width(children) : width,
    }
    return (
      <td className={clsx(styles.column, className)} style={style}>
        {children}
      </td>
    )
  }
)

export default Column
