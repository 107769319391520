import React from "react"

import { transformAnswerText } from "@utils/textUtils"

import Column, { ColumnProps } from "./Column"

export interface TextColumnProps extends ColumnProps {
  children: string
}

export const TextColumn: React.FC<TextColumnProps> = React.memo(
  ({ className, children, ...rest }) => {
    return (
      <Column className={className} {...rest}>
        {transformAnswerText(children).map((paragraph) => (
          <p key={paragraph.name}>{paragraph.value}</p>
        ))}
      </Column>
    )
  }
)

export default TextColumn
