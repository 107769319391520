export interface UserActionEntity {
  id: string
  name: string
  description: string
  createdAt: string
}

export interface BaseUserRole {
  id: string
  name: string
}

export interface UserRoleEntity extends BaseUserRole {
  userActions: { id: string; name: string }[]
}

export type UserAction = UserActionEntity

export enum UserRequestStatuses {
  PENDING = "pending",
  DECLINED = "declined",
  ACCEPTED = "accepted",
}

export interface UserRequest {
  id: string
  email: string
  status: UserRequestStatuses
  createdAt: string
}

export type UserRequestsHash = Record<UserRequest["id"], UserRequest>
