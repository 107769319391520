import difference from "lodash/difference"
import { makeAutoObservable } from "mobx"

import { AvatarDetailsData } from "@framework/types/avatar"

type MembershipData = {
  usersToAdd: string[]
  dataTypeIdsToAdd: string[]
  productSolutionIdsToAdd: string[]
}

export class AvatarMembershipStore {
  membershipData: MembershipData

  isEditing: boolean

  initialData: Partial<AvatarDetailsData>

  constructor(
    config: {
      avatar?: Partial<AvatarDetailsData>
    } = {}
  ) {
    makeAutoObservable(this)

    this.isEditing = false

    this.initialData = config.avatar ?? {}

    this.membershipData = {
      usersToAdd: [],
      dataTypeIdsToAdd: [],
      productSolutionIdsToAdd: [],
    }
  }

  handleEdit = (status: boolean) => {
    this.isEditing = status
  }

  updateMembershipData = (key: keyof MembershipData, id: string) => {
    const items = this.membershipData[key]

    this.membershipData = {
      ...this.membershipData,
      [key]: items.includes(id) ? difference(items, [id]) : [...items, id],
    }
  }
}

export default AvatarMembershipStore
