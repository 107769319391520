import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import { useStore } from "@store"

import styles from "./index.module.sass"

interface RolesHeaderProps {}

export const SubscriptionHeader: React.FC<RolesHeaderProps> = observer(() => {
  const { subscriptionStore } = useStore()
  const { isEditable } = subscriptionStore
  return (
    <Row>
      <Column as="th" key="label" width="50%">
        SUBSCRIPTION
      </Column>
      <Column as="th" justify="start" width="25%">
        <div className={clsx({ [styles.purchasedPadding]: isEditable })}>
          PURCHASED
        </div>
      </Column>
      <Column as="th" justify="start" width="25%">
        <div>STATUS / IN USE</div>
      </Column>
    </Row>
  )
})

export default SubscriptionHeader
