import clsx from "clsx"
import React from "react"

import Loader from "@components/ui/Loader/BarLoader"

import styles from "./LoadingCover.module.sass"

export interface LoadingCoverProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean
}

export const LoadingCover: React.FC<LoadingCoverProps> = React.memo(
  ({ isLoading = false, children, className, ...rest }) => {
    return (
      <div className={clsx(styles.root, className)}>
        {children}

        <div
          {...rest}
          className={clsx(styles.cover, { [styles.active]: isLoading })}
        >
          <span className={styles.glass} />
          <Loader primary className={styles.loader} size="huge" />
        </div>
      </div>
    )
  }
)

export default LoadingCover
