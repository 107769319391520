import React from "react"

import { Icon } from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"

export interface DefaultControlButtonProps {
  title: string
  icon: IconName
  disableToolTip?: boolean
  active?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
}

export const DefaultControlButton: React.FC<DefaultControlButtonProps> = ({
  title,
  icon,
  disableToolTip,
  active,
  disabled,
  onClick,
}) => (
  <Tooltip
    disabled={disableToolTip}
    content={<TooltipContainer placement="top">{title}</TooltipContainer>}
    mode="onHover"
  >
    <IconButton
      size="big"
      disabled={disabled}
      active={active}
      onClick={onClick}
    >
      <Icon name={icon} />
    </IconButton>
  </Tooltip>
)

export default DefaultControlButton
