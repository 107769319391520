import React, { useState } from "react"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import AlertBanner from "@components/ui/AlertBanner/AlertBanner"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Loader from "@components/ui/Loader/BarLoader"
import Text from "@components/ui/Typography/Text"

import styles from "./index.module.sass"

export interface DeleteOptionModalProps {
  onSubmit: () => Promise<void>
  warning?: string
  question: string
  title?: string
}

const DEFAULT_MODAL_TITLE = "Remove Item"

const DeleteOptionModal: React.FC<DeleteOptionModalProps> = ({
  onSubmit,
  warning,
  question,
  title = DEFAULT_MODAL_TITLE,
}) => {
  const [loading, setLoading] = useState(false)

  const { hideModal } = useModal(
    ModalsTypes.DELETE_AVATAR_MEMBERSHIP_ITEM_MODAL
  )

  const handleClose = () => hideModal()

  const handleSubmit = async () => {
    setLoading(true)
    await onSubmit()
    setLoading(false)
    hideModal()
  }

  return (
    <BaseModal title={<ModalTitle titleText={title} />} onClose={handleClose}>
      <div className={styles.root}>
        {warning && <AlertBanner>{warning}</AlertBanner>}
        <Text variant="h2" align="center" className={styles.question}>
          {question}
        </Text>
      </div>
      <ModalFooterContainer>
        <Button variant="outlined" disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button color="red" disabled={loading} onClick={handleSubmit}>
          Remove {loading && <Loader />}
        </Button>
      </ModalFooterContainer>
    </BaseModal>
  )
}

export default DeleteOptionModal
