import { randomNumber } from "./numberUtils"

export const isSetsEqual = <T>(set1: Set<T>, set2: Set<T>) =>
  set1.size === set2.size &&
  Array.from(set1.values()).every((it) => set2.has(it))

export default null

export const replaceArrayElementByIndex = <T = any>(
  array: Array<T>,
  element: T,
  index: number
) => [...array.slice(0, index), element, ...array.slice(index + 1)]

export const removeArrayElementByIndex = (array: Array<any>, index: number) => [
  ...array.slice(0, index),
  ...array.slice(index + 1),
]

export const arrayToHash = <T, G = unknown>(
  array: Array<T>,
  transformCallback: (arrayElement: T, index: number) => { [key: string]: T }
) =>
  array.reduce(
    (acc, arrayElement, index) => ({
      ...acc,
      ...transformCallback(arrayElement, index),
    }),
    {}
  ) as G

export const isDeepEqual = (a: any, b: any): boolean => {
  // If both are identical (including type), return true
  if (a === b) {
    return true
  }

  // If either of them is null or not an object, they are not equal
  if (
    a === null ||
    b === null ||
    typeof a !== "object" ||
    typeof b !== "object"
  ) {
    return false
  }

  // If both are Date objects, compare their time values
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime()
  }

  // If both are Array, compare their elements
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false
    }

    for (let i = 0; i < a.length; i += 1) {
      if (!isDeepEqual(a[i], b[i])) {
        return false
      }
    }

    return true
  }

  // Collect keys from both objects for comparison
  const keysA = Object.keys(a)
  const keysB = Object.keys(b)

  // If number of keys is different, objects are not equal
  if (keysA.length !== keysB.length) {
    return false
  }

  // Check if every key-value pair in 'a' is in 'b' with deep equality
  // eslint-disable-next-line no-restricted-syntax
  for (const key of keysA) {
    if (!keysB.includes(key) || !isDeepEqual(a[key], b[key])) {
      return false
    }
  }

  return true
}

// Usage example
// const obj1 = { a: 1, b: { c: [1, 2, 3] } }
// const obj2 = { a: 1, b: { c: [1, 2, 3] } }
// console.log(isDeepEqual(obj1, obj2)) // true

// const obj3 = { a: 1, b: { c: [1, 2, 4] } }
// console.log(isDeepEqual(obj1, obj3)) // false

export const pickRandom = <T>(array: T[]): T => {
  return array[randomNumber(0, array.length)]
}
