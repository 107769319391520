import React from "react"

import { AnswerItem } from "@framework/types/search"
import { ImageAnswerSection } from "@components/prototypes/ResultsCard/AnswerSection/AnswerSection"
import ResultsCard from "@components/prototypes/ResultsCard/ResultsCard"

import SearchAnswerControls from "../AnswerControl/SearchAnswerControls"
import usePassageChips from "./usePassageChips"

export interface ImageResultsCardProps {
  id: string
  answer: AnswerItem<"image">
  question: string
  color?: "primary" | "transparent"
  className?: string
  onTitleClick?: React.MouseEventHandler
}

export const ImageResultsCard: React.FC<ImageResultsCardProps> = ({
  id,
  answer: answerItem,
  question,
  color,
  className,
  onTitleClick,
}) => {
  const answer = answerItem.value

  const chips = usePassageChips(answerItem)

  return (
    <ResultsCard
      id={id}
      type="vertical"
      color={color}
      chips={chips}
      className={className}
      answerSocket={
        <ImageAnswerSection
          title={answer.source_name}
          onTitleClick={onTitleClick}
          content={answer.value ?? answer.image_url}
        />
      }
      controllers={
        <SearchAnswerControls
          question={question}
          answer={answerItem}
          type="Image"
        />
      }
      key={id}
    />
  )
}

export default ImageResultsCard
