import { AxiosResponse } from "axios"
import type { Observable } from "rxjs"

import { SimpleInviteUserFormType } from "@framework/types/auth"
import {
  UserActionEntity,
  UserRequest,
  UserRequestStatuses,
  UserRoleEntity,
} from "@framework/types/security"
import {
  BaseUserData,
  DigestSubscriptionOption,
  UpdateUserFormData,
  UserData,
} from "@framework/types/user"
import { PaginationListMeta, PaginationParams } from "@framework/types/utils"
import { ApiKey, ApiKeyWithSecret } from "@framework/types/api-keys"

import { DefaultSuccessResponse } from "./common/types"
import HttpService from "./http.service"

export interface CreateRoleRequestData {
  name: string
  actionIds?: string[]
}

export interface CreateActionRequestData {
  name: string
  description: string
}

export interface UpdateRoleActionsRequestData {
  deletedUserActionIds: string[]
  newUserActionIds: string[]
}

export interface UpdateUserAssignedRolesRequestData {
  userRoleIds: string[]
}

export interface UpdateRoleNameRequestData {
  name: string
}

export interface GetUserDataResponse extends DefaultSuccessResponse {
  data: UserData
}

export interface GetUserActionsResponse extends DefaultSuccessResponse {
  data: string[]
}

export interface GetDigestSubscriptionOptionsResponse
  extends DefaultSuccessResponse {
  data: DigestSubscriptionOption[]
}

export interface GetAllUsersResponse extends DefaultSuccessResponse {
  data: BaseUserData[]
  meta: PaginationListMeta
}

export interface EditRoleResponse extends DefaultSuccessResponse {
  data: UserRoleEntity
}

export interface EditActionResponse extends DefaultSuccessResponse {
  data: UserActionEntity
}

export interface UploadUserAvatarResponse extends UserData {}

export type UploadUserProfileResponse = UserData

export interface GetRolesListResponse extends DefaultSuccessResponse {
  data: UserRoleEntity[]
}

export interface GetAllActionsResponse extends DefaultSuccessResponse {
  data: UserActionEntity[]
}

export interface UpdateUserResponse extends DefaultSuccessResponse {
  data: BaseUserData
}

export interface GetAllUserRequestsResponse {
  data: UserRequest[]
}

export interface CreateUserRequestResponse {
  data: UserRequest
}

export interface UpdateUserRequestResponse {
  data: UserRequest
}

export interface GetAllApiKeysResponse {
  data: ApiKey[]
}

export interface CreateApiKeyResponse {
  data: ApiKeyWithSecret
}

export interface UpdateApiKeyResponse {
  data: ApiKey
}

class AuthAPI extends HttpService {
  getUserData = (): Promise<AxiosResponse<GetUserDataResponse>> =>
    this.get("ts/user/profile")

  getUserActions = (): Promise<AxiosResponse<GetUserActionsResponse>> =>
    this.get("ts/user/actions")

  getDigestSubscriptionOptions = (): Promise<
    AxiosResponse<GetDigestSubscriptionOptionsResponse>
  > => this.get("ts/users/digest-subscription-frequencies")

  inviteUser = (form: SimpleInviteUserFormType): Promise<AxiosResponse> =>
    this.post("ts/invite-user", form)

  changeUserPassword = (
    userID: string,
    form: { password: string }
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.patch(`ts/admin/user/${userID}/reset-password`, form)

  updateUserAvatar = (
    file: FormData
  ): Promise<AxiosResponse<UploadUserAvatarResponse>> =>
    this.post("ts/user/update-avatar", file)

  updateUserProfile = (
    profile: UpdateUserFormData
  ): Promise<AxiosResponse<UploadUserProfileResponse>> =>
    this.patch("ts/user/profile", profile)

  getUsers$ = (
    params: PaginationParams
  ): Observable<AxiosResponse<GetAllUsersResponse>> =>
    this.getStream$("ts/user/all", { params })

  getAdminUsers = (
    options: PaginationParams
  ): Promise<AxiosResponse<GetAllUsersResponse>> =>
    this.get("ts/admin/users", true, options)

  deleteUser = (
    userId: string
  ): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.delete("ts/admin/user", undefined, true, { userId })

  getAllApiKeys = (): Promise<AxiosResponse<GetAllApiKeysResponse>> =>
    this.get("ts/api-keys")

  createApiKey = (params: {
    name: string
    permissions: string[]
  }): Promise<AxiosResponse<CreateApiKeyResponse>> =>
    this.post("ts/api-keys", params)

  updateApiKey = (
    id: string,
    params: {
      name?: string
      addPermissions?: string[]
      removePermissions?: string[]
    }
  ): Promise<AxiosResponse<UpdateApiKeyResponse>> =>
    this.patch(`ts/api-keys/${id}`, params)

  deleteApiKey = (id: string): Promise<AxiosResponse<DefaultSuccessResponse>> =>
    this.delete(`ts/api-keys/${id}`)

  getAllRoles = (): Promise<AxiosResponse<GetRolesListResponse>> =>
    this.get("ts/admin/user-role/all")

  createRole = (
    data: CreateRoleRequestData
  ): Promise<AxiosResponse<EditRoleResponse>> =>
    this.post("ts/admin/user-role", data)

  deleteRole = (userRoleId: string): Promise<AxiosResponse<any>> =>
    this.delete("ts/admin/user-role", undefined, true, { userRoleId })

  updateRole = (
    userRoleId: string,
    data: UpdateRoleActionsRequestData | UpdateRoleNameRequestData
  ): Promise<AxiosResponse<EditRoleResponse>> =>
    this.patch(`ts/admin/user-role`, data, true, { userRoleId })

  updateUserRoles = (
    userRoleId: string,
    data: UpdateUserAssignedRolesRequestData
  ): Promise<AxiosResponse<UpdateUserResponse>> =>
    this.patch(`ts/admin/user/${userRoleId}/user-role`, data)

  getAllActions = (): Promise<AxiosResponse<GetAllActionsResponse>> =>
    this.get("ts/admin/user-action/all")

  createAction = (
    data: CreateActionRequestData
  ): Promise<AxiosResponse<EditActionResponse>> =>
    this.post("ts/admin/user-action", data)

  updateAction = (
    actionId: string,
    data: CreateActionRequestData
  ): Promise<AxiosResponse<EditActionResponse>> =>
    this.patch("ts/admin/user-action", data, true, { actionId })

  deleteAction = (actionId: string): Promise<AxiosResponse<any>> =>
    this.delete("ts/admin/user-action", undefined, true, { actionId })

  createUserRequest = (
    email: string
  ): Promise<AxiosResponse<CreateUserRequestResponse>> =>
    this.post("ts/user-request", { email }, false)

  getAllUserRequests = (): Promise<AxiosResponse<GetAllUserRequestsResponse>> =>
    this.get("ts/admin/user-request/all", true)

  updateUserRequest = (
    requestId: string,
    status: UserRequestStatuses
  ): Promise<AxiosResponse<UpdateUserRequestResponse>> =>
    this.patch(`ts/admin/user-request?requestId=${requestId}`, { status }, true)
}

export default new AuthAPI()
