import React from "react"
import { pdfjs } from "react-pdf"

import RootController from "@store/RootController"
import AppConfigProvider from "@components/security/AppConfigProvider"
import AppThemeProvider from "@components/theme/AppThemeProvider"
import { ModalContextProvider } from "@components/modals/ModalContext"
import ModalStore from "@store/modals/modal.store"

import StoreProvider from "./store"
import RootStore from "./store/RootStore"
import SecureProvider from "./components/security/SecureProvider"
import AlertProvider from "./components/ui/AlertPopup/AlertProvider"
import AppMetaHelmet from "./AppMetaHelmet"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const store = new RootStore()
const controller = new RootController(store)
const modalStore = new ModalStore()

const AppProvider: React.FC = ({ children }) => {
  const { appConfigController } = controller

  React.useLayoutEffect(() => {
    appConfigController.loadInstanceConfig()
  }, [])

  return (
    <StoreProvider controller={controller} store={store}>
      <ModalContextProvider instance={modalStore}>
        <AlertProvider>
          <AppThemeProvider>
            <AppMetaHelmet />

            <AppConfigProvider>
              <SecureProvider>{children}</SecureProvider>
            </AppConfigProvider>
          </AppThemeProvider>
        </AlertProvider>
      </ModalContextProvider>
    </StoreProvider>
  )
}

export default AppProvider
