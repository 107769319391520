import clsx from "clsx"
import React from "react"

import Text from "../Typography/Text"

import styles from "./Label.module.sass"

export interface LabelProps {
  id: string
  label?: string
  error?: string
  className?: string
  uppercase?: boolean
  description?: React.ReactNode
}

export const Label: React.FC<LabelProps> = ({
  id,
  label,
  error,
  className,
  uppercase = false,
  children,
  description,
}) => (
  <label htmlFor={id} className={clsx(styles.root, className)}>
    {label || error ? (
      <div className={styles.header}>
        <Text variant="h5" className={clsx(styles.label)} upperCase={uppercase}>
          {label} {description}
        </Text>
        <div className={styles.error}>{error}</div>
      </div>
    ) : null}
    {children}
  </label>
)

export default Label
