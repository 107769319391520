import React, { useEffect, useMemo } from "react"
import difference from "lodash/difference"
import differenceBy from "lodash/differenceBy"
import { observer } from "mobx-react-lite"

import { useController, useStore } from "@store/index"
import { BaseUserData } from "@framework/types/user"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import useAvatarMembershipContext from "../MembershipContext/useAvatarMembershipContext"
import Option from "../Option/Option"
import BaseContainer from "../BaseContainer/BaseContainer"
import { BasicForm } from "../types"

interface UsersProps extends BasicForm {}

const DELETE_MODAL_TITLE = "Remove User"

const QUESTION_TEXT = "Are you sure you want to remove user?"

const Users: React.FC<UsersProps> = observer(({ onAdd, onRemove }) => {
  const multiformContext = useAvatarMembershipContext()

  const { showModal } = useModal(
    ModalsTypes.DELETE_AVATAR_MEMBERSHIP_ITEM_MODAL
  )

  const {
    initialData: { userIds },
    membershipData: { usersToAdd },
    isEditing,
    handleEdit,
    updateMembershipData,
  } = multiformContext

  const { userListController } = useController()

  const {
    userListStore: { users, isLoading },
  } = useStore()

  useEffect(() => {
    const initUsers = async () => {
      await userListController.loadUsers()
    }
    initUsers()
  }, [])

  const items = useMemo(() => {
    const avatarUsers = users.filter((user) => userIds?.includes(user.id))

    return isEditing ? differenceBy(users, avatarUsers, "id") : avatarUsers
  }, [isEditing, users, userIds])

  const handleAddUsers = (id: string) => {
    updateMembershipData("usersToAdd", id)
  }
  const handleRemoveConfirm = async (id: string) => {
    await onRemove?.({ userIds: difference(userIds, [id]) })
  }

  const handleOptionClick = (id: string) => {
    if (isEditing) {
      handleAddUsers(id)
      return
    }

    showModal({
      onSubmit: () => handleRemoveConfirm(id),
      question: QUESTION_TEXT,
      title: DELETE_MODAL_TITLE,
    })
  }

  const optionRenderer = (item: BaseUserData) => {
    return (
      <Option
        key={item.id}
        avatarUrl={item.avatarURL}
        label={`${item.firstName} ${item.lastName}`}
        onClick={() => handleOptionClick(item.id)}
        checked={usersToAdd.includes(item.id)}
        metaInfo={item.email}
        isEdit={isEditing}
      />
    )
  }

  return (
    <BaseContainer<BaseUserData>
      items={items}
      isEditing={isEditing}
      isLoading={isLoading}
      entityKey="Users"
      renderer={optionRenderer}
      filterProperty={["firstName", "lastName"]}
      addLength={usersToAdd.length}
      onSubmit={() => onAdd?.()}
      onEdit={handleEdit}
    />
  )
})

export default Users
