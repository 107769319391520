import React from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import Switch from "@components/ui/Switch/Switch"
import useSearchDetailsContext from "@pages/search/SearchContext/useSearchDetailsContext"
import { useUnstructuredSearchEntity } from "@pages/search/SearchContext/SearchResultContext"

import styles from "./index.module.sass"

export interface DataSourceHeaderProps {}

export const AnswerSourceHeader: React.FC<DataSourceHeaderProps> = observer(
  () => {
    const { searchEntity } = useUnstructuredSearchEntity()
    const { resultViewOptions, resultView, setResultView } =
      useSearchDetailsContext()

    return (
      <div className={styles.root}>
        <Text variant="h2">Answer Source</Text>
        <Text variant="h5">Q: {searchEntity.filter.searchQuery}</Text>
        <Switch
          items={resultViewOptions}
          checked={resultView}
          onChange={setResultView}
        />
      </div>
    )
  }
)

export default AnswerSourceHeader
