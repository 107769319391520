import { observer } from "mobx-react-lite"
import React, { PropsWithChildren } from "react"

import { ModalsTypes } from "@components/modals/constants"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"

import styles from "./ConfirmModal.module.sass"

export interface ConfirmModalProps {
  title: string
  onConfirm: () => void
}

export const ConfirmModal: React.FC<ConfirmModalProps> = observer(
  ({ onConfirm, title }) => {
    const { hideModal } = useModal(ModalsTypes.CONFIRM_MODAL)

    return (
      <BaseModal
        title={<ModalTitle titleText={title} />}
        className={styles.root}
        containerClassName={styles.container}
        onClose={hideModal}
      >
        <ModalFooterContainer className={styles.footer}>
          <Button variant="outlined" onClick={hideModal}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={() => {
              hideModal()
              onConfirm()
            }}
          >
            Confirm
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  }
)

export default ConfirmModal
