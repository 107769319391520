import { observer } from "mobx-react-lite"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import StarRatings from "@components/prototypes/StarRatings/StarRatings"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import Expert from "@pages/search/SearchFlow/Expert"

import styles from "./AnswerSummary.module.sass"

interface AnswerControlProps {
  opened: boolean
  handleOpen: (val: boolean) => void
  hidePostToExpert?: boolean
}

export const AnswerControl: React.FC<AnswerControlProps> = observer(
  ({ opened, handleOpen, hidePostToExpert = false }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const { searchEntityBlock, searchBlockId, searchEntity } = useSearchBlock(
      SearchSummaryBlockStore
    )

    const handleSourceClick = () => {
      navigate(`${searchEntity.id}/${searchBlockId}`, {
        state: { backTo: location.pathname },
      })
    }

    return (
      <section className={styles.control}>
        <Templates.Header
          left={
            <div className={styles.action}>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                before={<Icon name="checkbox-multiple-blank" />}
                onClick={handleSourceClick}
              >
                Source
              </Button>

              {!hidePostToExpert && (
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  before={<Icon name="AI" />}
                  after={
                    <Icon name="arrow-down" rotateAngle={opened ? 180 : 0} />
                  }
                  onClick={() => handleOpen(!opened)}
                >
                  Analysis
                </Button>
              )}

              {!hidePostToExpert && (
                <Expert color="secondary" variant="contained" />
              )}
            </div>
          }
          right={
            !hidePostToExpert && (
              <div className={styles.rating}>
                <span>How helpful was this?</span>
                <StarRatings
                  className={styles.fitContent}
                  disabled={!!searchEntityBlock.feedback}
                  message={searchEntityBlock.feedback?.comments || ""}
                />
              </div>
            )
          }
        />
      </section>
    )
  }
)

export default AnswerControl
