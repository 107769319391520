/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"

import styles from "./ImagePreview.module.sass"

interface PDFViewerProps {
  src: string
  checked?: boolean
  onClick?: () => void
}

const ImagePreview: React.FC<PDFViewerProps> = ({
  src,
  checked = false,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={clsx(styles.root, { [styles.active]: checked })}
  >
    <img src={src} alt={src} />
  </div>
)

export default ImagePreview
