import { makeAutoObservable } from "mobx"

import { UserTrendPointData } from "@framework/types/analytics"

export default class UserTrendsStore {
  constructor() {
    makeAutoObservable(this)
  }

  isLoading = false

  error: string | null = null

  data: UserTrendPointData[] = []

  setData = (data: UserTrendPointData[]) => {
    this.data = data
  }

  resetData = () => {
    this.data = []
  }
}
