import { makeAutoObservable } from "mobx"

import { InstanceConfigRecord } from "@framework/types/app"

export class InstanceConfigStore {
  config: InstanceConfigRecord[] | null = null

  isLoading: boolean = false

  error: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  updateConfig = (config: InstanceConfigRecord[]) => {
    this.config = config
  }
}

export default InstanceConfigStore
