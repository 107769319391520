import { autorun, toJS, makeAutoObservable } from "mobx"

import config from "@root/config"
import { AppUIConfig, AppUIConfigData } from "@framework/types/theme"

const isFullAccess = config.FULL_ACCESS_MODE

export class AppUIConfigStore {
  config: Partial<AppUIConfigData> | null = null

  isLoading: boolean = false

  error: string | null = null

  constructor() {
    makeAutoObservable(this)

    if (isFullAccess)
      autorun(() => {
        console.log("APP STORE: INSTANCE CONFIG", toJS(this.config))
      })
  }

  updateConfig = (config: Partial<AppUIConfig>) => {
    this.config = config
  }
}

export default AppUIConfigStore
