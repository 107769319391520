import { makeAutoObservable } from "mobx"

import knowledgeService from "@services/knowledge.service"
import { KnowledgeDatatypeFilters } from "@framework/types/knowledge"
import { DataSourceName } from "@framework/types/upload"

import DataTypesStore from "./data-types.store"
import {
  transformAppendItemsForm,
  transformDeleteItemsForm,
} from "./assign-data-type.utils"

export class DataTypesController {
  datatypesStore: DataTypesStore

  constructor(injections: { datatypesStore: DataTypesStore }) {
    makeAutoObservable(this)
    this.datatypesStore = injections.datatypesStore
  }

  loadAllDataTypes = async (
    query?: string,
    filters?: KnowledgeDatatypeFilters
  ) => {
    const store = this.datatypesStore
    try {
      store.isLoading = true
      store.error = null

      const response = await knowledgeService.getAllDataTypes(query, filters)

      store.storeData(response.data.data ?? [])
    } catch (error: any) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
  }

  updateDataTypes = async (
    sourceName: DataSourceName,
    sources: string[],
    dataTypeIds: string[]
  ) => {
    const store = this.datatypesStore
    try {
      store.isLoading = true
      store.error = null

      await Promise.all(
        dataTypeIds.map((dataTypeId) =>
          knowledgeService.updateDataTypes(
            dataTypeId,
            transformAppendItemsForm(sourceName, sources) as any
          )
        )
      )
    } catch (error: any) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  reassignDataTypes = async (
    sourceName: DataSourceName,
    sources: string[],
    addToDataTypeIds: string[],
    deleteFromDataTypeIds: string[]
  ) => {
    const store = this.datatypesStore
    try {
      store.isLoading = true
      store.error = null

      await Promise.all(
        deleteFromDataTypeIds.map((dataTypeId) =>
          knowledgeService.updateDataTypes(
            dataTypeId,
            transformDeleteItemsForm(sourceName, sources) as any
          )
        )
      )

      await Promise.all(
        addToDataTypeIds.map((dataTypeId) =>
          knowledgeService.updateDataTypes(
            dataTypeId,
            transformAppendItemsForm(sourceName, sources) as any
          )
        )
      )
    } catch (error: any) {
      store.error = "Unexpected error"
    } finally {
      store.isLoading = false
    }
    return store.error
  }
}

export default DataTypesController
