import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"
import moment from "moment"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Button from "@components/ui/Button/Button"
import Checkbox from "@components/ui/Checkbox/Checkbox"
import { UserRequest, UserRequestStatuses } from "@framework/types/security"
import { MMM_DD_YYYY } from "@framework/constants/global"

import ContextMenu from "./UserRequestContextMenu"

import styles from "./index.module.sass"

interface UserRequestRowProps {
  userRequest: UserRequest
  selected?: boolean
  onSelect?: (id: string) => void
  onApprove?: (id: string) => void
  onDecline?: (id: string) => void
}

export const UserRequestRow: React.FC<UserRequestRowProps> = observer(
  ({ userRequest, selected, onSelect, onApprove, onDecline }) => {
    const { email, status, id, createdAt } = userRequest

    const handleContextAction = (action: string) => {
      if (action === "approve") onApprove?.(id)
      if (action === "decline") onDecline?.(id)
    }

    return (
      <Row className={clsx(styles.row)}>
        <Column key="selection" align="center">
          <Checkbox id={id} checked={selected} onClick={() => onSelect?.(id)} />
        </Column>

        <Column key="email" align="center" justify="start">
          <p>{email}</p>
        </Column>

        <Column key="actions" align="center" justify="start">
          <ContextMenu
            userRequest={userRequest}
            onAction={handleContextAction}
          />
        </Column>

        <Column key="approve" align="center" justify="start">
          <Button
            color="primary"
            variant="outlined"
            size="medium"
            onClick={() => onApprove?.(id)}
            disabled={status !== UserRequestStatuses.PENDING}
          >
            Approve
          </Button>
        </Column>

        <Column key="status" align="center" justify="start">
          {status}
        </Column>

        <Column key="created_date" align="center" justify="start">
          {moment(createdAt).format(MMM_DD_YYYY)}
        </Column>
      </Row>
    )
  }
)

export default UserRequestRow
