import clsx from "clsx"
import React from "react"

import styles from "./ModalFooterContainer.module.sass"

export interface ControlFooterProps {
  className?: string
}

export const ModalFooterContainer: React.FC<ControlFooterProps> = ({
  className,
  children,
}) => <div className={clsx(styles.root, className)}>{children}</div>

export default ModalFooterContainer
