import { observer } from "mobx-react-lite"
import React from "react"

import Button from "@components/ui/Button/Button"
import useCollapsible from "@components/ui/Collapsable/useCollapsible"
import Icon from "@components/ui/Icon/Icon"

export const CollapseAllButton: React.FC = observer(() => {
  const collapsible = useCollapsible()

  return (
    <Button
      variant="outlined"
      color="default"
      size="tiny"
      onClick={() => collapsible.toggleAll()}
    >
      {collapsible.isAnyActive ? "Collapse All" : "Open All"}{" "}
      <Icon name="arrow-up" rotateAngle={collapsible.isAnyActive ? 0 : 180} />
    </Button>
  )
})

export default CollapseAllButton
