import clsx from "clsx"
import React from "react"

import styles from "./List.module.sass"

type ListOverflow = "initial" | "hidden" | "auto"

export const listGutterOptions = ["0", "4", "8", "16", "24", "32"] as const

export type ListGutter = (typeof listGutterOptions)[number]

export const listDirectionOptions = ["column", "row"] as const

export type ListDirection = (typeof listDirectionOptions)[number]

type ListAlign = "initial" | "flex-start" | "center" | "flex-end" | "stretch"

type ListJustify =
  | "initial"
  | "flex-start"
  | "center"
  | "flex-end"
  | "space-between"

export const listWrapOptions = ["nowrap", "wrap"] as const

export type ListWrap = (typeof listWrapOptions)[number]

export interface ListProps extends React.HTMLProps<HTMLDivElement> {
  gutter?: ListGutter
  direction?: ListDirection
  align?: ListAlign
  justify?: ListJustify
  overflow?: ListOverflow
  wrap?: ListWrap
}

export const List = ({
  children,
  className,
  gutter = "16",
  direction = "column",
  align = "initial",
  justify = "initial",
  overflow = "auto",
  wrap = "nowrap",
  ...rest
}: ListProps) => {
  const rootClassName = clsx(
    styles.root,
    styles[`gutter-${gutter}`],
    styles[`direction-${direction}`],
    styles[`align-${align}`],
    styles[`justify-${justify}`],
    styles[`overflow-${overflow}`],
    styles[`wrap-${wrap}`],
    className
  )
  return (
    <div className={rootClassName} {...rest}>
      {children}
    </div>
  )
}

export default List
