export const delay = <T = string | undefined>(
  timeout: number,
  data: T
): Promise<T> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(data), timeout)
  })

export const runTask = <T = any>(task: () => T): Promise<T> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(task()), 0)
  })
