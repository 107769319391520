import { ModalsTypes } from "@components/modals/constants"
import ModalsFactory, {
  ModalsFactoryProps,
} from "@components/modals/ModalsFactory"

export interface ComponentProps<K, P> {
  id: K | null
  props?: P
}

export const Component = <
  K extends ModalsTypes,
  P extends ModalsFactoryProps[ModalsTypes]
>({
  id,
  props,
}: ComponentProps<K, P>) => {
  if (id && ModalsFactory[id] && props) {
    return ModalsFactory[id](props as any)
  }

  return null
}
