import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import Text from "@components/ui/Typography/Text"
import Avatar from "@components/ui/Avatar/Avatar"

import styles from "./AvatarPickerButton.module.sass"

const AvatarPickerButton: React.FC<{
  avatarName?: string
  onClick?: React.MouseEventHandler
}> = observer(({ avatarName, onClick }) => {
  const { avatarsStore } = useStore()

  const data = avatarsStore.getAvatarByName(avatarName)

  return (
    <button type="button" className={styles.root} onClick={onClick}>
      <Avatar src={data?.imageUrl} width={38} height={38} />

      <div className={styles.label}>
        <Text variant="caption2" color="text50Color">
          Avatar
        </Text>
        {data != null ? (
          <Text variant="h4" className={styles.name}>
            {data.name}
          </Text>
        ) : (
          <Text variant="h4" color="text70Color" className={styles.name}>
            Select...
          </Text>
        )}
      </div>
    </button>
  )
})

export default AvatarPickerButton
