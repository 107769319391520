import moment from "moment"
import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store"
import Col from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"

import ContextMenu from "./ContextMenu"

interface ApiKeysTableRowProps {
  apiKeyId: string
}

const ApiKeysTableRow: React.FC<ApiKeysTableRowProps> = observer(
  ({ apiKeyId }) => {
    const {
      apiKeysStore: { apiKeys },
    } = useStore()

    const apiKey = apiKeys.find((key) => key.id === apiKeyId)

    if (!apiKey) return null

    const { name, createdAt } = apiKey

    return (
      <Row>
        <Col key="name" align="center">
          {name}
        </Col>
        <Col key="actions" align="center">
          <ContextMenu apiKeyId={apiKeyId} />
        </Col>
        <Col key="apiKeys" align="center">
          ••••••••••••••••••••••••
        </Col>
        <Col key="createdAt" align="center" justify="center">
          {moment(createdAt).format("MMM DD, YYYY")}
        </Col>
      </Row>
    )
  }
)

export default ApiKeysTableRow
