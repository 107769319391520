import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import Container from "@components/ui/Container/Container"
import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import CollapsibleContextProvider from "@components/ui/Collapsable/CollapsibleContext"
import Button from "@components/ui/Button/Button"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Icon from "@components/ui/Icon/Icon"

import InstanceConfigRecordForm from "./InstanceConfigRecordForm"

import styles from "./index.module.sass"

export const InstanceConfigPage: React.FC = observer(() => {
  const navigate = useNavigate()

  const { appConfigController } = useController()
  const {
    appStore: { instanceConfig },
  } = useStore()

  const modal = useModal(ModalsTypes.CREATE_INSTANCE_CONFIG_MODAL)

  const init = () => {
    appConfigController.loadAllInstanceConfigurations()
  }

  const handleAddConfig = () => {
    modal.showModal({ callback: init })
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <CollapsibleContextProvider singleActive>
      <MainLayout noPadding>
        <Container>
          <div className={styles.root}>
            <EntityPageHeaderLayout
              className={styles.header}
              left={
                <BackButton onClick={() => navigate("../")}>
                  Instance configuration
                </BackButton>
              }
              right={
                <Button
                  color="primary"
                  size="big"
                  onClick={handleAddConfig}
                  before={<Icon name="plus" />}
                >
                  Add config
                </Button>
              }
            />

            {instanceConfig.config == null ? (
              instanceConfig.isLoading ? (
                <Loader size="huge" primary fluid />
              ) : (
                <NotFound>Failed to load config</NotFound>
              )
            ) : (
              instanceConfig.config.map((it) => (
                <InstanceConfigRecordForm config={it} key={it.id} />
              ))
            )}
          </div>
        </Container>
      </MainLayout>
    </CollapsibleContextProvider>
  )
})

export default InstanceConfigPage
