import { autorun, makeAutoObservable, toJS } from "mobx"

import { ExpertAnswerData } from "@framework/types/knowledge"
import { PaginationListMeta } from "@framework/types/utils"
import config from "@root/config"

const isFullAccess = config.FULL_ACCESS_MODE

export default class ExpertAnswersListStore {
  constructor() {
    makeAutoObservable(this)

    if (isFullAccess) autorun(() => console.log(toJS(this.data)))
  }

  isLoading = false

  loadingError: string | null = null

  data: ExpertAnswerData[] = []

  meta: {
    total: number
    totalPages: number
  } = { total: 0, totalPages: 0 }

  get dataMap(): Map<string, ExpertAnswerData> {
    return new Map(this.data.map((it) => [it.id, it]))
  }

  appendFromResponse = (data: ExpertAnswerData[], meta: PaginationListMeta) => {
    const { pageNum, pageSize, total, totalPages } = meta
    const from = pageNum * pageSize
    for (let i = from; i < data.length; i += 1) {
      this.data[i] = data[i + from]
    }

    this.meta = { total, totalPages }
  }

  resetData = () => {
    this.data = []
    this.meta = { total: 0, totalPages: 0 }
  }

  get getAnswerById() {
    const { dataMap } = this
    return (answerId: string) => dataMap.get(answerId) ?? null
  }

  get getAnswerByIndex() {
    const { data } = this
    return (index: number) => data[index] ?? null
  }
}
