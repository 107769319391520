import React from "react"
import moment from "moment"

import UserCard from "@components/ui/UserCard/UserCard"
import Column from "@components/ui/BaseTable/Column"
import { SolutionData } from "@framework/types/solution"
import Row from "@components/ui/BaseTable/Row"
import VerticalItemList from "@pages/admin/components/VerticalItemList"
import { AccessType, AvatarDetailsData } from "@framework/types/avatar"
import Chip from "@components/ui/Chip/Chip"

import ContextMenu from "../ContextMenu/ContextMenu"

interface AvatarTableRowProps {
  avatar: AvatarDetailsData
}

const { Private, Public } = AccessType

const AvatarTableRow: React.FC<AvatarTableRowProps> = ({ avatar }) => {
  const {
    name,
    description,
    imageUrl,
    userCount,
    expertCount,
    createdAt,
    accessType,
    adminCount,
    productSolutions,
  } = avatar

  const renderPrivacy = () => {
    const isPrivate = accessType === Private
    return (
      <Chip variant="rounded" color={isPrivate ? "gold" : "green"} uppercase>
        {accessType}
      </Chip>
    )
  }

  return (
    <Row>
      <Column key="name" align="center">
        <UserCard
          fullName={name}
          // eslint-disable-next-line react/no-danger
          metaInfo={<span dangerouslySetInnerHTML={{ __html: description }} />}
          avatarSrc={imageUrl}
        />
      </Column>
      <Column key="actions" align="center">
        <ContextMenu avatarId={avatar.id} isPublic={accessType === Public} />
      </Column>
      <Column key="users" align="center">
        {userCount}
      </Column>
      <Column key="experts" align="center">
        {expertCount}
      </Column>
      <Column key="admins" align="center">
        {adminCount}
      </Column>
      <Column key="privacy" align="center">
        {renderPrivacy()}
      </Column>
      <Column key="createdAt" align="center">
        {moment(createdAt).format("MMM DD, YYYY")}
      </Column>
      <Column key="solutions" align="center">
        {!!productSolutions?.length && (
          <VerticalItemList<SolutionData> items={productSolutions} />
        )}
      </Column>
    </Row>
  )
}

export default AvatarTableRow
