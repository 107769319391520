import React from "react"
import { observer } from "mobx-react-lite"

import useToggle from "@components/hooks/useToggle"
import SidebarPopup from "@components/modals/components/SidebarPopup"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import { useStore } from "@store/index"
import NotFound from "@components/ui/NotFound/NotFound"
import Text from "@components/ui/Typography/Text"
import useEventListener from "@components/hooks/useEventListener"

import AvatarPickerButton from "./AvatarPickerButton"
import AvatarItem from "./AvatarItem"

import styles from "./index.module.sass"

interface FilterSidebarProps {
  value?: string
  onChange?: (newAvatar: string) => void
}

const AvatarPickerSidebar = observer((props: FilterSidebarProps) => {
  const { value, onChange } = props

  const {
    avatarsStore: { avatars, getAvatarById },
  } = useStore()

  const { isOpened, handleToggle, setOpened } = useToggle()

  const avatar = getAvatarById(value)

  const handleSelect = (values: string) => {
    onChange?.(values)
    setOpened(false)
  }

  const handleCancel = () => {
    setOpened(false)
  }

  useEventListener({
    type: "keydown",
    disabled: !isOpened,
    listener: (e) => {
      if (!(e instanceof KeyboardEvent)) return
      if (e.key === "Escape") handleCancel()
    },
  })

  return (
    <>
      <AvatarPickerButton
        avatarName={avatar?.name}
        onClick={() => setOpened(true)}
      />

      <SidebarPopup open={isOpened} onClose={handleToggle}>
        <Templates.RollScript
          gutter="24"
          headerSocket={
            <List overflow="initial">
              <Text variant="h2" align="center">
                Select Avatar
              </Text>
            </List>
          }
        >
          {avatars?.length ? (
            avatars.map((it, idx) => (
              <React.Fragment key={it.id}>
                {idx !== 0 && <span className={styles.divider} />}

                <AvatarItem
                  data={it}
                  active={value === it.id}
                  onClick={() => handleSelect(it.id)}
                />
              </React.Fragment>
            ))
          ) : (
            <NotFound />
          )}
        </Templates.RollScript>
      </SidebarPopup>
    </>
  )
})

export default AvatarPickerSidebar
