import { makeAutoObservable } from "mobx"

import ApiUsageHistoryStore from "./api-usage-history.store"
import ApiUsageSummaryStore from "./api-usage-summary.store"
import ApiUsageTrendStore from "./api-usage-trend.store"

export default class ApiUsageAnalyticsStore {
  summaryStore: ApiUsageSummaryStore

  usageTrendStore: ApiUsageTrendStore

  usageHistoryStore: ApiUsageHistoryStore

  constructor() {
    makeAutoObservable(this)

    this.summaryStore = new ApiUsageSummaryStore()

    this.usageTrendStore = new ApiUsageTrendStore()

    this.usageHistoryStore = new ApiUsageHistoryStore()
  }
}
