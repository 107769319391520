/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"

import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"
import Avatar from "@components/ui/Avatar/Avatar"

import styles from "./index.module.sass"

interface IconCardProps {
  value: IconName
  onClick: React.MouseEventHandler
}

const IconCard: React.FC<IconCardProps> = ({ value = "global", onClick }) => {
  return (
    <div className={styles.root}>
      <Avatar icon={value} className={styles.icon} />
      <div className={styles.plus} onClick={onClick}>
        <Icon name="plus" color="primary" />
      </div>
    </div>
  )
}

export default IconCard
