import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import * as yup from "yup"
import { useAlert } from "react-alert"
import omit from "lodash/omit"

import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import TextInput, { TextAreaInput } from "@components/ui/TextInput/TextInput"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import { IconName } from "@components/ui/Icon/IconName"
import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Label from "@components/ui/Label/Label"
import List from "@components/ui/List/List"
import { useController } from "@store/index"
import { DataCategoryEntity } from "@framework/types/knowledge"

import IconCard from "../IconCard"
import IconSelector from "../IconSelector"

import styles from "./DatatypeCreateForm.module.sass"

interface DatatypeCreateFormProps {
  datatype: Partial<DataCategoryEntity>
  onCancel?: () => void
}

const validationSchema = yup.object({
  name: yup
    .string()
    .required("Field required")
    .max(100)
    .min(3)
    .default("")
    .trim(),
  description: yup.string().max(1000).default("").trim(),
  iconUrl: yup.mixed<IconName>().notRequired().default("global"),
})

const ICON_LIST: IconName[] = [
  "safety-data-sheet",
  "data-sheets",
  "competitor-intelligence",
  "handbook-fill",
  "voice",
  "storage",
  "maintenance-manual",
  "engineering-design",
  "standards",
  "global",
]

type FormData = yup.InferType<typeof validationSchema>

const DatatypeCreateForm: React.FC<DatatypeCreateFormProps> = observer(
  ({ datatype, onCancel }) => {
    const [isIconSelector, setIsIconSelector] = useState(false)

    const alert = useAlert()

    const {
      knowledgeDatatypesController: { addNewDatatype, editDatatype },
      datatypesController: { loadAllDataTypes },
    } = useController()

    const isEdit = !!datatype.id

    const handleSubmit = async (values: FormData) => {
      let error: string | null
      if (!datatype.id) {
        error = await addNewDatatype(values)
      } else {
        error = await editDatatype(datatype.id, omit(values, "name"))
      }

      if (error) {
        alert.error(error)
        return
      }

      loadAllDataTypes()
      onCancel?.()
    }

    const formik = useFormik({
      initialValues: {
        ...validationSchema.getDefault(),
        ...validationSchema.cast({ ...datatype }, { stripUnknown: true }),
      },
      onSubmit: handleSubmit,
      validationSchema,
      validateOnBlur: true,
    })

    const handleIconSelect = (icon: IconName) => {
      formik.setFieldValue("iconUrl", icon)
      setIsIconSelector(false)
    }

    return (
      <form className={styles.root} onSubmit={formik.handleSubmit}>
        {isIconSelector ? (
          <IconSelector
            list={ICON_LIST}
            value={formik.values.iconUrl}
            onChange={handleIconSelect}
            onCancel={() => setIsIconSelector(false)}
          />
        ) : (
          <Templates.RollScript
            gutter="32"
            headerClassName={styles.header}
            headerSocket={
              <ModalTitle
                titleText={`${isEdit ? "Edit" : "Create"} Data Type`}
              />
            }
            footerSocket={
              <ModalFooterContainer>
                <Button variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  {isEdit ? "Save" : "Create"}
                </Button>
              </ModalFooterContainer>
            }
          >
            <List gutter="32">
              <IconCard
                value={formik.values.iconUrl}
                onClick={() => setIsIconSelector(true)}
              />

              <Label id="name" label="NAME OF DATA TYPE" uppercase>
                <TextInput
                  className={styles.input}
                  placeholder="Enter name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  disabled={isEdit}
                  name="name"
                  withError={!!(formik.touched.name && formik.errors.name)}
                  onBlur={formik.handleBlur}
                  after={
                    formik.touched.name && formik.errors.name ? (
                      <div className={styles.after}>
                        <ErrorChip
                          messagePlacement="left"
                          message={formik.errors.name}
                        />
                      </div>
                    ) : null
                  }
                />
              </Label>
              <Label id="description" label="Add description" uppercase>
                <TextAreaInput
                  placeholder="Add Description of the Data Type"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                  onBlur={formik.handleBlur}
                  withError={
                    !!(formik.touched.description && formik.errors.description)
                  }
                />
              </Label>
            </List>
          </Templates.RollScript>
        )}
      </form>
    )
  }
)

export default DatatypeCreateForm
