import React from "react"
import { observer } from "mobx-react-lite"

import { AnswerItem } from "@framework/types/search"
import { testAnswerType } from "@store/search/utils"
import NotFound from "@components/ui/NotFound/NotFound"
import { ExtendedResultType } from "@framework/constants/search-results"
import { useController, useStore } from "@store/index"
import { useSearchFlowPassageContext } from "@pages/search/SearchContext/SearchPassageContext"

import ImageResultsCard from "../TypedResultsCard/ImageResultsCard"
import TextResultsCard from "../TypedResultsCard/TextResultsCard"
import TableResultsCard from "../TypedResultsCard/TableResultsCard"

import styles from "./AnswerList.module.sass"

export interface AnswerListProps {
  items?: AnswerItem[]
  query: string
  notFound?: React.ReactNode
  answerTextMaxLines?: number
}

export const AnswerList: React.FC<AnswerListProps> = observer(
  ({ items, query, notFound = null, answerTextMaxLines }) => {
    const { openExtendedView } = useSearchFlowPassageContext()
    const { restrictionsStore: access } = useStore()

    const { dataConnectorController } = useController()

    const signAndOpenSourceURL = async (sourceUrl: string) => {
      const res = await dataConnectorController.getSignedDataURL(sourceUrl)

      if (res.status === "SUCCESS") window.open(res.data, "_blank")
    }

    if (!items?.length) {
      return <>{notFound ?? <NotFound>No answer was found</NotFound>}</>
    }

    return (
      <>
        {items.map((item) => {
          const sourceLink =
            item.value.source_type !== "expert_answer" && item.value.source

          const handleOpenExtendResult = access.isExtendedResultAvailable
            ? (tab?: ExtendedResultType) => openExtendedView?.(item.name, tab)
            : undefined

          const handleTitleClick = !access.isExtendedResultAvailable
            ? sourceLink
              ? () => signAndOpenSourceURL(sourceLink)
              : undefined
            : () => openExtendedView?.(item.name)

          const handleReadMoreClick = () =>
            openExtendedView?.(item.name, "Text")

          if (testAnswerType(item, "summaries"))
            return (
              <TextResultsCard
                id={item.name}
                className={styles.rowContainer}
                question={query}
                answer={item}
                onTitleClick={handleTitleClick}
                onReadMoreClick={handleReadMoreClick}
                onOpenExtendedView={handleOpenExtendResult}
                maxLines={answerTextMaxLines}
                key={item.name}
              />
            )

          if (testAnswerType(item, "image")) {
            return (
              <ImageResultsCard
                id={item.name}
                color="transparent"
                question={query}
                answer={item}
                className={styles.cardContainer}
                onTitleClick={handleTitleClick}
                key={item.name}
              />
            )
          }

          if (testAnswerType(item, "quants") || testAnswerType(item, "discard"))
            return (
              <TableResultsCard
                id={item.name}
                className={styles.rowContainer}
                question={query}
                answer={item}
                onTitleClick={handleTitleClick}
                key={item.name}
              />
            )
          return null
        })}
      </>
    )
  }
)

export default AnswerList
