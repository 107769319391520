import React, { useLayoutEffect, useMemo, useState } from "react"
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  IndexRange,
  InfiniteLoader,
  List,
  ListProps,
} from "react-virtualized"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { FieldProps } from "formik"

import Dropdown from "@components/prototypes/FilterSidebar/components/Dropdown/Dropdown"
import NotFound from "@components/ui/NotFound/NotFound"
import Loader from "@components/ui/Loader/BarLoader"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store/index"
import UserCard from "@components/ui/UserCard/UserCard"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import { BaseUserData } from "@framework/types/user"
import ListItem from "@components/ui/ListItem/ListItem"
import Text from "@components/ui/Typography/Text"

import styles from "./UserSelect.module.sass"

const BATCH_SIZE = 10
export interface UserSelectProps extends FieldProps<string[]> {
  label?: string
  valueKey?: keyof BaseUserData
}

const UserSelect: React.FC<UserSelectProps> = observer(
  ({ label, field, form, valueKey = "email" }) => {
    const [open, setOpen] = useState(false)

    const { value: fieldValue = [], name } = field
    const { setFieldValue } = form

    const value = [...fieldValue]

    const { adminUsersController } = useController()
    const { adminUsersStore } = useStore()

    const users = adminUsersStore.getPage(adminUsersStore.meta.pageNum)

    const handleLoadMore = async ({ startIndex, stopIndex }: IndexRange) => {
      const pageSize = Math.max(stopIndex - startIndex, 1) * 2

      return adminUsersController.loadActiveUsersPage({
        pageNum: Math.floor(startIndex / pageSize),
        pageSize,
      })
    }

    useLayoutEffect(() => {
      handleLoadMore({ startIndex: 0, stopIndex: BATCH_SIZE })
    }, [])

    const userCards: BaseUserData[] = useMemo(() => {
      return Array.from(adminUsersStore.users, ([_name, value]) => toJS(value))
    }, [users])

    const handleUserClick = (newValue: string) => {
      const foundIdx = value?.findIndex((value) => value === newValue)
      if (foundIdx === -1) {
        setFieldValue(name, [...value, newValue])
        return
      }
      value.splice(foundIdx, 1)
      setFieldValue(name, value)
    }

    const renderRow: ListProps["rowRenderer"] = ({
      index,
      key,
      parent,
      style,
    }) => {
      const user = userCards[index]
      if (!user)
        return (
          <div style={style} key={key}>
            <ListItem>
              <Text color="text50Color" variant="inherit">
                loading...
              </Text>
            </ListItem>
          </div>
        )

      const active = value?.includes(user[valueKey] as string)

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
          key={`${key}-cached`}
        >
          <div style={style} key={key}>
            <ListItem
              active={active}
              onClick={() => handleUserClick(user[valueKey] as string)}
            >
              <UserCard
                fullName={`${user.firstName} ${user.lastName}`}
                metaInfo={user.email}
                avatarSrc={user.avatarURL}
              />
            </ListItem>
          </div>
        </CellMeasurer>
      )
    }

    const cache = useMemo(() => {
      return new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 58,
      })
    }, [])

    const isChecked = !!value?.length

    const handleUncheck = (e: React.MouseEvent) => {
      if (isChecked) setFieldValue(name, undefined)
      else setOpen(true)
      e.stopPropagation()
    }

    return (
      <Dropdown
        title={label}
        opened={open}
        onClick={() => setOpen((value) => !value)}
        before={
          <CheckboxWithLabel
            checked={!!value?.length}
            onClick={handleUncheck}
          />
        }
      >
        <Templates.RollScript className={styles.root}>
          <div className={styles.container}>
            {adminUsersStore.meta.total === 0 ? (
              adminUsersStore.isLoading ? (
                <Loader size="large" fluid />
              ) : (
                <NotFound>No Users found</NotFound>
              )
            ) : (
              <InfiniteLoader
                isRowLoaded={({ index }) => userCards[index] != null}
                loadMoreRows={handleLoadMore}
                rowCount={adminUsersStore.meta.total}
                minimumBatchSize={BATCH_SIZE}
                threshold={BATCH_SIZE}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer>
                    {({ width }) => (
                      <List
                        rowCount={userCards.length}
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        rowHeight={cache.rowHeight}
                        rowRenderer={renderRow}
                        height={300}
                        width={width}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </div>
        </Templates.RollScript>
      </Dropdown>
    )
  }
)

export default UserSelect
