import React from "react"
import { observer } from "mobx-react-lite"

import useSearchDetailsContext from "../SearchContext/useSearchDetailsContext"
import MixedResult from "./MixedResult"
import SourceOrientedResults from "./SourceOrientedResults"

const SourcePassages: React.FC = observer(() => {
  const { resultView } = useSearchDetailsContext()

  return (
    <>
      {resultView === "SUMMARY_VIEW" && <MixedResult />}
      {resultView === "SOURCE_VIEW" && <SourceOrientedResults />}
    </>
  )
})

export default SourcePassages
