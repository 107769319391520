import clsx from "clsx"
import React, { CSSProperties } from "react"

import styles from "./index.module.sass"

export interface ColumnProps {
  width?: React.CSSProperties["width"]
  height?: React.CSSProperties["height"]
  className?: string
  align?: "center" | "start" | "end"
  justify?: "start" | "center" | "end"
  style?: CSSProperties
}

export const Column: React.FC<ColumnProps> = React.memo(
  ({
    width,
    height,
    className,
    align = "start",
    justify = "start",
    style,
    children,
  }) => {
    return (
      <div
        className={clsx(
          styles.column,
          styles[`align-${align}`],
          styles[`justify-${justify}`],
          className
        )}
        style={{ ...style, ...{ width, height } }}
      >
        {children}
      </div>
    )
  }
)

export default Column
