/* eslint-disable react/no-array-index-key */
import React from "react"

import Table from "./Table"
import Row from "./Row"
import TextColumn from "./TextColumn"

export interface SimpleTableProps {
  data: string[][]
  className?: string
}

export const SimpleTable: React.FC<SimpleTableProps> = ({
  data,
  className,
}) => (
  <Table className={className}>
    {data.map((rowData, rowIdx) => (
      <Row key={rowIdx}>
        {rowData.map((value, colIdx) => {
          const width = value.length ? value.length * 2 : 50
          const minWidth = width > 320 ? 320 : width
          return (
            <TextColumn minWidth={minWidth} key={colIdx}>
              {value}
            </TextColumn>
          )
        })}
      </Row>
    ))}
  </Table>
)

export default SimpleTable
