/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React from "react"

import { ColorType } from "@framework/types/utils"

import type { IconName } from "./IconName"

import styles from "./Icon.module.scss"

export interface IconProps extends React.HTMLProps<HTMLElement> {
  name?: IconName
  className?: string
  onClick?: React.MouseEventHandler<HTMLElement>
  style?: React.CSSProperties
  color?: ColorType | "transparent"
  circle?: boolean
  inline?: boolean
  rotateAngle?: number
}

export const Icon: React.FC<IconProps> = ({
  name = "name",
  className,
  onClick,
  style,
  color,
  circle,
  rotateAngle: rotateDeg = 0,
  inline = false,
  children,
  ...rest
}) => (
  <i
    alt={name}
    {...rest}
    style={{ transform: `rotateZ(${rotateDeg}deg)`, ...style }}
    onClick={onClick}
    className={clsx(
      styles.icon,
      styles[`icon-${name}`],
      styles[`color-${color}`],
      className,
      {
        [styles.circle]: circle,
        [styles.inline]: inline,
        [styles.clickable]: !!onClick,
      }
    )}
  >
    {children}
  </i>
)

export default Icon
