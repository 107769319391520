import { useCallback, useLayoutEffect, useRef } from "react"

const useEvent = <T extends (...args: any[]) => any>(handler: T): T => {
  const handlerRef = useRef<T>()

  useLayoutEffect(() => {
    handlerRef.current = handler
  })

  return useCallback((...args) => {
    if (!handlerRef.current) throw new Error()
    const fn = handlerRef.current
    return fn(...args)
  }, []) as unknown as T
}

export default useEvent
