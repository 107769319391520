import { makeAutoObservable } from "mobx"

import {
  ConnectingDataConnector,
  DataConnectorSourceName,
  DataSourceName,
} from "@framework/types/upload"
import { ConnectWebsiteFormType } from "@pages/upload/ConnectWebsite/types"
import uploadService, {
  ConnectWebsiteErrorResponse,
} from "@services/upload.service"
import googleService from "@services/google.service"
import {
  CONNECT_AMAZON_S3_REDIRECT_URI,
  CONNECT_CONFLUENCE_REDIRECT_URI,
  CONNECT_EC2_SMB_REDIRECT_URI,
  CONNECT_GOOGLE_DRIVE_REDIRECT_URI,
  CONNECT_SHAREPOINT_REDIRECT_URI,
} from "@pages/upload/AddDataConnector/constants"
import { SuccessResponse, FailResponse } from "@framework/types/common"

import RootStore from "../RootStore"

export class UploadSourceStore {
  rootStore: RootStore

  errorMessage: string | null = null

  uploadRequestError: string | null = null

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  get uploadStore() {
    return this.rootStore.uploadStore
  }

  isWebsiteConnecting = false

  uploadRequestSuccess: string | null = null

  connectWebsite = async (form: ConnectWebsiteFormType) => {
    this.isWebsiteConnecting = true
    this.uploadRequestError = null
    try {
      const response = await uploadService.connectWebsite(form)

      if (response.data.status === "SUCCESS") {
        return null
      }
    } catch (error: any) {
      const { response } = error

      if (!response) {
        return "Unknown error"
      }

      const responseData: ConnectWebsiteErrorResponse = response.data

      if (responseData.status === "VALIDATION_FAILED") {
        return "Validation failed"
      }
      if (responseData.status === "BODY_INCOMPLETE") {
        return "Validation failed"
      }
      return "Unknown error"
    } finally {
      this.isWebsiteConnecting = false
    }
    return null
  }

  sourceDeleteError: string | null = null

  isSourceDeleting: boolean = false

  deleteSourceUpload = async (sourceName: DataSourceName, id: string) => {
    this.isSourceDeleting = true
    this.sourceDeleteError = null
    try {
      await uploadService.removeDataSource(sourceName, id)
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.sourceDeleteError = "Unknown error while deleting"
        return
      }
    } finally {
      this.isSourceDeleting = false
    }
  }

  isDataConnectorLoading: boolean = false

  dataConnectorError: string | null = null

  connectGoogleDrive = async (
    name: string,
    clientId: string,
    clientSecret: string,
    code: string
  ) => {
    this.isDataConnectorLoading = true
    this.dataConnectorError = null
    try {
      const response = await googleService.getGoogleRefreshTokenByCode({
        code,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: CONNECT_GOOGLE_DRIVE_REDIRECT_URI,
        grant_type: "authorization_code",
      })

      if (response.status !== 200 || !response.data.refresh_token) {
        throw Error("NO_REFRESH_TOKEN_PROVIDED")
      }

      const { access_token, refresh_token } = response.data

      const addResponse = await uploadService.addDataConnector({
        token: access_token,
        refreshToken: refresh_token,
        clientId,
        clientSecret,
        connectorType: "google-drive",
        name,
      })
      return { payload: addResponse.data.data }
    } catch (error: Error | any) {
      if (error.message === "NO_REFRESH_TOKEN_PROVIDED") {
        this.dataConnectorError = "Your google drive account already connected"
      } else {
        this.dataConnectorError = "Failed to connect account for unknown reason"
      }
      return { error: this.dataConnectorError }
    } finally {
      this.isDataConnectorLoading = false
    }
  }

  connectSharepoint = async (
    name: string,
    clientId: string,
    clientSecret: string,
    code: string
  ) => {
    this.isDataConnectorLoading = true
    this.dataConnectorError = null
    try {
      const addResponse = await uploadService.addDataConnector({
        name,
        code,
        clientId,
        clientSecret,
        connectorType: "sharepoint",
        redirectUrl: CONNECT_SHAREPOINT_REDIRECT_URI,
      })

      return { payload: addResponse.data.data }
    } catch (error: Error | any) {
      if (error.message === "NO_REFRESH_TOKEN_PROVIDED") {
        this.dataConnectorError = "Your sharepoint account already connected"
      } else {
        this.dataConnectorError = "Failed to connect account for unknown reason"
      }
      return { error: this.dataConnectorError }
    } finally {
      this.isDataConnectorLoading = false
    }
  }

  connectConfluence = async (params: {
    name: string
    clientId: string
    clientSecret: string
    code: string
    connectorType?: DataConnectorSourceName
    redirectUrl?: string
    baseUrl?: string
  }) => {
    this.isDataConnectorLoading = true
    this.dataConnectorError = null
    try {
      const addResponse = await uploadService.addDataConnector({
        name: params.name,
        code: params.code,
        clientId: params.clientId,
        clientSecret: params.clientSecret,
        connectorType: params.connectorType || "atlassian-confluence",
        redirectUrl: params.redirectUrl || CONNECT_CONFLUENCE_REDIRECT_URI,
        baseUrl: params.baseUrl,
      })
      return { payload: addResponse.data.data }
    } catch (error: Error | any) {
      if (error.message === "NO_REFRESH_TOKEN_PROVIDED") {
        this.dataConnectorError = "Your confluence account already connected"
      } else {
        this.dataConnectorError = "Failed to connect account for unknown reason"
      }
      return { error: this.dataConnectorError }
    } finally {
      this.isDataConnectorLoading = false
    }
  }

  connectEC2SMB = async (params: {
    name: string
    clientId: string
    clientSecret: string
    baseURL: string
  }): Promise<SuccessResponse<ConnectingDataConnector> | FailResponse> => {
    this.isDataConnectorLoading = true
    this.dataConnectorError = null
    try {
      const addResponse = await uploadService.addDataConnector({
        connectorType: "smb",
        name: params.name,
        clientId: params.clientId,
        clientSecret: params.clientSecret,
        baseUrl: params.baseURL,
        redirectUrl: CONNECT_EC2_SMB_REDIRECT_URI,
        refreshToken: "",
        token: "",
      })
      return { status: "SUCCESS", data: addResponse.data.data }
    } catch (error: Error | any) {
      if (error.message === "NO_REFRESH_TOKEN_PROVIDED") {
        this.dataConnectorError = "Your account already connected"
      } else {
        this.dataConnectorError = "Failed to connect account for unknown reason"
      }
      return { status: "FAILED", message: this.dataConnectorError }
    } finally {
      this.isDataConnectorLoading = false
    }
  }

  connectAmazonS3 = async (params: {
    name: string
    bucketName: string
    clientId: string
    clientSecret: string
    region: string
  }): Promise<SuccessResponse<ConnectingDataConnector> | FailResponse> => {
    this.isDataConnectorLoading = true
    this.dataConnectorError = null
    try {
      const addResponse = await uploadService.addDataConnector({
        connectorType: "amazon-aws-s3",
        name: params.name,
        baseUrl: params.bucketName,
        clientId: params.clientId,
        clientSecret: params.clientSecret,
        region: params.region,
        redirectUrl: CONNECT_AMAZON_S3_REDIRECT_URI,
        refreshToken: "",
        token: "",
      })
      return { status: "SUCCESS", data: addResponse.data.data }
    } catch (error: Error | any) {
      if (error.message === "NO_REFRESH_TOKEN_PROVIDED") {
        this.dataConnectorError = "Your account already connected"
      } else {
        this.dataConnectorError = "Failed to connect account for unknown reason"
      }
      return { status: "FAILED", message: this.dataConnectorError }
    } finally {
      this.isDataConnectorLoading = false
    }
  }
}

export default UploadSourceStore
