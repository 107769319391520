import { useCallback, useMemo } from "react"

import { ModalsTypes } from "./constants"
import { ModalsFactoryProps } from "./ModalsFactory"
import { useModalContext } from "./ModalContext"

const useModal = <Type extends ModalsTypes>(id: Type) => {
  const modalsStore = useModalContext()
  const { activeModalId, openModal, closeModal } = modalsStore

  const showModal = useCallback(
    (props: ModalsFactoryProps[Type]) => {
      openModal(id, props)
    },
    [openModal, id]
  )

  const hideModal = useCallback(() => {
    if (id) {
      closeModal(id)
    }
  }, [id, closeModal])

  const hideAllModals = useCallback(() => {
    closeModal()
  }, [closeModal])

  return useMemo(
    () => ({
      showModal,
      hideModal,
      hideAllModals,
      isOpen: activeModalId === id,
      isAnyOpened: activeModalId != null,
    }),
    [activeModalId, showModal, hideModal, hideAllModals]
  )
}

export default useModal
