import React from "react"
import { observer, useLocalObservable } from "mobx-react-lite"

import AppUIConfigStore from "@store/app/app-ui-config.store"
import { useStore } from "@store/index"

import AppUIConfigManager, {
  IColorSchemeSwitchContextState,
} from "./AppUIConfigManager"
import AppColorSchemeProvider from "./AppColorSchemeProvider"
import AppConfigProvider from "./AppConfigProvider"

export const AppThemeManagerContext =
  React.createContext<IColorSchemeSwitchContextState | null>(null)

const AppThemeProvider: React.FC<{
  uiConfigStore?: AppUIConfigStore
}> = observer(({ children }) => {
  const { appStore } = useStore()

  const themeManager = useLocalObservable(() => {
    return new AppUIConfigManager({ uiConfigStore: appStore.uiConfigStore })
  })

  return (
    <AppThemeManagerContext.Provider value={themeManager}>
      <AppConfigProvider config={themeManager.config}>
        <AppColorSchemeProvider colorScheme={themeManager.activeColorScheme}>
          {children}
        </AppColorSchemeProvider>
      </AppConfigProvider>
    </AppThemeManagerContext.Provider>
  )
})

export default AppThemeProvider

export const useAppThemeManager = () => {
  const context = React.useContext(AppThemeManagerContext)
  if (context == null) throw new Error("AppThemeManagerContext was not found")
  return context
}
