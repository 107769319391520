import { makeAutoObservable } from "mobx"
import keyBy from "lodash/keyBy"

import { UserAction } from "@framework/types/security"

export class UserActionsStore {
  constructor() {
    makeAutoObservable(this)
  }

  actions: Record<string, UserAction> = {}

  isLoading = false

  error: string | null = null

  setActions = (actions: UserAction[]) => {
    this.actions = keyBy(actions, (it) => it.id)
  }

  updateAction = (action: UserAction) => {
    this.actions[action.id] = action
  }

  setLoading = (value: boolean = true) => {
    this.isLoading = value
  }

  setError = (error: string | null = null) => {
    this.error = error
  }

  get actionIds() {
    return Object.keys(this.actions)
  }
}

export default UserActionsStore
