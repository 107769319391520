import clsx from "clsx"
import React, { CSSProperties, ReactNode } from "react"

import styles from "./index.module.sass"

export interface RowProps {
  highlightable?: boolean
  className?: string
  children?: ReactNode
  style?: CSSProperties
}

export const Row = React.forwardRef<HTMLTableRowElement, RowProps>(
  ({ className, highlightable = false, children, style }, ref) => (
    <tr
      ref={ref}
      className={clsx(
        styles.row,
        { [styles.highlightable]: highlightable },
        className
      )}
      style={style}
    >
      {children}
    </tr>
  )
)

export default Row
