import { makeAutoObservable, toJS } from "mobx"

import SearchAnswersDataStore from "./search-answers-data.store"
import SearchPassagesStore from "./search-passages.store"
import SearchSourcesStore from "./search-sources.store"
import AnswerFeedbackStore from "./answer-feedback.store"
import SummaryEntityStore from "./summary-entity.store"
import ISearchEntityBlockStore, { SummaryQueryType } from "./types"
import { trimAnswerDataForFeedback } from "./utils"

export class SearchSummaryBlockStore implements ISearchEntityBlockStore {
  // injections

  searchAnswersData: SearchAnswersDataStore | null = null

  searchPassages: SearchPassagesStore | null = null

  searchSources: SearchSourcesStore | null = null

  searchSummary: SummaryEntityStore

  feedback: AnswerFeedbackStore | null = null

  // constructor

  constructor(config: { id: string }) {
    this.id = config.id
    this.timestamp = Date.now()

    this.searchSummary = new SummaryEntityStore({ loading: true })

    makeAutoObservable(this)
  }

  // state

  id: string

  timestamp: number

  queryType: SummaryQueryType = "DEFAULT"

  messages: string[] = []

  // actions

  // actions

  serialize = () => {
    const answer = this.searchAnswersData?.rawAnswer
      ? trimAnswerDataForFeedback(toJS(this.searchAnswersData.rawAnswer))
      : null

    const summary = this.searchSummary.summary ?? null

    return JSON.stringify({
      answer,
      summary,
    })
  }

  addMessage = (message: string) => {
    this.messages = [...this.messages, message]
  }

  setAnswersDataStore = (instance: SearchAnswersDataStore) => {
    this.searchAnswersData = instance
  }

  setPassagesStore = (instance: SearchPassagesStore) => {
    this.searchPassages = instance
  }

  setSourcesStore = (instance: SearchSourcesStore) => {
    this.searchSources = instance
  }

  setFeedback = (feedbackStore: AnswerFeedbackStore) => {
    this.feedback = feedbackStore
  }
}

export default SearchSummaryBlockStore
