import { makeAutoObservable } from "mobx"

import { DataSourceStatusEntity } from "@framework/types/upload"

import EntityCollection from "../utils/EntityCollection"

export default class AvatarUploadedFilesGroupsStore {
  constructor() {
    makeAutoObservable(this)
  }

  collectionsMap: Map<string, EntityCollection<DataSourceStatusEntity>> =
    new Map()

  getCollection = (dataCategoryId: string) => {
    if (!this.collectionsMap.has(dataCategoryId))
      this.resetCollections(dataCategoryId)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.collectionsMap.get(dataCategoryId)!
  }

  resetCollections = (collectionKey: string) => {
    this.collectionsMap.set(collectionKey, new EntityCollection())
  }
}
