import React from "react"
import { useNavigate } from "react-router-dom"

import Button, { ButtonProps } from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"

export const BackButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => navigate(-1)}
      variant="text"
      size="medium"
      before={<Icon name="line-arrow-down" rotateAngle={90} />}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default BackButton
