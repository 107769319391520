import React from "react"
import clsx from "clsx"

import { ColorType } from "@framework/types/utils"

import styles from "./Box.module.scss"

export type TooltipContainerProps = {
  color?: ColorType
  className?: string
}

export const Box: React.FC<TooltipContainerProps> = ({
  color = "default",
  children,
  className,
}) => (
  <div className={clsx(styles.root, styles[`color-${color}`], className)}>
    {children}
  </div>
)

export default Box
