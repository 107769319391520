import { makeAutoObservable, autorun } from "mobx"

import { AUTH_REFRESH_TOKEN_STORAGE_KEY } from "@framework/constants/auth"

class AccessTokenStore {
  accessToken: string | null = null

  refreshToken: string | null = this.getRefreshToken()

  constructor() {
    makeAutoObservable(this)

    autorun(() => {
      this.updateRefreshToken()
    })
  }

  private updateRefreshToken() {
    if (this.refreshToken) {
      localStorage.setItem(AUTH_REFRESH_TOKEN_STORAGE_KEY, this.refreshToken)
    } else {
      localStorage.removeItem(AUTH_REFRESH_TOKEN_STORAGE_KEY)
    }
  }

  private getRefreshToken() {
    return localStorage.getItem(AUTH_REFRESH_TOKEN_STORAGE_KEY)
  }
}

export default new AccessTokenStore()
