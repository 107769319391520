import { reaction, makeAutoObservable } from "mobx"
import _groupBy from "lodash/groupBy"
import _map from "lodash/map"

import { sortResultOptions } from "@framework/constants/search-results"
import { SearchFilterType } from "@framework/types/search"
import { Option } from "@framework/types/utils"

export class FilterEntityStore {
  // injections

  // constructor

  constructor(config: {
    query: string
    avatarName: string
    avatarId: string
    productSolutionId?: string
    oldThreadId?: string
  }) {
    this.searchAvatar = config.avatarName
    this.searchAvatarId = config.avatarId
    this.searchQuery = config.query
    this.productSolutionId = config.productSolutionId ?? ""
    this.oldThreadId = config.oldThreadId

    makeAutoObservable(this)

    reaction(
      () => this.orderByOptions,
      (newOrderByOptions) => this.setOrderBy(newOrderByOptions[0] ?? "")
    )
  }

  // state

  searchQuery: string = ""

  orderBy: string = sortResultOptions[0].name

  orderByOptions: Option[] = sortResultOptions

  searchAvatar: string

  searchAvatarId: string

  productSolutionId: string

  oldThreadId?: string

  toJson = (): SearchFilterType => {
    return {
      query: this.searchQuery,
      channel: this.searchAvatar,
      avatarId: this.searchAvatarId,
      productSolutionId: this.productSolutionId,
      oldThreadId: this.oldThreadId,
    }
  }

  // actions

  setOrderBy = (orderBy: Option<any>) => {
    this.orderBy = orderBy.name
  }

  setOrderByOptions = (orderByOptions: Option[]) => {
    this.orderByOptions = orderByOptions
  }

  // utils
}

export default FilterEntityStore
