import clsx from "clsx"
import React from "react"
import { observer } from "mobx-react-lite"
import toString from "lodash/toString"

import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import { useMatrixContext } from "./MatrixContext"
import { cellIdFromPoint, pointToCode } from "./utils"
import Icon from "../Icon/Icon"
import TruncatedText from "../Typography/TruncatedText"
import MagicTextLoader from "../Loader/MagicTextLoader"

import styles from "./Cell.module.sass"

type CellProps = {
  columnIndex: number
  rowIndex: number
}

const Cell: React.FC<CellProps> = observer(({ columnIndex, rowIndex }) => {
  const { editor, selectedRange } = useMatrixContext()

  const longTextModal = useModal(ModalsTypes.LONG_TEXT_MODAL)

  const currentCellPoint = { x: columnIndex, y: rowIndex }

  const handleMouseDown = (e: React.MouseEvent) => {
    editor.submitCell()

    selectedRange.startRange(currentCellPoint)
  }

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (selectedRange.rangeStarted) {
      selectedRange.updateRange(currentCellPoint)
    }
  }

  const handleMouseDoubleClick = (e: React.MouseEvent) => {
    editor.editCell(currentCellPoint)
  }

  const cell = editor.findCell(currentCellPoint)

  const isFailed = !!cell?.state.error

  const renderValue = (value: any) => {
    const content = value == null ? "" : toString(value)

    if (content?.length > 450) {
      return (
        <TruncatedText
          maxLines={2}
          truncated
          onReadMoreClick={() =>
            longTextModal.showModal({
              title: pointToCode(currentCellPoint),
              content,
            })
          }
        >
          {content}
        </TruncatedText>
      )
    }

    return value
  }

  return (
    <div
      className={clsx(styles.root, { [styles.withError]: isFailed })}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onDoubleClick={handleMouseDoubleClick}
      aria-label={cellIdFromPoint(currentCellPoint)}
      role="button"
      tabIndex={-1}
    >
      {cell != null && (
        <>
          <div className={clsx(styles.content)}>
            {cell.state.isLoading ? (
              <MagicTextLoader>Processing...</MagicTextLoader>
            ) : (
              renderValue(cell.value)
            )}
          </div>

          {cell.validation?.type === "OPTION" && (
            <button
              type="button"
              className={clsx(styles.arrow)}
              onClick={(e) => {
                editor.editCell(currentCellPoint)
                e.stopPropagation()
              }}
            >
              <Icon name="arrow-down" />
            </button>
          )}
        </>
      )}
      {isFailed && (
        <span className={styles.error} title={cell.state.error || undefined}>
          !
        </span>
      )}
    </div>
  )
})

export default Cell
